import React, { useEffect, useState } from "react"
import CustomHeader from "../../Components/Header/CustomHeader"
import { RiShieldCrossFill } from "react-icons/ri"
import {
  Typography,
  Box,
  Grid,
  Button,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
} from "@material-ui/core"
import { Controller, useForm } from "react-hook-form"
import DocumentsHandler from "../../Components/Commons/DocumentHandling/DocumentsHandler"
import AutocompleteCreatable from "../../Components/FormComponents/AutocomlpleteCreatable"
import { removeItemByIndex, toBase64, updateItemByIndex } from "../../utils/utils"
import { addProduct, getAllActivities, getAllDocuments, getAllProfessions } from "../../Axios/Call/ClientCredential"
import CommonDateTimePicker from "../../Components/FormComponents/CommonDateTimePicker"
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral"
import { useHistory } from "react-router-dom"
import moment from "moment"
import "moment/locale/fr"
import CommonSelect from "../../Components/FormComponents/CommonSelect"
import CommonTextField from "../../Components/FormComponents/CommonTextField"
import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular"
import { useDispatch } from "react-redux"
import * as actionCreator from "../../Store/action/index"
moment.locale("fr")

const NewProduit = () => {
  const [rqDocuments, setrqDocuments] = useState([])
  const [rqDocList, setrqDocList] = useState([])
  const [attached, setattached] = useState([])
  const [successapi, setsuccessapi] = useState(false)
  const [errorapi, seterrorapi] = useState(false)
  const [professions, setprofessions] = useState([])
  const [selectedProfessions, setSelectedProfessions] = useState([])
  const [activities, setactivities] = useState([])
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const history = useHistory()
  // attached rows creation

  const createDataDocumentAttached = (name, description, type, starting_date, ending_date, base64) => {
    return {
      name: name,
      description: description,
      type,
      starting_date: starting_date,
      base64: base64,
      ...(ending_date && { ending_date: ending_date }),
    }
  }

  const createProductData = async (
    code,
    description,
    market,
    activity_id,
    madelin,
    produit_independant,
    profession_ids,
    type,
    idcc,
    commercial_id,
    status,
    starting_date,
    ending_date,
    document_justification_products,
    products_documents
  ) => {
    const djp = JSON.stringify(document_justification_products)
    const pdocs = JSON.stringify(products_documents)

    return {
      code: code,
      description: description,
      market: market,
      activity_id: activity_id,
      madelin: madelin,
      produit_independant: produit_independant,
      profession_ids: profession_ids,
      type: type,
      idcc: idcc,
      commercial_id: commercial_id,
      status: status,
      starting_date: starting_date,
      ending_date: ending_date,
      document_justification_products: djp,
      products_documents: pdocs,
    }
  }

  const createOption = (id, value) => {
    return { id, value }
  }

  // required docs handling

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "300px",
      },
    },
  }

  const handleChangeRQDocsList = (event) => {
    const {
      target: { value },
    } = event
    setrqDocuments(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    )
  }

  const handleChangeProfessions = (event) => {
    setSelectedProfessions(event.target.value)
  }

  // form

  const { register, handleSubmit, errors, setValue, getValues, watch, control } = useForm({
    mode: "all",
    defaultValues: {
      market: "",
      activity_id: "",
    },
  })

  const submitNewProduct = async (data) => {
    setLoading(true)
    console.log(data)
    const tmprqdoclist = rqDocuments

    console.log(rqDocuments)

    const tmpattached = await Promise.all(
      attached.map(async (a) => {
        const tmp = await toBase64(a.file)
        return createDataDocumentAttached(a.name, a.description, a.type, a.starting_date, a.ending_date, tmp)
      })
    )

    console.log(tmpattached)

    let tmpData = createProductData(
      data.code,
      data.description,
      data.market,
      data.activity_id,
      data.madelin,
      data.produit_independant,
      selectedProfessions.join(","),
      data.type,
      data.idcc,
      data.commercial_id,
      data.status,
      data.starting_date,
      data.ending_date,
      tmprqdoclist,
      tmpattached
    )

    console.log(tmpData)

    addProduct(await tmpData)
      .then((res) => {
        var id = res.data.id
        setsuccessapi(true)
        dispatch(actionCreator.populateProducts())
        if (data.redirect) {
          setTimeout(() => {
            history.push(`/Produits/${id}/Garanties`)
          }, 1200)
        } else {
          setTimeout(() => {
            history.push("/Produits")
          }, 1200)
        }
      })
      .catch((error) => {
        console.warn(error)
        seterrorapi(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  //   hook
  useEffect(() => {
    setLoading(true)
    getAllProfessions().then((res) => {
      const tmp = res.data.map((p) => createOption(p.id, p.name))
      setprofessions(tmp)
    })

    getAllActivities().then((res) => {
      const tmp = res.data.map((p) => createOption(p.id, p.name))
      setactivities(tmp)
    })

    getAllDocuments().then((res) => {
      console.log(res.data)
      setrqDocList(res.data.filter((d) => d.type === "justification"))
    })
  }, [])

  useEffect(() => {
    if (professions.length > 0 && activities.length > 0 && rqDocList.length > 0) {
      setLoading(false)
    }
  }, [professions, activities, rqDocList])

  useEffect(() => {}, [watch("market"), watch("code"), attached])

  return (
    <>
      {successapi && <AlertGeneral color="" setTrigger={setsuccessapi} description="Nouveau produit enregistré !" />}
      {errorapi && <AlertGeneral color="danger" setTrigger={seterrorapi} description="Une erreur est survenue." />}
      <CustomHeader
        icon={<RiShieldCrossFill size={40} style={{ fill: "#c31d23", marginRight: ".8rem" }} />}
        pageName={"Nouveau produit"}
      />
      {loading ? (
        <LoaderCircular />
      ) : (
        <>
          <Typography
            variant="body1"
            style={{
              color: "#c23d31",
              textAlign: "left",
              marginLeft: "3.3rem",
              marginTop: "-1em",
            }}
          >
            Entrez toutes les informations concernant le nouveau produit
          </Typography>
          <Box className="spacer2" />

          <form style={{ maxWidth: "980px", margin: "0 auto" }} onSubmit={handleSubmit(submitNewProduct)} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h3" style={{ textAlign: "left" }}>
                  Informations produit
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="code"
                  render={(field) => <CommonTextField field={field} label="Code produit" error={errors.code} />}
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="description"
                  render={(field) => <CommonTextField field={field} label="Libellé" error={errors.description} />}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="market"
                  render={(field) => (
                    <CommonSelect
                      field={field}
                      label="Marché"
                      options={[
                        { id: null, value: " " },
                        { id: "individual", value: "Individuel" },
                        { id: "company", value: "Collectif" },
                      ]}
                      error={errors.market}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="activity_id"
                  render={(field) => (
                    <CommonSelect
                      field={field}
                      label="Domaine d'activité"
                      options={[{ id: null, value: "" }, ...activities]}
                      error={errors.activity_id}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box className="spacer2" />
            {/*  */}
            {getValues("market") === "individual" && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3" style={{ textAlign: "left" }}>
                    Individuel
                  </Typography>
                </Grid>

                {/*                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="profession_id"
                    render={(field) => (
                      <CommonSelect
                        field={field}
                        label="Statut professionnel"
                        options={[{ id: null, value: "" }, ...professions]}
                        error={errors.profession_id}
                      />
                    )}
                  />
                </Grid>*/}

                <Grid item xs={12} md={6}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-checkbox-label" style={{ paddingLeft: ".8rem", marginTop: "-3px" }}>
                      Statut professionnel
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      required
                      value={selectedProfessions}
                      onChange={handleChangeProfessions}
                      ref={register("professions")}
                      input={<OutlinedInput label="Statut professionnel" fullWidth />}
                      renderValue={
                        (selected) =>
                          selected
                            .map((i) => {
                              return professions?.find((profession) => profession.id === i)?.value
                            })
                            .join(", ")
                        // selected.map(i => i).join(", ")
                      }
                      MenuProps={MenuProps}
                      style={{ textAlign: "left" }}
                    >
                      {professions?.map((profession) => {
                        return (
                          <MenuItem key={profession?.value} value={profession?.id}>
                            <Checkbox checked={selectedProfessions?.indexOf(profession?.id) > -1} />
                            <ListItemText primary={profession?.value} />
                          </MenuItem>
                        )
                      })}
                      f
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="madelin"
                    defaultValue={3}
                    render={(field) => (
                      <CommonSelect
                        field={field}
                        label="Madelin"
                        options={[
                          { id: null, value: "" },
                          { id: 1, value: "Non" },
                          { id: 0, value: "Oui" },
                        ]}
                        error={errors.madelin}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="produit_independant"
                    defaultValue={0}
                    render={(field) => (
                      <CommonSelect
                        field={field}
                        label="Produit indépendant"
                        options={[
                          { id: 0, value: "Non", label: "Non" },
                          { id: 1, value: "Oui", label: "Oui" }

                        ]}
                        error={errors.produit_independant}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            {getValues("market") === "company" && (
              <Grid container spacing={2}>
                {" "}
                <Grid item xs={12}>
                  <Typography variant="h3" style={{ textAlign: "left" }}>
                    Collectif
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    control={control}
                    name="idcc"
                    defaultValue={""}
                    rules={{
                      required: getValues("type") === "CCN" ? true : false,
                    }}
                    render={(field) => <CommonTextField label="Code IDCC" field={field} error={errors.idcc} />}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    control={control}
                    rules={{
                      required: getValues("type") === "CCN" ? true : false,
                    }}
                    name="type"
                    render={(field) => (
                      <CommonSelect
                        field={field}
                        label="Type"
                        options={[
                          { id: null, value: "" },
                          { id: "standard", value: "Standard" },
                          { id: "ccn", value: "CCN" },
                        ]}
                        error={errors.type}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    control={control}
                    name="commercial_id"
                    render={() => (
                      <AutocompleteCreatable
                        label="Cible commerciale"
                        field={{ value: getValues("commercial_id") }}
                        onSelectOption={(x) => setValue("commercial_id", x)}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            {/*  */}
            <Box className="spacer2" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h3" style={{ textAlign: "left" }}>
                  Commercialisation
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="status"
                  render={(field) => (
                    <CommonSelect
                      field={field}
                      label="État"
                      options={[
                        { id: null, value: "" },
                        { id: "active", value: "Actif" },
                        { id: "inactive", value: "Inactif" },
                      ]}
                      error={errors.status}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  render={(field) => (
                    <CommonDateTimePicker
                      field={field}
                      label={"Début de commercialisation"}
                      name={"starting_date"}
                      error={errors.starting_date}
                      required={true}
                    />
                  )}
                  control={control}
                  name="starting_date"
                  defaultValue={null}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  name="ending_date"
                  defaultValue={null}
                  rules={{}}
                  render={(field) => (
                    <CommonDateTimePicker
                      field={field}
                      label={"Fin de commercialisation"}
                      name={"ending_date"}
                      error={errors.ending_date}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControl style={{ width: "100%" }} required>
                  <InputLabel id="demo-multiple-checkbox-label" style={{ paddingLeft: ".8rem", marginTop: "-3px" }}>
                    Documents justificatifs
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    required
                    value={rqDocuments}
                    onChange={handleChangeRQDocsList}
                    ref={register("document_justification_products")}
                    input={<OutlinedInput label=" Documents justificatifs" fullWidth />}
                    renderValue={(selected) =>
                      selected
                        .map((i) => {
                          let doc = rqDocList.filter((doc) => doc.id === i)
                          return doc[0].name
                        })
                        .join(", ")
                    }
                    MenuProps={MenuProps}
                    style={{ textAlign: "left" }}
                  >
                    {rqDocList.map((doc) => (
                      <MenuItem key={doc.name} value={doc.id}>
                        <Checkbox checked={rqDocuments.indexOf(doc.id) > -1} />
                        <ListItemText primary={doc.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Box className="spacer2" />
            <DocumentsHandler
              name={"Nouveau document"}
              newProductCode={getValues("code")}
              attached={attached}
              newAttached={(x) => setattached([...attached, x])}
              deleteAttached={(x) => setattached(removeItemByIndex(attached, x))}
              updateAttached={(x) => setattached(updateItemByIndex(attached, x.updatedData, x.selectedIndex))}
            />
            <Box className="spacer2" />
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Button
                  className="buttonGreenStyle"
                  style={{ maxWidth: "none" }}
                  type="submit"
                  ref={register("redirect")}
                  onClick={() => setValue("redirect", false)}
                >
                  ENREGISTRER
                </Button>
              </Grid>
              <Grid item xs={12} md={7}>
                <Button
                  className="buttonGeneralStyle"
                  style={{ maxWidth: "none" }}
                  type="submit"
                  ref={register("redirect")}
                  onClick={() => setValue("redirect", true)}
                >
                  ENREGISTRER ET AJOUTER UN GARANTIE
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  )
}

export default NewProduit
