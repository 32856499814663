import React from "react"
import { Typography, Grid, TextField } from "@material-ui/core"
import moment from "moment"

moment.locale("fr", {
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "dd-MM-yyyy",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
})

const SimpleDatePicker = ({ value, name, error, label, labelCustom, size, onChange, fullWidth = false }) => {
  return (
    <>
      <Grid container>
        {labelCustom && (
          <Grid items xs={12} align="left">
            <Typography component="span" variant="body1" style={{ margin: 0 }}>
              {labelCustom}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} style={{ display: "flex", justifyContent: "start" }}>
          <TextField
            style={{ width: "100%", maxWidth: fullWidth ? undefined : "330px" }}
            name={name}
            label={label}
            value={value}
            error={!!error}
            helperText={error?.message}
            onChange={onChange}
            format={"yyyy-MM-dd"}
            variant="outlined"
            type="date"
            size={size}
            InputLabelProps={{ shrink: true }}
            fullWidth={fullWidth}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default SimpleDatePicker
