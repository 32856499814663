import React, { useEffect, useState } from "react"
import CustomHeader from "../../Components/Header/CustomHeader"
import { RiFileShield2Line } from "react-icons/ri"
import {
  Typography,
  Box,
  Grid,
  Button,
  TextField,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core"
import TextFieldCustom from "../../Components/FormComponents/TextFieldCustom"
import DocumentsHandler from "../../Components/Commons/DocumentHandling/DocumentsHandler"
import CustomModal from "../../Components/Commons/Modal/CustomModal"
import { Controller, useForm } from "react-hook-form"
import { removeItemByIndex, updateItemByIndex } from "../../utils/utils"
import { useParams, useHistory } from "react-router-dom"
import { FaRegTrashAlt } from "react-icons/fa"
import CommonDateTimePicker from "../../Components/FormComponents/CommonDateTimePicker"
import { toBase64 } from "../../utils/utils"
import { addPackage, addPackagesAssignations, getAllGuaranties } from "../../Axios/Call/ClientCredential"
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral"
import moment from "moment"
import "moment/locale/fr"
import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular"
import Back from "../../Components/Commons/Back/Back"
import { useSelector } from "react-redux"
import { set } from "date-fns"
moment.locale("fr")

const NewPack = () => {
  const params = useParams()
  const history = useHistory()

  // ici ce sont toutes le G du pack qui ont été sélectionnées
  const [baseList, setbaseList] = useState([])
  const [renfortList, setrenfortList] = useState([])
  const [optionList, setoptionList] = useState([])
  const [PCList, setPCList] = useState([])
  // ici ce sont les noms des G dans le Select
  const [tmpBase, settmpBase] = useState([])
  const [tmpSelectRenfortList, settmpSelectRenfortList] = useState([])
  const [tmpSelectOptionList, settmpSelectOptionList] = useState([])
  const [tmpSelectPCList, settmpSelectPCList] = useState([])
  // ici ce sont les G qui ont été sélectionnées pour faire une assoc dans le créateur d'assoc
  const [openAssoc, setopenAssoc] = useState(false)
  const [opendelete, setopendelete] = useState(false)

  const [assocList, setassocList] = useState([])
  const [attached, setattached] = useState([])

  const [focus, setfocus] = useState("")

  const [successapi, setsuccessapi] = useState(false)
  const [errorapi, seterrorapi] = useState(false)
  const [loading, setloading] = useState(false)

  const produits = useSelector((state) => state.user.products)

  function findName(id) {
    return produits.find((p) => p.id === id * 1)?.code
  }

  const { register, handleSubmit, setValue, control } = useForm({
    mode: "all",
  }) // initialise the hook**

  // createData
  const createPack = (
    product_id,
    code,
    name,
    need_level,
    starting_date,
    ending_date,
    description,
    package_documents
  ) => {
    return {
      product_id,
      code,
      name,
      need_level,
      starting_date,
      ending_date,
      description,
      package_documents,
    }
  }

  const createDataAssoc = (base_id, renforts_option_id, base_name, renforts_names, options_names, pc_names) => {
    return {
      base_id,
      renforts_option_id,
      // down here data to display in table only
      base_name,
      renforts_names,
      options_names,
      pc_names,
    }
  }

  const createPackageDocument = async (name, description, type, starting_date, ending_date, guarantee_id, b64, for_supp) => {
    const base64 = await toBase64(b64)
    return {
      name,
      description,
      type,
      starting_date,
      ending_date,
      guarantee_id,
      base64,
      for_supp
    }
  }

  const checkIfContainName = (arr, value) => {
    return arr?.filter((i) => i === value).length > 0
  }

  const handleChangeRenfort = (event) => {
    const {
      target: { value },
    } = event
    settmpSelectRenfortList(typeof value === "string" ? value.split(",") : value)
  }

  const handleChangeOption = (event) => {
    const {
      target: { value },
    } = event
    settmpSelectOptionList(typeof value === "string" ? value.split(",") : value)
  }

  const handleChangePC = (event) => {
    const {
      target: { value },
    } = event
    settmpSelectPCList(typeof value === "string" ? value.split(",") : value)
  }

  // assoc

  const submitNewAssoc = () => {
    const tmpRenf = []
    const tmpRenfFull = []
    tmpSelectRenfortList.forEach((x) => {
      renfortList.map((y) => {
        if (y.name === x) {
          tmpRenf.push(y.id)
          tmpRenfFull.push(y)
        }
      })
    })

    const tmpOpt = []
    const tmpOptFull = []
    tmpSelectOptionList.forEach((x) => {
      optionList.map((y) => {
        if (y.name === x) {
          tmpOpt.push(y.id)
          tmpOptFull.push(y)
        }
      })
    })

    const tmpPC = []
    const tmpPCFull = []
    tmpSelectPCList.forEach((x) => {
      PCList.map((y) => {
        if (y.name === x) {
          tmpPC.push(y.id)
          tmpPCFull.push(y)
        }
      })
    })

    // console.log("renforts => ", tmpRenf);
    // console.log("options => ", tmpOpt);

    const newAssoc = createDataAssoc(
      tmpBase.id,
      tmpRenf.concat(tmpOpt).concat(tmpPC),
      // et pour le tableau
      tmpBase.name,
      tmpRenfFull?.map((x) => x.name)?.join(" "),
      tmpOptFull?.map((x) => x.name)?.join(" "),
      tmpPCFull?.map((x) => x.name)?.join(" ")
    )
    settmpBase("")
    // settmpRenfortList([]);
    // settmpOptionList([]);
    setassocList([...assocList, newAssoc])
    handleCloseAssoc()
  }

  const handleOpenDeleteAssoc = (x) => {
    setfocus(x)
    setopendelete(true)
  }

  const deleteAssoc = () => {
    console.log(focus)
    setassocList(removeItemByIndex(assocList, focus))
    setopendelete(false)
    setfocus("")
  }

  const handleCloseAssoc = () => {
    setfocus("")
    settmpBase("")
    settmpSelectRenfortList([])
    settmpSelectOptionList([])
    settmpSelectPCList([])
    setopenAssoc(false)
  }

  // forms hooks

  const submit = async (elt, docs) => {
    setloading(true)
    addPackage(
      createPack(
        params.id * 1,
        elt.code,
        elt.name,
        elt.need_level,
        elt.starting_date,
        elt.ending_date,
        elt.description,
        JSON.stringify(docs)
      )
    )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setsuccessapi(true)
          console.log("res package =", res)

          console.log(assocList,'list')

          assocList.map((a) => {
            console.log("a.renforts_option_id =", a.renforts_option_id)
            return addPackagesAssignations({
              base_id: a.base_id,
              package_id: res.data.id * 1,
              ...(a.renforts_option_id.length > 0 && { renfort_option_ids: JSON.stringify(a.renforts_option_id) }),
            }).then((response) => {
              setTimeout(() => {
                history.push(`/Produits/${params.id}/Packs/`)
              }, 2000)
            })
          })
        }
      })
      .finally(() => {
        setloading(false)
      })
  }

  const submitNewPack = async (data) => {
    console.log("data", data)
    console.log("attached", attached)
    let tmpAttached = []

    for (let index = 0; index < attached.length; index++) {
      console.log(attached[index])
      let tmp = await createPackageDocument(
        attached[index].name,
        attached[index].description,
        attached[index].type,
        attached[index].starting_date,
        attached[index].ending_date,
        attached[index].guarantee_id,
        attached[index].file,
        attached[index].for_supp
      )
      tmpAttached.push(tmp)
    }

    submit(data, tmpAttached)
  }

  // useEffect

  useEffect(() => {
    getAllGuaranties().then((res) => {
      const tmpData = res.data.filter((g) => g.product_id === params.id * 1)
      const tmpBases = []
      const tmpOptions = []
      const tmpRenforts = []
      const tmpPCs = []
      tmpData.map((g) => {
        if (g.nature === "base") {
          return tmpBases.push(g)
        } else if (g.nature === "renfort") {
          return tmpRenforts.push(g)
        } else if (g.nature === "option") {
          return tmpOptions.push(g)
        } else if (g.nature === "supp") {
          return tmpPCs.push(g)
        }
      })
      setoptionList(tmpOptions)
      setrenfortList(tmpRenforts)
      setbaseList(tmpBases)
      setPCList(tmpPCs)
    })
  }, [])

  return (
    <>
      {successapi && <AlertGeneral setTrigger={setsuccessapi} color="" description="Pack enregistré !" />}
      {errorapi && <AlertGeneral setTrigger={seterrorapi} color="danger" description="Une erreur est survenue" />}

      <CustomHeader
        icon={
          <RiFileShield2Line
            size={40}
            style={{
              color: "c31d23",
              cursor: "pointer",
              marginRight: ".8rel",
            }}
          />
        }
        pageName={`Produit ${findName(params.id)} - Nouveau pack`}
        userName={"Username here"}
      />
      <Typography variant="body1" style={{ color: "#c23d31", textAlign: "left" }}>
        Création d'un nouveau pack
      </Typography>

      <Box className="spacer1" />
      <Back />
      <Box className="spacer2" />
      {loading ? (
        <Box style={{ paddingTop: "3rem" }}>
          <LoaderCircular />
        </Box>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" style={{ textAlign: "left" }}>
                Informations
              </Typography>
              <form style={{ margin: "0 auto" }} onSubmit={handleSubmit(submitNewPack)}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextFieldCustom
                      name="code"
                      label="Code pack"
                      ref={register("code")}
                      onChange={(e) => setValue("code", e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextFieldCustom
                      name="name"
                      label="Libellé du pack"
                      ref={register("name")}
                      onChange={(e) => setValue("name", e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextFieldCustom
                      name="need_level"
                      label="Niveau de besoin"
                      ref={register("need_level")}
                      onChange={(e) => setValue("need_level", e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      render={(field) => (
                        <CommonDateTimePicker
                          field={field}
                          label={"Début de commercialisation"}
                          name={"starting_date"}
                          required={true}
                        />
                      )}
                      control={control}
                      name="starting_date"
                      defaultValue={null}
                      rules={{}}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name={"ending_date"}
                      render={(field) => <CommonDateTimePicker field={field} label={"Fin de commercialisation"} />}
                      control={control}
                      defaultValue={null}
                      rules={{}}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      minRows={4}
                      label="Description du pack (facultatif)"
                      name="description"
                      ref={register("description")}
                      onChange={(e) => setValue("description", e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      bottom: "-80px",
                      paddingBottom: "20px",
                      position: "absolute",
                      width: "calc(100% - 295px)",
                    }}
                  >
                    <Button className="buttonGreenStyle" type="submit" style={{ marginTop: ".1rem" }}>
                      ENREGISTRER LE PACK
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>

            <Grid item xs={1}>
              <Box className="spacer2" />
            </Grid>

            <Grid item xs={12} md={5}>
              <DocumentsHandler
                name={"Nouveau document"}
                newProductCode={1}
                attached={attached}
                newAttached={(x) => setattached([...attached, x])}
                deleteAttached={(x) => setattached(removeItemByIndex(attached, x))}
                updateAttached={(x) => setattached(updateItemByIndex(attached, x.updatedData, x.selectedIndex))}
                // assignementList={[...baseList, ...optionList, ...renfortList]} // DISABLED
              />
            </Grid>
          </Grid>

          <Box className="spacer2" />

          {/* creation d'associations */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography variant="h3" style={{ textAlign: "left", marginBottom: ".8rem" }}>
                Association de garanties
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button size="small" className="buttonGeneralStyle" onClick={() => setopenAssoc(true)}>
                CRÉER UNE ASSOCIATION
              </Button>
            </Grid>
          </Grid>

          <Box container style={{ border: "1px dashed lightgrey" }} />

          <CustomModal open={openAssoc} handleClose={() => handleCloseAssoc()} size="550px">
            <Typography variant="h3" style={{ textAlign: "left" }}>
              Nouvelle association
            </Typography>
            <Typography variant="body2" style={{ textAlign: "left", color: "#c31d23" }}>
              Création d'une nouvelle association de garantie
            </Typography>

            <Box className="spacer2" />

            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h3" style={{ textAlign: "left" }}>
                  Base
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" style={{ textAlign: "left", color: "#c31d23" }}>
                  Sélectionner la garantie de base pour cette association
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  select
                  variant="outlined"
                  size="large"
                  fullWidth
                  label="Bases"
                  value={tmpBase?.name}
                  onChange={(e) => settmpBase(e.target.value)}
                >
                  {baseList?.map((base) => (
                    <MenuItem key={base.name} value={base}>
                      <Typography variant="body1">{base.name}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Box className="spacer2" />

              <Grid items xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h3" style={{ textAlign: "left" }}>
                    Renforts
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" style={{ textAlign: "left", color: "#c31d23" }}>
                    Sélectionner les renforts pour cette association
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-checkbox-label" style={{ paddingLeft: ".8rem", marginTop: "-3px" }}>
                      Renforts
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={tmpSelectRenfortList}
                      onChange={handleChangeRenfort}
                      input={<OutlinedInput label="Structure de cotisation" fullWidth />}
                      renderValue={(selected) => {
                        return selected.join(", ")
                      }}
                      style={{ textAlign: "left" }}
                    >
                      {renfortList?.map((base) => (
                        <MenuItem key={base.name} value={base.name}>
                          <Checkbox checked={checkIfContainName(tmpSelectRenfortList, base?.name)} />
                          <ListItemText primary={base.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Box className="spacer2" />
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="h3" style={{ textAlign: "left" }}>
                      Options
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" style={{ textAlign: "left", color: "#c31d23" }}>
                      Sélectionner les options pour cette association
                    </Typography>
                  </Grid>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-checkbox-label" style={{ paddingLeft: ".8rem", marginTop: "-3px" }}>
                      Options
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={tmpSelectOptionList}
                      onChange={handleChangeOption}
                      input={<OutlinedInput label="Structure de cotisation" fullWidth />}
                      renderValue={(selected) => selected.join(", ")}
                      style={{ textAlign: "left" }}
                    >
                      {optionList?.map((base) => (
                        <MenuItem key={base.name} value={base.name}>
                          <Checkbox checked={checkIfContainName(tmpSelectOptionList, base?.name)} />
                          <ListItemText primary={base.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Box className="spacer2" />
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="h3" style={{ textAlign: "left" }}>
                      Produits complémentaires
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" style={{ textAlign: "left", color: "#c31d23" }}>
                      Sélectionner les produits complémentaires pour cette association
                    </Typography>
                  </Grid>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-checkbox-label" style={{ paddingLeft: ".8rem", marginTop: "-3px" }}>
                      Produits complémentaires
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={tmpSelectPCList}
                      onChange={handleChangePC}
                      input={<OutlinedInput label="Structure de cotisation" fullWidth />}
                      renderValue={(selected) => selected.join(", ")}
                      style={{ textAlign: "left" }}
                    >
                      {PCList?.map((base) => (
                        <MenuItem key={base.name} value={base.name}>
                          <Checkbox checked={checkIfContainName(tmpSelectPCList, base?.name)} />
                          <ListItemText primary={base.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                className="buttonGeneralStyle"
                style={{ marginTop: "2rem", margin: "2rem auto 0" }}
                onClick={() => submitNewAssoc()}
              >
                CRÉER
              </Button>
            </Grid>
          </CustomModal>

          {/* delete */}
          <CustomModal open={opendelete} handleClose={() => setopendelete(false)} size={400}>
            <Typography variant="h3">Suppression de l'association</Typography>
            <Typography variant="body1" style={{ margin: "1rem 0 2rem" }}>
              Attention, cette association sera supprimée !
            </Typography>
            <Button className="buttonGeneralStyle" fullWidth onClick={() => deleteAssoc()}>
              Supprimer
            </Button>
          </CustomModal>

          {/* tableau associations */}
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    <Typography component="p" variant="h6">
                      Base
                    </Typography>
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    <Typography component="p" variant="h6">
                      Renforts
                    </Typography>
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    <Typography component="p" variant="h6">
                      Options
                    </Typography>
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    <Typography component="p" variant="h6">
                      Prestations complémentaires
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assocList?.map((row, index) => {
                  return (
                    <TableRow
                      key={row.base}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row" align="left">
                        <Typography variant="body2">{row.base_name}</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        <Typography variant="body2">{row.renforts_names}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">{row.options_names}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">{row.pc_names}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          <FaRegTrashAlt
                            size={25}
                            style={{ fill: "#f24e1e", cursor: "pointer" }}
                            onClick={() => handleOpenDeleteAssoc(index)}
                          />{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}

export default NewPack
