import { authApiAxios, clientCredentialAxios } from "../Axios/AxiosInstance";
import {useEffect, useRef} from "react";

export function removeItemByIndex(arr, index) {
  return arr?.filter((_, i) => i !== index);
}

export function updateItemByIndex(arr, item, index) {
  const newArr = [...arr];
  newArr[index] = item;
  return newArr;
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
    reader.onload = () => resolve(reader.result);
  });
}

export const getBase64FromUrl = async (url) => {
  console.log(url);
  const bgn = "http://mba-api.vigee.fr/";
  const data = clientCredentialAxios.get(`${bgn}${url}`);
  // var blob = await data.blob();
  // blob = blob.slice(0, blob.size, "application/pdf");
  // return new Promise((resolve) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   reader.onloadend = () => {
  //     const base64data = reader.result;
  //     resolve(base64data);
  //   };
  // });
  return data;
};

export function upperCaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    },[value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

export function isObjectEmpty(object) {
    return Object.keys(object).length === 0
}

export function calculateHTBaseOnPure(pure, coefZone, frais_gestion, commission) {
    return parseFloat(((pure * coefZone) / (1 - (frais_gestion + commission))).toFixed(3))
}

export function calculatePureBasedOnHT(HT, coefZone, frais_gestion, commission) {
    return parseFloat(((HT * (1 - (commission + frais_gestion))) / coefZone).toFixed(3))
}

export function calculateTTCBasedOnHT(HT, tva) {
    return parseFloat((HT * (1 + tva)).toFixed(3))
}

export function calculateHTbasedOnTTC(TTC, tva) {
    return parseFloat((TTC / (1 + tva)).toFixed(3))
}

export function checkKeyDown (e) {
  if (e.code === 'Enter') e.preventDefault();
}

export function defaultLabelDisplayedRows({ from, to, count }) {
    return `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`;
}