import React, { useEffect, useState } from "react"
import CustomHeader from "../../Components/Header/CustomHeader"
import { RiShieldCrossFill } from "react-icons/ri"
import { BsSearch, BsPencilSquare } from "react-icons/bs"
import { AiOutlineEuroCircle } from "react-icons/ai"
import { FaRegTrashAlt } from "react-icons/fa"

import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TablePagination,
} from "@material-ui/core"
import CustomModal from "../../Components/Commons/Modal/CustomModal"
import { useHistory, useParams } from "react-router-dom"
import { deleteGuarantee, getAllActivities, getAllGuaranties, getProduct } from "../../Axios/Call/ClientCredential"
import Back from "../../Components/Commons/Back/Back"
import moment from "moment"
import "moment/locale/fr"
import { useSelector } from "react-redux"
import UserComponentLayout from "../../Hoc/UserComponentLayout"
import { defaultLabelDisplayedRows } from "../../utils/utils"
import EnhancedTableHead from "../../Components/Table/EnhancedTableHead"
import { getComparator, stableSort } from "../../utils/tableSort"
moment.locale("fr")

const Garanties = () => {
  const [open, setopen] = useState(false)
  const [toDelete, settoDelete] = useState(null)
  const [searchValue, setsearchValue] = useState("")
  const [activities, setactivities] = useState([])
  const [product, setproduct] = useState([])
  const [garanties, setGaranties] = useState([])
  const [filteredGaranties, setFilteredGaranties] = useState([])
  const [loading, setloading] = useState(false)
  const history = useHistory()
  const params = useParams()

  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("")
  const isAdmin = useSelector((state) => state.user.userData.type === "admin")

  const produits = useSelector((state) => state.user.products)
  const warrantyNatures = useSelector((state) => state.user.warrantyNatures)

  const createRow = (id, code, name, nature, activity_id, market, starting_date, ending_date) => {
    return {
      id: id,
      code: code,
      name: name,
      nature: warrantyNatures.find((warrantyNature) => warrantyNature.id === nature)?.value,
      activity_id: activity_id,
      market: market === "individual" ? "Individuel" : "Collectif",
      starting_date: starting_date !== null ? moment(starting_date).format("DD/MM/yyyy") : "-",
      ending_date: ending_date !== null ? moment(ending_date).format("DD/MM/yyyy") : "-",
    }
  }

  const headCells = [
    {
      id: "code",
      label: "Code garantie",
      isOrderable: true,
    },
    {
      id: "name",
      label: "Garantie",
      isOrderable: true,
    },
    {
      id: "nature",
      label: "Nature",
      isOrderable: true,
    },
    {
      id: "activity_id",
      label: "Domaine d'activité",
      isOrderable: true,
    },
    {
      id: "market",
      label: "Marché",
      isOrderable: true,
    },
    {
      id: "starting_date",
      label: "Début de commercialisation",
      isOrderable: true,
    },
    {
      id: "ending_date",
      label: "Fin de commercialisation",
      isOrderable: true,
    },
    {
      id: "rates",
      label: "Tarifs",
      isOrderable: false,
    },
    isAdmin && {
      id: "actions",
      label: "Actions",
      isOrderable: false,
    },
  ]

  const handlesetsearchValue = (val) => {
    setsearchValue(val)
  }

  function findName(id) {
    return produits.find((p) => p.id === id * 1)?.description
  }

  const handleToDelete = (id) => {
    setopen(true)
    settoDelete(id)
  }

  const handleDelete = () => {
    deleteGuarantee(toDelete)
    settoDelete(null)
    setopen(false)
    setloading(true)
    getAllGuaranties().then((res) => setGaranties(res.data))
    setTimeout(() => {
      setloading(false)
    }, 500)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  useEffect(() => {
    getAllActivities().then((res) => setactivities(res.data))
    getProduct(params?.id).then((res) => setproduct(res.data))
    getAllGuaranties().then((res) => {
      setGaranties(res.data.filter((g) => g.product_id === params.id * 1))
    })
  }, [])

  useEffect(() => {
    if (product && garanties.length > 0) {
      setRows(
        garanties.map((garantie) =>
          createRow(
            garantie.id,
            garantie.code,
            garantie.name,
            garantie.nature,
            product.activity_id,
            product.market,
            product.starting_date,
            product.ending_date
          )
        )
      )
    }
  }, [garanties, product])

  useEffect(() => {
    setFilteredGaranties(
      rows?.filter(
        (row) =>
          row.code.toLowerCase().match(searchValue.toLowerCase().trim()) ||
          row.name.toLowerCase().match(searchValue.toLowerCase().trim())
      )
    )
    setPage(0)
  }, [rows, searchValue, product])

  return (
    <>
      <CustomHeader
        icon={<RiShieldCrossFill size={40} style={{ fill: "#c31d23", marginRight: ".8rem" }} />}
        pageName={`Produit ${findName(params.id)} - Garanties`}
      />
      <Grid container style={{ margin: "1.5rem 0 2rem" }}>
        <Back />
        <Grid item xs={12} md={5}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Rechercher par code, nom"
            value={searchValue}
            onChange={(e) => handlesetsearchValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsSearch size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {isAdmin && (
          <Grid item xs={12} md={2}>
            <UserComponentLayout>
              <Button
                className="buttonGeneralStyle"
                onClick={() => history.push(`/Produits/${params.id}/Garanties/Nouveau`)}
                style={{ marginLeft: ".8rem" }}
              >
                NOUVELLE GARANTIE
              </Button>
            </UserComponentLayout>
          </Grid>
        )}
      </Grid>

      <Box className="spacer2" />

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />

          <TableBody>
            {stableSort(filteredGaranties, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row" align="left">
                    <Typography variant="body2">{row.code}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.name}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.nature}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">
                      {activities?.filter((a) => a.id === product?.activity_id)[0]?.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.market}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.starting_date}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.ending_date}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <AiOutlineEuroCircle
                      fill="#C32D21"
                      size={30}
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push(`/Produits/${params.id}/Garantie/${row.id}`)}
                    />
                  </TableCell>

                  {isAdmin && (
                    <TableCell align="center">
                      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <BsPencilSquare
                          size={25}
                          style={{ cursor: "pointer", marginRight: ".8rem" }}
                          onClick={() => history.push(`/Produits/${params.id}/Garanties/${row.id}/Modifier`)}
                        />
                        <UserComponentLayout>
                          <FaRegTrashAlt
                            size={25}
                            style={{ fill: "#f24e1e", cursor: "pointer" }}
                            onClick={() => handleToDelete(row.id)}
                          />{" "}
                        </UserComponentLayout>
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        size="small"
        variant="outlined"
        count={filteredGaranties?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={() =>
          defaultLabelDisplayedRows({
            from: rowsPerPage * page + 1,
            to:
              rowsPerPage * page + rowsPerPage > filteredGaranties.length
                ? filteredGaranties.length
                : rowsPerPage * page + rowsPerPage,
            count: filteredGaranties?.length,
          })
        }
        labelRowsPerPage={"Lignes par page :"}
      />

      <CustomModal open={open} handleClose={() => setopen(false)} size={320}>
        <Typography variant="h3">Suppression de la garantie</Typography>
        <Typography variant="body1" style={{ margin: "1rem 0 2rem", textAlign: "center" }}>
          Attention, cette garantie sera supprimée!
        </Typography>
        <Button
          className="buttonGeneralStyle"
          style={{ width: "100%", maxWidth: "none" }}
          onClick={() => handleDelete()}
        >
          Supprimer
        </Button>
      </CustomModal>
    </>
  )
}

export default Garanties
