import React from "react"
import "./App.css"

/* Components */

import Login from "./AuthPages/Login/Login"
// import Registration from "./AuthPages/Registration/Registration.js";
//----------- Error ------------
import Page404 from "./ErrorPage/Page404"
import Page403 from "./ErrorPage/Page403"

/* Route */
// eslint-disable-next-line no-unused-vars
import {
  Route,
  Router as BrowserRouter,
  Switch,
  useHistory,
  Redirect,
  useLocation,
} from "react-router-dom"

import LayoutLogged from "../Hoc/LayoutLogged"
import NavLayout from "../Components/NavLayout/NavLayout"
import Dashboard from "./Dashboard/Dashboard"
import Devis from "./Devis/Devis"
import DevisIndividuels from "./Devis/Sections/Individuel"
import DevisCollectifs from "./Devis/Sections/Collectif"
import Contrats from "./Contrats/Contrats"
import ContratsIndividuels from "./Contrats/Sections/Individuel"
import ContratsCollectifs from "./Contrats/Sections/Collectif"
import Documents from "./Documents/Documents"
import Generic from "./Documents/Sections/Generic"
import CourtiersDocs from "./Documents/Sections/Courtiers"
import Souscription from "./Documents/Sections/Souscription"
import Justificatifs from "./Documents/Sections/Justificatifs"
import Users from "./Users/Users"
import Courtiers from "./Courtiers/Courtiers"
import NewCourtier from "./Courtiers/NewCourtier"
import Profil from "./Profil/Profil"
import Produits from "./Produits/Produits"
import NewProduit from "./Produits/NewProduit"
import Garanties from "./Garanties/Garanties"
import NewGarantie from "./Garanties/NewGarantie"
import ModifierProduit from "./Produits/ModifierProduit"
import ModifierGarantie from "./Garanties/ModifierGarantie"
import Packs from "./Packs/Packs"
import NewPack from "./Packs/NewPack"
import ModifyCourtier from "./Courtiers/ModifyCourtier"
import ModifierPack from "./Packs/ModifierPack"
import ResetPassword from "./AuthPages/ResetPassword"
import RecoverPassword from "./AuthPages/RecoverPassword"
import DevisIndividualSingle from "./Devis/Single/DevisIndividualSingle"
import DevisCollectifSingle from "./Devis/Single/DevisCollectifSingle"
import ContratIndividuelSingle from "./Contrats/Single/ContratIndividuelSingle"
import ContratCollectifSingle from "./Contrats/Single/ContratCollectifSingle"
import EditableRates from "./Tarifs/EditableRates"
import { useSelector } from "react-redux"
import GarantieTarifs from "./Garanties/GarantieTarifs";
import Contacts from "./Contacts/Contacts"

import Performances from "./Dashboard/Sections/Performances"
import Repartitions from "./Dashboard/Sections/Repartitions"
import Actu from "./Dashboard/Sections/Actu"
import Map from "./Dashboard/Sections/Map"

import Gestion from "./Gestion/Gestion"
import AddEditContact from "./Contacts/AddEditContact"
import Zones from "./Zones/Zones"

import { SearchProviderDevisIndiv } from "../Context/Search/SearchDevisContratsContext"


const isAuthenticated = () => localStorage.getItem("token")
const PublicRoute = (props) => <Route {...props} />
const PrivateRoute = (props) =>
  isAuthenticated() ? <Route {...props} /> : <Redirect to={"/"} />

const App = () => {
  const history = useHistory()
  const userType = useSelector((state) => state.user?.userData?.type)

  const AdminOnly = (props) =>
    userType === "admin" ? <Route {...props} /> : <Redirect to={"/"} />

  return (
    <div className="App">
      <SearchProviderDevisIndiv>
      <BrowserRouter history={history} basename={"/"}>
        <Switch>
          {/*  */}

          <PublicRoute path="/" exact>
            <Login />
          </PublicRoute>

          <PublicRoute path="/recover-password" exact>
            <RecoverPassword />
          </PublicRoute>

          <PublicRoute path="/reset-password/:token" exact>
            <ResetPassword />
          </PublicRoute>

          <LayoutLogged>
            <NavLayout>
              <PrivateRoute path="/Profil" exact>
                <Profil />
              </PrivateRoute>



              <PrivateRoute path="/Dashboard" exact>
                <Dashboard>
                  <Performances />
                </Dashboard>
              </PrivateRoute>


              <PrivateRoute path="/Dashboard/actu" exact>
                <Dashboard>
                  <Actu />
                </Dashboard>
              </PrivateRoute>

              <PrivateRoute path="/Dashboard/Repartitions" exact>
                <Dashboard>
                  <Repartitions />
                </Dashboard>
              </PrivateRoute>

              <PrivateRoute path="/Dashboard/carte" exact>
                <Dashboard>
                  <Map />
                </Dashboard>
              </PrivateRoute>


              <PrivateRoute path="/Gestion" exact>
                <Gestion>
                  <Gestion />
                </Gestion>
              </PrivateRoute>




              <PrivateRoute path="/Devis/Individuels" exact>
                <Devis>
                  <DevisIndividuels />
                </Devis>
              </PrivateRoute>

              <PrivateRoute path="/Devis/Individuels/:id" exact>
                <DevisIndividualSingle />
              </PrivateRoute>

              <PrivateRoute path="/Devis/Collectifs" exact>
                <Devis>
                  <DevisCollectifs />
                </Devis>
              </PrivateRoute>

              <PrivateRoute path="/Devis/Collectifs/:id" exact>
                <DevisCollectifSingle />
              </PrivateRoute>

              <PrivateRoute path="/Contrats/Individuels" exact>
                <Contrats>
                  <ContratsIndividuels />
                </Contrats>
              </PrivateRoute>

              <PrivateRoute path="/Contrats/Individuels/:id" exact>
                <ContratIndividuelSingle />
              </PrivateRoute>

              <PrivateRoute path="/Contrats/Collectifs" exact>
                <Contrats>
                  <ContratsCollectifs />
                </Contrats>
              </PrivateRoute>

              <PrivateRoute path="/Contrats/Collectifs/:id" exact>
                <ContratCollectifSingle />
              </PrivateRoute>

              <PrivateRoute path="/Courtiers" exact>
                <Courtiers />
              </PrivateRoute>

              <PrivateRoute path="/Courtiers/Nouveau" exact>
                <AdminOnly>
                  <NewCourtier />
                </AdminOnly>
              </PrivateRoute>

              <PrivateRoute path="/Courtiers/:id/Modifier" exact>
                <ModifyCourtier />
              </PrivateRoute>

              <PrivateRoute path="/Documents/Generiques" exact>
                <Documents>
                  <Generic />
                </Documents>
              </PrivateRoute>

              <PrivateRoute path="/Documents/Courtiers" exact>
                <Documents>
                  <CourtiersDocs />
                </Documents>
              </PrivateRoute>

              <PrivateRoute path="/Documents/Souscription" exact>
                <Documents>
                  <Souscription />
                </Documents>
              </PrivateRoute>

              <PrivateRoute path="/Documents/Justificatifs" exact>
                <Documents>
                  <Justificatifs />
                </Documents>
              </PrivateRoute>

              <PrivateRoute path="/Produits" exact>
                <Produits />
              </PrivateRoute>

              <PrivateRoute path="/Produits/Nouveau" exact>
                <AdminOnly>
                  <NewProduit />
                </AdminOnly>
              </PrivateRoute>

              <PrivateRoute path="/Produits/:id/Modifier" exact>
                <ModifierProduit />
              </PrivateRoute>

              <PrivateRoute path="/Produits/:id/Garanties" exact>
                <Garanties />
              </PrivateRoute>

              <PrivateRoute path="/Produits/:id/Packs" exact>
                <Packs />
              </PrivateRoute>

              <PrivateRoute path="/Produits/:id/Packs/Nouveau" exact>
                <AdminOnly>
                  <NewPack />
                </AdminOnly>
              </PrivateRoute>

              <PrivateRoute
                path="/Produits/:id/Packs/:package_id/Modifier"
                exact
              >
                <ModifierPack />
              </PrivateRoute>

              <PrivateRoute path="/Produits/:id/Garanties/Nouveau" exact>
                <AdminOnly>
                  <NewGarantie />
                </AdminOnly>
              </PrivateRoute>

              <PrivateRoute
                path="/Produits/:id/Garanties/:guarantee_id/Modifier"
                exact
              >
                <ModifierGarantie />
              </PrivateRoute>

              <PrivateRoute path="/Produits/:code/Garantie/:guarantee_id" exact>
                <GarantieTarifs />
              </PrivateRoute>

              <PrivateRoute path="/Utilisateurs" exact>
                <Users />
              </PrivateRoute>

              <PrivateRoute path="/Contacts" exact>
                <Contacts />
              </PrivateRoute>
              <PrivateRoute path="/Contacts/Nouveau" exact>
                <AddEditContact />
              </PrivateRoute>
              <PrivateRoute path="/Contacts/:id/Modifier" exact>
                <AddEditContact />
              </PrivateRoute>

              <PrivateRoute path="/zones" exact>
                <Zones />
              </PrivateRoute>

              <PrivateRoute path="/Tarifs" exact>
                <EditableRates />
              </PrivateRoute>
            </NavLayout>
          </LayoutLogged>

          {/*  */}

          {/*  */}

          <PublicRoute path="/403" exact component={Page403} />
          <Route component={Page404} />

          {/*  */}
        </Switch>
      </BrowserRouter>
      </SearchProviderDevisIndiv>
    </div>
  )
}

export default App
