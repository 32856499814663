import React, { useState, useEffect } from "react"
import CustomHeader from "../../Components/Header/CustomHeader"
import { useSelector } from "react-redux"
import {
  Grid,
  TextField,
  Box,
  Typography,
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Checkbox,
  ListItemText,
  Divider,
  Container,
} from "@material-ui/core"
import { AiOutlineUserAdd } from "react-icons/ai"
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral"
import { addBroker } from "../../Axios/Call/ClientCredential"
import { useHistory } from "react-router-dom"
import { Controller, useForm } from "react-hook-form"
import CommonDateTimePicker from "../../Components/FormComponents/CommonDateTimePicker"
import CommonSelect from "../../Components/FormComponents/CommonSelect"
import moment from "moment"
import "moment/locale/fr"
import Back from "../../Components/Commons/Back/Back"
import {
  formatErrorEmail,
  formatErrorPhoneNumber,
  formatErrorZip,
  formatErrorSiret,
} from "../../utils/formValidation"
import CommonTextField from "../../Components/FormComponents/CommonTextField"
moment.locale("fr")

const NewCourtier = () => {
  //   datas
  const [successApi, setsuccessApi] = useState(false)
  const [errorApi, seterrorApi] = useState(false)
  const [products_forbidden, setproducts_forbidden] = useState([])
  const [productList, setproductList] = useState([])
  const history = useHistory()

  const products = useSelector((state) => state.user.products)
  const associations = useSelector((state) => state.user.associations)

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "300px",
      },
    },
  }

  const createData = (
    name,
    surname,
    email,
    mobile,
    type,
    status,
    convention_signed_at,
    creation_account_at,
    company,
    siret,
    orias,
    orias_end_at,
    code,
    address,
    code_postal,
    city,
    association_id,
    not_accessible_product,
    suivi,
  ) => {
    return {
      name,
      surname,
      email,
      mobile,
      type,
      status,
      convention_signed_at,
      creation_account_at,
      company,
      siret,
      orias,
      orias_end_at,
      code,
      address,
      code_postal,
      city,
      association_id,
      not_accessible_product,
      suivi,
    }
  }

  const createProduct = (id, value) => {
    return { id, value }
  }

  const handleNewUser = (data) => {

    console.log(data)

    const tmp = products_forbidden
      ?.map((p) => {
        return p.id
      })
      ?.join(",")

    const tmpdata = createData(
      data.name,
      data.surname,
      data.email,
      data.mobile,
      "broker",
      data.status,
      data.convention_signed_at,
      data.creation_account_at,
      data.company,
      data.siret,
      data.orias,
      data.orias_end_at,
      data.code,
      data.address,
      data.code_postal,
      data.city,
      data.association_broker,
      tmp,
      data.suivi
    )

    addBroker(tmpdata)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setsuccessApi(true)
          setTimeout(() => {
            history.push("/Courtiers")
          }, 1500)
        }
      })
      .catch((error) => {
        if (error.response.data.email) {
          setError("email", { message: "Cet email est déjà utilisé." })
        } else if (
          error.response.status !== 200 &&
          error.response.status !== 201 &&
          error.response.status !== 400
        ) {
          seterrorApi(true)
        }
      })
  }

  const handleChangeProductsForbiden = (event) => {
    const {
      target: { value },
    } = event
    setproducts_forbidden(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    )
  }

  const handleChangeAssoc = (x) => {
    associations?.filter((a) => a.name === x)
    return
  }

  // form hook

  const { register, handleSubmit, setValue, control, errors, setError } =
    useForm({
      mode: "all",
      defaultValues: {
        association_broker: "",
      },
    }) // initialise the hook**



  return (
    <>
      {successApi && (
        <AlertGeneral
          color=""
          description="Courtier enregistré !"
          setTrigger={setsuccessApi}
        />
      )}
      {errorApi && (
        <AlertGeneral
          color="danger"
          description="Une erreur est survenue"
          setTrigger={setsuccessApi}
        />
      )}
      
      <Grid container style={{paddingTop:10}}>
     <Back/>
      </Grid>

      <AiOutlineUserAdd size="40" style={{ fill: "#C31D23" }} />
      <Typography variant='h3' style={{fontSize:'30px', paddingBottom:10}}> Nouveau Courtier</Typography>

      <Container maxWidth="md"
        
          style={{backgroundColor:'#FFF', padding:50, borderRadius:5,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", marginBottom:10 }}
      
      >
      
        <form
          style={{ margin: "0 auto" }}
          onSubmit={handleSubmit(handleNewUser)}
        >
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <Typography variant="h3" style={{textAlign:'left'}}>Activité</Typography>
              <Divider style={{backgroundColor:"#C31D2315", marginTop:10}}/>
            </Grid>
     
            <Grid item xs={12} md={3}>
              <Controller
                control={control}
                rules={{ required: true }}
                name="status"
                render={(field) => (
                  <CommonSelect
                    field={field}
                    label={"État"}
                    options={[
                      { id: "inactive", value: "Inactif" },
                      { id: "active", value: "Actif" },
                    ]}
                    required
                    error={errors.status}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Controller
                rules={{ required: true }}
                control={control}
                name={"convention_signed_at"}
                // defaultValue={moment().format("YYYY-MM-DD")}
                defaultValue={null}
                render={(field) => {
                  return (
                    <CommonDateTimePicker
                      field={field}
                      label={"Convention signée le"}
                      required={true}
                      error={errors.convention_signed_at}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                rules={{ required: true }}
                name="association_broker"
                render={(field) => (
                  <CommonSelect
                    field={field}
                    label={"Association"}
                    options={[{ id: null, value: "" }, ...associations]}
                    error={errors.association_broker}
                  />
                )}
              />
            </Grid>
            {/* infos */}
            <Grid item xs={12} style={{ marginTop: "1.5rem" }}>
              <Typography variant="h3" style={{textAlign:'left'}}>Informations courtier</Typography>
              <Divider style={{backgroundColor:"#C31D2315", marginTop:10}}/>
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="code"
                control={control}
                rules={{ required: true }}
                ref={register("code")}
                render={(field) => {
                  return (
                    <CommonTextField
                      label="Code apporteur"
                      field={field}
                      variant="outlined"
                      required
                      error={errors.code}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="email"
                control={control}
                rules={{ required: true, validate: formatErrorEmail }}
                ref={register("email")}
                render={(field) => {
                  return (
                    <CommonTextField
                      type="email"
                      label="Identifiant (email)"
                      required
                      error={errors.email}
                      field={field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"creation_account_at"}
                control={control}
                rules={{ required: true }}
                defaultValue={null}
                render={(field) => (
                  <CommonDateTimePicker
                    field={field}
                    label={"Date de création"}
                    required={true}
                    error={errors.creation_account_at}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="company"
                control={control}
                rules={{ required: true }}
                render={(field) => (
                  <CommonTextField
                    label="Raison sociale"
                    field={field}
                    required
                    error={errors.company}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="siret"
                control={control}
                rules={{ required: true, validate: formatErrorSiret }}
                render={(field) => (
                  <CommonTextField
                    type="number"
                    label="Siret"
                    field={field}
                    required
                    error={errors.siret}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Controller
                name="orias"
                control={control}
                rules={{ required: true }}
                render={(field) => (
                  <CommonTextField
                    type="number"
                    label="ORIAS"
                    field={field}
                    required
                    error={errors.orias}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name={"orias_end_at"}
                rules={{ required: true }}
                defaultValue={null}
                render={(field) => (
                  <CommonDateTimePicker
                    field={field}
                    label={"Fin de validité ORIAS"}
                    required={true}
                    error={errors.orias_end_at}
                  />
                )}
              />
            </Grid>
            {/* address */}
            <Grid item xs={12}>
              <Typography variant="h3" style={{ marginTop: "1.5rem", textAlign:'left' }}>
                Adresse
              </Typography>
              <Divider style={{backgroundColor:"#C31D2315", marginTop:10}}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="address"
                rules={{ required: true }}
                render={(field) => (
                  <CommonTextField
                    label="Numéro et rue"
                    field={field}
                    required
                    error={errors.address}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={control}
                name="code_postal"
                rules={{ required: true, validate: formatErrorZip }}
                render={(field) => (
                  <CommonTextField
                    label="Code postal"
                    type="number"
                    field={field}
                    required
                    error={errors.code_postal}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={control}
                name="city"
                rules={{ required: true }}
                render={(field) => (
                  <CommonTextField
                    label="Commune"
                    field={field}
                    required
                    error={errors.city}
                  />
                )}
              />
            </Grid>
            {/* interlocuteur */}
            <Grid item xs={12}>
              <Typography variant="h3" style={{ marginTop: "1.5rem", textAlign:'left' }}>
                Interlocuteur
              </Typography>
              <Divider style={{backgroundColor:"#C31D2315", marginTop:10}}/>
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="name"
                rules={{ required: true }}
                render={(field) => (
                  <CommonTextField
                    label="Prénom"
                    field={field}
                    required
                    error={errors.name}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="surname"
                rules={{ required: true }}
                render={(field) => (
                  <CommonTextField
                    label="Nom"
                    field={field}
                    required
                    error={errors.surname}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="mobile"
                rules={{ required: true, validate: formatErrorPhoneNumber }}
                render={(field) => (
                  <CommonTextField
                    label="Téléphone"
                    type="number"
                    field={field}
                    error={errors.mobile}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" style={{ marginTop: "1.5rem",textAlign:'left' }}>
                Produits non accessibles
              </Typography>
              <Divider style={{backgroundColor:"#C31D2315", marginTop:10}}/>
            </Grid>
            <Grid item xs={12}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  style={{ paddingLeft: ".8rem", marginTop: "-3px" }}
                >
                  Produits
                </InputLabel>
                <Select
                  // native
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  variant="outlined"
                  value={products_forbidden}
                  onChange={handleChangeProductsForbiden}
                  renderValue={(selected) => {
                    console.log(selected)
                    let res = []
                    selected.forEach((s) => res.push(s.description))
                    return res.join(",")
                  }}
                  style={{ textAlign: "left" }}
                  MenuProps={MenuProps}
                >
                  {products?.map((p) => (
                    <MenuItem key={p.id} value={p}>
                      <Checkbox checked={products_forbidden.indexOf(p) > -1} />
                      <ListItemText primary={p.description} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" style={{ marginTop: "1.5rem",textAlign:'left' }}>
                Suivi
              </Typography>
              <Divider style={{backgroundColor:"#C31D2315", marginTop:10}}/>
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                control={control}
                name="suivi"
                rules={{ required: false}}
                render={(field) => (
                  <CommonTextField
                    multiline={true}
                    minRows={6}
                    style={{ minHeight: "200px" }}
                    label="Note"
                    variant="outlined"
                    fullWidth
                    error={errors.suivi}
                    field={field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box className="spacer1" />
          <Button
            className="buttonGeneralStyle"
            type="submit"
            style={{ alignSelf: "center", margin: "1.5rem 0 0" }}
          >
            ENREGISTRER
          </Button>
        </form>
        </Container>
    </>
  )
}

export default NewCourtier
