import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../utility";

const initializeState = {
  data: {},
  errorApi: false,
};

const reducer = (state = initializeState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_OAV_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.name]: action.payload.data
        }
      }
        
    case actionTypes.ERROR_ON_API:
      return updateObject(state, { errorApi: true });
    case actionTypes.CLEAN_OAV_DATA:
      return updateObject(state, { oav: {} });
    default:
      return state;
  }
};

export default reducer;
