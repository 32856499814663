import React, { useState, useEffect, useCallback } from "react"
import { Grid } from "@mui/material"
import Note from "../../../Components/Dashboard/Note"
import { getAllNotes, upsertNote } from "../../../Axios/Call/ClientCredential"
import MyEditor from "../../../Components/wysiwyg/MyEditor"
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral"

const COURTIER_SLUG = "note-aux-courtiers"
const INFO_SLUG = "informations-utiles"
const INTERN_NOTES = "note-interne"

const notesList = [COURTIER_SLUG, INFO_SLUG, INTERN_NOTES]

const notesTitles = {
  [COURTIER_SLUG]: "Note aux courtiers",
  [INFO_SLUG]: "Informations utiles",
  [INTERN_NOTES]: "Note interne"
}

const Actu = () => {
  const [notes, setNotes] = useState({})
  const [successapi, setsuccessapi] = useState(false)
  const [errorapi, seterrorapi] = useState(false)

  const fetchNotes = useCallback(async () => {
    const { data } = await getAllNotes()
    setNotes(data)
  }, [])

  useEffect(() => {
    fetchNotes()
  }, [fetchNotes])

  const getOnSaveNote = useCallback(
    (slug) => async (data) => {
      await upsertNote(slug, data)
      fetchNotes()
    },
    [fetchNotes]
  )


  const saveNoteEditor = (slug, data) => {
    upsertNote(slug, data)
      .then((res) => {
        if (res.status === 200) {
          setsuccessapi(true)
        } else {
          seterrorapi(true)
        }
      })
  }


  return (
    <>
      {successapi && <AlertGeneral color="" setTrigger={setsuccessapi} description="Actualité sauvegardée !" />}
      {errorapi && <AlertGeneral color="danger" setTrigger={seterrorapi} description="Une erreur est survenue" />}
      <Grid container justifyContent="center">

        {notesList.map((note) => (
          <Grid item md={12} xs={12} xl={10} key={note}>
            <MyEditor saveNote={saveNoteEditor} content={notes[note]?.text ?? ""} slug={note}
                      title={notesTitles[note]} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default Actu
