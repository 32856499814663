const text = {
  General: {
    formatError: "Format incorrect",
    emailNotMatching: "Les emails ne correspondent pas",
    required: "Champ obligatoire",
    continue: "Continuer",
    connect: "Se connecter",
    emailTooken: "Cet email est déjà utilisé",
    seeAll: "Voir tout",
    forward: "Suivant",
    alertGeneralError: "Erreur de chargement des données",
    alertGeneralSuccess: "Données chargées avec succès",
    finish: "Terminer",
    yes: "Oui",
    no: "Non",
    add: "Ajouter",
    modify: "Modifier",
    anull: "Annuler",
    errorDate: "Date invalide",
    noRatingYet: "Pas encore d'évaluation",
    precedent: "Précédent",
    register: "Enregister",
    next: "Suivant",
  },
  ErrorPage: {
    error403: "Vous n'avez pas les droits pour accéder à cette page",
    error404: "La page n'existe pas",
  },
  Login: {
    title: "Connexion",
    forgotPassword: "Mot de passe oublié ?",
    dontHaveAccount: "Pas de compte ?",
    register: "S'inscrire",
    alertError: "Connexion impossible",
    alertAccount: "Nom d'utilisateur ou mot de passe incorrect",
  },
  RecoverPassword: {
    title: "Récupérer votre mot de passe",
    description1: "Renseignez votre email pour créer",
    description2: "un nouveau mot de de passe",
    createAccount: "Créer un compte",
    alertSuccess:
      "Nous vous avons envoyé un email pour réinitialiser votre mot de passe",
    alertError: "Cet email n'existe pas",
    step1Title: "Récuperation de mot de passe",
    step1Description1:
      "Cliquez sur le lien suivant pour créer votre nouveau mot de passe",
    step1Button: "Créer mon mot de passe",
    step1Error: "Données invalides",
    step2Title: "Nouveau mot de passe",
    step2Description1:
      "Nous n'avons pas trouvé votre email dans notre base de données",
    step2Description2: "Essayez avec un autre email",
    step2FooterText:
      "Erreur lors de connexion avec l'API",
    step2AlertError: "Erreur, le mot de passe n'a pas pu être réinitialisé.",
    step2AlertSuccess: "Le mot de passe a bien été modifié",
  },
  ConfirmEmail: {
    title: "Validation de votre inscription",
    description1: "Merci pour votre inscription !",
    description2:
      "Votre compte a été créé. Nous vous invitons à valider votre inscription afin de commencer votre expérience sur Kisoigne",
    buttonText: "Je valide mon inscription",
    alertSuccess: "L'email a bien été validé !",
    alertError: "Erreur. Impossible de confirmer l'email.",
  },
  OAV: {
    title: "Tarificateur",
    indiv: "Individuel",
    collectif: "Collectif",
    adhesoin: "Adhésion",
    avenant: "Avenant",
  },
  Registration: {
    title: "Votre établissement",
    description: "Renseignez les données concernant votre établissement",
    step2Title: "Contact du dirigeant",
    step2Description:
      "Renseignez les informations de contact du dirigeant de l'établissement",
    step3Title: "Identifiant et mot de passe",
    step3Description:
      "Renseignez l'email de connexion et créez un mot de passe pour vous connecter",
    step4Title: "Votre inscription est presque terminée !",
    step4Description1: "Suivez le lien dans l'email que nous venons de vous",
    step4Description2: "envoyer pour finaliser votre inscription",
    labelName: "Raison Sociale *",
    labelVat: "Numéro de SIRET *",
    labelApeNaf: "Code APE / NAF *",
    labelAddress: "Adresse du siège *",
    labelCodePostal: "Code Postal *",
    labelAddressNumber: "Ville *",
    labelNamePerson: "Nom *",
    labelSurname: "Prénom *",
    labelEmailProfessional: "Email *",
    labelPhoneFixed: "Numéro de téléphone fixe",
    labelPhone: "Numéro de téléphone portable *",
    condition1: "Je comprends et j'accepte les",
    condition2: "conditions d'utilisations de Kisoigne",
    password0:
      "Erreur de connexion avec l'API",
    password1: "Votre mot de passe n'est pas suffisamment sécurisé",
    password2:
      "Votre mot de passe n'est pas suffisamment sécurisé. C'est presque ca !",
    password3: "Votre mot de passe est suffisamment sécurisé. Félicitation !",
  },
  Dashboard: {
    myBusiness: "Établissements",
    noBusiness:
      "Vous n'avez pas encore ajouté d'établissement à votre organisation",
    myResponsable: "Mes référents",
    noResponsable: "Il n'y a pas de référent pour cet établissement",
    myMission: "Missions",
    noMission: "Il n'y a pas de mission pour cet établissement",
    welcomeToKisogne: "Bienvenue sur Kisoigne",
    noBusinessDescription:
      "Pour bien commencer sur Kisoigne, nous vous invitons à paramétrer vos établissements et à définir les contacts dirigeants pour chaque établissement",
    startAddBusiness: "Commencer maintenant",
    missions: "Missions",
    monthsLeft: "Sur les 12 derniers mois",
    missionDone: "Missions réalisées",
    professionist: "Professionnels",
    hoursMission: "Heures de missions",
    professionistSection:
      "Les professionels de santé avec qui nous avons travaillé",
    allProfessionist: "Tous les professionnels",
    ofMission: "de missions",
    evaluation: "Évaluations",
    hours: "heures",
  },
  CreateBusiness: {
    addBusiness: "Ajouter un établissement",
    businessNames: "Raison Sociale",
    services: "Services",
    contactReferent: "Contact dirigeant",
    anull: "Annuler",
    estabilishmentDetail: "Ajouter un établissement",
    myBusiness: "Mes établissements",
    estabilishmentDescription:
      "Ajouter vos établissements de soins à votre compte Kisoigne. Vous pouvez ajouter jusqu'à 5 établissements",
    estabilishmentLabel: "Nom de l'établissement",
    address: "Adresse de l'établissement",
    noMoreThen5: "J'ai plus de 5 établissements",
    yourBusiness: "Vos établissements",
    delete: "supprimer",
    sits: "lit(s)",
    errorAlert: "Erreur lors de la création de l'établissement",
    modalTitleDelete: "Suppression d'établissement",
    modalDescriptionDelete:
      "Êtes-vous certain de vouloir supprimer cet établissement ?",
    modalTitleDeleteContact: "Suppression de contact",
    modalDescriptionDeleteContact:
      "Êtes-vous certain de vouloir supprimer ce contact ?",
    sectorTitle: "Secteurs d'activités de l'établissement",
    sectorDescription:
      "Ajouter les secteurs d'activité que vous assurez dans cet établissement pour faciliter la création des annonces.",
    sectorMissing: "les secteurs d'activité de l'établissement ",
    sectorActivityLabel: "Type d'établissement",
    numberOfSitsLabel: "Nombre de lits",
    sectorActivity: "TYPE D'ÉTABLISSEMENT",
    numberBed: "NOMBRE DE LITS",
    contactTitle: "Contact de l'établissement",
    contactDescription:
      "Ajouter le contact référent de l'établissement pour lui céder la gestion des annonces pour son établissement",
    noContactText:
      "Le référent pour cet établissement pourra gérer les annonces. Nous enverrons un email au contact pour l'inviter à s'inscrire et accéder a son espace personnel sur Kisoigne",
    nameLabel: "Nom du contact référent",
    surnameLabel: "Prénom du contact référent",
    emailLabel: "Email du contact",
    phoneLabel: "Téléphone du contact",
    roleLabel: "Fonction",
    authorizePublish: "Autoriser à publier des annonces",
    addContact: "Ajouter le contact",
    editContact: "Modifier le contact",
    contacts: "Référents",
    addAnotherBusiness: "Ajouter un autre établissement",
    tooMuchBusiness: "Vous ne pouvez pas ajouter plus de 5 établissements",
    addressInfo:
      "Si vous n'avez pas d'adresse précise, merci de préciser l'adresse du point le plus proche",
  },
  BusinessDetail: {
    onGoingMission: "Annonces publiées",
    missionPublish: "Annonces en ligne",
    addEditBusiness: "Ajouter ou modifier un établissement",
  },
  ProfileSection: {
    profile: "Profil",
    accountParameter: "Paramètres du compte",
    subscription: "Abonnement",
    profilePhoto: "Photo du profil",
    nameComplete: "Nom complet",
    dob: "Date de naissance",
    day: "Jour",
    month: "Mois",
    year: "Année",
    emailAddress: "Adresse email",
    mobileNumber: "Téléphone portable",
    fixedNumber: "Téléphone fixe",
    password: "Mot de passe",
    register: "Enregister",
    nameLabel: "Nom",
    surnameLabel: "Prénom",
  },
  AccountSettings: {
    pushTitle: "Notifications push",
    pushDescription:
      "Vous pouvez choisir d'activer les notifications push directement sur votre ordinateur pour être alerté en temps réel des annonces de votre organisation",
    activePush: "Activer les notifications push",
    notificationTitle: "Paramètres des notifications",
    email: "EMAIL",
    push: "PUSH",
    newAnnounceByCollaborator: "Un collaborateur a publié une nouvelle annonce",
    deleteAnnounce: "Un annonce a été supprimée",
    noResponse: "Une annonce est en ligne depuis 15 jours sans response",
    professionistInterese: "Un professionnel est intéressé par votre annonce",
    recruited: "Un professionnel a été recruté pour une mission",
    missionFinishOk: "Une mission se termine bientôt",
    missionFinish: "une mission se termine",
    reminderEvaluation: "Rappel pour évaluer une mission",
    missionEvaluated: "Une mission a été évaluée",
    kisoigneTitle: "Actualités de Kisoigne",
    kisoigneDescription:
      "Recevez tous les mois la newsletter de Kisoigne. Les nouveautés de la plateforme et les actualités sur la réglementation du travail en établissement de soins",
    deleteAccountTitle: "Supprimer mon compte",
    deleteAccountDesc1:
      "Vous souhaitez supprimer votre compte ou arrêter votre abonnement ? Nous prenons en compte votre demande, cliquez ici pour prendre contact avec nous",
    deleteAccountDesc2:
      "Supprimer votre compte Kisoigne revient a annuler tous vos abonnements et supprimer toutes les annonces en ligne et enregistrées. Cette action est irréversible",
    deleteAccountTitleModal: "Supprimer mon compte",
    deleteAccountDescriptionModal:
      "Êtes-vous sûr de vouloir supprimer votre compte ?",
    deleteAccountCta: "Oui, je suis sûr",
    deleteAccountCta2: "Non, annuler ma demande",
    alertSuccessChangeSettings:
      "Les nouveaux paramètres ont bien été enregistrés",
  },
  createMission: {
    addHoursWeek: "Générer une semaine type",
    overlapError: "Des horaires semblent en conflit",
    step1Title: "Déposer un nouvelle annonce",
    labelTitle: "Titre de la mission",
    step2Title: "Titre de la mission",
    labelModel: "À partir du modèle ( faculatif )",
    createModel: "Créer un modèle pour ce type d'annonce",
    rolesLabel: "Fonction recherchée",
    activitySectorLabel: "Secteur d'activité",
    datesLabel: "Dates de la mission",
    startingDateError:
      "Merci de renseigner une date de début antérieure à la date de fin",
    endingDateError:
      "Merci de renseigner une date de fin ultérieure à la date de début",
    startingDateError2:
      "Merci de renseigner une date de début ultérieure à aujourd'hui",
    businessLabel: "Établissement",
    splitTimeLabel: "Fractionné",
    splitTimePlaceholder:
      "Veuillez spécifier la fréquence des journées fractionnées",
    weekendFrequency: "Weekends travaillés",
    weekendFrequencyPlaceholder:
      "Veuillez préciser la fréquence des weekends travaillés",
    hoursWeekly: "Nombre d'heures par semaine",
    jobTypeLength: "Type d'emploi",
    partTime: "Temps partiel",
    fullTime: "Temps plein",
    contanctReferent: "Contact référent",
    addNewContact: "Ajouter un contact référent",
    descriptionLabel: "Description de la mission",
    descriptionPlaceholder: "Description de la mission",
    accessModalityLabel: "Modalités d'accès",
    accessPlaceholder: "Decrivez les modalités d'accès au bâtiment",
    securiteLabel: "Consigne(s) de securité particulière(s) (faculatif)",
    securitePlaceholder:
      "S'il y en a, décrivez les consignes de sécurité à respecter lors de la mission",
    hasDresses: "Tenue fournie",
    longTermJob: "Nous recrutons également des professionnels de santé en CDI",
    needAContact: "Vous devez selectionner un contact pour continuer",
    step1Header: "Titre",
    step2Header: "Annonce",
    step3Header: "Horaires",
    step4Header: "Détails",
    step5Header: "Publication",
    createMission: "Créer une annonce",
    missionHours: "Horaires de la mission",
    missionHoursDescription:
      "Selectionnez les horaires de travail de la mission sur la période",
    businesses: "Établissement",
    securiteLabelPreview: "Consigne(s) de sécurité particulière(s)",
    addMission: "Publier maintenant",
    addMissionLate: "Publier plus tard",
    yourAddIsLiveTitle: "Votre annonce a été publiée",
    yourAddIsLiveDescription:
      "Vous serez alerté dés qu'un professionnel de santé aura répondu",
    notTerminatedTitle: "Vouz n'avez pas terminé la rédaction de votre annonce",
    notTerminatedDescription:
      "Vous pouvez enregister votre annonce en brouillon et la reprendre plus tard",
    notTeminerClose: "Quitter sans enregister",
    notTermineRegister: "Enregister et quitter",
    titleNotRegister: "Votre annonce a été sauvegardée dans vos brouillons.",
    prevWeek: "Semaine précédente",
    nextWeek: "Semaine suivante",
    copy: "Copier les horaires",
    paste: "Coller",
    remove: "Supprimer",
    start: "Début",
    end: "Fin",
    hour: "heure",
    minutes: "minutes",
    ok: "ok",
  },
  navBar: {
    dashboard: "Tableau de bord",
    mission: "Annonces",
    clients: "Professionnels",
    businesses: "Établissements",
    createMission: "Déposer une annonce",
    myAccount: "Mon compte",
    editProfile: "Éditer mon profil",
    logout: "Déconnexion",
    parameterProfile: "Paramètres du compte",
  },
  missions: {
    pendingMission: "Annonces en ligne",
    holdMission: "Annonces passées",
    templateMission: "Modèles d'annonces",
    draft: "Brouillons",
    missingPendingMission: "Vous n'avez pas d'annonces en ligne",
    missingHoldMission: "Vous n'avez pas d'annonces passées",
    missingTemplateMission: "Vous n'avez pas de modèles d'annonces",
    missingDraftMission: "Vous n'avez pas de brouillons d'annonces",
    buttonMission: "Déposer une annonce",
    createMission: "Créer un modèle d'annonce",
    useThisModel: "Utiliser ce modèle",
    restart: "Reprendre",
    advancement: "Avancement",
    referent: "RÉFÉRENT",
    startMission: "DÉBUT DE MISSION",
    interestedProfessionel: "Professionnel(s) intéressé(s)",
    professionistNotResponded: "Aucune réponse pour le moment",
    missionLength: "DURÉE DE LA MISSION",
    hours: "Heures",
    waitEvaluation: "En attente d'évaluation",
    du: "Du",
    au: "au",
    pasDePlageHorair: "Pas de plage horaire",
  },
  OneBusiness: {
    nowInMission: "Actuellement en mission",
    missionDone: "Missions terminées",
    missionLive: "Annonces en ligne",
    business: "établissements",
    whereInMission: "En mission dans la clinique",
    booked: "NOMBRE D'HEURES",
    referent: "RÉFÉRENT",
    missioneLength: "DURÉE DE LA MISSION",
    leftOver: "RESTANT",
    days: "Jours",
    workingDays: "Dernière mission il y a",
    hours: "heures",
  },
  Professionists: {
    nowInMission: "Actuellement en mission",
    missionDone: "Missions terminées",
    noClient: "Créez une nouvelle mission",
    createMission: "Créer une mission",
    missionSelected: "Prochainement en mission",
  },
  ProfessionistIterested: {
    title: "Professionnels intéressés par la mission",
  },
  MissionDetailByClient: {
    header1: "Détails de la mission",
    header2: "Horaires de la mission",
    header3: "Lettre d'intention",
    header4: "Professionels intéressés",
    header5: "Professionnel sélectionné",
    longTerm: "Mission à long terme",
    areClothsAvailable: "Tenue fournie",
    notLongTerm: "Mission ponctuelle",
    notAreClothsAvailable: "Tenue non fournie",
    jobType: "Fonction recherchée",
    datesMission: "Dates de la mission",
    descriptionLabel: "Description de la mission",
    accessModalityLabel: "Modalités d'accès",
    securiteLabel: "Consigne(s) de securité particulière(s)",
    business: "ÉTABLISSEMENTS",
    contactRef: "CONTACTS RÉFÉRENTS",
    professionel: "PROFESSIONNELS DE SANTÉ",
    acceptShift: "Accepter le professionnel",
    rejectShift: "Refuser",
    clientAccepted: "Professionnel accepté",
    clientRefuse: "Professionnel refusé",
    clientHasBeenAccepted: "Le professionnel a été accepté",
    clientHasBeenRefused: "Le professionnel a été refusé",
    announces: "Annonces",
  },
  ClientDetail: {
    professionists: "PROFESSIONNELS",
    hoursNet: "heure, brut",
    experience: "années d'expérience",
    totalMission: "missions avec Kisoigne",
    yourMissions: "missions dans vos établissements",
    missions: "missions",
    allMissiongOf: "Toutes les missions de ",
    preference: "Préférence de disponibilité",
    preferencePlaceholder: "Plutôt le matin",
    documents: "Documents",
    qualification: "Qualification",
  },
  Review: {
    theMission: "La mission de ",
    isFinish: "est terminée",
    reviewDescription:
      "Une fois la mission teminée, n'oubliez pas d'évaluer les professionnels avec qui vous avez travaillé. Récompensez le goût du travail bien fait",
    buttonText: "Commencer l'évaluation",
    buttonQuestion: "Question suivante",
    descriptionReview: "Laisser un message",
    finishEvaluation: "Terminer l'évaluation",
  },
  ContactForm: {
    title: "Contacter Kisoigne",
    description: "Vous avez plus de 5 établissements ? ",
    placeHolder: "Votre message",
    error:
      "Il y a eu une erreur au moment d'envoyer le message. Veuillez vérifier votre connexion et réessayer.",
    contactUs: "Envoyer",
  },
  AddWeekDialog: {
    title: "Ajouter une semaine type",
  },
};

export default text;
