

import { useState, useEffect } from "react";
import MapComponent from "../../../Components/Map/Map";
import { Grid, Typography, Divider } from "@material-ui/core";
import { useSelector } from "react-redux";
import { scaleLinear } from "d3-scale"
import { getQuotes } from "../../../Axios/Call/ClientCredential";
import { getDpts, getRegions } from "../../../Axios/Call/GouvApi";


const Map = () => {

  const testBrokerId = useSelector((state) => state.user.testBrokerId);

  const [region, setRegion] = useState({ nom: 'France', code: 0 });
  const [departement, setDepartement] = useState({ nom: 'France', code: 0 });

  const [departements, setDepartements] = useState([]);
  const [regions, setRegions] = useState([]);

  const [departementsNew, setDepartementsNew] = useState([]);
  const [departementsList, setDepartementsList] = useState([]);

  const [regionsNew, setRegionsNew] = useState([]);

  const [quotes, setquotes] = useState([]); // all the devis/contrats
  const [quotescountRegion, setQuotescountRegion] = useState(0);
  const [quotescountDpt, setQuotescountDpt] = useState(0);


  // GET ALL DEPARTEMENTS FROM GOUV API
  useEffect(() => {

    let contratsEtDevis = [];
    let contratsSignes = [];

    // WE GET THE QUOTES AND FILTER SIGNED QUOTES AND NO TEST QUOTES, WE ALSO GET DEPARTEMENTS
    getQuotes().then((res) => {
      //CONTRATS ET DEVIS
      contratsEtDevis = res.data;
      // CONTRATS SIGNES
      contratsSignes = res.data.filter(quote => quote.broker_id !== testBrokerId).filter((q) => q.type === "subscription" && (q.contract_status === "signed" || q.contract_status === "managment"));
 


      setquotes(contratsSignes);
      setQuotescountRegion(contratsSignes.length);
      setQuotescountDpt(contratsSignes.length);


    }).catch(error => console.log(error));

    getDpts().then((res) => setDepartements(res.data));
    getRegions().then((res) => { setRegions(res.data) });


  }, [testBrokerId]);



  useEffect(() => {
    let contractsDpt = [];

    // JE COMPARE LES CONTRATS ET LA LISTE DES DEPARTEMENTS POUR SORTIR LE NB DE CONTRATS PAR DEPARTEMENTS
    departements.map(d => {
      let count = 0;
      quotes.map(quote => {
        if (quote.zip_code.slice(0, 2) === d.code) {
          count = count + 1;
        }
      })
      contractsDpt.push({ code: d.code, codeRegion: d.codeRegion, nom: d.nom, contrats: count });
    })
    setDepartementsNew(contractsDpt);
  }, [departements, quotes]);



  // To get the contracts by region
  useEffect(() => {
    let count = 0;
    let regionsLocal = regions.map(r => {
      count = 0;
      departements.map(d => {
        if (r.code === d.codeRegion) {
          quotes.map(q => {
            if (q.zip_code.slice(0, 2) === d.code) {
              count = count + 1;
            }
          })
        }
      })
      return ({ nom: r.nom, code: r.code, contrats: count })
    })

    setRegionsNew(regionsLocal);

  }, [regions, departements, quotes]);




  // To get the contracts by dpt
  useEffect(() => {
    let departementsLocal = departements.map(d => {
      let count = 0;
      quotes.map(q => {
        if (q.zip_code.slice(0, 2) === d.code) {
          count = count + 1;
        }
      })
      return ({ nom: d.nom, code: d.code, codeRegion: d.codeRegion, contrats: count })
    })
    setDepartementsNew(departementsLocal);


  }, [departements, quotes]);



  // TO FILL THE REGIONS
  const handleFillDepartements = (geo) => {

    // We create a color scale with min and max contracts in regions
    let color = '#FFF'
    let min = 1;
    let max = Math.max.apply(Math, regionsNew.map(function (o) { return o.contrats; }));
    const colorScale = scaleLinear().domain([min, max]).range(['#FFF', "#C31D23"]);

    // We apply it to the map
    departementsNew.map(c => {
      if (geo === c.code) {
        color = colorScale(c.contrats);
      }
    });

    return (color);

  }

  // TO FILL THE REGIONS
  const handleFillRegions = (geo) => {

    // We create a color scale with min and max contracts in regions
    let color = '#000'
    let min = 1;
    let max = Math.max.apply(Math, regionsNew.map(function (o) { return o.contrats; }));
    const colorScale = scaleLinear().domain([min, max]).range(['#FFF', "#C31D23"]);

    // We apply it to the map

    regionsNew.map(c => {
      if (geo === c.code) {
        color = colorScale(c.contrats);
      }
    });

    return (color);

  }

  // WHEN THE MOUSE GOES ON THE MAP
  const handleHoverMapRegions = (nom, codeRegion) => {

    setRegion({ nom: nom, code: codeRegion });

    regionsNew.map(r => {
      if (r.code === codeRegion)
        setQuotescountRegion(r.contrats)
    })


    setDepartementsList(departementsNew.filter(d => d.codeRegion === codeRegion));
  }

  // WHEN THE MOUSE GOES OUT THE MAP
  const handleNotHoverMapRegions = (nom, codeRegion) => {
    getDpts().then((res) => setDepartements(res.data));
    setRegion({ nom: 'France', code: 0 });
    setQuotescountRegion(quotes.length);
  }

  // WHEN THE MOUSE GOES ON THE MAP
  const handleHoverMapDpts = (nom, code) => {
    //setRegion({ nom: nom, code: codeRegion });
    setDepartement({ nom: nom, code: code });
    //setRegion({ nom: nom, code: code });

    departementsNew.map(d => {
      if (d.code === code)
        setQuotescountDpt(d.contrats)
    })

    //setDepartementsNew(departementsNew.filter(d => d.code === code));

  }

  // WHEN THE MOUSE GOES OUT THE MAP
  const handleNotHoverMapDpts = (nom, codeRegion) => {
    // setDepartementsNew(departements);
    //setRegion({ nom: 'France', code: 0 });
    setDepartement({ nom: 'France', code: 0 });
    setQuotescountDpt(quotes.length);
  }



  return (
    <>

      <Grid container spacing={1} style={{ padding: 20 }}>

        <Grid container alignItems='center' style={{ margin: 'auto', textAlign: 'left', borderRadius: 10, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", margin: 20 }}>
          <MapComponent geoUrl={'./../../regionsFrance.geojson'} quotes={quotes} handleFillMap={handleFillRegions} handleHoverMap={handleHoverMapRegions} handleNotHoverMap={handleNotHoverMapRegions} strokeColor={'#C31D2350'} />

          {quotes && <Grid item xs={12} md={3} style={{ margin: 'auto', textAlign: 'left', borderRadius: 10, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", padding: 20 }}>
            <Typography variant='h6' style={{ fontSize: "14px", textAlign: 'left', paddingBottom: 5, fontWeight: 400, color: "#c31d2380" }}>Régionnal</Typography>
            <Divider style={{ backgroundColor: '#c31d2330' }} />
            <Typography variant='h3' style={{ fontSize: "28px", paddingTop: 20, textAlign: 'left' }}>{region.nom}</Typography>
            <>
              <Typography variant="h6" style={{ color: "#444", fontSize: "16px" }}>Contrats signés :  <strong>{quotescountRegion}</strong></Typography>
              {region.nom !== 'France' && <Typography variant="h3" style={{ paddingTop: 20, paddingBottom: 10 }}>Départements</Typography>}
              {region.nom !== 'France' && departementsList.map((d, index) => <Typography variant="body1" key={index}>{d.nom} : <strong>{d.contrats}</strong></Typography>)}
            </>

          </Grid>}

        </Grid>


        <Grid container alignItems='center' style={{ margin: 'auto', textAlign: 'left', borderRadius: 10, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", margin: 20 }}>


          {quotes && <Grid item xs={12} md={3} style={{ margin: 'auto', textAlign: 'left' }}>
            <div style={{ borderRadius: 10, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", padding: 20 }}>
              <Typography variant='h6' style={{ fontSize: "14px", paddingBottom: 5, fontWeight: 400, color: "#c31d2380" }}>Départemental</Typography>
              <Divider style={{ backgroundColor: '#c31d2330' }} />
              <Typography variant='h3' style={{ fontSize: "28px", paddingTop: 20 }}>{departement.nom}</Typography>
              <>
                <Typography variant="h6" style={{ color: "#444", fontSize: "16px" }}>Contrats signés :  <strong>{quotescountDpt}</strong></Typography>

              </>
            </div>
          </Grid>}


          <MapComponent geoUrl={'./../../departementsFrance.geojson'} quotes={quotes} handleHoverMap={handleHoverMapDpts} handleNotHoverMap={handleNotHoverMapDpts} handleFillMap={handleFillDepartements} strokeColor={'#C31D2350'} />


        </Grid>
      </Grid>
    </>
  )
}

export default Map