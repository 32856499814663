import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Grid, Typography, Button, Avatar, Box } from "@material-ui/core";
import { FaUserCircle } from "react-icons/fa";
import TextFieldCustom from "../../Components/FormComponents/TextFieldCustom";
import CustomHeader from "../../Components/Header/CustomHeader";
import { useDispatch, useSelector } from "react-redux";
import { formatErrorEmail, formatErrorPhoneNumber, formatErrorZip, passwordChecker} from "../../utils/formValidation";
import CustomModal from "../../Components/Commons/Modal/CustomModal";
import { changePassword, getmypicture, changeProfile } from "../../Axios/Call/AuthApi";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import { isObjectEmpty, toBase64 } from "../../utils/utils";
import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular";
import { populateUserData } from "../../Store/action";
import PasswordCheck from "../../Components/Commons/PasswordCheck/PasswordCheck";

const Profil = () => {
  const user = useSelector((state) => state.user?.userData);
  const [openImage, setopenImage] = useState(false);
  const [openPassword, setopenPassword] = useState(false);
  const [actualPassword, setactualPassword] = useState(undefined);
  const [newPassword, setnewPassword] = useState("");
  const [newPassCheck, setnewPassCheck] = useState({
    color: "white",
    text: "",
  });
  const [newPasswordBis, setnewPasswordBis] = useState("");
  const [newPassBisCheck, setnewPassBisCheck] = useState({
    color: "white",
    text: "",
  });
  const [actualPicture, setactualPicture] = useState("");
  const [newpicture, setnewpicture] = useState("");
  const [errorActualPassword, seterrorActualPassword] = useState(false);
  const [errorApi, seterrorApi] = useState(false);
  const [successApi, setsuccesApi] = useState(false);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const [identicalAlert, setIdenticalAlert] = useState(false);
  const [complexityAlert, setComplexityAlert] = useState(false);

  const[state, setState]=useState({
      password:'',
      confirmPassword:'',
      identical:false,
      complexity:'Faible',
  });

  const send = (data) => {
    changeProfile({
      name: data.name,
      surname: data.surname,
      email: data.email,
      mobile: data.mobile,
      address: data.address,
      code_postal: data.code_postal,
      city: data.city,
      status: user.status,
      type: user.type,
    })
      .then((res) => {

        if (res.status === 200 || res.status === 201) {
          setsuccesApi(true);
          dispatch(populateUserData());
        } else {
          seterrorApi(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const handlePass = (e) => {
    setnewPassword(e.target.value);
    setnewPassCheck(passwordChecker(e.target.value));
  };

  const handlePassBis = (e) => {
    setnewPasswordBis(e.target.value);
    setnewPassBisCheck(passwordChecker(e.target.value));
  };

  // CHECK PASSWORD AND CONFIRM PASSWORD
  const handlePassword = (password, confirmPassword) => {
    setnewPassword(password.target.value);
    setnewPasswordBis(confirmPassword.target.value);
    setnewPassCheck(passwordChecker(password.target.value));
    setnewPassBisCheck(passwordChecker(confirmPassword.target.value));
  };

  const submitImage = async () => {
    setloading(true);
    const tmpPicture = await toBase64(newpicture);
    console.log(tmpPicture);
    return changeProfile({
      name: user?.name,
      surname: user?.surname,
      email: user?.email,
      mobile: user?.mobile,
      address: user?.address,
      code_postal: user?.code_postal,
      city: user?.city,
      file: { name: "Picture", base64: tmpPicture },
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setsuccesApi(true);
          getmypicture().then((res) => setactualPicture(res.data));
        } else {
          seterrorApi(true);
        }
      })
      .finally(() => {
        handleClosePicture();
        setloading(false);
      });
  };

  const handleClosePicture = () => {
    setnewpicture("");
    return setopenImage(false);
  };

  const submitNewPassword = (e) => {
        if (state.identical) {
          if(state.complexity === 'Bonne' || state.complexity === 'Excellente') {
          return changePassword({
            password: newPassword,
            confirm_password: newPasswordBis,
          }).then((res) => {
            if (res.status === 200) {
              setsuccesApi(true);
              handleClosePassWord();
            } else {
              seterrorApi(true);
              handleClosePassWord();
            }
          });
        }
        else {
          setComplexityAlert(true);
        }
        }
        else {
          setIdenticalAlert(true);
        }
    
  };

  const handleClosePassWord = () => {
    setopenPassword("");
    setnewPassword("");
    setactualPassword(undefined);
  };

  // FORM
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "all",
  });

  // USEEFFECT

  useEffect(() => {
    setloading(true);
    getmypicture().then((res) => setactualPicture(res.data));
    setloading(false);
  }, []);

  return (
    <>
      {errorActualPassword && (
        <AlertGeneral
          color="danger"
          description="Le mot de passe actuel est erroné"
          setTrigger={seterrorActualPassword}
        />
      )}
      {successApi && (
        <AlertGeneral
          color=""
          description="Enregistré !"
          setTrigger={setsuccesApi}
        />
      )}
      {errorApi && (
        <AlertGeneral
          color="danger"
          description="Une erreur est survenue"
          setTrigger={seterrorApi}
        />
      )}
         {complexityAlert && (
        <AlertGeneral
          color="danger"
          description="Le mot de passe n'est pas assez complexe"
          setTrigger={setComplexityAlert}
        />
      )}


{identicalAlert && (
        <AlertGeneral
          color="danger"
          setTrigger={setIdenticalAlert}
          description={"Les mdp ne sont pas identiques"}
        />
      )}


      <CustomHeader icon={<FaUserCircle size={40} style={{ fill: "rgb(195, 29, 35)", marginRight: ".5rem" }} />} pageName="Mon Profil" />

      {isObjectEmpty(user)
        ? <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} height={"200px"}>
          <LoaderCircular />
          <Box height={"20px"} />
          <Typography variant="h4" style={{ color: "black" }}>Chargement des données</Typography>
        </Box>
        :
        <Grid container spacing={1} style={{ paddingLeft: "4rem" }}>
          <Grid item xs={12} lg={8}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <Avatar
                  src={actualPicture}
                  style={{
                    width: "125px",
                    height: "125px",
                    boxShadow: "0 1px 4px 2px lightgrey",
                  }}
                />
              </Grid>
              <Grid item xs={9} style={{ textAlign: "left" }}>
                <Button
                  type="button"
                  size="large"
                  className={"buttonGeneralStyle"}
                  style={{
                    maxWidth: "275px",
                  }}
                  onClick={() => setopenImage(true)}
                >
                  Modifier la photo de profil
                </Button>
                <Typography component="p" variant="body1">
                  Maximum 1Mo. Formats acceptés: JPG, PNG.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/*  */}
          <form onSubmit={handleSubmit(send)}>
            <Grid item xs={12} lg={10} style={{ marginTop: "3rem" }}>
              <Typography variant="h3" style={{ marginBottom: "1rem", textAlign: 'left' }}>
                Informations personnelles
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={7}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextFieldCustom
                        label="Prénom"
                        type="text"
                        ref={register({ required: 'Champ requis' })}
                        name="name"
                        defaultValue={user?.name}
                        error={errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextFieldCustom
                        label="Nom"
                        type="text"
                        ref={register({ required: 'Champ requis' })}
                        name="surname"
                        error={errors.surname}
                        defaultValue={user?.surname}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextFieldCustom
                        label="Téléphone portable"
                        type="tel"
                        name="mobile"
                        ref={register({
                          validate: {formatErrorPhoneNumber}
                        })}
                        error={errors.mobile}
                        defaultValue={user?.mobile}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextFieldCustom
                        label="Email / Identifiant"
                        type="email"
                        name="email"
                        ref={register({
                          required: 'Champ requis',
                          validate: {formatErrorEmail},
                        })}
                        defaultValue={user?.email}
                        error={errors.email}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={7} style={{ marginTop: "1rem" }}>
                  <Typography variant="h3" style={{ marginBottom: "1rem", textAlign: 'left' }}>
                    Adresse
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldCustom
                        label="Adresse"
                        type="text"
                        ref={register({})}
                        name="address"
                        defaultValue={user?.address}
                        error={errors.address}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextFieldCustom
                        label="Code Postal"
                        type="text"
                        ref={register({})}
                        name="code_postal"
                        defaultValue={user?.code_postal}
                        error={errors.code_postal}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextFieldCustom
                        label="Ville"
                        type="text"
                        ref={register({ required: '' })}
                        name="city"
                        defaultValue={user?.city}
                        error={errors.city}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/*  */}
            <Grid item xs={12} lg={7} style={{ marginTop: "3rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Button
                    size="large"
                    type="button"
                    className={"buttonGeneralStyle"}
                    style={{
                      maxWidth: "275px",
                      maxHeight: "none",
                      height: "33.5px",
                      marginTop: "10px",
                    }}
                    onClick={() => setopenPassword(true)}
                  >
                    Changer mon mot de passe
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={10} style={{ marginTop: "2rem" }}>
                <Box style={{width: "100%", marginTop: "10%",}}>
                  <Button className={"buttonGeneralStyle"} style={{ maxWidth: "250px" }} type="submit">
                    ENREGISTRER
                  </Button>
                </Box>
              </Grid>
            </Grid>
            {/*  */}
          </form>
        </Grid>
      }

      <CustomModal open={openImage} handleClose={handleClosePicture} size={300} loading={loading}>
        <input type="file" name="picture" onChange={(e) => setnewpicture(e.target.files[0])} accept="image/*" size="1000"/>
        {newpicture && (
          <>
            <Avatar src={URL.createObjectURL(newpicture)} style={{ width: "150px", height: "150px", margin: "2rem auto 0" }} alt="preview"/>
            <Box className="spacer2" />
            <Button
              type="button"
              className="buttonGeneralStyle"
              onClick={() => submitImage()}
            >
              Enregister
            </Button>
          </>
        )}
      </CustomModal>
      <CustomModal open={openPassword} handleClose={handleClosePassWord} size={400}>
        <Grid container spacing={2}>
          
          {/*<Grid item xs={12}>
            {" "}
            <TextField
              label="Mot de passe actuel"
              variant="outlined"
              name="actual"
              fullWidth
              onChange={(e) => setactualPassword(e.target.value)}
              type="password"
            />
        </Grid>*/}

          <Grid item xs={12}>
            <PasswordCheck setState={setState} />
          </Grid>

          <Grid item xs={12}>
            <Button
              className="buttonGeneralStyle"
              onClick={() => submitNewPassword()}
              style={{ width: "100%", maxWidth: "none" }}
            >
              Enregister
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};

export default Profil;
