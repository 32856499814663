import text from "./text";
import SSN from "french-ssn";
import siret from "siret";

// ----------------- Hook Form Validation ---------------

export const formatErrorLetters = (value) => {
  return (
    /^[A-Za-ëíìîïóñòôøõöúùûüßÿçÇÀÁÂÃÄÅÉÈÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÆÑØ][a-zA-Z'áàâåãäæéèêëíìîïóñòôøõöúùûüßÿçÇÀÁÂÃÄÅÉÈÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÆÑØ ]*$/.test(
      value
    ) || text.General.formatError
  );
};

export const formatErrorPhoneNumber = (value) => {
  if (value) {
    return /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(value) || text.General.formatError;
  } else {
    return null;
  }
};

export const formatErrorSSN = (value) => {
  return SSN.validate(value) || "Code erroné";
};

export const formatErrorSiret = (value) => {
  return siret.isSIRET(value) || "Siret incorrect";
};

export const formatErrorZip = (value) => {
  return value.length === 5 || "Code postal invalide.";
};

export const formatErrorBeforeDate = (value) => {
  return new Date(value) < new Date() || "Date erronée.";
};

export const formatErrorAfterDate = (value) => {
  return new Date(value) > new Date() || "Date erronée.";
};

export const formatErrorEmail = (value) => {
  return (
    /^[a-zA-Z0-9]([a-zA-Z0-9._-]+)@(([a-zA-Z0-9-]+\.)+)([a-zA-Z]{2,4}|[0-9]{1,3})$/.test(
      value
    ) || text.General.formatError
  );
};

export const emailNotSame = (emailConfirmation, value) => {
  if (value.trim().toLowerCase() !== emailConfirmation.trim().toLowerCase()) {
    return text.General.emailNotMatching;
  }

  return null;
};

export const passwordChecker = (PasswordParameter) => {
  const strongPassword = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );
  
  const mediumPassword = new RegExp(
    "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
  );
  if (strongPassword.test(PasswordParameter)) {
    return { color: "green", text: "Fort" };
  } else if (mediumPassword.test(PasswordParameter)) {
    return { color: "blue", text: "Moyen" };
  } else {
    return { color: "red", text: "Faible" };
  }
};


export const errorPasswordNotSame = (value, otherPassword) => {
  if (value && otherPassword && value === otherPassword) {
    return null;
  } else {
    return "Les mots de passe ne sont pas identiques";
  }
};


export const errorPassword = (value) => {
  return value.length === 5 || text.General.formatError;
};