import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import fr from "date-fns/locale/fr";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import text from "../../utils/text";
import moment from "moment";
import "moment/locale/fr";
import { MdClear} from "react-icons/md";
import InputAdornment from "@material-ui/core/InputAdornment";

moment.locale("fr");

const useStyles = makeStyles({
  label: {
    fontFamily: ['"Helvetica Neue"', "Roboto", "-apple-system"].join(","),
    fontWeight: 700,
    color: "#2A3254",
  },
  input: {
    fontFamily: ['"Helvetica Neue"', "Roboto", "-apple-system"].join(","),
    fontSize: 14,
  },
  datePicker: {
    "& .MuiToolbar-root": {
      backgroundColor: "#C31D23 !important",
    },
    "& .MuiPickersDay-daySelected": {
      backgroundColor: "#C31D23 !important",
    },
    "& .MuiButton-label": {
      color: "#C31D23 !important",
    },
  },
});

const CommonDateTimePicker = ({ field, name, error, label, labelCustom, size, required, disabled }) => {
  const classes = useStyles();

  function handleClear(e) {
      e.stopPropagation();
      field.onChange(null);
  }

  return (
    <>
      <Grid container>
        {labelCustom && (
          <Grid item xs={12} align="left">
            <Typography component="span" className={classes.label}>
              {labelCustom}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={fr}
          >
            <KeyboardDatePicker
              margin="normal"
              name={name}
              DialogProps={{ className: classes.datePicker }}
              id="date-picker-dialog"
              label={label}
              inputVariant="outlined"
              size={size}
              format="dd-MM-yyyy"
              value={field.value}
              // defaultValue={null}
              onChange={(date) => field.onChange(moment(date).format("YYYY-MM-DD"))}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              error={!!error}
              invalidDateMessage={
                <Typography component="span" style={{ color: "red" }}>
                  {text.General.errorDate}
                </Typography>
              }
              style={{ margin: 0, width:"100%" }}
              required={required}
              disabled={disabled}
              InputProps={field.value && {
                startAdornment:
                  <InputAdornment onClick={e => handleClear(e)}>
                    <MdClear size={18} fill={"#757575"} />
                  </InputAdornment>
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>

      <Typography component="span" style={{ color: "red" }}>
        {error?.message}
      </Typography>
    </>
  );
};

export default CommonDateTimePicker;
