
import {useRef} from 'react';
import CustomHeader from '../../Components/Header/CustomHeader';
import { Grid } from '@mui/material';
import Frame from 'react-frame-component';

//ICONS
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';




const Gestion = () => {

const url = "https://monespacepersonnel.cimut.net/web/mba-alm";

  return (

    <>
    <CustomHeader
    icon={
      <SettingsSuggestIcon
        style={{ fill: "rgb(195, 29, 35)", marginRight: ".5rem", width: 50, height:'30px' }}
      />
    }
    pageName={`Gestion`}
  />


<Grid container height='100vh' style={{padding:30}}>
  <iframe width='1920px' height='100%' src={url} style={{borderRadius:5, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", border: 'solid 0px #FFF'}} xFrameOptions='deny'></iframe>
</Grid>
  </>

  )
}

export default Gestion