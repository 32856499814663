import React, { useState, useEffect } from "react"
import { Box, Typography, Grid, TextField, Button, MenuItem } from "@material-ui/core"
import { AiOutlineFileAdd } from "react-icons/ai"
import CommonDateTimePicker from "../../FormComponents/CommonDateTimePicker"
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack"
import CommonSelect from "../../FormComponents/CommonSelect"
import { set } from "date-fns"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const PDFHandler = ({ title, func, close, selectedData, assign, hasType }) => {
  const [name, setname] = useState("")
  const [type, settype] = useState("all")
  const [description, setdescription] = useState("")
  const [starting_date, setstarting_date] = useState(null)
  const [ending_date, setending_date] = useState(null)
  const [file, setfile] = useState(null)
  const [numpage, setNumpage] = useState(null)
  const [pageNumber, setpageNumber] = useState(1)
  const [errorEmpty, seterrorEmpty] = useState(false)
  const [assigned, setassigned] = useState(null)
  const [for_supp, setFor_supp] = useState("0")
  // PDF reading
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumpage(numPages)
  }
  const goToPrevPage = () => setpageNumber(pageNumber <= 1 ? 1 : pageNumber - 1)
  const goToNextPage = () => setpageNumber(pageNumber >= numpage ? numpage : pageNumber + 1)

  // funcs
  const handleErrorEmpty = () => {
    seterrorEmpty(true)
    const timer = setTimeout(() => {
      seterrorEmpty(false)
    }, 2500)
    return () => clearTimeout(timer)
  }

  const handleFunc = () => {
    if (name !== null && starting_date !== null && file !== null) {
      const newFile = {
        name,
        description,
        starting_date,
        ending_date,
        guarantee_id: assigned,
        file,
        type: hasType ? type : null,
        for_supp
      }
      close()
      return func(newFile)
    } else {
      handleErrorEmpty()
    }
  }

  // hooks
  useEffect(() => {
    if (selectedData) {
      setname(selectedData.name)
      setdescription(selectedData.description)
      setstarting_date(selectedData.starting_date)
      setending_date(selectedData.ending_date)
      setfile(selectedData.file)
      setassigned(selectedData.guarantee_id)
      settype(selectedData.type)
      setFor_supp(selectedData.for_supp)
    }
  }, [selectedData])

  return (
    <>
      <Box display="flex" alignItems="center">
        <AiOutlineFileAdd size={30} style={{ fill: "#C31D23", marginRight: "1rem" }} />
        <Typography variant="h3">{title}</Typography>
      </Box>
      {errorEmpty && (
        <Typography variant="body2" style={{ textAlign: "center", color: "#c31d23" }}>
          Veuillez remplir les champs obligatoires
        </Typography>
      )}
      <Box
        style={{
          padding: "1em",
          margin: "1em",
          display: "flex",
          flexDirection: "column",
          border: "1px solid #ffffff00",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              name="name"
              label="Titre du document"
              type="text"
              variant="outlined"
              fullWidth
              placeHolder=""
              value={name}
              required
              onChange={(e) => setname(e.target.value)}
            />
          </Grid>
          {hasType && (
            <Grid item xs={12} md={6}>
              <CommonSelect
                field={{ value: type, onChange: settype }}
                label={"En pièce jointe de"}
                options={[
                  { id: "all", value: "Tous" },
                  { id: "quote", value: "Devis" },
                  { id: "contract", value: "Contrat" },
                ]}
                required={true}
              />
            </Grid>
          )}
          
     </Grid>
     <Grid item xs={12} md={12}>
              <CommonSelect
                field={{ value: for_supp, onChange: setFor_supp }}
                label={"Apparait sur le produit complémentaire"}
                options={[
                  { id: "0", value: "Non" },
                  { id: "1", value: "Oui" },
                ]}
                required={true}
              />
            </Grid>


   
          <Grid item xs={12} md={3}>
            {!!assign && (
              <CommonSelect
                field={{ value: assigned, onChange: setassigned }}
                label={"Assigner à"}
                options={[
                  { id: null, value: "Au pack" },
                  ...assign?.map((g) => {
                    return { id: g.id, value: g.name }
                  }),
                ]}
                required={true}
              />
            )}
          </Grid>
        
       

          <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CommonDateTimePicker
              field={{ value: starting_date, onChange: setstarting_date }}
              label={"Début de commercialisation"}
              name={"starting_date"}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CommonDateTimePicker
              field={{ value: ending_date, onChange: setending_date }}
              label={"Fin de commercialisation"}
              name={"ending_date"}
            />
          </Grid>
          </Grid>
          

          <Grid container spacing={2} style={{marginTop:10}}>
          <Grid item xs={12}>
            <TextField type="file" variant="outlined" fullWidth onChange={(e) => setfile(e.target.files[0])} required />
            {file !== null && (
              <Box margin="1em 0">
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                  {file?.name}
                  {/* TODO: changer la largeur du PDF en fonction du deviceWidth ??? */}
                  <Page pageNumber={pageNumber} width={700} />
                </Document>
                <nav
                  style={{
                    margin: "1em auto",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Button variant="outlined" onClick={() => goToPrevPage()}>
                    Précédent
                  </Button>
                  <Button variant="outlined" onClick={() => goToNextPage()}>
                    Suivant
                  </Button>
                </nav>
                {numpage && (
                  <p style={{ margin: "0 auto" }}>
                    Page {pageNumber} of {numpage}
                  </p>
                )}
              </Box>
            )}
          </Grid>
          </Grid>
          <Grid item xs={12} style={{marginTop:10}}>
            <TextField
              rows={4}
              multiline
              fullWidth
              variant="outlined"
              name="description"
              value={description}
              label="Description"
              type="text"
              placeHolder=""
              onChange={(e) => setdescription(e.target.value)}
            />
          </Grid>

        <Button className="buttonGeneralStyle" style={{ margin: "1em auto 0" }} onClick={handleFunc}>
          ENREGISTRER
        </Button>
      </Box>
    </>
  )
}

export default PDFHandler
