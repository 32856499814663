import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Grid, Button, Typography } from '@material-ui/core';

const api_tiny = process.env.REACT_APP_TINY_API_KEY;
const MyEditor = ({saveNote,slug,content = '',title}) => {

  const editorRef = useRef(null);
  const save = () => {
    if (editorRef.current) {
      saveNote(slug,{text:editorRef.current.getContent()});
    }
  };

  return (

    <>
      <Typography variant='h3' style={{textAlign:'left', paddingBottom:20, paddingTop:20, fontSize:30}}>{title}</Typography>

      <Editor
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={content}
        apiKey={api_tiny}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap' ,'print', 'preview', 'anchor',
            'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
         /* enable title field in the Image dialog*/
         image_title: true,
         /* enable automatic uploads of images represented by blob or data URIs*/
         automatic_uploads: true,
         /*
           URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
           images_upload_url: 'postAcceptor.php',
           here we add custom filepicker only to Image dialog
         */
         file_picker_types: 'image',
         /* and here's our custom image picker*/
         file_picker_callback: function (cb, value, meta) {
           var input = document.createElement('input');
           input.setAttribute('type', 'file');
           input.setAttribute('accept', 'image/*');

           /*
             Note: In modern browsers input[type="file"] is functional without
             even adding it to the DOM, but that might not be the case in some older
             or quirky browsers like IE, so you might want to add it to the DOM
             just in case, and visually hide it. And do not forget do remove it
             once you do not need it anymore.
           */

           input.onchange = function () {
             var file = this.files[0];

             var reader = new FileReader();
             reader.onload = function () {
               /*
                 Note: Now we need to register the blob in TinyMCEs image blob
                 registry. In the next release this part hopefully won't be
                 necessary, as we are looking to handle it internally.
               */
               var id = 'blobid' + (new Date()).getTime();
               var blobCache =  window.tinymce.activeEditor.editorUpload.blobCache;
               var base64 = reader.result.split(',')[1];
               var blobInfo = blobCache.create(id, file, base64);
               blobCache.add(blobInfo);

               /* call the callback and populate the Title field with the file name */
               cb(blobInfo.blobUri(), { title: file.name });
             };
             reader.readAsDataURL(file);
           };

           input.click();
         },

          toolbar: 'undo redo | formatselect | ' +
            'bold italic forecolor backcolor fontsize | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | image',
            
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
        
      />
    
<Grid container justifyContent='center' alignItems='center' style={{paddingTop:10}}>
  <Grid item>
       <Button className="buttonGeneralStyle"   onClick={save}>
            Sauvegarder
          </Button>
          </Grid>
          </Grid>
    </>

  )
}

export default MyEditor;