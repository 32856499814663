import React, { useEffect, useState } from "react"
import CustomHeader from "../../Components/Header/CustomHeader"
import { FaUserFriends, FaRegTrashAlt } from "react-icons/fa"
import { BsSearch, BsPencilSquare } from "react-icons/bs"
import CustomModal from "../../Components/Commons/Modal/CustomModal"
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core"
import {
  addBusiness,
  getAllBusinesses,
  patchBusiness,
  getBusiness,
  deleteBusiness,
} from "../../Axios/Call/ClientCredential"
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral"
import UserComponentLayout from "../../Hoc/UserComponentLayout"

const Users = () => {
  const [open, setopen] = useState(false);
  const [openmodif, setopenmodif] = useState(false);
  const [opendelete, setopendelete] = useState(false);
  const [search, setsearch] = useState("");
  const [type, settype] = useState("");
  const [name, setname] = useState("");

  const [surname, setsurname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [status, setstatus] = useState("");
  const [data, setdata] = useState([]);
  const [focus, setfocus] = useState("");
  const [successapi, setsuccessapi] = useState(false);
  const [errorapi, seterrorapi] = useState(false);
  const [errormail, seterrormail] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const createData = (name, surname, email, mobile, status, type) => {
    return {
      name,
      surname,
      email,
      mobile,
      status,
      type,
    }
  };

  //   close new user modal and reset form
  const handleCloseModal = () => {
    setname("");
    setsurname("");
    setmobile("");
    setemail("");
    setstatus("");
    settype("");
    setfocus("");
    setopen(false);
    setopenmodif(false);
    setopendelete(false)
  };

  const handleNewUser = () => {
    const tmpData = createData(name, surname, email, mobile, status, type);
    addBusiness(tmpData)
      .then(() => {
        setsuccessapi(true);
        getAllBusinesses().then((res) => setdata(res.data))
      })
      .catch((error) => {
        if (error.response.data.email) {
          seterrormail(true)
        } else if (
          error.response.status !== 200 &&
          error.response.status !== 400
        ) {
          seterrorapi(true)
        }
      })
      .finally(() => {
        setname("");
        setsurname("");
        setemail("");
        setmobile("");
        setstatus("");
        settype("");
        handleCloseModal()
      })
  };

  const handleOpenModificationUser = (x) => {
    getBusiness(x).then((res) => {
      setname(res?.data?.name);
      setsurname(res?.data?.surname);
      setmobile(res?.data?.mobile);
      setemail(res?.data?.email);
      setstatus(res?.data?.status);
      settype(res?.data?.type)
    });
    setfocus(x);
    setopenmodif(true)
  };

  const handleSaveModificationUser = () => {
    const tmpData = createData(name, surname, email, mobile, status, type);
    patchBusiness(tmpData, focus).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setsuccessapi(true);
        getAllBusinesses().then((res) => setdata(res.data))
      } else {
        seterrorapi(true)
      }
    });
    setname("");
    setsurname("");
    setemail("");
    setmobile("");
    setstatus("");
    settype("");
    setfocus("");
    setopenmodif(false)
  };

  const handleOpenDeleteUser = (x) => {
    setfocus(x);
    setopendelete(true)
  };

  const handleDeleteUser = () => {
    deleteBusiness(focus).then((res) => {
      if (res.status === 200) {
        setsuccessapi(true);
        handleCloseModal();
        getAllBusinesses().then((res) => setdata(res.data))
      } else {
        seterrorapi(true)
      }
    })
  };

  // hooks

  useEffect(() => {
    getAllBusinesses().then((res) => {
      setdata(res.data)
    })
  }, []);


  useEffect(() => {
    setFilteredData(data?.filter(row =>
      row?.name?.toLowerCase().match(search.toLowerCase().trim()) || row?.surname?.toLowerCase().match(search.toLowerCase().trim()) || row?.email?.toLowerCase().match(search.toLocaleLowerCase().trim()) || row?.role?.toLowerCase().match(search.toLocaleLowerCase().trim())
    ));
  }, [data, search]);

  return (
    <>
      {successapi && (
        <AlertGeneral setTrigger={setsuccessapi} description="Enregistré !" />
      )}
      {errorapi && (
        <AlertGeneral
          setTrigger={setsuccessapi}
          description="Une erreur est survenue"
          color="danger"
        />
      )}
      {errormail && (
        <AlertGeneral
          setTrigger={seterrormail}
          description="Cet email est déjà utilisé"
          color="danger"
        />
      )}
      <CustomHeader
        icon={
          <FaUserFriends
            size={30}
            style={{ fill: "rgb(195, 29, 35)", marginRight: ".5rem" }}
          />
        }
        pageName={`Utilisateurs`}
      />
      {/* tools */}
      <Grid container spacing={1} style={{ margin: "1.5rem 0 1rem" }}>
        <Grid item xs={12} md={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Rechercher par nom, email"
            value={search}
            onChange={(e) => setsearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsSearch size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <UserComponentLayout>
            <Button
              className="buttonGeneralStyle"
              onClick={() => setopen(true)}
              style={{ width: "300", maxWidth: "none" }}
            >
              NOUVEL UTILISATEUR
            </Button>
          </UserComponentLayout>
        </Grid>
      </Grid>
      {/* tableau */}
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography component="p" variant="h6">Utilisateur</Typography>
              </TableCell>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography variant="h6">Rôle</Typography>
              </TableCell>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography variant="h6">Email</Typography>
              </TableCell>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography variant="h6">Téléphone</Typography>
              </TableCell>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography variant="h6">Statut</Typography>
              </TableCell>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography variant="h6">Action</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData?.filter(row =>
                  row.name.toLowerCase().match(search.toLowerCase().trim()) ||
                  row.surname.toLowerCase().trim() ||
                  row.role.toLowerCase().match(search.toLowerCase().trim())
              )?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{"&:last-child td, &:last-child th": { border: 0 }}}>
                  <TableCell align="left">
                    <Typography>{row.name} {row.surname}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography>{row.type === "admin" ? "Administrateur" : "Utilisateur"}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography>{row.email}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography>{row.mobile}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography style={{color: row.status === "active" ? "green" : "red"}}>
                      {row.status === "active" ? "Actif" : "Inactif"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <UserComponentLayout>
                      <BsPencilSquare
                        size={25}
                        style={{ cursor: "pointer", marginRight: ".8rem" }}
                        onClick={() => handleOpenModificationUser(row.id)}
                      />{" "}
                      <FaRegTrashAlt
                        size={25}
                        style={{ cursor: "pointer", fill: "#F24E1E" }}
                        onClick={() => handleOpenDeleteUser(row.id)}
                      />
                    </UserComponentLayout>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* modals */}
      <CustomModal open={open} handleClose={handleCloseModal}>
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <FaUserFriends
              size={30}
              style={{ fill: "rgb(195, 29, 35)", marginRight: ".5rem" }}
            />
            <Typography variant="h3">Utilisateur</Typography>
          </Box>

          <Box display="flex" alignItems="center" margin="1rem 0 .8rem">
            <Typography variant="h6" style={{ marginRight: "1rem" }}>
              Rôle
            </Typography>
            <RadioGroup name="type" row>
              <FormControlLabel
                value="admin"
                control={<Radio style={{ color: "#0E4DA4" }} />}
                label="Administrateur"
                checked={type === "admin"}
                onChange={(e) => settype(e.target.value)}
              />
              <FormControlLabel
                value="user"
                control={<Radio style={{ color: "#0E4DA4" }} />}
                label="Utilisateur (lecture seule sur les produits)"
                checked={type === "user"}
                onChange={(e) => settype(e.target.value)}
              />
            </RadioGroup>
          </Box>

          <Box display="flex" alignItems="center" margin="0 0 1.2rem">
            <Typography variant="h6" style={{ marginRight: "1rem" }}>
              État
            </Typography>
            <RadioGroup name="status" row>
              <FormControlLabel
                value={"active"}
                control={<Radio style={{ color: "#0E4DA4" }} />}
                label="Actif"
                checked={status === "active"}
                onChange={(e) => setstatus(e.target.value)}
              />
              <FormControlLabel
                value={"inactive"}
                control={<Radio style={{ color: "#0E4DA4" }} />}
                label="Inactif"
                checked={status === "inactive"}
                onChange={(e) => setstatus(e.target.value)}
              />
            </RadioGroup>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                name="firstname"
                label="Prénom"
                value={name}
                onChange={(e) => setname(e.target.value)}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="lastname"
                label="Nom"
                value={surname}
                onChange={(e) => setsurname(e.target.value)}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="email"
                name="email"
                label="Email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="tel"
                name="phone"
                label="Téléphone"
                value={mobile}
                onChange={(e) => setmobile(e.target.value)}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Button
            className="buttonGeneralStyle"
            onClick={() => handleNewUser()}
            style={{ alignSelf: "center", margin: "2rem 0 0" }}
          >
            ENREGISTRER
          </Button>
        </Box>
      </CustomModal>
      {/* modif */}
      <CustomModal open={openmodif} handleClose={handleCloseModal}>
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <FaUserFriends
              size={30}
              style={{ fill: "rgb(195, 29, 35)", marginRight: ".5rem" }}
            />
            <Typography variant="h3">Modifier l'utilisateur</Typography>
          </Box>
          <Box display="flex" alignItems="center" margin="1rem 0">
            <Typography variant="h6" style={{ marginRight: "1rem" }}>
              Rôle
            </Typography>
            <RadioGroup name="type" row>
              <FormControlLabel
                value="admin"
                control={<Radio style={{ color: "#0E4DA4" }} />}
                label="Administrateur"
                checked={type === "admin"}
                onChange={(e) => settype(e.target.value)}
              />
              <FormControlLabel
                value="user"
                control={<Radio style={{ color: "#0E4DA4" }} />}
                label="Utilisateur (lecture seule sur les produits)"
                checked={type === "user"}
                onChange={(e) => settype(e.target.value)}
              />
            </RadioGroup>
          </Box>
          <Box display="flex" alignItems="center" margin="1rem 0">
            <Typography variant="h6" style={{ marginRight: "1rem" }}>
              État
            </Typography>
            <RadioGroup name="status" row>
              <FormControlLabel
                value={"active"}
                control={<Radio style={{ color: "#0E4DA4" }} />}
                label="Actif"
                checked={status === "active"}
                onChange={(e) => setstatus(e.target.value)}
              />
              <FormControlLabel
                value={"inactive"}
                control={<Radio style={{ color: "#0E4DA4" }} />}
                label="Inactif"
                checked={status === "inactive"}
                onChange={(e) => setstatus(e.target.value)}
              />
            </RadioGroup>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                name="firstname"
                label="Prénom"
                value={name}
                onChange={(e) => setname(e.target.value)}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="lastname"
                label="Nom"
                value={surname}
                onChange={(e) => setsurname(e.target.value)}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="email"
                name="email"
                label="Email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="tel"
                name="phone"
                label="Téléphone"
                value={mobile}
                onChange={(e) => setmobile(e.target.value)}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Button
            className="buttonGeneralStyle"
            onClick={() => handleSaveModificationUser()}
            style={{ alignSelf: "center", margin: "2rem 0 0" }}
          >
            ENREGISTRER
          </Button>
        </Box>
      </CustomModal>
      {/* delete */}
      <CustomModal open={opendelete} handleClose={handleCloseModal} size={320}>
        <Typography variant="h3">Suppression de l'utilisateur</Typography>
        <Typography
          variant="body1"
          style={{ margin: "1rem 0 2rem", textAlign: "center" }}
        >
          Attention, cet utilisateur sera supprimé!
        </Typography>
        <Button
          className="buttonGeneralStyle"
          style={{ width: "100%", maxWidth: "none" }}
          onClick={() => handleDeleteUser()}
        >
          Supprimer
        </Button>
      </CustomModal>
    </>
  )
};

export default Users