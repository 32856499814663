import React, {useCallback, useEffect, useState} from "react"
import CustomHeader from "../../Components/Header/CustomHeader"
import { FaRegTrashAlt, FaUserFriends } from "react-icons/fa"
import { BsPencilSquare, BsSearch } from "react-icons/bs"
import {
    Box,
    Button,
    Grid,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core"

import { withStyles } from "@material-ui/core/styles"

import { useDispatch } from "react-redux"
import * as action from "../../Store/action/index"
import { useHistory } from "react-router-dom"
import { addDocument, deleteBroker, getAllBrokers } from "../../Axios/Call/ClientCredential"
import CustomModal from "../../Components/Commons/Modal/CustomModal"
import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular"
import moment from "moment"
import "moment/locale/fr"
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral"
import UserComponentLayout from "../../Hoc/UserComponentLayout"
import { defaultLabelDisplayedRows } from "../../utils/utils"
import EnhancedTableHead from "../../Components/Table/EnhancedTableHead"
import { getComparator, stableSort } from "../../utils/tableSort"
import exportToCsv from "../../utils/covertToCsv";

moment.locale("fr")

const StyledTableRow = withStyles({
    root: {
        background: "#FFF",
        marginTop: "10px!important",
        paddingTop: "10px!important",
        color: "black",
        height: 68,

        borderRadius: 10,
        "&:hover": {
            color: "black",
            background: "#F8F9FA",
        },
    },
})(TableRow)

const Courtiers = () => {
    const [open, setopen] = useState(false)
    const [search, setsearch] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [data, setdata] = useState([])
    const [toDelete, settoDelete] = useState(null)
    const history = useHistory()
    const dispatch = useDispatch()
    const [loading, setloading] = useState(false)
    const [successapi, setsuccessapi] = useState(false)
    const [errorapi, seterrorapi] = useState(false)
    const [filteredData, setFilteredData] = useState([])

    const [order, setOrder] = React.useState("asc")
    const [orderBy, setOrderBy] = React.useState("")

    const headCells = [
        {
            id: "code",
            label: "Code apporteur",
            isOrderable: true,
        },
        {
            id: "businessName",
            label: "Raison sociale",
            isOrderable: true,
        },
        {
            id: "email",
            label: "Email",
            isOrderable: true,
        },
        {
            id: "phone",
            label: "Téléphone",
            isOrderable: true,
        },
        {
            id: "siret",
            label: "SIRET",
            isOrderable: true,
        },
        {
            id: "orias",
            label: "ORIAS",
            isOrderable: true,
        },
        {
            id: "endValiditOrias",
            label: "Fin de validité ORIAS",
            isOrderable: true,
        },
        {
            id: "code_postal",
            label: "Code postal",
            isOrderable: true,
        },
        {
            id: "city",
            label: "Commune",
            isOrderable: true,
        },
        {
            id: "status",
            label: "État",
            isOrderable: true,
        },
        {
            id: "conventionSignedDate",
            label: "Conv. signée le",
            isOrderable: true,
        },
        {
            id: "connexionNumber",
            label: "Connexions",
            isOrderable: true,
        },
        {
            id: "actions",
            label: "Actions",
            isOrderable: true,
        },
    ]

    const createData = (
        code,
        businessName,
        email,
        phone,
        siret,
        orias,
        endValiditOrias,
        code_postal,
        city,
        status,
        conventionSignedDate,
        connexionNumber,
        id
    ) => {
        return {
            code,
            businessName,
            email,
            phone,
            siret,
            orias,
            endValiditOrias,
            code_postal,
            city,
            status,
            conventionSignedDate,
            connexionNumber,
            id,
        }
    }

    function controlDate(date) {
        moment(date).isBefore(moment())
        return moment(date) < moment() ? "#C31D23" : "inherit"
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleToDelete = (id) => {
        setopen(true)
        settoDelete(id)
    }

    const handleDelete = () => {
        setloading(true)
        deleteBroker(toDelete).then((res) => {
            if (res.status === 200 || res.status === 201) {
                setsuccessapi(true)
                getAllBrokers().then((res) => {
                    const tmpRows = res.data?.map((broker) => {
                        return createData(
                            broker?.code,
                            broker?.company,
                            broker?.email,
                            broker?.mobile,
                            broker?.siret,
                            broker?.orias,
                            broker?.orias_end_at,
                            broker?.code_postal,
                            broker?.city,
                            broker?.status,
                            broker?.convention_signed_at,
                            broker?.connection_number,
                            broker?.id
                        )
                    })
                    setdata(tmpRows)
                })
            } else {
                seterrorapi(true)
            }
        })
        settoDelete(null)
        setopen(false)
        setTimeout(() => {
            setloading(false)
        }, 500)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
    }

    const exportCsv = useCallback(() => {
        const headerFormatter = () => [
            "Code apporteur",
            "Raison sociale",
            "Email",
            "Téléphone",
            "SIRET",
            "ORIAS",
            "Fin de validité ORIAS",
            "Code postal",
            "Commune",
            "État",
            "Conv signée le",
            "Connexions",
        ]
        const formatter = (broker) => [
            broker?.code,
            broker?.businessName,
            broker?.email,
            broker?.phone,
            broker?.siret,
            broker?.orias,
            broker?.endValiditOrias,
            broker?.code_postal,
            broker?.city,
            broker?.status,
            broker?.conventionSignedDate,
            broker?.connexionNumber,
        ]
        exportToCsv({
            formatter,
            headerFormatter,
            data: filteredData,
            filename: "Courtiers.csv",
        })
    }, [filteredData])

    // hooks
    useEffect(() => {
        setloading(true)
        dispatch(action.populateAssoc())
        getAllBrokers()
            .then((res) => {
                const tmpRows = res.data?.filter(r => !r.broker_id).map((broker) => //Check if the broker is a principal broker to dislay it
                    createData(
                        broker?.code,
                        broker?.company,
                        broker?.email,
                        broker?.mobile,
                        broker?.siret,
                        broker?.orias,
                        broker?.orias_end_at,
                        broker?.code_postal,
                        broker?.city,
                        broker?.status,
                        broker?.convention_signed_at,
                        broker?.connection_number,
                        broker?.id
                    )
                )

                setdata(tmpRows)
                setloading(false)
            })
            .catch((error) => console.log(error))
        //TODO Remove this in the futur, if datas aren't fetching, we should push an error and display an alert
        // setTimeout(() => {
        //   setloading(false);
        // }, 500);

        addDocument({ type: "justification" })
    }, [])

    useEffect(() => {
        setFilteredData(
            data?.filter(
                (row) =>
                    row?.businessName?.toLowerCase().match(search.toLowerCase().trim()) ||
                    row?.email?.toLowerCase().match(search.toLowerCase().trim()) ||
                    row?.code?.toLowerCase().match(search.toLocaleLowerCase().trim()) ||
                    row?.code_postal?.toLowerCase().match(search.toLocaleLowerCase().trim())
            )
        )

        setPage(0)
    }, [data, search])

    return (
        <>
            {successapi && <AlertGeneral setTrigger={setsuccessapi} color="" description="Enregistré !" />}
            {errorapi && <AlertGeneral setTrigger={seterrorapi} color="danger" description="Une erreur est survenue" />}
            <CustomHeader
                icon={<FaUserFriends size={30} style={{ fill: "rgb(195, 29, 35)", marginRight: ".5rem" }} />}
                pageName={`Courtiers`}
                userName={"Username here"}
            />
            {/* tools */}
            <Grid container spacing={1} style={{ margin: "1.5rem 0 1rem" }}>
                <Grid item xs={12} md={4}>
                    <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Rechercher par nom, email"
                        value={search}
                        onChange={(e) => setsearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <BsSearch size={20} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <UserComponentLayout>
                        <Button className="buttonGeneralStyle" onClick={() => history.push("/Courtiers/Nouveau")}>
                            NOUVEAU COURTIER
                        </Button>
                    </UserComponentLayout>
                </Grid>
                <Grid item xs={12} md={2}>
                    <UserComponentLayout>
                        <Button className="buttonGeneralStyle" onClick={exportCsv}>
                            Exporter en CSV
                        </Button>
                    </UserComponentLayout>
                </Grid>
            </Grid>
            {/* tableau */}
            {loading === true ? (<Box className="spacer2"><LoaderCircular /></Box>
            ) : (
                <>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                headCells={headCells}
                            />

                            <TableBody>
                                {stableSort(filteredData, getComparator(order, orderBy))
                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    ?.map((row) => (
                                        <StyledTableRow
                                            key={row.id}
                                            sx={{"&:last-child td, &:last-child th": { border: 1 },}}
                                        >
                                            <TableCell align="left">
                                                <Typography variant="body2">{row.code}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{row.businessName}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{row.email}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{row.phone}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{row.siret}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{row.orias}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2" style={{ color: controlDate(row.endValiditOrias) }}>
                                                    {moment(row.endValiditOrias).format("DD/MM/yyyy")}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{row.code_postal}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{row.city}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography
                                                    variant="body2"
                                                    style={row.status === "inactive" ? { color: "red" } : { color: "green" }}
                                                >
                                                    {row.status === "inactive" ? "Inactif" : "Actif"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{moment(row.conventionSignedDate).format("DD/MM/yyyy")}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{(row.connexionNumber/2).toFixed(0)}</Typography>
                                            </TableCell>

                                            <TableCell align="left">
                                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                    <BsPencilSquare
                                                        size={20}
                                                        style={{ cursor: "pointer", marginRight: ".8rem" }}
                                                        onClick={() => history.push(`Courtiers/${row.id}/Modifier`)}
                                                    />{" "}
                                                    <UserComponentLayout>
                                                        <FaRegTrashAlt
                                                            size={20}
                                                            style={{ cursor: "pointer", fill: "#F24E1E" }}
                                                            onClick={() => handleToDelete(row.id)}
                                                        />
                                                    </UserComponentLayout>
                                                </Box>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        size="small"
                        variant="outlined"
                        count={data.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={() =>
                            defaultLabelDisplayedRows({
                                from: rowsPerPage * page + 1,
                                to:
                                    rowsPerPage * page + rowsPerPage > filteredData.length
                                        ? filteredData.length
                                        : rowsPerPage * page + rowsPerPage,
                                count: filteredData?.length,
                            })
                        }
                        labelRowsPerPage={"Lignes par page :"}
                    />
                </>
            )}

            <CustomModal open={open} handleClose={() => setopen(false)} size={250}>
                <Typography variant="h3">Suppression du courtier</Typography>
                <Typography variant="body1" style={{ margin: "1rem 0 2rem" }}>Attention, cet utilisateur sera supprimé !</Typography>
                <Button className="buttonGeneralStyle" fullWidth onClick={handleDelete}>Supprimer</Button>
            </CustomModal>
        </>
    )
}

export default Courtiers
