import React from 'react';
import { Typography } from '@mui/material';
import { BarChart, ResponsiveContainer, Bar, Tooltip, XAxis, YAxis, Legend } from 'recharts';

const VerticalBarChartCustom = ({ title, data }) => {

class CustomizedLabel extends React.Component {
    render() {
      const { x, y, fill, value } = this.props;
      return (
        <text
          x={x}
          y={y}
          dy={-4}
          fontSize="16"
          fontFamily="sans-serif"
          fill={fill}
          textAnchor="middle"
        >
          {value}
        </text>
      );
    }
  }


  return (
    <>
      <Typography variant="h4" m={3} style={{fontSize:25, fontWeight:500}}>
        {title}
      </Typography>
      <ResponsiveContainer height={330} >
        <BarChart
          margin={{ top: 30, right: 30, left: 30, bottom: 30}}
          data={data}
         
          layout="vertical"
          style={{
            fontSize: 10,
            fontFamily: ['Roboto', '-apple-system'].join(','),
            color: '#28395A',
            fontWeight:500,
            padding:10,
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", 
            borderRadius:"15px"
          }}
          
        >
          <XAxis type={'number'} />
          <YAxis dataKey="name" type="category" />
         
          <Tooltip />

          <Bar dataKey="value" stackId="a" fill="#c31d23" 
     
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default VerticalBarChartCustom;
