import {Select, Typography} from "@material-ui/core";
import React from "react";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import {
    calculateHTbasedOnTTC,
    calculateHTBaseOnPure,
    calculatePureBasedOnHT,
    calculateTTCBasedOnHT
} from "../../utils/utils";
import Box from "@material-ui/core/Box";

export function renderProductCell(params, products) {
    let product = products.find((product) => product.id === params.value)
    return <Typography>{product?.description}</Typography>
}
export function renderEditProductCell(params, products, setEditRowsModel) {
    const handleChange = (event) => {
        let id = params.id
        let field = params.field

        // Reset age field if market change.
        let isOldCompany = products.find((product) => product.id === params.value)?.market === "company"
        let isCurrentCompany = products.find((product) => product.id === parseInt(event.target.value))?.market === "company"
        if (isCurrentCompany !== isOldCompany) {
            isCurrentCompany
                ? setEditRowsModel((prevState) => {
                    prevState[params.id].age.value = [0, 0]
                    return prevState
                })
                : setEditRowsModel((prevState) => {
                    prevState[params.id].age.value = 0
                    return prevState
                })
        }

        params.api.setEditCellValue({ id, field, value: parseInt(event.target.value) }, event)
    }

    return (
        <Select native value={params.value} variant="outlined" onChange={(event) => handleChange(event)}>
            {products?.map((product) => (
                <option key={product.id} value={product.id} label={product.description}>
                    {product.description}
                </option>
            ))}
        </Select>
    )
}

export function renderGuarantyCell(params, garanties) {
    let guaranty = garanties.find((guaranty) => guaranty.id === params.value)
    return <Typography>{guaranty?.name}</Typography>
}
export function renderEditGuarantyCell(params, garanties) {
    const handleChange = (event) => {
        let id = params.id
        let field = params.field
        params.api.setEditCellValue({ id, field, value: parseInt(event.target.value) }, event)
    }

    return (
        <Select native value={params.value} variant="outlined" onChange={(event) => handleChange(event)}>
            {garanties?.map((garantie) => (
                <option key={garantie.id} value={garantie.id} label={garantie.name}>
                    {garantie.name}
                </option>
            ))}
        </Select>
    )
}

export function renderPercentSalaryCell(params) {
    return <Typography>{params.value}%</Typography>
}
export function renderEditPercentSalaryCell(params) {
    const handleChange = (event) => {
        let id = params.id;
        let field = params.field;
        params.api.setEditCellValue({id, field, value: parseFloat(event.target.value)}, event);
    };

    return(
        <TextField
            type="number"
            value={params.value}
            onChange={handleChange}
        />
    );
}

export function renderAgeCell(params) {
    let string
    if (Array.isArray(params.value)) {
        string = `${params.value[0]} - ${params.value[1]}`
    } else {
        string = params.value
    }
    return <Typography>{string} ans</Typography>
}
export function renderEditAgeCell(params, products, editRowsModel, setEditRowsModel, product,guarantee) {
    let id = params.id
    let field = params.field
    let [age, age_min, age_max] = [0, 0, 0]
    if (Array.isArray(params.value)) {
        [age_min, age_max] = params.value;
    } else {
        age = params.value
    }
    console.log(editRowsModel)
    let isCompany = product
        ? product.market === "company"
        : products?.find((product) => product.id === editRowsModel[params.id]?.product.value)?.market === "company"

    const handleChange = (event) => {
        let value = event.target.value === "" ? 0 : parseInt(event.target.value)
        params.api.setEditCellValue({ id, field, value: value }, event)
    }

    const handleChangeAgeMin = (event) => {
        age_min = event.target.value === "" ? 0 : parseInt(event.target.value)
        params.api.setEditCellValue({ id, field, value: [age_min, age_max] }, event)
        setEditRowsModel((prevState) => {
            prevState[params.id].age.value = [age_min, age_max]
            return prevState
        })
    }

    const handleChangeAgeMax = (event) => {
        age_max = event.target.value === "" ? 0 : parseInt(event.target.value)
        params.api.setEditCellValue({ id, field, value: [age_min, age_max] }, event)
        setEditRowsModel((prevState) => {
            prevState[params.id].age.value = [age_min, age_max]
            return prevState
        })
    }

    return (isCompany  || guarantee.typerate) == 1 ? (
        <Box sx={{ display: "flex", alignItems: "center", pr: 2, justifyContent: "center" }}>
            <TextField
                style={{ width: "30px" }}
                type="number"
                name="age_min"
                variant="standard"
                value={age_min}
                onChange={handleChangeAgeMin}
            />
            <TextField
                style={{ width: "30px" }}
                type="number"
                name="age_max"
                variant="standard"
                value={age_max}
                onChange={handleChangeAgeMax}
            />
        </Box>
    ) : (
        <TextField
            style={{ width: "30px" }}
            type="number"
            name="age_min"
            variant="standard"
            value={age}
            onChange={handleChange}
        />
    )
}

export function renderPriceTypeCell(params, priceTypes) {
    let priceType = priceTypes.find((type) => type.id === params.value)
    return <Typography>{priceType?.value}</Typography>
}
export function renderEditPriceTypeCell(params, priceTypes, setEditRowsModel) {
    const handleChange = (event) => {
        const id = params.id
        const field = params.field
        const value = parseInt(event.target.value)
        params.api.setEditCellValue({ id, field, value: value }, event)
        setEditRowsModel((prevState) => {
            prevState[id].priceType.value = value
            return prevState
        })
    }

    return (
        <Select native value={params.value} variant="outlined" onChange={(event) => handleChange(event)}>
            {priceTypes?.map((type) => (
                <option key={type.id} value={type.id} label={type.value}>
                    {type.value}
                </option>
            ))}
        </Select>
    )
}

export function renderPriceCell(params, priceTypes) {
    let priceType = priceTypes.find((type) => type.id === params.row.priceType)
    return (
        <Typography>
            {params.value.toFixed(priceType?.id === 2 ? 3 : 2)}
            {priceType?.value}
        </Typography>
    )
}
export function renderEditBasePriceCell(params, zones, editRowsModel, setEditRowsModel) {
    const handleChange = (event) => {
        let value = event.target.value === "" ? 0 : parseFloat(event.target.value)
        let id = params.id
        let field = params.field
        let coefZone = parseFloat(zones.find((zone) => zone.id === editRowsModel[id].zone.value)?.taux)

        let HT = calculateHTBaseOnPure(
            value,
            coefZone,
            editRowsModel[id].frais_gestion.value,
            editRowsModel[id].commission.value
        )
        let TTC = calculateTTCBasedOnHT(HT, editRowsModel[id].tva.value)

        setEditRowsModel((prevState) => {
            // prevState[id].base_price.value = value;
            prevState[id].price_no_tva.value = HT
            prevState[id].price_with_tva.value = TTC
            return prevState
        })
        params.api.setEditCellValue({ id, field, value: value }, event)
    }

    return (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <TextField type={"number"} value={params.value} onChange={handleChange} />
        </Box>
    )
}
export function renderEditHTCell(params, zones, editRowsModel, setEditRowsModel) {
    const handleChange = (event) => {
        let value = event.target.value === "" ? 0 : parseFloat(event.target.value)
        let id = params.id
        let field = params.field
        let coefZone = parseFloat(zones.find((zone) => zone.id === editRowsModel[id].zone.value)?.taux)

        let Pure = calculatePureBasedOnHT(
            value,
            coefZone,
            editRowsModel[id].frais_gestion.value,
            editRowsModel[id].commission.value
        )
        let TTC = calculateTTCBasedOnHT(value, editRowsModel[id].tva.value)

        setEditRowsModel((prevState) => {
            prevState[id].base_price.value = Pure
            prevState[id].price_with_tva.value = TTC
            return prevState
        })
        params.api.setEditCellValue({ id, field, value: value }, event)
    }

    return <TextField type="number" value={params.value} onChange={handleChange} />
}
export function renderEditTTCCell(params, zones, editRowsModel, setEditRowsModel) {
    const handleChange = (event) => {
        let value = event.target.value === "" ? 0 : parseFloat(event.target.value)
        let id = params.id
        let field = params.field
        let coefZone = parseFloat(zones.find((zone) => zone.id === editRowsModel[id].zone.value)?.taux)

        let HT = calculateHTbasedOnTTC(value, editRowsModel[id].tva.value)
        let Pure = calculatePureBasedOnHT(
            HT,
            coefZone,
            editRowsModel[id].frais_gestion.value,
            editRowsModel[id].commission.value
        )

        setEditRowsModel((prevState) => {
            prevState[id].base_price.value = Pure
            prevState[id].price_no_tva.value = HT
            return prevState
        })
        params.api.setEditCellValue({ id, field, value: value }, event)
    }

    return <TextField type="number" value={params.value} onChange={handleChange} />
}

export function renderZoneCell(params, zones) {
    let zone = zones.find((zone) => zone.id === params.value)
    return <Typography>{zone?.value}</Typography>
}
export function renderEditZoneCell(params, zones, editRowsModel, setEditRowsModel) {
    const handleChange = (event) => {
        let id = params.id
        let field = params.field
        let value = parseInt(event.target.value)

        let coefZone = parseFloat(zones.find((zone) => zone.id === value)?.taux)
        let Pure = editRowsModel[id].base_price.value
        let HT = calculateHTBaseOnPure(
            Pure,
            coefZone,
            editRowsModel[id].frais_gestion.value,
            editRowsModel[id].commission.value
        )
        let TTC = calculateTTCBasedOnHT(HT, editRowsModel[id].tva.value)

        setEditRowsModel((prevState) => {
            prevState[id].price_no_tva.value = HT
            prevState[id].price_with_tva.value = TTC
            return prevState
        })
        params.api.setEditCellValue({ id, field, value: value }, event)
    }

    return (
        <Select native value={params.value} variant="outlined" onChange={(event) => handleChange(event)}>
            {zones?.map((zone) => (
                <option key={zone.id} value={zone.id} label={zone.value}>
                    {zone.value}
                </option>
            ))}
        </Select>
    )
}

export function renderCommissionCell(params, commissions) {
    let commission = commissions.find(commission => commission.id === params.value)
    return <Typography>{commission?.value}</Typography>
}
export function renderEditCommissionCell(params, zones, commissions, editRowsModel, setEditRowsModel) {
    const handleChange = (event) => {
        let id = params.id
        let field = params.field
        let value = parseFloat(event.target.value)
        params.api.setEditCellValue({ id, field, value: value }, event)
        let coefZone = parseFloat(zones.find((zone) => zone.id === editRowsModel[id].zone.value)?.taux)
        let Pure = editRowsModel[id].base_price.value

        let HT = calculateHTBaseOnPure(Pure, coefZone, editRowsModel[id].frais_gestion.value, value)
        let TTC = calculateTTCBasedOnHT(HT, editRowsModel[id].tva.value)

        setEditRowsModel((prevState) => {
            prevState[id].price_no_tva.value = HT
            prevState[id].price_with_tva.value = TTC
            return prevState
        })
    }

    return (
        <Select
            style={{ width: "100%" }}
            native
            value={params.value}
            variant="outlined"
            onChange={event => handleChange(event)}
        >
            {commissions?.map((commission) => (
                <option key={commission.id} value={commission.id} label={commission.value}>
                    {commission.value}
                </option>
            ))}
        </Select>
    )
}

export function renderFraisGestionCell(params, frais_gestion) {
    let frais = frais_gestion.find(frais => frais.id === params.value)
    return <Typography>{frais?.value}</Typography>
}
export function renderEditFraisGestionCell(params, zones, frais_gestion, editRowsModel, setEditRowsModel) {
    const handleChange = event => {
        let id = params.id
        let field = params.field
        let value = parseFloat(event.target.value)
        params.api.setEditCellValue({ id, field, value: value }, event)
        let coefZone = parseFloat(zones.find(zone => zone.id === editRowsModel[id].zone.value)?.taux)
        let Pure = editRowsModel[id].base_price.value

        let HT = calculateHTBaseOnPure(Pure, coefZone, value, editRowsModel[id].commission.value)
        let TTC = calculateTTCBasedOnHT(HT, editRowsModel[id].tva.value)

        setEditRowsModel((prevState) => {
            prevState[params.id].price_no_tva.value = HT
            prevState[params.id].price_with_tva.value = TTC
            return prevState
        })
    }

    return (
        <Select
            style={{ width: "100%" }}
            native
            value={params.value}
            variant="outlined"
            onChange={event => handleChange(event)}
        >
            {frais_gestion?.map(frais => (
                <option key={frais.id} value={frais.id} label={frais.value}>{frais.value}</option>
            ))}
        </Select>
    )
}

export function renderRegimeCell(params, regimes) {
    let regime = regimes.find(regime => regime.id === params.value)
    return <Typography>{regime?.value}</Typography>
}
export function renderEditRegimeCell(params, products, regimes, editRowsModel, product) {
    let isCompany = product
        ? product.market === 'company'
        : products?.find((product) => product.id === editRowsModel[params.id]?.product.value)?.market === "company"

    const handleChange = (event) => {
        let id = params.id
        let field = params.field
        params.api.setEditCellValue({ id, field, value: parseInt(event.target.value) }, event)
    }

    return (
        <Select
            style={{ width: "100%" }}
            native
            value={params.value}
            variant="outlined"
            onChange={(event) => handleChange(event)}
        >
            {regimes
                ?.filter(regime => regime.market === (isCompany ? 1 : 0))
                ?.map(regime => (<option key={regime.id} value={regime.id} label={regime.value}>{regime.value}</option>))
            }
        </Select>
    )
}

export function renderStructureCell(params, structures) {
    let structure = structures.find((structure) => structure.id === params.value)
    return <Typography>{structure?.value}</Typography>
}
export function renderEditStructureCell(params, structures) {
    const handleChange = (event) => {
        let id = params.id
        let field = params.field
        params.api.setEditCellValue({ id, field, value: event.target.value }, event)
    }

    return (
        <Select
            style={{ width: "100%" }}
            native
            value={params.value}
            variant="outlined"
            onChange={(event) => handleChange(event)}
        >
            {structures?.map((structure) => (
                <option key={structure.id} value={structure.id} label={structure.value}>
                    {structure.value}
                </option>
            ))}
        </Select>
    )
}

export function renderTvaCell(params, tvas) {
    let tva = tvas.find(tva => tva.id === params.value);
    return <Typography>{tva?.value}</Typography>
}
export function renderEditTvaCell(params, tvas, editRowsModel, setEditRowsModel) {
    const handleChange = (event) => {
        let id = params.id;
        let field = params.field;
        let value = parseFloat(event.target.value);
        params.api.setEditCellValue({id, field, value: value}, event);

        let HT = editRowsModel[id].price_no_tva.value;
        let TTC = calculateTTCBasedOnHT(HT, value);

        setEditRowsModel(prevState => {
            prevState[params.id].price_with_tva.value = TTC;
            return prevState;
        });
    };

    return (
        <Select
            style={{width: "100%"}}
            native
            value={params.value}
            variant="outlined"
            onChange={event => handleChange(event)}
        >
            {tvas?.map(tva =>
                <option key={tva.id} value={tva.id} label={tva.value}>{tva.value}</option>
            )}
        </Select>
    )
}

export function renderDateCell(params) {
    let date = moment(params.value).format("DD/MM/YYYY")
    return <Typography>{date}</Typography>
}