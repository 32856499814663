import React from "react";

import classes from "./ErrorPage.module.css";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import animation from "../../Assets/Animation/403animation.json";
import Lottie from "react-lottie";
import { useWindowWidth } from "@react-hook/window-size";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core//Container";
import text from "../../utils/text";

const Page403 = () => {
  const screenWidth = useWindowWidth();
  const defualtOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    renderSettings: {
      preserveAspectRation: "xMidYmid slice",
    },
  };

  return (
    <>
      <Container fluid>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <Grid item xs={1}>
            <Link to="/">
              <FaHome size={30} color="#9a76ff" />
            </Link>
          </Grid>
          <Grid item xs={11}>
            <span className={classes.nameError}>{text.ErrorPage.error404}</span>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={12}>
            <Lottie
              options={defualtOptions}
              width={screenWidth > 1025 ? "30%" : "100%"}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default Page403;
