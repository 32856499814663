import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Container,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
} from "@material-ui/core";
import { BsArrowRightShort } from "react-icons/bs";
import { BsArrowLeftShort } from "react-icons/bs";
import TextFieldCustom from "../../Components/FormComponents/TextFieldCustom";
import { Link, useHistory, useParams } from "react-router-dom";
import { formatErrorEmail } from "../../utils/formValidation";
import text from "../../utils/text";
import { login } from "../../Axios/Call/ClientCredential";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreator from "../../Store/action/index";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import AuthLayout from "../../Components/Auth/Registration/AuthLayout";
import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular";
import Links from "../../Components/Commons/Links/Links";
import { resetPasswordApi } from "../../Axios/Call/ClientCredential";
import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import { makeStyles } from "@material-ui/core";
import PasswordCheck from "../../Components/Commons/PasswordCheck/PasswordCheck";


import {
  passwordChecker,
  errorPasswordNotSame,
  formatErrorLetters,
  formatErrorPhoneNumber,
} from "../../utils/formValidation";

const ResetPassword = () => {

  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);

  const [emailSuccess, setEmailSuccess] = useState(false);
  const history = useHistory();
  const { token } = useParams();
  const { register, handleSubmit, errors, watch: watch2, watch, register: register2, errors: errors2 } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const [newPassword, setnewPassword] = useState("");
  const [newPasswordBis, setnewPasswordBis] = useState("");
  
  const [complexityAlert, setComplexityAlert] = useState(false);
  const [identicalAlert, setIdenticalAlert] = useState(false);

  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
    identical: false,
    complexity:'Faible',
  });

 


  const sendFormLogin = (data) => {

    if (state.identical) {
      if(state.complexity === 'Bonne' || state.complexity === 'Excellente') {
    setLoadingApi(true);
    resetPasswordApi({ password: state.password, reset_token: token })
      .then((response) => {
        setEmailSuccess(true);
        setLoadingApi(false);
        setTimeout(() => {
          history.push("/");
        }, 1000);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
    }
    else {
      setComplexityAlert(true);
    }
  }
    else {
      setIdenticalAlert(true);
    }

  };

  return (
    <AuthLayout>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.RecoverPassword.step2FooterText}
        />
      )}

      {emailSuccess && (
        <AlertGeneral
          color="success"
          setTrigger={setEmailSuccess}
          description={"Votre mot de passe a bien été mis à jour"}
        />
      )}

      {complexityAlert && (
        <AlertGeneral
          color="danger"
          description="Le mot de passe n'est pas assez complexe"
          setTrigger={setComplexityAlert}
        />
      )}


      {identicalAlert && (
        <AlertGeneral
          color="danger"
          setTrigger={setIdenticalAlert}
          description={"Les mots de passe ne sont pas identiques"}
        />
      )}



      <Box style={{ textAlign: "left" }}>
        <Typography component="h2" variant="h1" style={{ fontSize: "33px" }}>
          {/* {text.Login.title} */}
          Nouveau mot de passe
        </Typography>

        <Typography
          component="h2"
          variant="h3"
          style={{ fontSize: "14px", color: "#222", marginTop: "20px" }}
        >
          Pour des raisons de sécurité, le mot de passe doit contenir au moins 8 caractères, au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre.
        </Typography>



      </Box>
      <form
        onSubmit={handleSubmit(sendFormLogin)}
        style={{ marginTop: 25 }}
        noValidate
      >
        <Grid container>



          <Grid item xs={12}>
            <PasswordCheck setState={setState} />
          </Grid>



          <Link
            to={"/"}
            style={{ textDecoration: "none", margin: "0.4rem auto" }}
          >
            <Typography component="span" className={"linkBottomGrey"}>
              Retour à la connexion
            </Typography>
          </Link>

          <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
            {loadingApi ? (
              <LoaderCircular />
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                width="auto"

                endIcon={<BsArrowRightShort />}
              >
                Changer mon mot de passe
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
      <Links />
    </AuthLayout>
  );
};

export default ResetPassword;
