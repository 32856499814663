import React, { useEffect, useState } from "react"
import CustomHeader from "../../Components/Header/CustomHeader"
import Back from "../../Components/Commons/Back/Back"
import {
  Typography,
  Box,
  Grid,
  Button,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
} from "@material-ui/core"
import { Controller, useForm } from "react-hook-form"
import CommonTextField from "../../Components/FormComponents/CommonTextField"
import DocumentsHandler from "../../Components/Commons/DocumentHandling/DocumentsHandler"
import AutocompleteCreatable from "../../Components/FormComponents/AutocomlpleteCreatable"
import { removeItemByIndex, updateItemByIndex, toBase64, checkKeyDown } from "../../utils/utils"
import { useParams, useHistory } from "react-router-dom"
import {
  patchProduct,
  getAllActivities,
  getProduct,
  getAllDocuments,
  getAllProfessions,
  showProductDocument,
  getAllCommercials,
  deleteDocument,
  deleteProductsDocument,
} from "../../Axios/Call/ClientCredential"
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral"
import CommonDateTimePicker from "../../Components/FormComponents/CommonDateTimePicker"
import CommonSelect from "../../Components/FormComponents/CommonSelect"
import { useDispatch } from "react-redux"
import * as actionCreator from "../../Store/action/index"
import moment from "moment"
import "moment/locale/fr"
moment.locale("fr")

const ModifierProduit = () => {
  const [rqDocuments, setrqDocuments] = useState([])
  const [rqDocumentsList, setRqDocumentsList] = useState([])
  const [oldAttached, setOldAttached] = useState([])
  const [attached, setattached] = useState([])
  const [product, setproduct] = useState([])
  const [activities, setactivities] = useState([])
  const [professions, setprofessions] = useState([])
  const [selectedProfessions, setSelectedProfessions] = useState([])
  const [commercials, setCommercials] = useState([])

  const [successapi, setsuccessapi] = useState(false)
  const [errorapi, seterrorapi] = useState(false)

  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  // attached rows creation
  const createDataDocumentAttached = (
    product_id,
    name,
    description,
    type,
    starting_date,
    ending_date,
    file_path,
    isEdit,
    document_id,
    base64
  ) => {
    return {
      product_id: product_id,
      file_path: file_path,
      name: name,
      description: description,
      type,
      starting_date: starting_date,
      base64: base64,
      ...(document_id && { isEdit: isEdit }),
      ...(ending_date && { ending_date: ending_date }),
      ...(document_id && { id: document_id }),
    }
  }

  // required docs handling
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "300px",
      },
    },
  }

  const handleChangeRQDocsList = (event) => {
    const {
      target: { value },
    } = event
    setrqDocuments(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    )
  }

  const handleChangeProfessions = (event) => {
    setSelectedProfessions(event.target.value)
  }

  const createProductData = async (
    code,
    description,
    market,
    activity_id,
    madelin,
    produit_independant,
    profession_ids,
    idcc,
    type,
    commercial_id,
    status,
    starting_date,
    ending_date,
    document_justification_products,
    products_documents,
  ) => {
    const djp = JSON.stringify(document_justification_products)
    const pdocs = JSON.stringify(products_documents)
    return {
      code: code,
      description: description,
      market: market,
      activity_id: activity_id,
      madelin: madelin,
      produit_independant: produit_independant,
      profession_ids: profession_ids,
      type: type,
      idcc: idcc,
      commercial_id: commercial_id,
      status: status,
      starting_date: starting_date,
      ending_date: ending_date,
      document_justification_products: djp,
      products_documents: pdocs,
    }
  }

  const submitModifProduct = async (data) => {
    const tmprqdoclist = rqDocuments
    let tmpattached
    if (attached?.length > 0) {
      tmpattached = await Promise.all(
        attached?.map(async (a) => {
          let file
          if (a.file instanceof Blob) {
            file = await toBase64(a.file)
          } else {
            file = a.file
          }

          return createDataDocumentAttached(
            params.id,
            a.name,
            a.description,
            a.type,
            a.starting_date,
            a.ending_date,
            a.file_path,
            a.isEdit !== undefined,
            a.id,
            file
          )
        })
      )
    }

    if (oldAttached.length > 0) {
      oldAttached.forEach((oldDocument) => {
        let isDeleted = !attached.find((document) => document.id === oldDocument.id)
        if (isDeleted) {
          deleteProductsDocument(oldDocument.id)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                return setsuccessapi(true)
              } else {
                return seterrorapi(true)
              }
            })
            .catch((error) => {
              console.warn(error)
              return seterrorapi(true)
            })
        }
      })
    }

    let tmpData = await createProductData(
      data.code,
      data.description,
      data.market,
      data.activity_id,
      data.madelin,
      data.produit_independant,
      selectedProfessions.join(","),
      data.idcc,
      data.type,
      data.commercial_id,
      data.status,
      data.starting_date,
      data.ending_date,
      tmprqdoclist,
      tmpattached
    )

    patchProduct(tmpData, params.id)
      .then((res) => {
        dispatch(actionCreator.populateProducts())
        setsuccessapi(true)
        setTimeout(() => {
          history.push("/Produits")
        }, 300)
      })
      .catch((error) => {
        console.log(error)
        if (error.response.data.email) {
        } else if (error.response.status === 500) {
          setsuccessapi(true)
          setTimeout(() => {
            history.push("/Produits")
          }, 300)
        } else {
          seterrorapi(true)
        }
      })
  }

  // form

  const { register, handleSubmit, errors, setValue, getValues, watch, control } = useForm({
    mode: "all",
  }) // initialise the hook

  //   hook
  useEffect(() => {
    getAllActivities().then((res) => {
      // console.log(res.data);
      const tmpAct = res?.data?.map((a) => {
        return { id: a.id, value: a.name }
      })
      setactivities(tmpAct)
    })

    getAllProfessions().then((res) => {
      // console.log(res.data);
      const tmpProf = res?.data?.map((a) => {
        return { id: a.id, value: a.name }
      })
      setprofessions(tmpProf)
    })

    getAllDocuments().then((res) => {
      const tmp = res.data.filter((d) => d.type === "justification")
      setRqDocumentsList(tmp)
    })

    getAllCommercials().then((res) => {
      const tmpData = res.data.map((commercial) => {
        return { id: commercial.id, value: commercial.name }
      })
      setCommercials(tmpData)
    })
  }, [])

  const getBase64File = async (document) => {
    let file = await showProductDocument(document.id).then((res) => res.data)
    return { ...document, file: file }
  }

  const createAttached = async (documents) => {
    if (documents.length > 0) {
      console.log("documents", documents)

      let data = []
      for (const document of documents) {
        let tmp = await getBase64File(document)
        data.push(tmp)
      }
      return data
    }
  }

  useEffect(() => {
    if (activities.length > 0 && rqDocumentsList.length > 0 && professions.length > 0 && commercials.length > 0) {
      getProduct(params.id).then((res) => {
        console.log("data", res.data)
        setValue("code", res.data.code)
        setValue("description", res.data.description)
        setValue("market", res.data.market)
        setValue("activity_id", res.data.activity_id)
        // setValue("profession_id", res.data.profession_id);
        setValue("madelin", res.data.madelin)
        setValue("produit_independant", res.data.produit_independant)
        setValue("status", res.data.status)
        setValue("starting_date", res.data.starting_date)
        setValue("ending_date", res.data.ending_date)
        setValue("idcc", res.data.idcc)
        setValue("commercial_id", res.data.commercial_id)
        setValue("type", res.data.type)
        setSelectedProfessions(
          res.data.profession_ids ? res.data.profession_ids?.split(",")?.map((x) => parseInt(x)) : []
        )

        setrqDocuments(res.data.document_justifications.map((doc) => doc.document_id))
        if (res.data.documents?.length > 0) {
          createAttached(res.data.documents)
            .then((res) => {
              setattached(res)
              setOldAttached(res)
            })
            .catch((error) => console.log(error))
        }
        setproduct(res.data)
      })
    }
  }, [activities, rqDocumentsList, professions, commercials])

  useEffect(() => {
    console.log("selectedProfessions", selectedProfessions)
  }, [selectedProfessions])

  useEffect(() => {}, [watch("market")])

  return (
    <>
      {successapi && <AlertGeneral color="" setTrigger={setsuccessapi} description="Nouveau produit enregistré !" />}
      {errorapi && <AlertGeneral color="danger" setTrigger={seterrorapi} description="Une erreur est survenue." />}
      <CustomHeader icon={<Back />} pageName={`Modifier: ${product?.description}`} />

      <Typography
        variant="body1"
        style={{
          color: "#c23d31",
          textAlign: "left",
          marginTop: -25,
          marginLeft: 50,
        }}
      >
        Entrez les nouvelles informations concernant ce produit
      </Typography>
      <Box className="spacer2" />
      <form
        style={{ maxWidth: "980px", margin: "0 auto" }}
        onSubmit={handleSubmit(submitModifProduct)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" style={{ textAlign: "left" }}>
              Informations produit
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Controller
              control={control}
              name="code"
              defaultValue={""}
              rules={{ required: true }}
              render={(field) => {
                return <CommonTextField name="code" label="Code produit" field={field} error={errors.code} />
              }}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Controller
              control={control}
              name="description"
              defaultValue={""}
              rules={{ required: true }}
              render={(field) => (
                <CommonTextField name="description" label="Libellé" field={field} error={errors.description} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Controller
              control={control}
              name="market"
              defaultValue={""}
              render={(field) => (
                <CommonSelect
                  required
                  field={field}
                  label="Marché"
                  options={[
                    { id: null, value: "" },
                    { id: "individual", value: "Individuel" },
                    { id: "company", value: "Collectif" },
                  ]}
                  error={errors.market}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Controller
              control={control}
              name="activity_id"
              defaultValue={""}
              rules={{ required: true }}
              render={(field) => {
                return (
                  <CommonSelect
                    required
                    field={field}
                    label="Secteur d'activité"
                    options={[{ id: null, value: "" }, ...activities]}
                    error={errors.activity_id}
                  />
                )
              }}
            />
          </Grid>
        </Grid>

        <Box className="spacer2" />

        {getValues("market") === "individual" && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3" style={{ textAlign: "left" }}>
                Individuel
              </Typography>
            </Grid>

            {/*            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="profession_id"
                rules={{ required: true }}
                render={(field) => {
                  return (
                    <CommonSelect
                      field={field}
                      label="Statut professionnel"
                      options={[{ id: null, value: "" }, ...professions]}
                      error={errors.profession_id}
                    />
                  );
                }}
              />
            </Grid>*/}

            <Grid item xs={12} md={6}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label" style={{ paddingLeft: ".8rem", marginTop: "-3px" }}>
                  Statut professionnel
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  required
                  value={selectedProfessions}
                  onChange={handleChangeProfessions}
                  ref={register("professions")}
                  input={<OutlinedInput label="Statut professionnel" fullWidth />}
                  renderValue={
                    (selected) =>
                      selected
                        .map((i) => {
                          return professions?.find((profession) => profession.id === i)?.value
                        })
                        .join(", ")
                    // selected.map(i => i).join(", ")
                  }
                  MenuProps={MenuProps}
                  style={{ textAlign: "left" }}
                >
                  {professions?.map((profession) => {
                    return (
                      <MenuItem key={profession?.value} value={profession?.id}>
                        <Checkbox checked={selectedProfessions?.indexOf(profession?.id) > -1} />
                        <ListItemText primary={profession?.value} />
                      </MenuItem>
                    )
                  })}
                  f
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="madelin"
                rules={{ required: true }}
                render={(field) => {
                  return (
                    <CommonSelect
                      field={field}
                      label="Madelin"
                      options={[
                        { id: null, value: "" },
                        { id: 0, value: "Madelin" },
                        { id: 1, value: "Non madelin" },
                      ]}
                      error={errors.madelin}
                    />
                  )
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="produit_independant"
                    render={(field) => (
                      <CommonSelect
                        field={field}
                        label="Produit indépendant"
                        options={[
                          { id: 0, value: "Non", label: "Non" },
                          { id: 1, value: "Oui", label: "Oui" },
                        ]}
                        error={errors.produit_independant}
                      />
                    )}
                  />
                </Grid>
          </Grid>
        )}

        {getValues("market") === "company" && (
          <Grid container spacing={2}>
            {" "}
            <Grid item xs={12}>
              <Typography variant="h3" style={{ textAlign: "left" }}>
                Collectif
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="type"
                rules={{}}
                render={(field) => {
                  return (
                    <CommonSelect
                      field={field}
                      label="Type"
                      options={[
                        { id: null, value: "" },
                        { id: "standard", value: "Standard" },
                        { id: "ccn", value: "CCN" },
                      ]}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="idcc"
                defaultValue={""}
                rules={{ required: getValues("type") === "CCN" ? true : false }}
                render={(field) => <CommonTextField label="Code IDCC" field={field} error={errors.idcc} />}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="commercial_id"
                defaultValue={null}
                rules={{ required: getValues("type") === "CCN" ? true : false }}
                render={(field) => (
                  <CommonSelect field={field} nullFirstOption label={"Cible commerciale"} options={commercials} />
                )}
              />
            </Grid>
          </Grid>
        )}

        <Box className="spacer2" />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" style={{ textAlign: "left" }}>
              Commercialisation
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              control={control}
              name="status"
              defaultValue={""}
              rules={{ required: true }}
              render={(field) => {
                return (
                  <CommonSelect
                    field={field}
                    label="État"
                    options={[
                      { id: null, value: "" },
                      { id: "active", value: "Actif" },
                      { id: "inactive", value: "Inactif" },
                    ]}
                    error={errors.status}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              control={control}
              name="starting_date"
              defaultValue={moment()}
              rules={{ required: true }}
              render={(field) => (
                <CommonDateTimePicker
                  field={field}
                  label={"Début de commercialisation"}
                  name={"starting_date"}
                  error={errors.starting_date}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              control={control}
              name="ending_date"
              defaultValue={moment()}
              rules={{}}
              render={(field) => (
                <CommonDateTimePicker
                  field={field}
                  label={"Fin de commercialisation"}
                  name={"ending_date"}
                  error={errors.ending_date}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label" style={{ paddingLeft: ".8rem", marginTop: "-3px" }}>
                Documents justificatifs
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                required
                value={rqDocuments}
                onChange={handleChangeRQDocsList}
                ref={register("document_justification_products")}
                input={<OutlinedInput label="Documents justificatifs" fullWidth />}
                renderValue={(selected) =>
                  selected
                    .map((i) => {
                      return rqDocumentsList?.find((doc) => doc.id === i)?.name
                    })
                    .join(", ")
                }
                MenuProps={MenuProps}
                style={{ textAlign: "left" }}
              >
                {rqDocumentsList.map((doc) => {
                  return (
                    <MenuItem key={doc?.name} value={doc?.id}>
                      <Checkbox checked={rqDocuments?.indexOf(doc?.id) > -1} />
                      <ListItemText primary={doc?.name} />
                    </MenuItem>
                  )
                })}
                f
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box className="spacer2" />
        <DocumentsHandler
          name={"Nouveau document"}
          title={"Nouveau document"}
          newProductCode={getValues("code")}
          attached={attached}
          newAttached={(x) => setattached([...attached, x])}
          deleteAttached={(x) => setattached(removeItemByIndex(attached, x))}
          updateAttached={(x) => setattached(updateItemByIndex(attached, x.updatedData, x.selectedIndex))}
          setAttached={setattached}
          fileType={"product"}
        />
        <Box className="spacer2" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Button className="buttonGeneralStyle" style={{ maxWidth: "none" }} type="submit">
              ENREGISTRER
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default ModifierProduit
