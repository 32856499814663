import React, { createContext, useState } from 'react';

// Création du contexte
const SearchContextDI = createContext();
const SearchContextDC = createContext();
const SearchContextCI = createContext();
const SearchContextCC = createContext();

const CourtierContextDI = createContext();
const CourtierContextDC = createContext();
const CourtierContextCI = createContext();
const CourtierContextCC = createContext();  

const EtatContextDI = createContext();
const EtatContextDC = createContext();
const EtatContextCI = createContext();
const EtatContextCC = createContext();  

const DebutContextDI = createContext();
const DebutContextDC = createContext();
const DebutContextCI = createContext();
const DebutContextCC = createContext(); 

const FinContextDI = createContext();
const FinContextDC = createContext();
const FinContextCI = createContext();
const FinContextCC = createContext(); 

// Composant fournisseur du contexte
const SearchProviderDevisIndiv = ({ children }) => {
  const [searchTermDI, setSearchTermDI] = useState('');
  const [searchTermDC, setSearchTermDC] = useState('');
  const [searchTermCI, setSearchTermCI] = useState('');
  const [searchTermCC, setSearchTermCC] = useState('');

  const [courtierTermDI, setCourtierTermDI] = useState('all');
  const [courtierTermDC, setCourtierTermDC] = useState('all');
  const [courtierTermCI, setCourtierTermCI] = useState('all');
  const [courtierTermCC, setCourtierTermCC] = useState('all');

  const [etatTermDI, setEtatTermDI] = useState('all');
  const [etatTermDC, setEtatTermDC] = useState('all');
  const [etatTermCI, setEtatTermCI] = useState('all');
  const [etatTermCC, setEtatTermCC] = useState('all');

  const [debutTermDI, setDebutTermDI] = useState(null);
  const [debutTermDC, setDebutTermDC] = useState(null);
  const [debutTermCI, setDebutTermCI] = useState(null);
  const [debutTermCC, setDebutTermCC] = useState(null);

  const [finTermDI, setFinTermDI] = useState(null);
  const [finTermDC, setFinTermDC] = useState(null);
  const [finTermCI, setFinTermCI] = useState(null);
  const [finTermCC, setFinTermCC] = useState(null);


  return (
    <SearchContextDI.Provider value={{ searchTermDI, setSearchTermDI }}>
        <SearchContextDC.Provider value={{ searchTermDC, setSearchTermDC }}>
        <SearchContextCI.Provider value={{ searchTermCI, setSearchTermCI }}>
        <SearchContextCC.Provider value={{ searchTermCC, setSearchTermCC }}>
          <CourtierContextCC.Provider value={{ courtierTermCC, setCourtierTermCC }}>
          <CourtierContextCI.Provider value={{ courtierTermCI, setCourtierTermCI }}>
          <CourtierContextDC.Provider value={{ courtierTermDC, setCourtierTermDC }}>
            <CourtierContextDI.Provider value={{ courtierTermDI, setCourtierTermDI }}>
              <EtatContextCC.Provider value={{ etatTermCC, setEtatTermCC }}>
              <EtatContextCI.Provider value={{ etatTermCI, setEtatTermCI }}>
              <EtatContextDC.Provider value={{ etatTermDC, setEtatTermDC }}>
                <EtatContextDI.Provider value={{ etatTermDI, setEtatTermDI }}>
                  <DebutContextCC.Provider value={{ debutTermCC, setDebutTermCC }}>
                  <DebutContextCI.Provider value={{ debutTermCI, setDebutTermCI }}>
                  <DebutContextDC.Provider value={{ debutTermDC, setDebutTermDC }}>
                    <DebutContextDI.Provider value={{ debutTermDI, setDebutTermDI }}>
                      <FinContextCC.Provider value={{ finTermCC, setFinTermCC }}>
                      <FinContextCI.Provider value={{ finTermCI, setFinTermCI }}>
                      <FinContextDC.Provider value={{ finTermDC, setFinTermDC }}>
                        <FinContextDI.Provider value={{ finTermDI, setFinTermDI }}>
      {children}
      </FinContextDI.Provider>
      </FinContextDC.Provider>
      </FinContextCI.Provider>
      </FinContextCC.Provider>
      </DebutContextDI.Provider>
      </DebutContextDC.Provider>
        </DebutContextCI.Provider>
      </DebutContextCC.Provider>
      </EtatContextDI.Provider>
      </EtatContextDC.Provider>
      </EtatContextCI.Provider>
      </EtatContextCC.Provider>
      </CourtierContextDI.Provider>
      </CourtierContextDC.Provider>
      </CourtierContextCI.Provider>
      </CourtierContextCC.Provider>
      </SearchContextCC.Provider>
      </SearchContextCI.Provider>
      </SearchContextDC.Provider>
    </SearchContextDI.Provider>
  );
};

export { SearchProviderDevisIndiv, SearchContextDI, SearchContextDC, SearchContextCC, SearchContextCI, CourtierContextDI, CourtierContextDC, CourtierContextCC, CourtierContextCI, EtatContextDI, EtatContextDC, EtatContextCC, EtatContextCI, DebutContextDI, DebutContextDC, DebutContextCC, DebutContextCI, FinContextDI, FinContextDC, FinContextCC, FinContextCI };