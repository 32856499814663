import React, { useEffect, useState } from "react"
import CustomHeader from "../../Components/Header/CustomHeader"
import { RiShieldCrossFill } from "react-icons/ri"
import { BsSearch, BsPencilSquare } from "react-icons/bs"
import { AiOutlineFileProtect } from "react-icons/ai"
import { FaRegTrashAlt } from "react-icons/fa"
import { BiPackage } from "react-icons/bi"

import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular"
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TablePagination,
} from "@material-ui/core"

import CustomModal from "../../Components/Commons/Modal/CustomModal"
import { useHistory } from "react-router-dom"
import { deleteProduct, getAllActivities, getAllProducts } from "../../Axios/Call/ClientCredential"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import "moment/locale/fr"
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral"
import UserComponentLayout from "../../Hoc/UserComponentLayout"
import { defaultLabelDisplayedRows } from "../../utils/utils"
import EnhancedTableHead from "../../Components/Table/EnhancedTableHead"
import { getComparator, stableSort } from "../../utils/tableSort"
moment.locale("fr")

const Produits = () => {
  const [open, setopen] = useState(false)
  const [toDelete, settoDelete] = useState(null)
  const [searchValue, setsearchValue] = useState("")
  const [products, setproducts] = useState([])
  const [rows, setRows] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const history = useHistory()
  const [activities, setactivities] = useState([])
  const [loading, setloading] = useState(false)
  const [successapi, setsuccessapi] = useState(false)
  const [errorapi, seterrorapi] = useState(false)
  const isAdmin = useSelector((state) => state.user.userData.type === "admin")

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("")

  const createRow = (id, code, description, activity_id, market, madelin, starting_date, ending_date) => {
    return {
      id: id,
      code: code,
      description: description,
      activity_id: activity_id,
      market: market === "individual" ? "Individuel" : "Collectif",
      madelin: madelin !== null ? (madelin === 1 ? "Non" : "Oui") : "",
      starting_date: starting_date !== null ? moment(starting_date).format("DD/MM/yyyy") : "-",
      ending_date: ending_date !== null ? moment(ending_date).format("DD/MM/yyyy") : "-",
    }
  }

  const headCells = [
    {
      id: "code",
      label: "Code",
      isOrderable: true,
    },
    {
      id: "description",
      label: "Produit",
      isOrderable: true,
    },
    {
      id: "activity_id",
      label: "Domaine d'activité",
      isOrderable: true,
    },
    {
      id: "market",
      label: "Marché",
      isOrderable: true,
    },
    {
      id: "madelin",
      label: "Madelin",
      isOrderable: true,
    },
    {
      id: "starting_date",
      label: "Début de commercialisation",
      isOrderable: true,
    },
    {
      id: "ending_date",
      label: "Fin de commercialisation",
      isOrderable: true,
    },
    {
      id: "garanties",
      label: "Garanties",
      isOrderable: false,
    },
    {
      id: "packs",
      label: "Packs",
      isOrderable: false,
    },
    isAdmin && {
      id: "actions",
      label: "Actions",
      isOrderable: false,
    },
  ]

  const handlesetsearchValue = (val) => {
    setsearchValue(val)
  }

  const handleToDelete = (id) => {
    setopen(true)
    settoDelete(id)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleDelete = () => {
    deleteProduct(toDelete).then((res) => {
      if (res.status === 200) {
        setsuccessapi(true)
      } else {
        seterrorapi(true)
      }
    })
    settoDelete(null)
    setopen(false)
    setloading(true)
    getAllProducts().then((res) => setproducts(res.data))
    setTimeout(() => {
      setloading(false)
    }, 500)
  }

  useEffect(() => {
    getAllActivities().then((res) => setactivities(res.data))
    getAllProducts().then((res) => setproducts(res.data))
    // setRows();
  }, [])

  useEffect(() => {
    if (products.length > 0) {
      setRows(
        products.map((product) =>
          createRow(
            product.id,
            product.code,
            product.description,
            product.activity_id,
            product.market,
            product.madelin,
            product.starting_date,
            product.ending_date
          )
        )
      )
    }
  }, [products])

  useEffect(() => {
    setFilteredProducts(
      rows?.filter(
        (row) =>
          row.code?.toLowerCase().match(searchValue.toLowerCase().trim()) ||
          row.description?.toLowerCase().match(searchValue.toLowerCase().trim()) ||
          activities
            ?.filter((a) => a.id === row.activity_id)[0]
            ?.name?.toLowerCase()
            .match(searchValue.toLowerCase().trim())
      )
    )
    setPage(0)
  }, [rows, searchValue])

  return (
    <>
      {successapi && <AlertGeneral setTrigger={setsuccessapi} color="" description="Enregistré" />}
      {errorapi && <AlertGeneral setTrigger={seterrorapi} color="danger" description="Une erreur est survenue" />}
      <CustomHeader
        icon={<RiShieldCrossFill size={40} style={{ fill: "#c31d23", marginRight: ".8rem" }} />}
        pageName={"Produits"}
      />
      <Grid container style={{ margin: "1.5rem 0 2rem" }}>
        <Grid item xs={12} md={5}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Rechercher par code, nom, domaine..."
            value={searchValue}
            onChange={(e) => handlesetsearchValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsSearch size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {isAdmin && (
          <Grid item xs={12} md={2}>
            <UserComponentLayout>
              <Button
                className="buttonGeneralStyle"
                onClick={() => history.push("/Produits/Nouveau")}
                style={{ marginLeft: "1rem" }}
              >
                NOUVEAU PRODUIT
              </Button>
            </UserComponentLayout>
          </Grid>
        )}
      </Grid>
      <Box className="spacer2" />

      {loading === true ? (
        <Box className="spacer2">
          <LoaderCircular />
        </Box>
      ) : (
        <>
          <TableContainer style={{ paddingBottom: "50px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />

              <TableBody>
                {stableSort(filteredProducts, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row" align="left">
                        <Typography variant="body2">{row.code}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">{row.description}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">
                          {activities?.filter((a) => a.id === row.activity_id)[0]?.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">{row.market}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">{row.madelin}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">{row.starting_date}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">{row.ending_date}</Typography>
                      </TableCell>

                      <TableCell align="center" onClick={() => history.push(`/Produits/${row.id}/Garanties`)}>
                        <Typography variant="body2">
                          <AiOutlineFileProtect size={25} style={{ color: "c31d23", cursor: "pointer" }} />
                        </Typography>
                      </TableCell>
                      <TableCell align="center" onClick={() => history.push(`/Produits/${row.id}/Packs`)}>
                        <Typography variant="body2">
                          <BiPackage size={25} style={{ color: "c31d23", cursor: "pointer" }} />
                        </Typography>
                      </TableCell>

                      {isAdmin && (
                        <TableCell align="center">
                          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <BsPencilSquare
                              size={20}
                              style={{ cursor: "pointer", marginRight: ".8rem" }}
                              onClick={() => history.push(`/Produits/${row.id}/Modifier`)}
                            />

                            <UserComponentLayout>
                              <FaRegTrashAlt
                                size={20}
                                style={{ fill: "#C31D23", cursor: "pointer" }}
                                onClick={() => handleToDelete(row.id)}
                              />{" "}
                            </UserComponentLayout>
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            size="small"
            variant="outlined"
            count={filteredProducts?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={() =>
              defaultLabelDisplayedRows({
                from: rowsPerPage * page + 1,
                to:
                  rowsPerPage * page + rowsPerPage > filteredProducts.length
                    ? filteredProducts.length
                    : rowsPerPage * page + rowsPerPage,
                count: filteredProducts?.length,
              })
            }
            labelRowsPerPage={"Lignes par page :"}
          />
        </>
      )}

      <CustomModal open={open} handleClose={() => setopen(false)} size={320}>
        <Typography variant="h3">Suppression du produit</Typography>
        <Typography variant="body1" style={{ margin: "1rem 0 2rem", textAlign: "center" }}>
          Attention, ce produit sera supprimé!
        </Typography>
        <Button
          className="buttonGeneralStyle"
          style={{ width: "100%", maxWidth: "none" }}
          onClick={() => handleDelete()}
        >
          Supprimer
        </Button>
      </CustomModal>
    </>
  )
}

export default Produits
