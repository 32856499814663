import React, { useEffect, useState } from "react"
import CustomHeader from "../../Components/Header/CustomHeader"
import { RiFileShield2Line } from "react-icons/ri"
import {
  Typography,
  Box,
  Grid,
  Button,
  TextField,
  MenuItem,
  ListItemText,
  Checkbox,
} from "@material-ui/core"
import { useForm, Controller } from "react-hook-form"
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral"

import {
  getGuarantee,
  getProduct,
  patchGuarantee,
} from "../../Axios/Call/ClientCredential"
import { useParams, useHistory } from "react-router-dom"
import CommonTextField from "../../Components/FormComponents/CommonTextField"
import CommonSelect from "../../Components/FormComponents/CommonSelect"
import { useSelector } from "react-redux"
import Back from "../../Components/Commons/Back/Back"
import UserComponentLayout from "../../Hoc/UserComponentLayout"
import { checkKeyDown } from "../../utils/utils"
import _ from "lodash"

const ModifierGarantie = () => {
  const [successapi, setsuccessapi] = useState(false)
  const [errorapi, seterrorapi] = useState(false)

  const structures = useSelector((state) => state.user.structures_codes);
  const [updatedStructures, setupdatedStructures] = useState([])

  const history = useHistory()
  const params = useParams()

  const produits = useSelector((state) => state.user.products)
  const garanties = useSelector((state) => state.user.garanties)

  function findName(id) {
    return produits.find((p) => p.id === id * 1)?.code
  }

  function findNameG(id) {
    return garanties.find((p) => p.id === id * 1)?.name
  }

  const createProduct = (
    product_id,
    code,
    name,
    description,
    nature,
    long_description,
    structure_cotisation
  ) => {
    return {
      product_id,
      code,
      name,
      description,
      nature,
      long_description,
      structure_cotisation,
    }
  }

  // required docs handling

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "300px",
      },
    },
  }
  // form

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
    control,
  } = useForm({
    mode: "all",
    defaultValues: {
      nature: "",
    },
  });

  const submitNewProduct = (data) => {
    console.log(data)
    const tmpProduct = createProduct(
      params.id,
      data.code,
      data.name,
      data.description,
      data.nature,
      data.long_description,
      data.structure_cotisation.map((s) => s.trim()).join(",")
    );
    patchGuarantee(tmpProduct, params.guarantee_id).then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        setsuccessapi(true)
        setTimeout(() => {
          history.push(`/Produits/${params.id}/Garanties`)
        }, 1500)
      } else {
        seterrorapi(true)
      }
    })
  };

  //   hook
  useEffect(() => {
    getProduct(params.id).then((res) => {
      if (res.data?.market === "company") {
        setupdatedStructures(structures)
      } else {
        setupdatedStructures([{ id: "AE", value: "AE" },{id: "U", value: "Unique"}])
      }
    });

    getGuarantee(params.guarantee_id).then((res) => {
      const guarantee = res.data
      setValue("code", guarantee.code)
      setValue("name", guarantee.name)
      setValue("description", guarantee.description)
      setValue("nature", guarantee.nature)
      setValue("long_description", guarantee.long_description)
      setValue(
        "structure_cotisation",
        guarantee.structure_cotisation.split(",")
      )
    })
  }, []);

  useEffect(() => {}, [watch("market"), watch("product_code")])

  return (
    <>
      {successapi && (
        <AlertGeneral
          setTrigger={setsuccessapi}
          color=""
          description="Garanties enregistrée!"
        />
      )}
      {errorapi && (
        <AlertGeneral
          setTrigger={seterrorapi}
          color="danger"
          description="Une erreur est survenue"
        />
      )}
      <CustomHeader
        icon={
          <RiFileShield2Line
            size={40}
            style={{ color: "c31d23", cursor: "pointer", marginRight: ".8rel" }}
          />
        }
        pageName={`Produit ${findName(
          params.id
        )} - Modifier garantie ${findNameG(params.guarantee_id)}`}
      />
      <Typography
        variant="body1"
        style={{ color: "#c23d31", textAlign: "left" }}
      >
        Modification de la garantie {params.guarantee_id} associée au produit{" "}
        {params.id}
      </Typography>
      <Box className="spacer1" />
      <Back />
      <Box className="spacer2" />
      <form
        style={{ maxWidth: "980px", margin: "0 auto" }}
        onSubmit={handleSubmit(submitNewProduct)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" style={{ textAlign: "left" }}>
              Informations sur la garantie
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="code"
              defaultValue={""}
              render={(field) => {
                return (
                  <CommonTextField
                    field={field}
                    label={"Code garantie"}
                    error={errors.code}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="name"
              defaultValue={""}
              render={(field) => {
                return (
                  <CommonTextField
                    field={field}
                    label={"Nom de la garantie"}
                    error={errors.name}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="description"
              defaultValue={""}
              render={(field) => {
                return (
                  <CommonTextField
                    field={field}
                    label={"Niveau de besoin"}
                    error={errors.description}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="nature"
              render={(field) => {
                return (
                  <CommonSelect
                    field={field}
                    label={"Nature de la garantie"}
                    error={errors.nature}
                    options={[
                      { id: null, value: "" },
                      { id: "base", value: "Base" },
                      { id: "renfort", value: "Renfort" },
                      { id: "option", value: "Option" },
                      { id: "supp", value: "Produit supplémentaire" },
                    ]}
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="structure_cotisation"
              defaultValue={[]}
              rules={{ required: true }}
              render={(field) => {
                return (
                  <>
                    <TextField
                      fullWidth
                      select
                      variant="outlined"
                      label="Structures de cotisation"
                      SelectProps={{
                        multiple: true,
                        value: field.value,
                        renderValue: (selected) => selected?.map(item => updatedStructures?.find(structure => structure.id === item)?.value)?.join(", "),
                        onChange: field.onChange,
                      }}
                    >
                      <MenuItem/>
                      {updatedStructures?.map((s) => (
                        <MenuItem key={s.id} value={s.id}>
                          <Checkbox checked={field.value.includes(s.id)} />
                          <ListItemText primary={s.value} />
                        </MenuItem>
                      ))}
                    </TextField>
                    <Typography
                      component="span"
                      style={{
                        color: "#F44336",
                        fontSize: 12,
                        margin: "3px 14px 0",
                      }}
                    >
                      {errors.structure_cotisation
                        ? "Sélectionnez une option"
                        : ""}
                    </Typography>
                  </>
                )
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{}}
              name="long_description"
              defaultValue={""}
              render={(field) => {
                return (
                  <CommonTextField
                    field={field}
                    label={"Description de la garantie (facultatif)"}
                    error={errors.long_description}
                    InputProps={{ multiline: true, minRows: 4 }}
                  />
                )
              }}
            />
          </Grid>
        </Grid>
        <Box className="spacer2" />
        <Box className="spacer2" />
        <Grid container spacing={2}>
          <UserComponentLayout>
            <Grid item xs={12}>
              <Button className="buttonGeneralStyle" type="submit">
                ENREGISTRER
              </Button>
            </Grid>
          </UserComponentLayout>
        </Grid>
      </form>
    </>
  )
}

export default ModifierGarantie
