import React, { useState, useEffect } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  InputAdornment,
  TextField,
  Box,
  Grid,
  MenuItem,
} from "@material-ui/core"
import CustomModal from "../../../Components/Commons/Modal/CustomModal"
import { FaRegTrashAlt } from "react-icons/fa"
import { AiOutlineFileAdd } from "react-icons/ai"
import { BsSearch } from "react-icons/bs"
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom"
import {
  addDocument,
  deleteDocument,
  getAllDocuments,
} from "../../../Axios/Call/ClientCredential"
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral"
import moment from "moment"
import "moment/locale/fr"
import UserComponentLayout from "../../../Hoc/UserComponentLayout"
moment.locale("fr")

const Justificatifs = () => {
  const [loadingApi] = useState(false)
  const [searchValue, setsearchValue] = useState("")
  const [open, setopen] = useState(false)
  const [name, setname] = useState("")
  const [status, setstatus] = useState(null)
  const [focus, setfocus] = useState("")
  const [opendelete, setopendelete] = useState(false)
  const [successapi, setsuccessapi] = useState(false)
  const [errorapi, seterrorapi] = useState(false)
  const [data, setdata] = useState([])
  const [errorEmpty, seterrorEmpty] = useState(false)

  const handleSearchValue = (val) => {
    setsearchValue(val)
  }

  const createData = (name, description, type, file) => {
    return { name, description, type, file }
  }

  const handleErrorEmpty = () => {
    seterrorEmpty(true)
    const timer = setTimeout(() => {
      seterrorEmpty(false)
    }, 2500)
    return () => clearTimeout(timer)
  }

  const handleNew = async () => {
    if (name !== null && status !== null) {
      const tmpData = createData(name, status, "justification", {
        name: "blank",
        base64: "blank",
      })
      return addDocument(tmpData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            return setsuccessapi(true)
          } else {
            return seterrorapi(true)
          }
        })
        .finally(() => {
          getAllDocuments({ type: "justification" }).then((res) => {
            if (res.data !== "") {
              const tmpData = res?.data?.filter(
                (d) => d.type === "justification"
              )
              return setdata(tmpData)
            } else {
              seterrorapi(true)
            }
            setopen(false)
            setname("")
            setstatus("")
          })
        })
    } else {
      handleErrorEmpty()
    }
  }

  const handleOpenDelete = (x) => {
    setopendelete(true)
    setfocus(x)
  }

  const handleDelete = () => {
    deleteDocument(focus)
      .then((res) => {
        if (res.status === 200) {
          setsuccessapi(true)
          getAllDocuments({ type: "justification" }).then((res) => {
            if (res.data !== "") {
              const tmpData = res?.data?.filter(
                (d) => d.type === "justification"
              )
              return setdata(tmpData)
            } else {
              seterrorapi(true)
            }
          })
        } else {
          seterrorapi(true)
        }
      })
      .finally(() => setopendelete(false))
  }

  const handleCloseDelete = () => {
    setopendelete(false)
    setfocus("")
  }

  // hooks

  useEffect(() => {
    getAllDocuments("justification").then((res) => {
      if (res.data !== "") {
        const tmpData = res?.data?.filter((d) => d.type === "justification")
        return setdata(tmpData)
      } else {
        seterrorapi(true)
      }
    })
  }, [])

  return (
    <>
      {successapi && (
        <AlertGeneral
          color=""
          setTrigger={setsuccessapi}
          description="Enregistré !"
        />
      )}
      {errorapi && (
        <AlertGeneral
          color="danger"
          setTrigger={seterrorapi}
          description="Une erreur est survenue"
        />
      )}
      <Box style={{ display: "flex" }}>
        <TextField
          variant="outlined"
          size="small"
          style={{ width: "300px", margin: "1rem 0" }}
          placeholder="Rechercher par nom, date..."
          value={searchValue}
          onChange={(e) => handleSearchValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BsSearch size={20} />
              </InputAdornment>
            ),
          }}
        />
        <UserComponentLayout>
          <Button
            style={{ marginLeft: "1em", alignSelf: "center" }}
            className="buttonGeneralStyle"
            onClick={() => setopen(true)}
          >
            NOUVEAU DOCUMENT
          </Button>
        </UserComponentLayout>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="h6">Nom du document</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6">Statut</Typography>
              </TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.filter(
                (row) =>
                  row.name?.toLowerCase().match(searchValue.toLowerCase().trim()) ||
                  row.description?.toLowerCase().match(searchValue.toLowerCase().trim())
              )
              ?.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="left">
                    <Typography variant="body2">{row.name}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.description}</Typography>
                  </TableCell>

                  <TableCell align="left" style={{ stroke: "#F24E1E" }}>
                    {/* TODO: changer d'icones ??? */}
                    <UserComponentLayout>
                      <FaRegTrashAlt
                        size={25}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleOpenDelete(row.id)}
                      />
                    </UserComponentLayout>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/*  */}
      <CustomModal
        open={open}
        handleClose={() => setopen(false)}
        loading={loadingApi}
      >
        <Box display="flex" alignItems="center">
          <AiOutlineFileAdd
            size={30}
            style={{ fill: "#C31D23", marginRight: "1rem" }}
          />
          <Typography variant="h3">Document adhérents</Typography>
        </Box>
        {errorEmpty && (
          <Typography
            variant="body2"
            style={{ textAlign: "center", color: "#c31d23" }}
          >
            Veuillez remplir les champs obligatoires
          </Typography>
        )}
        <Box
          style={{
            padding: "0 1em",
            margin: "1em",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextFieldCustom
                name="name"
                defaultValue={null}
                label="Titre du document"
                type="text"
                placeHolder=""
                value={name}
                required
                onChange={(e) => setname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                name="descrition"
                label="Statut"
                value={status}
                onChange={(e) => setstatus(e.target.value)}
                required
                fullWidth
                variant="outlined"
              >
                <MenuItem key="0" value={"Obligatoire"}>
                  Obligatoire
                </MenuItem>
                <MenuItem key="1" value={"Facultatif"}>
                  Facultatif
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Button
            className="buttonGeneralStyle"
            style={{ margin: "1em auto 0" }}
            onClick={() => handleNew()}
            type="submit"
          >
            ENREGISTRER
          </Button>
        </Box>
      </CustomModal>
      <CustomModal
        open={opendelete}
        handleClose={() => handleCloseDelete()}
        size="300px"
      >
        <Typography variant="h3">Suppression</Typography>
        <Typography
          variant="body1"
          style={{ margin: "1rem 0 2rem", textAlign: "center" }}
        >
          Attention, cet document sera supprimé!
        </Typography>
        <Button
          className="buttonGeneralStyle"
          style={{ width: "100%", maxWidth: "none" }}
          onClick={() => handleDelete()}
        >
          Supprimer
        </Button>
      </CustomModal>
      {/*  */}
    </>
  )
}

export default Justificatifs
