import { useState } from "react"
import { Button, Typography, TextField, Box, Grid, Divider } from "@material-ui/core"
import CustomModal from "../Commons/Modal/CustomModal"
import TextFieldCustom from "../FormComponents/TextFieldCustom"
import CommonSelect from "../FormComponents/CommonSelect"
import PDFViewer from "../Commons/FileHandler/PDFViewer"
import { AiOutlineFileAdd } from "react-icons/ai"
import SimpleDatePicker from "../FormComponents/SimpleDatePicker"
import { toBase64 } from "../../utils/utils"
import moment from "moment"

export default function GenerateCourtierDocumentModal({ open, onClose, loading, onSave, brokers }) {
  const [name, setName] = useState("")
  const [broker, setBroker] = useState("")
  const [appendix, setAppendix] = useState("")
  const [description, setDescription] = useState("")
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [boardingDate, setBoardingDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [trimester, setTrimester] = useState("1er trimestre");
  const [year, setYear] = useState(moment().year().toString());



  const handleClose = () => {
    setName("")
    setBroker("")
    setAppendix(null)
    setDescription("")
    setDate(moment(new Date()).format("YYYY-MM-DD"))
    setBoardingDate(moment(new Date()).format("YYYY-MM-DD"))
    setTrimester("")
    setYear("");
    onClose()
  }

  const handleSave = async () => {
    const base64 = await toBase64(appendix)
    let trimesterYear = `${trimester} ${year}`;
    onSave({
      name,
      broker_id: broker,
      appendix: base64,
      description,
      date,
      boarding_date: boardingDate,
      trimester: trimesterYear,
    })
  }





  const isSaveDisabled = !(name && broker && appendix && date && boardingDate && trimester)

  return (
    <CustomModal open={open} handleClose={handleClose} loading={loading}>
      <Box display="flex" alignItems="center">
        <AiOutlineFileAdd size={30} style={{ fill: "#C31D23", marginRight: "1rem", marginLeft: 20 }} />
        <Typography variant="h3" style={{ fontSize: 24, fontWeight: 800 }}>Bordereau de commission</Typography>
      </Box>

      <Box
        style={{
          padding: "0 1em",
          margin: "1em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={1}>



          <Grid item xs={12}>
            <Typography variant='h4'>Informations et assignation</Typography>
            <Divider style={{ backgroundColor: "#C31D2315", marginTop: 5, marginBottom: 5 }} />
          </Grid>


          <Grid item xs={6}>
            <TextFieldCustom
              name="name"
              defaultValue={""}
              label="Titre du document"
              type="text"
              placeholder=""
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <CommonSelect
              label={"Courtier"}
              name="broker"
              defaultValue={[]}
              field={{ value: broker, onChange: setBroker }}
              options={[
                { id: "", value: "" },
                ...brokers.map((broker) => {
                  return {
                    id: broker.id,
                    value: `${broker.company}`,
                  }
                }),
              ]}
              required={true}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='h4'>Dates</Typography>
            <Divider style={{ backgroundColor: "#C31D2315", marginTop: 5, marginBottom: 5 }} />
          </Grid>


          <Grid item xs={12}>
            <SimpleDatePicker
              name="date"
              label="Date du jour"
              placeHolder=""
              value={date}
              required
              onChange={(e) => setDate(e.target.value)}
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={6}>
            <SimpleDatePicker
              name="bordeauxDate"
              label="Date de la convention"
              placeholder=""
              value={boardingDate}
              required
              onChange={(e) => setBoardingDate(e.target.value)}
              fullWidth
            />
          </Grid> */}
          <Grid item xs={6}>
            <CommonSelect
              name="trimester"
              label="Trimestre"
              field={{ value: trimester, onChange: setTrimester }}
              value={trimester}
              required
              options={[
                { value: "1er trimestre" },
                { value: "2ème trimestre" },
                { value: "3ème trimestre" },
                { value: "4ème trimestre" },

              ]}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="year"
              value={year}
              //labelCustom="Déposer le fichier"
              type="text"
              variant="outlined"
              fullWidth
              onChange={(e) => setYear(e.target.value)}
              required
            />
          </Grid>



          <Grid item xs={12}>
            <Typography variant='h4'>Document annexe</Typography>
            <Divider style={{ backgroundColor: "#C31D2315", marginTop: 10 }} />
          </Grid>


          <Grid item xs={12}>
            <TextField
              name="file"
              defaultValue={""}
              //labelCustom="Déposer le fichier"
              type="file"
              variant="outlined"
              inputProps={{ accept: "application/pdf" }}
              fullWidth
              onChange={(e) => setAppendix(e.target.files[0])}
              required
            />

            <PDFViewer file={appendix} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              row={4}
              name="description"

              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              type="text"
              placeholder=""
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container justifyContent='center' style={{ marginTop: 10 }}>
          <Grid item >
            <Button
              // className="buttonGeneralStyle"
              onClick={handleSave}
              disabled={isSaveDisabled}
              style={{ minWidth: '200px', backgroundColor: isSaveDisabled ? '#999' : '#c31d23', color: 'white' }}
            >
              ENREGISTRER
            </Button>
          </Grid>
        </Grid>
      </Box>

    </CustomModal>
  )
}
