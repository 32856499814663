export const RatesHeader = [
    {label: "ID", key: "id"},
    {label: "Produit", key: "product"},
    {label: "Garantie", key: "garanty"},
    {label: "Âge", key: "age"},
    {label: "Type de tarif", key: "priceType"},
    {label: "Tarif pur", key: "base_price"},
    {label: "Pourcentage salaire", key: 'percent_salary'},
    {label: "Zone", key: "zone"},
    {label: "Commission", key: "commission"},
    {label: "Frais de gestion", key: "frais_gestion"},
    {label: "HT", key: "price_no_tva"},
    {label: "Régime", key: "regime"},
    {label: "Structure", key: "structure"},
    {label: "TTC", key: "price_with_tva"},
    {label: "Date de début", key: "starting_date"},
    {label: "Date de fin", key: "ending_date"}
];