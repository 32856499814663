import React, { useEffect, useState } from "react"
import CustomHeader from "../../Components/Header/CustomHeader"
import { RiShieldCrossFill } from "react-icons/ri"
import { BsSearch, BsPencilSquare } from "react-icons/bs"
import { FaRegTrashAlt } from "react-icons/fa"
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import { deletePackage, getAllPackages } from "../../Axios/Call/ClientCredential"
import moment from "moment"
import "moment/locale/fr"
import CustomModal from "../../Components/Commons/Modal/CustomModal"
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral"
import Back from "../../Components/Commons/Back/Back"
import { useSelector } from "react-redux"
import UserComponentLayout from "../../Hoc/UserComponentLayout"
moment.locale("fr")

const Packs = () => {
  const [searchValue, setsearchValue] = useState("")
  const [data, setdata] = useState([])
  const [focus, setfocus] = useState("")
  const [successapi, setsuccessapi] = useState(false)
  const [errorapi, seterrorapi] = useState(false)
  const [opendelete, setopendelete] = useState(false)
  const history = useHistory()
  const params = useParams()

  const produits = useSelector((state) => state.user.products)
  const isAdmin = useSelector((state) => state.user.userData.type === "admin")

  function findName(id) {
    return produits.find((p) => p.id === id * 1)?.code
  }

  const createData = (code, pack_name, LON, warranties, start_date, end_date, docs, id) => {
    return {
      code,
      pack_name,
      LON,
      warranties,
      start_date,
      end_date,
      docs,
      id,
    }
  }

  const handleOpenDelete = (x) => {
    setfocus(x)
    setopendelete(true)
  }

  const handleDelete = () => {
    setfocus("")
    deletePackage(focus).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setsuccessapi(true)
        getAllPackages().then((res) => {
          const tmpdata = res.data
            ?.filter((p) => p.product_id === params.id * 1)
            ?.map((p) => {
              return createData(p.code, p.name, p.need_level, "???", p.starting_date, p.ending_date, "no_docs", p.id)
            })

          setdata(tmpdata)
        })
      } else {
        seterrorapi(true)
      }
    })
    handleCloseDelete()
  }

  const handleCloseDelete = () => {
    setfocus("")
    setopendelete(false)
  }

  const handlesetsearchValue = (val) => {
    setsearchValue(val)
  }

  useEffect(() => {
    getAllPackages().then((res) => {
      const tmpdata = res.data
        ?.filter((p) => p.product_id === params.id * 1)
        ?.map((p) => {
          return createData(p.code, p.name, p.need_level, "???", p.starting_date, p.ending_date, "no_docs", p.id)
        })

      setdata(tmpdata)
    })
  }, [])

  return (
    <>
      {successapi && <AlertGeneral setTrigger={setsuccessapi} color="" description="Enregistré" />}
      {errorapi && <AlertGeneral setTrigger={seterrorapi} color="danger" description="Une erreur est survenue" />}
      <CustomHeader
        icon={<RiShieldCrossFill size={40} style={{ fill: "#c31d23", marginRight: ".8rem" }} />}
        pageName={`Produit ${findName(params.id)} - Packs`}
        userName={"User name here"}
      />
      <Typography
        variant="body1"
        style={{
          color: "#c23d31",
          textAlign: "left",
          marginLeft: "3.3rem",
          marginTop: "-1em",
        }}
      >
        Liste de packs associés au produit {params.code}
      </Typography>
      <Grid container style={{ margin: "1.5rem 0 2rem" }}>
        <Back />

        <Grid item xs={12} md={5}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Rechercher par code, nom, niveau de besoin..."
            value={searchValue}
            onChange={(e) => handlesetsearchValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsSearch size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {isAdmin && (
          <Grid item xs={12} md={3}>
            <UserComponentLayout>
              <Button
                className="buttonGeneralStyle"
                onClick={() => history.push(`/Produits/${params.id}/Packs/Nouveau`)}
                style={{ marginLeft: ".8rem" }}
              >
                NOUVEAU PACK
              </Button>
            </UserComponentLayout>
          </Grid>
        )}
      </Grid>

      <Box className="spacer2" />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography component="p" variant="h6">
                  Code pack
                </Typography>
              </TableCell>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography variant="h6">Libellé du pack</Typography>
              </TableCell>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography variant="h6">Niveau de besoin</Typography>
              </TableCell>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography variant="h6">Début de commercialisation</Typography>
              </TableCell>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography variant="h6">Fin de commercialisation</Typography>
              </TableCell>
              {isAdmin && (
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  <Typography variant="h6">Modifier</Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.filter(
                (row) =>
                  row.code.toLowerCase().match(searchValue.toLowerCase().trim()) ||
                  row.pack_name.toLowerCase().match(searchValue.toLowerCase().trim()) ||
                  row.LON.toLowerCase().match(searchValue.toLocaleLowerCase().trim()) ||
                  row.warranties.toLowerCase().match(searchValue.toLocaleLowerCase().trim())
              )
              ?.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row" align="left">
                    <Typography variant="body2">{row.code}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.pack_name}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.LON}</Typography>
                  </TableCell>
                  {/* <TableCell align="left">
                    <Typography variant="body2">{row.warranties}</Typography>
                  </TableCell> */}
                  <TableCell align="left">
                    <Typography variant="body2">
                      {row.start_date !== null ? moment(row.start_date).format("DD/MM/yyyy") : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">
                      {row.end_date !== null ? moment(row.end_date).format("DD/MM/yyyy") : "-"}
                    </Typography>
                  </TableCell>

                  {isAdmin && (
                    <TableCell align="left">
                      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <BsPencilSquare
                          size={25}
                          style={{ cursor: "pointer", marginRight: ".8rem" }}
                          onClick={() => history.push(`/Produits/${params.id}/Packs/${row.id}/Modifier`)}
                        />
                        <UserComponentLayout>
                          <FaRegTrashAlt
                            size={25}
                            style={{ fill: "#f24e1e", cursor: "pointer" }}
                            onClick={() => handleOpenDelete(row.id)}
                          />{" "}
                        </UserComponentLayout>
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomModal open={opendelete} handleClose={() => handleCloseDelete()} size={350}>
        <Typography variant="h3">Suppression de l'association</Typography>
        <Typography variant="body1" style={{ margin: "1rem 0 2rem" }}>
          Attention, cette association sera supprimée !
        </Typography>
        <Button className="buttonGeneralStyle" fullWidth onClick={() => handleDelete()}>
          Supprimer
        </Button>
      </CustomModal>
    </>
  )
}

export default Packs
