import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  addCommercial,
  getAllCommercials,
} from "../../Axios/Call/ClientCredential";

const filter = createFilterOptions();

export default function AutocompleteCreatable({
  label,
  onSelectOption,
  field,
}) {
  const [value, setValue] = useState(null);
  const [options, setoptions] = useState([]);

  useEffect(() => {
    getAllCommercials().then((res) => setoptions(res.data));
  }, []);

  const handleAddOption = (newOption) => {
    addCommercial({ name: newOption });
    getAllCommercials().then((res) => {
      const tmpData = res.data;
      setoptions(tmpData);
      return onSelectOption(tmpData.filter((d) => d.name === newOption)[0].id);
    });
  };

  return (
    <Autocomplete
      value={field.value}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setValue({
            name: newValue,
          });
          onSelectOption(options.filter((o) => o.name === newValue.name)[0].id);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          handleAddOption(newValue.inputValue);
          setValue({
            name: newValue.inputValue,
          });
        } else {
          setValue(newValue);
          onSelectOption(options.filter((o) => o.name === newValue.name)[0].id);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            name: `Ajouter "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} />
      )}
    />
  );
}
