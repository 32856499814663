export const POPULATE_USER_DATA = "POPULATE_USER_DATA";
export const CLEAN_USER_DATA = "CLEAN_USER_DATA";

export const POPULATE_DASHBOARD = "POPULATE_DASHBOARD";

export const UPDATE_OAV_DATA = "UPDATE_OAV_DATA";
export const CLEAN_OAV_DATA = "CLEAN_OAV_DATA";

export const POPULATE_ASSOC = "POPULATE_ASSOC"
export const SAVE_ASSOC = "SAVE_ASSOC"

export const POPULATE_ACTIVITY = "POPULATE_ACTIVITY"
export const SAVE_ACTIVITY = "SAVE_ACTIVITY"

export const POPULATE_PACKS = "POPULATE_PACKS"
export const SAVE_PACKS = "SAVE_PACKS"

export const POPULATE_GARANTIES = "POPULATE_GARANTIES"
export const SAVE_GARANTIES = "SAVE_GARANTIES"

export const POPULATE_BROKERS = "POPULATE_BROKERS"
export const SAVE_BROKERS = "SAVE_BROKERS"

export const POPULATE_PRODUCTS = "POPULATE_PRODUCTS"
export const SAVE_PRODUCTS = "SAVE_PRODUCTS"

export const POPULATE_PROFESSIONS = "POPULATE_PROFESSIONS"
export const SAVE_PROFESSIONS = "SAVE_PROFESSIONS"

//-------- General Action ---------------------------
export const ERROR_ON_API = "ERROR_ON_API";
