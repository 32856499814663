import { useEffect, useState } from "react"
import CustomHeader from "../../Components/Header/CustomHeader"
import { Grid, Typography, Divider, Container, Button } from "@material-ui/core"
import { Controller, useForm } from "react-hook-form"
import CommonTextField from "../../Components/FormComponents/CommonTextField"
import Back from "../../Components/Commons/Back/Back"
import { useHistory, useParams } from "react-router-dom"
import { authApiAxios as axios } from "../../Axios/AxiosInstance"

//ICONS
import ContactPhoneIcon from "@mui/icons-material/ContactPhone"
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral"

const AddEditContact = () => {
  const history = useHistory()

  const [successApi, setsuccessApi] = useState(false)
  const [errorApi, seterrorApi] = useState(false)
  const { id } = useParams()

  const handleNewContact = async (data) => {
    try {
      if (id) {
        await axios.put(`contacts/${id}`, data)
      } else {
        await axios.post("/contacts", data)
      }
      setsuccessApi(true)
      setTimeout(() => {
        history.push("/Contacts")
      }, 2000)
    } catch (err) {
      seterrorApi(true)
    }
  }

  const { handleSubmit, control, errors, reset } = useForm({
    mode: "all",
    defaultValues: {
      first_name: "",
      last_name: "",
      role: "",
      phone: "",
      email: "",
    },
  })

  useEffect(() => {
    if (id) {
      ;(async () => {
        try {
          const res = await axios.get(`/contacts/${id}`)
          reset(res.data)
        } catch (err) {
          console.log(err)
          seterrorApi(true)
        }
      })()
    }
  }, [id, reset])

  return (
    <>
      {successApi && <AlertGeneral setTrigger={setsuccessApi} color="" description="Enregistré !" />}
      {errorApi && <AlertGeneral setTrigger={seterrorApi} color="danger" description="Une erreur est survenue" />}
      <CustomHeader
        icon={
          <>
            <Back />{" "}
            <ContactPhoneIcon style={{ fill: "rgb(195, 29, 35)", marginRight: ".5rem", width: 50, height: "30px" }} />
          </>
        }
        pageName={id ? `Modifier le contact` : `Nouveau contact`}
      />

      <form onSubmit={handleSubmit(handleNewContact)}>
        <Container fluid style={{ marginTop: 50 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3" style={{ textAlign: "left" }}>
                Contact
              </Typography>
              <Divider style={{ backgroundColor: "#C31D2315", marginTop: 10 }} />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="first_name"
                control={control}
                rules={{ required: true }}
                render={(field) => {
                  return (
                    <CommonTextField
                      label="Prénom"
                      field={field}
                      variant="outlined"
                      required
                      error={errors.first_name}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="last_name"
                control={control}
                rules={{ required: true }}
                render={(field) => {
                  return (
                    <CommonTextField label="Nom" field={field} variant="outlined" required error={errors.last_name} />
                  )
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ paddingTop: 20 }}>
            <Grid item xs={12}>
              <Typography variant="h3" style={{ textAlign: "left" }}>
                Rôle
              </Typography>
              <Divider style={{ backgroundColor: "#C31D2315", marginTop: 10 }} />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="role"
                control={control}
                rules={{ required: true }}
                render={(field) => {
                  return <CommonTextField label="Rôle" field={field} variant="outlined" required error={errors.role} />
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ paddingTop: 20, paddingBottom: 50 }}>
            <Grid item xs={12}>
              <Typography variant="h3" style={{ textAlign: "left" }}>
                Coordonnées
              </Typography>
              <Divider style={{ backgroundColor: "#C31D2315", marginTop: 10 }} />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="phone"
                control={control}
                rules={{ required: true }}
                render={(field) => {
                  return (
                    <CommonTextField label="Téléphone" field={field} variant="outlined" required error={errors.phone} />
                  )
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={(field) => {
                  return (
                    <CommonTextField label="Email" field={field} variant="outlined" required error={errors.email} />
                  )
                }}
              />
            </Grid>
          </Grid>

          <Button className="buttonGeneralStyle" type="submit" style={{ alignSelf: "center", margin: "1.5rem 0 0" }}>
            ENREGISTRER
          </Button>
        </Container>
      </form>
    </>
  )
}

export default AddEditContact
