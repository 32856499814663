import { useState, useEffect, useCallback } from "react"
import CustomHeader from "../../Components/Header/CustomHeader"
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt"
import MapComponent from "../../Components/Map/Map"
import { Grid, Tooltip } from "@material-ui/core"
import { getZones, updateZone } from "../../Axios/Call/ClientCredential"
import Zone from "../../Components/Zones/Zones"
import HelpIcon from "@mui/icons-material/Help"

const colors = ["#FFF", "#c31d23", "#0FB7F1", "#333", "#111"]

const Zones = () => {
  const [zonesCaps, setZonesCaps] = useState([])
  const [zonesCapsArray, setZonesCapsArray] = useState([])

  const fetchZones = async () => {
    const { data } = await getZones()

    setZonesCaps(
      data.map((zone) => ({
        id: zone.id,
        code: zone.code,
        taux: zone.taux,
        dpts: zone.caps,
        color: colors[zone.id],
      }))
    )
    setZonesCapsArray(
      data.map((zone) => ({
        id: zone.id,
        code: zone.code,
        taux: zone.taux,
        dpts: zone.caps.split(","),
        color: colors[zone.id],
      }))
    )
  }

  // GET THE ZONES FROM DB
  useEffect(() => {
    fetchZones();

  }, [])

  const handleFill = useCallback(
    (geo) => {
      const cap = zonesCapsArray.find((zone) => zone.dpts.includes(geo))
      return cap ? cap.color : "#c31d2350"
    },
    [zonesCapsArray]
  )

  const handleUpdateZone = async (id, zone) => {
    const promise = updateZone(id, zone)
    const updatedCaps = zone.caps.split(",").map((cap) => cap.trim())
    

    const zonesWithDuplicateCaps = zonesCaps
      .filter((zone) => zone.id !== id && zone.id !== 4)
      .filter((zone) =>
        zone.dpts
          .split(",")
          .map((dep) => dep.trim())
          .some((cap) => updatedCaps.includes(cap))
      )

    const promises = zonesWithDuplicateCaps
      .map((zone) =>
        updateZone(zone.id, {
          caps: zone.dpts
            .split(",")
            .map((cap) => cap.trim())
            .filter((cap) => !updatedCaps.includes(cap))
            .join(","),
          code: zone.code,
          taux: zone.taux,
        })
      )
      .concat([promise])

    await Promise.all(promises)

    fetchZones()
 
  }

  return (
    <>
      <CustomHeader
        icon={
          <AddLocationAltIcon
            style={{
              fill: "rgb(195, 29, 35)",
              marginRight: ".5rem",
              width: 50,
              height: "30px",
            }}
          />
        }
        pageName={`Zones`}
      />

      <Grid container alignContent="center" alignItems="center" style={{ padding: 30 }}>
        <Grid item xs={12} md={4}>
          {zonesCaps.map((zone, index) => {
            if (zone.id !== 4) {
              return (
                <Zone
                  key={index}
                  code={zone.code}
                  caps={zone.dpts}
                  taux={zone.taux}
                  onSave={(data) => handleUpdateZone(zone.id, data)}
                  color={zone.color}
                ></Zone>
              )
            }

            return null
          })}
        </Grid>

        <MapComponent geoUrl={"./../../departementsFrance.geojson"} handleFillMap={handleFill} strokeColor={"#FFF"} />
      </Grid>

      <Tooltip title="Les départements doivent être séparés par des virgules, sans espaces, et les 0 doivent être mis devant les départements inférieurs à 10, ex : 02">
        <HelpIcon
          style={{ paddingTop: 1, cursor: "pointer", marginBottom: 30 }}
          fontSize="large"
          sx={{ color: "#c31d2360" }}
        />
      </Tooltip>
    </>
  )
}

export default Zones
