import axios from "axios"

const url = process.env.REACT_APP_API_URL || "https://mba-api.vigee.fr/api"

export const clientCredentialAxios = axios.create({
  baseURL: (axios.defaults.baseURL = url),
})

export const authApiAxios = axios.create({
  baseURL: (axios.defaults.baseURL = url),
})

export const authGouvAxios = axios.create({
  baseURL: (axios.defaults.baseURL = "https://geo.api.gouv.fr/"),
})

export const initAxios = () => {
  authApiAxios.defaults.headers.common["Authorization"] = localStorage.getItem("token")
  authApiAxios.defaults.headers.get["Content-Type"] = "application/json;charset=utf-8"
  authApiAxios.defaults.headers.get["Access-Control-Allow-Origin"] = "*"
  clientCredentialAxios.defaults.headers.common["Authorization"] = localStorage.getItem("token")
  clientCredentialAxios.defaults.headers.get["Content-Type"] = "application/json;charset=utf-8"
  clientCredentialAxios.defaults.headers.get["Access-Control-Allow-Origin"] = "*"
}

if (process.env.NODE_ENV === "development") {
  if (document.cookie.includes("XDEBUG_SESSION")) {
    authApiAxios.interceptors.request.use((config) => {
      config.params = {
        ...config.params,
        XDEBUG_SESSION: "PHPSTORM",
      }
      return config
    })

    clientCredentialAxios.interceptors.request.use((config) => {
      config.params = {
        ...config.params,
        XDEBUG_SESSION: "PHPSTORM",
      }
      return config
    })
  }
}
