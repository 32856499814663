import { clientCredentialAxios } from "../AxiosInstance"

export const login = (data) => {
  return clientCredentialAxios.post("login", data)
}

// --------------------- Association

export const getAllAssociation = () => {
  return clientCredentialAxios.get("admin/associations")
}

export const getAssociation = (id) => {
  return clientCredentialAxios.get(`admin/association/${id}`)
}

export const addAssociation = (data) => {
  return clientCredentialAxios.post("admin/associations", data)
}

export const patchAssociation = (data, id) => {
  return clientCredentialAxios.patch(`admin/association/${id}`, data)
}

export const deleteAssociation = (id) => {
  return clientCredentialAxios.delete(`admin/association/${id}`)
}

// --------------------- Activity

export const getAllActivities = () => {
  return clientCredentialAxios.get("broker/activities")
}

export const getActivity = (id) => {
  return clientCredentialAxios.get(`admin/activity/${id}`)
}

export const addActivity = (data) => {
  return clientCredentialAxios.post("admin/activity", data)
}

export const patchActivity = (data, id) => {
  return clientCredentialAxios.patch(`admin/activity/${id}`, data)
}

export const deleteActivity = (id) => {
  return clientCredentialAxios.delete(`admin/activity/${id}`)
}

// --------------------- Professions

export const getAllProfessions = () => {
  return clientCredentialAxios.get("broker/professions")
}

export const getProfession = (id) => {
  return clientCredentialAxios.get(`admin/profession/${id}`)
}

export const addProfession = (data) => {
  return clientCredentialAxios.post("admin/profession", data)
}

export const patchProfession = (data, id) => {
  return clientCredentialAxios.patch(`admin/profession/${id}`, data)
}

export const deleteProfession = (id) => {
  return clientCredentialAxios.delete(`admin/profession/${id}`)
}

// --------------------- Subscriptions & Quotes
export const getQuote = (id) => {
  return clientCredentialAxios.get(`broker/quote/${id}`)
}

export const getQuotes = () => {
  return clientCredentialAxios.get("broker/quotes")
}

export const changeQuote = (id, data) => {
  return clientCredentialAxios.post(`broker/quote/${id}/change-status`, data)
}

export const getAllSubscriptions = () => {
  return clientCredentialAxios.get("admin/subscriptions")
}

export const getSubscription = (id) => {
  return clientCredentialAxios.get(`admin/subscription/${id}`)
}

export const addSubscription = (data) => {
  return clientCredentialAxios.post("admin/subscription", data)
}

export const patchSubscription = (data, id) => {
  return clientCredentialAxios.patch(`admin/subscription/${id}`, data)
}

export const deleteSubscription = (id) => {
  return clientCredentialAxios.delete(`admin/subscription/${id}`)
}

// --------------------- RatesTypes

export const getAllRatesTypes = () => {
  return clientCredentialAxios.get("admin/rate-types")
}

export const getRateType = (id) => {
  return clientCredentialAxios.get(`admin/rate-type/${id}`)
}

export const addRateType = (data) => {
  return clientCredentialAxios.post("admin/rate-type", data)
}

export const patchRateType = (data, id) => {
  return clientCredentialAxios.patch(`admin/rate-type/${id}`, data)
}

export const deleteRateType = (id) => {
  return clientCredentialAxios.delete(`admin/rate-type/${id}`)
}

// --------------------- Commercial

export const getAllCommercials = () => {
  return clientCredentialAxios.get("broker/commercials")
}

export const getCommercial = (id) => {
  return clientCredentialAxios.get(`admin/commercial/${id}`)
}

export const addCommercial = (data) => {
  return clientCredentialAxios.post("admin/commercial", data)
}

export const patchCommercial = (data, id) => {
  return clientCredentialAxios.patch(`admin/commercial/${id}`, data)
}

export const deleteCommercial = (id) => {
  return clientCredentialAxios.delete(`admin/commercial/${id}`)
}

// --------------------- Brokers

export const getAllBrokers = () => {
  return clientCredentialAxios.get("admin/brokers")
}

export const getBroker = (id) => {
  return clientCredentialAxios.get(`admin/broker/${id}`)
}

export const addBroker = (data) => {
  return clientCredentialAxios.post("admin/broker", data)
}

export const patchBroker = (data, id) => {
  return clientCredentialAxios.patch(`admin/broker/${id}`, data)
}

export const deleteBroker = (id) => {
  return clientCredentialAxios.delete(`admin/broker/${id}`)
}

// --------------------- Business

export const getAllBusinesses = () => {
  return clientCredentialAxios.get("admin/businesses")
}

export const getBusiness = (id) => {
  return clientCredentialAxios.get(`admin/business/${id}`)
}

export const addBusiness = (data) => {
  return clientCredentialAxios.post("admin/business", data)
}

export const patchBusiness = (data, id) => {
  return clientCredentialAxios.patch(`admin/business/${id}`, data)
}

export const deleteBusiness = (id) => {
  return clientCredentialAxios.delete(`admin/business/${id}`)
}

// --------------------- Documents

export const getAllDocuments = (type) => {
  if (type) {
    return clientCredentialAxios.get("broker/documents", { type: type })
  } else {
    return clientCredentialAxios.get("broker/documents")
  }
}

export const getDocument = (id) => {
  return clientCredentialAxios.get(`admin/document/${id}`)
}

export const addDocument = (data) => {
  return clientCredentialAxios.post("admin/document", data)
}

export const patchDocument = (data, id) => {
  return clientCredentialAxios.patch(`admin/document/${id}`, data)
}

export const deleteDocument = (id) => {
  return clientCredentialAxios.delete(`admin/document/${id}`)
}

export const showDocument = (id) => {
  return clientCredentialAxios.get(`broker/document/${id}/file`)
}

// --------------------- Products

export const getAllProducts = () => {
  return clientCredentialAxios.get("broker/products")
}

export const getProduct = (id) => {
  return clientCredentialAxios.get(`admin/product/${id}`)
}

export const addProduct = (data) => {
  return clientCredentialAxios.post("admin/product", data)
}

export const patchProduct = (data, id) => {
  return clientCredentialAxios.patch(`admin/product/${id}`, data)
}

export const deleteProduct = (id) => {
  return clientCredentialAxios.delete(`admin/product/${id}`)
}

// --------------------- ProductsDocuments

export const getAllProductsDocument = (id) => {
  return clientCredentialAxios.get(`admin/product/document/${id}`)
}

export const deleteProductsDocument = (id) => {
  return clientCredentialAxios.delete(`admin/product/document/${id}`)
}

export const showProductDocument = (id) => {
  return clientCredentialAxios.get(`admin/product/document/${id}`)
}

// --------------------- Guaranties

export const getAllGuaranties = () => {
  return clientCredentialAxios.get("broker/guarantees")
}

export const getGuarantee = (id) => {
  return clientCredentialAxios.get(`admin/guarantee/${id}`)
}

export const addGuarantee = (data) => {
  return clientCredentialAxios.post("admin/guarantee", data)
}

export const patchGuarantee = (data, id) => {
  return clientCredentialAxios.patch(`admin/guarantee/${id}`, data)
}

export const deleteGuarantee = (id) => {
  return clientCredentialAxios.delete(`admin/guarantee/${id}`)
}

// --------------------- Zone

export const getZones = () => {
  return clientCredentialAxios.get("admin/zones")
}

export const updateZone = (id, data) =>
  clientCredentialAxios.put(`admin/zones/${id}`, data)

// --------------------- Rates

export const getAllRates = () => {
  return clientCredentialAxios.get("broker/rates")
}

export const getRate = (id) => {
  return clientCredentialAxios.get(`admin/rate/${id}`)
}

export const addRate = (data) => {
  return clientCredentialAxios.post("admin/rate", data)
}

export const patchRate = (data, id) => {
  return clientCredentialAxios.patch(`admin/rate/${id}`, data)
}

export const patchRates = (data) => {
  return clientCredentialAxios.put(`admin/rate/batch`, data)
}

export const deleteRate = (id) => {
  return clientCredentialAxios.delete(`admin/rate/${id}`)
}

// --------------------- Packages

export const getAllPackages = () => {
  return clientCredentialAxios.get("broker/packages")
}

export const getPackage = (id) => {
  return clientCredentialAxios.get(`admin/package/${id}`)
}

export const addPackage = (data) => {
  return clientCredentialAxios.post("admin/package", data)
}

export const patchPackage = (data, id) => {
  return clientCredentialAxios.patch(`admin/package/${id}`, data)
}

export const deletePackage = (id) => {
  return clientCredentialAxios.delete(`admin/package/${id}`)
}

export const showPackageDocument = (id) => {
  return clientCredentialAxios.get(`broker/package/document/${id}`)
}

// --------------------- PackagesDocuments

export const getPackageDocuments = (id) => {
  return clientCredentialAxios.get(`broker/document/${id}/file`)
}

export const deletePackagesDocuments = (id) => {
  return clientCredentialAxios.delete(`admin/package/document/${id}`)
}

// --------------------- PackagesAssignation

export const addPackagesAssignations = (data) => {
  return clientCredentialAxios.post("admin/assignation", data)
}

export const patchPackagesAssignation = (data, id) => {
  return clientCredentialAxios.patch(`admin/assignation${id}`, data)
}

export const deletePackagesAssignation = (id) => {
  return clientCredentialAxios.delete(`admin/assignation/${id}`)
}

//------ Recover password
export const ResetPasswordEmail = (data) => {
  return clientCredentialAxios.post("reset-password/email", data)
}

export const resetPasswordApi = (data) => {
  return clientCredentialAxios.post("reset-password", data)
}

export const manuallyUploadDoc = (id, data) =>
  clientCredentialAxios.post(`admin/quote/${id}/upload-doc`, data)

// --------------------- Notes

export const getAllNotes = () => {
  return clientCredentialAxios.get("admin/notes")
}

export const upsertNote = (slug, data) => {
  return clientCredentialAxios.put(`admin/notes/${slug}`, data)
}

export const getAllProductsIndependants = () => {
  return clientCredentialAxios.get("broker/independent-products");
};