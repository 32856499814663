import React, { useEffect } from "react";
//--------- Others ------------------
import * as actionCreator from "../Store/action/index";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const LayoutNotLogin = ({ children, page }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(actionCreator.populateUserData());
      dispatch(actionCreator.populateGaranties());
      dispatch(actionCreator.populateProducts());
      dispatch(actionCreator.populatePacks());
      dispatch(actionCreator.populateBrokers());
      dispatch(actionCreator.populateAssoc());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(user.userData).length) {
      if (user.userData?.status === "active") {
        // if (user.userData?.email_verified_at) {
          if (user.userData.type === "admin" || user.userData.type === "user") {
            // console.log(location)
          }
        // } else {
        //   dispatch(actionCreator.cleanUserData());
        // }
      } else {
        dispatch(actionCreator.cleanUserData());
      }
    }
  }, [user]);

  return <>{children}</>;
};

export default LayoutNotLogin;
