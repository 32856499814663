import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import { BsArrowRightShort } from "react-icons/bs";

import TextFieldCustom from "../../Components/FormComponents/TextFieldCustom";
import { Link, useHistory } from "react-router-dom";
import { formatErrorEmail } from "../../utils/formValidation";
import text from "../../utils/text";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import AuthLayout from "../../Components/Auth/Registration/AuthLayout";
import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular";
import Links from "../../Components/Commons/Links/Links";
import { ResetPasswordEmail } from "../../Axios/Call/ClientCredential";

const RecoverPassword = () => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const sendFormLogin = (data) => {
    setLoadingApi(true);
    ResetPasswordEmail(data)
      .then((response) => {
        setEmailSuccess(true);
        setLoadingApi(false);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  };

  return (
    <AuthLayout>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.RecoverPassword.alertError}
        />
      )}

      {emailSuccess && (
        <AlertGeneral
          color="success"
          setTrigger={setEmailSuccess}
          description={"Vous allez recevoir un email avec la procédure pour changer votre mot de passe"}
        />
      )}

      <Box style={{ textAlign: "left" }}>
        <Typography component="h2" variant="h1" style={{ fontSize: "33px" }}>
          {/* {text.Login.title} */}
          Mot de passe oublié
        </Typography>

        <Typography
          component="h2"
          variant="h3"
          style={{ fontSize: "14px", color: "#222" }}
        >
          Changement de mot de passe
        </Typography>
      </Box>
      <form
        onSubmit={handleSubmit(sendFormLogin)}
        style={{ marginTop: 25 }}
        noValidate
      >
        <Grid container>
          <Grid item xs={12}>
            <TextFieldCustom
              label="Email"
              type="email"
              ref={register({
                required: text.General.required,
                validate: {
                  formatErrorEmail,
                },
              })}
              defaultValue=""
              name="email"
              error={errors.email}
            />
          </Grid>

          <Link
            to={"/"}
            style={{ textDecoration: "none", margin: "0.4rem auto" }}
          >
            <Typography component="span" className={"linkBottomGrey"}>
              Retour à la connexion
            </Typography>
          </Link>

          <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
            {loadingApi ? (
              <LoaderCircular />
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                width="auto"
                endIcon={<BsArrowRightShort />}
                onClick={() => history.push("recover-password")}
              >
                Changer mon mot de passe
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
      <Links />
    </AuthLayout>
  );
};

export default RecoverPassword;
