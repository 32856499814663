import React, { useEffect, useState } from "react"
import Form from "../../Components/FormComponents/Form"
import Grid from "@material-ui/core/Grid"
import { Controller, useForm } from "react-hook-form"
import { Box, Button, Typography } from "@material-ui/core"
import CommonTextField from "../../Components/FormComponents/CommonTextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import { ImPriceTags } from "react-icons/im"
import { getAllRates, getZones, patchRates } from "../../Axios/Call/ClientCredential"
import { calculateHTbasedOnTTC, calculatePureBasedOnHT } from "../../utils/utils"
import CommonSelect from "../../Components/FormComponents/CommonSelect"
import CommonDateTimePicker from "../../Components/FormComponents/CommonDateTimePicker"
import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular"

const RevalorisationForm = ({ selectedRatesIds, handleClose, update, title }) => {
  const [errorAPI, setErrorAPI] = useState(false)
  const [successAPI, setSuccessAPI] = useState(false)
  const [loadingAPI, setLoadingAPI] = useState(false)
  const [selectedRates, setSelectedRates] = useState([])
  const [zones, setZones] = useState([])
  const { errors, control, watch, getValues, handleSubmit } = useForm({
    mode: "onTouched",
    reValidateMode: "onTouched",
  })

  useEffect(() => {}, [watch("type")])

  useEffect(() => {
    setLoadingAPI(true)
    getZones().then((res) => {
      let tmp = res.data.map((zone) => {
        return { id: zone.id, value: zone.code, taux: zone.taux }
      })
      setZones(tmp)
    })

    getAllRates()
      .then((res) => {
        setSelectedRates(selectedRatesIds.map((id) => res.data.find((rate) => rate.id === id)))
        setLoadingAPI(false)
      })
      .catch((error) => {
        console.warn(error)
        setErrorAPI(true)
        setLoadingAPI(false)
      })
  }, [])

  const submitRevalorisation = (data) => {
    setLoadingAPI(true)
    const newRates = { rates: [] }

    selectedRates.forEach((rate) => {
      let newRate = rate
      if (!update) newRate.id = null
      newRate.starting_date = data.starting_date
      newRate.ending_date = data.ending_date
      let coefZone = zones.find((zone) => zone.id === rate.zone_id)?.taux

      let TTC, HT, Pure
      if (data.type === "euro") {
        TTC = rate.price_with_tva + parseFloat(data.revaluation)
      } else if (data.type === "percent") {
        TTC = rate.price_with_tva * (1 + parseFloat(data.revaluation) / 100)
      }
      HT = calculateHTbasedOnTTC(TTC, rate.tva)
      Pure = calculatePureBasedOnHT(HT, coefZone, rate.gestion_taux, rate.commission)

      newRate.price_with_tva = TTC
      newRate.price_no_tva = HT
      newRate.base_price = Pure
      newRates.rates.push(newRate)
    })

    patchRates(newRates)
      .then((res) => {
        if (res.status === 200 || res.status === 201 || res.status === "success") {
          setSuccessAPI(true)
        } else {
          setErrorAPI(true)
        }
        setSuccessAPI(true)
        setLoadingAPI(false)
      })
      .catch((err) => {
        setErrorAPI(true)
        setLoadingAPI(false)
        console.warn(err)
      })
      .finally(handleClose)
  }

  return (
    <>
      <Box display={"flex"} alignItems={"center"}>
        <ImPriceTags size={40} style={{ fill: "#c31d23", marginRight: ".8rem" }} />
        <Typography component="h2" variant="h2" style={{ color: "rgb(195, 29, 35)" }}>
          {title}
        </Typography>
        {loadingAPI && <LoaderCircular />}
      </Box>
      <Form onSubmit={handleSubmit(submitRevalorisation)}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="type"
              defaultValue={null}
              rules={{ required: true }}
              render={(field) => (
                <CommonSelect
                  nullFirstOption
                  field={field}
                  label="Type"
                  error={errors.type}
                  options={[
                    { id: "euro", value: "Montant" },
                    { id: "percent", value: "%" },
                  ]}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display={"flex"} alignItems={"center"}>
              <Controller
                control={control}
                rules={{ required: true }}
                defaultValue={null}
                name="revaluation"
                render={(field) => (
                  <CommonTextField
                    type="number"
                    field={field}
                    error={errors.revaluation}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {getValues("type") === "percent" ? "%" : "points"}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display={"flex"} alignItems={"center"}>
              <Controller
                control={control}
                rules={{ required: true }}
                defaultValue={null}
                name="starting_date"
                render={(field) => (
                  <CommonDateTimePicker field={field} error={errors.starting_date} label={"Date de début"} />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display={"flex"} alignItems={"center"}>
              <Controller
                control={control}
                rules={{ required: true }}
                defaultValue={null}
                name="ending_date"
                render={(field) => (
                  <CommonDateTimePicker field={field} error={errors.ending_date} label={"Date de fin"} />
                )}
              />
            </Box>
          </Grid>
        </Grid>

        <Box className={"spacer2"} />

        <Grid item xs={12} md={12}>
          <Button className="buttonCenter" type="submit" style={{ marginTop: "20px", width: "100%" }}>
            {title}
          </Button>
        </Grid>
      </Form>
    </>
  )
}

export default RevalorisationForm
