import React from "react";
import { Typography, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";

const AccesProfile = () => {
  const firstname = useSelector((state) => state.user.userData?.name);
  const lastame = useSelector((state) => state.user.userData?.surname);
  const history = useHistory();
  return (
    <Box
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      onClick={() => history.push("/Profil")}
    >
      <Typography component="p" variant="body2">
        {firstname} {lastame}
      </Typography>
      <FaUserCircle
        size={30}
        style={{ fill: "rgb(195, 29, 35)", marginLeft: ".5rem" }}
      />
    </Box>
  );
};

export default AccesProfile;
