import React, { useState, useEffect } from "react"
import { Box, Typography, Grid, Button, TextField } from "@material-ui/core"
import CommonSelect from "../../../Components/FormComponents/CommonSelect"
import { getQuotes, getZones } from "../../../Axios/Call/ClientCredential"
import CommonDateTimePicker from "../../../Components/FormComponents/CommonDateTimePicker"
import { useSelector } from "react-redux"
import VerticalBarChartCustom from "../../../Components/Charts/VerticalBarChartCustom"
import _ from "lodash"
import LineChartCustom from "../../../Components/Charts/LineChartCustom"
import moment from "moment"
import "moment/locale/fr"
import { getDpts, getRegions } from "../../../Axios/Call/GouvApi"

import { getAllProfessions } from "../../../Axios/Call/ClientCredential"

moment.locale("fr")

const Repartitions = () => {
  const [starting_date, setstarting_date] = useState(moment().subtract(1, "year"))
  const [ending_date, setending_date] = useState(moment().add(3, "month"))
  const [product, setproduct] = useState("all")
  const [market, setmarket] = useState("individual")
  const [broker, setbroker] = useState("all")
  const [zone, setzone] = useState(4)
  const [zones, setZones] = useState([])
  const [graphData, setgraphData] = useState([])
  const [pieData, setpieData] = useState([])

  const [pieDataGaranties, setpieDataGaranties] = useState([])
  const [pieDataGarantiesRenfort, setpieDataGarantiesRenfort] = useState([])

  const [professions, setProfessions] = useState([])
  const [pieDataProfessions, setpieDataProfessions] = useState([])

  const [pieData3, setpieData3] = useState([])

  const [pieDataIndice, setpieDataindice] = useState([])

  const [quotes, setquotes] = useState([]) // all the devis/contrats
  const [dpts, setdpts] = useState([])
  const [regions, setRegions] = useState([])
  const garanties = useSelector((state) => state.user.garanties)
  const markets = useSelector((state) => state.user.markets)
  const products = useSelector((state) => state.user.products)

  const testBrokerId = useSelector((state) => state.user.testBrokerId)
  const brokers = useSelector((state) => state.user.brokers.filter((broker) => broker.id !== testBrokerId))

  const onKeyDown = (e) => {
    e.preventDefault()
  }

  function getCommonCharacters(str1, str2) {
    const set1 = new Set(str1)
    const set2 = new Set(str2)
    const common = [...set1].filter((char) => set2.has(char))
    return common
  }

  const createGraphStats = (contrats, devis, start, end) => {
    const graph = []

    function monthDiff(d1, d2) {
      var months
      months = (d2.getFullYear() - d1.getFullYear()) * 12
      months -= d1.getMonth()
      months += d2.getMonth()
      return months <= 0 ? 0 : months
    }

    const diff = monthDiff(new Date(start), new Date(end))

    for (let i = 0; i < diff + 1; i++) {
      let tmpcontrat = 0
      let tmpdevis = 0

      let tmpstart = i === 0 ? new Date(start) : new Date(moment(start).startOf("month").add(i, "month"))
      let tmpend = i === diff ? new Date(end) : new Date(moment(start).endOf("month").add(i, "month"))

      contrats?.map((q) => {
        if (new Date(q.created_at) >= tmpstart && new Date(q.created_at) <= tmpend) {
          tmpcontrat += 1
        }
      })

      devis?.map((q) => {
        if (new Date(q.created_at) >= tmpstart && new Date(q.created_at) <= tmpend) {
          tmpdevis += 1
        }
      })

      graph.push({
        date: moment(tmpstart).format("MMMM YYYY"),
        Devis: tmpdevis,
        Contrats: tmpcontrat,
      })
    }

    setgraphData(graph)
  }

  const calculateNumberOfPerson = (quote) => {
    // que pour les quotes indiv
    let value = 1
    if (quote?.joint_birth_date) {
      value++
    }
    value += quote?.childrens?.length || 0
    return value
  }

  const calculateTotal = (data, PMSS, effectif) => {
    // que pour les quotes collectives
    let total = 0
    if (!!data && data !== "000") {
      let formated = data
      for (const [key, value] of Object?.entries(formated)) {
        total += value
      }
      return Math.round((total / 100) * PMSS * 12 * effectif)
    } else {
      return total
    }
  }

  function filterByDates(data, start, end) {
    const tmp = data.filter((q) => {
      if (new Date(q.created_at) >= new Date(start) && new Date(q.created_at) <= new Date(end)) {
        return q
      }
    })
    return tmp
  }

  function filterByMarket(data, market) {
    switch (market) {
      case "all":
        return data
      case "individual":
        return data.filter((q) => !q.company)
      case "company":
        return data.filter((q) => !!q.company)
      default:
        break
    }
  }

  function filterByProduct(data, id) {
    switch (id) {
      case "all":
        return data
      default:
        return data.filter((q) => q.product_id === product * 1)
    }
  }

  function thisYear() {
    let filteredByNo = quotes
    var first = new Date(new Date().getFullYear(), 0, 1)
    var last = new Date(new Date().getFullYear(), 11, 31)
    setstarting_date(first)
    setending_date(last)
    filteredByNo = filterByDates(filteredByNo, starting_date, ending_date)
  }

  function thisMonth() {
    let filteredByNo = quotes
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth()
    var firstDay = new Date(y, m, 1)
    var lastDay = new Date(y, m + 1, 0)
    setstarting_date(firstDay)
    setending_date(lastDay)
    filteredByNo = filterByDates(filteredByNo, starting_date, ending_date)
  }

  function fromBeginning() {
    let filteredByNo = quotes
    const result = quotes.reduce((r, o) => (o.created_at < r.created_at ? o : r))
    var lastDay = new Date()
    // console.log(result);
    // console.log(result.created_at);
    setstarting_date(result.created_at)
    setending_date(lastDay)
    filteredByNo = filterByDates(filteredByNo, starting_date, ending_date)
  }

  function filterByZone(data, id) {
    // console.log(id, data);
    switch (id) {
      case "all":
        return data

      default:
        // ici j'ai l'id 1 / 2 / 3
        // avec cet id je peux récupérer la zone et ses caps
        // une fois que j'ai ça, je filter les zip_code préalablement formatés des quote avec les caps
        let res = []
        zones?.map((z) => {
          if (z.id * 1 === id * 1) {
            data?.map((q) => {
              const charsZoneFull = q.zip_code.split("")
              let zoneCaps // c'est le caps formaté
              if (charsZoneFull !== undefined) {
                zoneCaps = `${charsZoneFull[0]}${charsZoneFull[1]}`
                if (zoneCaps === "97") {
                  zoneCaps = `${charsZoneFull[0]}${charsZoneFull[1]}${charsZoneFull[2]}`
                }
              }
              // console.log(z.caps, "vs", zoneCaps);
              // console.log(_.includes(z.caps, zoneCaps));
              // console.log("******************************");
              if (_.includes(z.caps, zoneCaps)) {
                res.push(q)
              }
            })
          }
        })
        return res
    }
  }

  function filterByBroker(data, id) {
    switch (id) {
      case "all":
        return data
      default:
        return data.filter((q) => q.broker_id === id * 1)
    }
  }

  function filterByNoAnswear(data) {
    return data.filter((q) => q.devis_status !== "no-answear" && q.contrat_status !== "no-answear")
  }

  function findOnGoingQuotes(data) {
    //DEBUG
    // return data?.filter((q) => q.signed === "0" || q.signed === 0);
    // console.log(data);
    return data.filter((q) => q.type === "quote" || q.signed === 1)
  }

  function findOnGoingContrat(data) {
    //DEBUG
    // return data?.filter((q) => q.signed === "1" || q.signed === 1);
    // console.log(data);
    return data.filter(
      (q) => q.type === "subscription" && (q.contract_status === "signed" || q.contract_status === "managment")
    )
  }

  const getSignedQuotes = (data) => {
    return data.filter((q) => q.signed === 1)
  }

  const getSignedQuotesPercent = (quotes, signedQuotes) => {
    let res = ((signedQuotes.length / quotes.length) * 100).toFixed(1)

    if (isNaN(res) || res == 0) {
      return 0
    } else {
      return res
    }
  }

  useEffect(() => {
    getQuotes()
      .then((res) => {
        //Filter test account
        setquotes(
          res.data
            .filter((quote) => quote.broker_id !== testBrokerId)
            .filter(
              (q) => q.type === "subscription" && (q.contract_status === "signed" || q.contract_status === "managment")
            )
        )
      })
      .catch((error) => console.log(error))
    getDpts().then((res) => setdpts(res.data))
    getRegions().then((res) => setRegions(res.data))
    getZones().then((res) => setZones(res.data))

    getAllProfessions().then((res) => {
      // console.log(res.data);
      const tmpProf = res?.data?.map((a) => {
        return { id: a.id, name: a.name }
      })
      setProfessions(tmpProf)
    })
  }, [])

  useEffect(() => {
    // quand une des valeurs de filtre change -----------------------------------
    // alors je filtre avec tous les filtres ------------------------------------
    let data = quotes
    let filteredByNo = quotes

    //DEBUG
    // let filteredByNo = filterByNoAnswear(data);
    // console.log("par no-answear", filteredByNo);

    filteredByNo = filterByDates(filteredByNo, starting_date, ending_date)

    filteredByNo = filterByBroker(filteredByNo, broker)

    filteredByNo = filterByMarket(filteredByNo, market)

    filteredByNo = filterByProduct(filteredByNo, product)

    filteredByNo = filterByZone(filteredByNo, zone)

    // puis je compute ----------------------------------------------------------

    // => all filtré
    let X = filterByProduct(filterByMarket(filterByDates(data, starting_date, ending_date), market), product)
    // => all signé = 1
    let Y = findOnGoingContrat(filteredByNo)

    // => le nombre de personnes et le prix

    let onGoingQuotes = findOnGoingQuotes(filteredByNo)
    let onGoingContrat = findOnGoingContrat(filteredByNo)
    let signedQuotes = getSignedQuotes(filteredByNo)

    let signedQuotesPercent = getSignedQuotesPercent(onGoingQuotes, onGoingContrat)

    let indivPP = 0
    let collecPP = 0

    let indivQuotePrice = 0
    let indivContratPrice = 0

    let collecQuotePrice = 0
    let collecContratPrice = 0

    // =======> pour les indivs
    // ===========> quotes
    filterByMarket(onGoingQuotes, "individual")?.forEach((q) => {
      !!q.total_price ? (indivQuotePrice += q?.total_price * 1 * 12) : (indivQuotePrice += 0)
    })

    // ===========> contrat
    filterByMarket(onGoingContrat, "individual")?.forEach((q) => {
      indivPP += calculateNumberOfPerson(q)
      indivContratPrice += q?.total_price * 1 * 12
    })

    // =======> pour les companies
    // ===========> quotes
    filterByMarket(onGoingQuotes, "company")?.forEach((q) => {
      !!q.meta ? (collecQuotePrice += calculateTotal(q?.meta, 3428, q?.effectif)) : (collecQuotePrice += 0)
    })

    // ===========> contrat
    filterByMarket(onGoingContrat, "company")?.forEach((q) => {
      collecPP += q?.effectif
      collecContratPrice += calculateTotal(q?.meta, 3428, q?.effectif)
    })

    // => GRAPH FUNCTIONS

    createGraphStats(onGoingContrat, onGoingQuotes, starting_date, ending_date)

    // je dois récup le nb de produit dans les quotes

    let pie1 = products
      ?.map((p) => {
        let res = { name: p.description, value: 0 }
        filteredByNo.forEach((q) => {
          if (q.product_id === p.id) {
            return (res.value += 1)
          }
        })
        return res
      })
      ?.filter((p) => p.value !== 0)
      .sort((a, b) => b.value - a.value)
      .slice(0, 5)
    setpieData(pie1)

    let pie2 = garanties
      ?.map((p) => {
        const product = products.find((prod) => prod.id === p.product_id).code
        let res = { name: p.description + " (" + product + ")", value: 0 }
        filteredByNo.forEach((q) => {
          q.packages.map((pack) => {
            if (pack.guarantee_id === p.id && pack.type === "base") {
              return (res.value += 1)
            }
          })
        })
        return res
      })
      ?.filter((p) => p.value !== 0)
      .sort((a, b) => b.value - a.value)
      .slice(0, 5)
    setpieDataGaranties(pie2)

    let pieRenforts = garanties
      ?.map((p) => {
        const product = products.find((prod) => prod.id === p.product_id).code
        let res = { name: p.description + " (" + product + ")", value: 0 }
        filteredByNo.forEach((q) => {
          q.packages.map((pack) => {
            if (pack.guarantee_id === p.id && (pack.type === "renfort" || pack.type === "option")) {
              return (res.value += 1)
            }
          })
        })
        return res
      })
      ?.filter((p) => p.value !== 0)
      .sort((a, b) => b.value - a.value)
      .slice(0, 5)
    setpieDataGarantiesRenfort(pieRenforts)

    // Fusionner les résultats avec des descriptions similaires
    let mergedPieIndice = []
    pie2.forEach((p) => {
      let found = false
      const pDescription = p.name
      const matches = pDescription.match(/\((.*?)\)/) // juste ce qu'il y a entre paranthèse
      const pDescriptionCommonChars = matches ? matches[1] : "" // Utiliser le contenu entre parenthèses s'il est présent, sinon une chaîne vide

      mergedPieIndice.forEach((merged) => {
        if (merged.name === pDescriptionCommonChars) {
          merged.value += p.value
          found = true
        }
      })

      if (!found) {
        mergedPieIndice.push({ name: pDescriptionCommonChars, value: p.value })
      }
    })
    setpieDataindice(mergedPieIndice)

    let pieProfessions = professions
      ?.map((p) => {
        let res = { name: p.name, value: 0 }

        filteredByNo.forEach((q) => {
          if (q.profession_id === p.id) {
            return (res.value += 1)
          }
        })
        return res
      })
      ?.filter((p) => p.value !== 0)
      .sort((a, b) => b.value - a.value)
      .slice(0, 5)
    setpieDataProfessions(pieProfessions)

    let pie3 = brokers
      ?.map((b) => {
        let res = { name: `${b.company}`, value: 0 }
        filteredByNo.forEach((q) => {
          if (q.broker_id * 1 === b.id * 1) {
            return (res.value += 1)
          }
        })
        return res
      })
      ?.filter((p) => p.value !== 0)
      .sort((a, b) => b.value - a.value)
      .slice(0, 5)
    setpieData3(pie3)

    // puis j'affiche -----------------------------------------------------------

    // console.log(data)
  }, [products, quotes, market, product, starting_date, ending_date, broker, zone])

  return (
    <div>
      <Box className="spacer2" />

      {/* ****************** GRAPHS *********************** */}
      <Grid container spacing={2}>
        {/* fields */}
        <Grid item xs={12} md={3} xl={2}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                padding: 30,
                borderRadius: 5,
                margin: 15,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3" style={{ textAlign: "left" }}>
                    Période
                  </Typography>
                </Grid>

                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <CommonDateTimePicker
                    label={"Début"}
                    name="starting_date"
                    field={{ value: starting_date, onChange: setstarting_date }}
                    renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CommonDateTimePicker
                    label={"Fin"}
                    name="ending_date"
                    field={{ value: ending_date, onChange: setending_date }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    className="buttonGeneralStyle"
                    type="submit"
                    style={{ alignSelf: "center" }}
                    onClick={() => thisMonth()}
                  >
                    Mois
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className="buttonGeneralStyle"
                    type="submit"
                    style={{ alignSelf: "center" }}
                    onClick={() => thisYear()}
                  >
                    Année
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    className="buttonGeneralStyle"
                    type="submit"
                    style={{ alignSelf: "center" }}
                    onClick={() => fromBeginning()}
                  >
                    Début
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              spacing={2}
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                padding: 30,
                borderRadius: 5,
                margin: 15,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3" style={{ textAlign: "left" }}>
                    Filtres
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <CommonSelect
                    label="Marché"
                    name="market"
                    defaultValue={"individual"}
                    field={{ value: market, onChange: setmarket }}
                    options={[...markets]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CommonSelect
                    //label={"Secteur"}
                    name="zone"
                    field={{ value: zone, onChange: setzone }}
                    options={[
                      ...zones.map((zone) => {
                        return { id: zone.id, value: zone.code }
                      }),
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CommonSelect
                    label={"Produits"}
                    name="market"
                    defaultValue={[]}
                    field={{ value: product, onChange: setproduct }}
                    options={[
                      { id: "all", value: "Tous les produits" },
                      ...products.map((product) => {
                        return { id: product.id, value: product.description }
                      }),
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CommonSelect
                    label={"Courtier"}
                    name="broker"
                    defaultValue={[]}
                    field={{ value: broker, onChange: setbroker }}
                    options={[
                      { id: "all", value: "Tous les courtiers" },
                      ...brokers.map((broker) => {
                        return {
                          id: broker.id,
                          value: `${broker.company}`,
                        }
                      }),
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={9} xl={10} spacing={2}>
          <Grid container spacing={2}>
            {/*AMOUNT PER COURTIER CHART */}
            <Grid item xs={12} md={6} style={{ margin: 0, borderRadius: 5, padding: 10 }}>
              <VerticalBarChartCustom title="Contrats signés par courtiers" data={pieData3} />
            </Grid>

            {/*AMOUNT PER COURTIER CHART */}
            <Grid item xs={12} md={6} style={{ margin: 0, borderRadius: 5, padding: 10 }}>
              <VerticalBarChartCustom title="Par produit" data={pieData} />
            </Grid>

            <Grid item xs={12} md={6} style={{ margin: 0, borderRadius: 5, padding: 10 }}>
              <VerticalBarChartCustom title="Par garanties (Base)" data={pieDataGaranties} />
            </Grid>

            <Grid item xs={12} md={6} style={{ margin: 0, borderRadius: 5, padding: 10 }}>
              <VerticalBarChartCustom title="Par garanties (Renfort ou Option)" data={pieDataGarantiesRenfort} />
            </Grid>

            <Grid item xs={12} md={6} style={{ margin: 0, borderRadius: 5, padding: 10 }}>
              <VerticalBarChartCustom title="Par Indice" data={pieDataIndice} />
            </Grid>

            <Grid item xs={12} md={6} style={{ margin: 0, borderRadius: 5, padding: 10 }}>
              <VerticalBarChartCustom title="Par profession" data={pieDataProfessions} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Repartitions
