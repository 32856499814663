import { addRate, deleteRate, getAllRates, getZones, patchRate } from "../../Axios/Call/ClientCredential"
import { DataGrid, frFR } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import TextField from "@material-ui/core/TextField"
import { Button, Grid, LinearProgress, Typography } from "@material-ui/core"
import CustomHeader from "../../Components/Header/CustomHeader"
import { AiOutlineCheck } from "react-icons/ai"
import { FaClone, FaRegTrashAlt } from "react-icons/fa"
import { ImPriceTags } from "react-icons/im"
import { MdClear, MdOutlineAdd } from "react-icons/md"
import Box from "@material-ui/core/Box"
import moment from "moment"
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral"
import CustomModal from "../../Components/Commons/Modal/CustomModal"
import RevalorisationForm from "./RevalorisationForm"
import InputAdornment from "@material-ui/core/InputAdornment"
import { CSVLink } from "react-csv"
import { RatesHeader } from "../../Components/CSV/Headers"
import GridToolbar from "../../Components/Datagrid/GridToolbar"
import CommonSelect from "../../Components/FormComponents/CommonSelect"
import {
  renderAgeCell,
  renderCommissionCell,
  renderDateCell,
  renderEditAgeCell,
  renderEditBasePriceCell,
  renderEditCommissionCell,
  renderEditFraisGestionCell,
  renderEditGuarantyCell,
  renderEditHTCell,
  renderEditPercentSalaryCell,
  renderEditPriceTypeCell,
  renderEditProductCell,
  renderEditRegimeCell,
  renderEditStructureCell,
  renderEditTTCCell,
  renderEditTvaCell,
  renderEditZoneCell,
  renderFraisGestionCell,
  renderGuarantyCell,
  renderPercentSalaryCell,
  renderPriceCell,
  renderPriceTypeCell,
  renderProductCell,
  renderRegimeCell,
  renderStructureCell,
  renderTvaCell,
  renderZoneCell,
} from "../../Components/Table/DataGridCustomCells"

const EditableRates = () => {
  const [errorAPI, setErrorAPI] = useState(false)
  const [successAPI, setSuccessAPI] = useState(false)
  const [loadingAPI, setLoadingAPI] = useState(true)
  const [errorSelection, setErrorSelection] = useState(false)
  const products = useSelector((state) => state.user.products)
  const garanties = useSelector((state) => state.user.garanties)
  const [zones, setZones] = useState([])
  const commissions = useSelector((state) => state.user.commission)
  const frais_gestion = useSelector((state) => state.user.frais_gestion)
  const regimes = useSelector((state) => state.user.regimes)
  const tvas = useSelector((state) => state.user.tvas)
  const structures = useSelector((state) => state.user.structures)
  const priceTypes = useSelector((state) => state.user.priceTypes)
  const [rows, setRows] = useState([])

  const [pageSize, setPageSize] = useState(25)
  const [editRowsModel, setEditRowsModel] = useState({})
  const [selectionModel, setSelectionModel] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [searchFieldValue, setSearchFieldValue] = useState("")
  const [openModal, setOpenModal] = useState(false)
  const [openModificationModal, setOpenModificationModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const isAdmin = useSelector((state) => state.user.userData.type === "admin")

  const [filters, setFilters] = useState({
    zone: "all",
    ageMin: "",
    ageMax: "",
    structure: "all",
    regime: "all",
    commission: "all",
  })

  let filteredRows = []

  if (rows.length > 0) {
    let tmp = rows
    if (filters.zone !== "all") tmp = tmp.filter((row) => row.zone?.toString() === filters.zone)
    if (filters.commission !== "all") tmp = tmp.filter((row) => row.commission?.toString() === filters.commission)
    if (filters.structure !== "all") tmp = tmp.filter((row) => row.structure?.toString() === filters.structure)
    if (filters.regime !== "all") tmp = tmp.filter((row) => row.regime?.toString() === filters.regime)

    if (filters.ageMin !== "") {
      tmp = tmp.filter((row) => {
        if (Array.isArray(row.age)) {
          return row.age[0].toString() === filters.ageMin
        } else {
          return row.age >= filters.ageMin
        }
      })
    }
    if (filters.ageMax !== "") {
      tmp = tmp.filter((row) => {
        if (Array.isArray(row.age)) {
          return row.age[1].toString() === filters.ageMax
        } else {
          return row.age <= filters.ageMax
        }
      })
    }

    tmp = tmp?.filter((row) => {
      let renderData = {
        id: row.id.toString(),
        product: products.find((product) => product.id === row.product)?.description.toLowerCase(),
        garanty: garanties.find((guaranty) => guaranty.id === row.guaranty)?.name.toLowerCase(),
        age: Array.isArray(row.age) ? `${row.age[0]} - ${row.age[1]} ans` : `${row.age} ans`,
        base_price: `${row.base_price.toFixed(row.priceType?.id === 2 ? 3 : 2)}${row.priceType?.value}`,
        percentSalary: `${row.percent_salary}%`,
        zone: zones.find((zone) => zone.id === row.zone)?.value.toLowerCase(),
        commission: commissions.find((commission) => commission.id === row.commission)?.value,
        frais_gestion: frais_gestion.find((frais) => frais.id === row.frais_gestion)?.value,
        price_no_tva: `${row.price_no_tva.toFixed(row.priceType?.id === 2 ? 3 : 2)}${row.priceType?.value}`,
        regime: regimes.find((regime) => regime.id === row.regime)?.value.toLowerCase(),
        structure: structures.find((structure) => structure.id === row.structure)?.value.toLowerCase(),
        tva: tvas.find((tva) => tva.id === row.tva)?.value.toLowerCase(),
        price_with_tva: `${row.price_with_tva.toFixed(row.priceType?.id === 2 ? 3 : 2)}${row.priceType?.value}`,
        starting_date: moment(row.starting_date).format("YYYY-MM-DD")?.toLowerCase(),
        ending_date: moment(row.ending_date).format("YYYY-MM-DD")?.toLowerCase(),
      }

      return (
        renderData.id?.match(searchValue) ||
        renderData.product?.match(searchValue.toLowerCase().trim()) ||
        renderData.garanty?.match(searchValue.toLowerCase().trim()) ||
        renderData.age?.match(searchValue.toLowerCase().trim()) ||
        renderData.base_price?.match(searchValue.toLowerCase().trim()) ||
        renderData.percent_salary?.match(searchValue.toLowerCase().trim()) ||
        renderData.zone?.match(searchValue.toLowerCase().trim()) ||
        renderData.commission?.match(searchValue.toLowerCase().trim()) ||
        renderData.frais_gestion?.match(searchValue.toLowerCase().trim()) ||
        renderData.price_no_tva?.match(searchValue.toLowerCase().trim()) ||
        renderData.regime?.match(searchValue.toLowerCase().trim()) ||
        renderData.structure?.match(searchValue.toLowerCase().trim()) ||
        renderData.tva?.match(searchValue.toLowerCase().trim()) ||
        renderData.price_with_tva?.match(searchValue.toLowerCase().trim()) ||
        renderData.starting_date?.match(searchValue.toLowerCase().trim()) ||
        renderData.ending_date?.match(searchValue.toLowerCase().trim())
      )
    })

    filteredRows = tmp
  }

  const csvData = filteredRows?.map((row) => {
    let priceType = priceTypes?.find((type) => type.id === row.priceType)
    return {
      id: row.id.toString(),
      product: products.find((product) => product.id === row.product)?.description.toLowerCase(),
      garanty: garanties.find((guaranty) => guaranty.id === row.guaranty)?.name.toLowerCase(),
      age: Array.isArray(row.age) ? `${row.age[0]} - ${row.age[1]} ans` : `${row.age} ans`,
      priceType: priceType?.value,
      base_price: `${row.base_price.toFixed(priceType?.id === 2 ? 3 : 2)}${priceType?.value}`,
      percent_salary: `${row.percent_salary}%`,
      zone: zones.find((zone) => zone.id === row.zone)?.value.toLowerCase(),
      commission: commissions.find((commission) => commission.id === row.commission)?.value,
      frais_gestion: frais_gestion.find((frais) => frais.id === row.frais_gestion)?.value,
      price_no_tva: `${row.price_no_tva.toFixed(priceType?.id === 2 ? 3 : 2)}${priceType?.value}`,
      regime: regimes.find((regime) => regime.id === row.regime)?.value.toLowerCase(),
      structure: structures.find((structure) => structure.id === row.structure)?.value.toLowerCase(),
      tva: tvas.find((tva) => tva.id === row.tva)?.value.toLowerCase(),
      price_with_tva: `${row.price_with_tva.toFixed(priceType?.id === 2 ? 3 : 2)}${priceType?.value}`,
      starting_date: moment(row.starting_date).format("YYYY-MM-DD")?.toLowerCase(),
      ending_date: moment(row.ending_date).format("YYYY-MM-DD")?.toLowerCase(),
    }
  })

  const createRow = (
    id,
    product,
    guaranty,
    age,
    priceType,
    base_price,
    percent_salary,
    zone,
    commission,
    frais_gestion,
    price_no_tva,
    regime,
    structure,
    tva,
    price_with_tva,
    starting_date,
    ending_date
  ) => {
    return {
      id,
      product,
      guaranty,
      age,
      priceType,
      base_price,
      percent_salary,
      zone,
      commission,
      frais_gestion,
      price_no_tva,
      regime,
      structure,
      tva,
      price_with_tva,
      starting_date,
      ending_date,
    }
  }

  const handleEditRowsModelChange = React.useCallback((model) => {
    setEditRowsModel(model)
  }, [])

  useEffect(() => {
    setLoadingAPI(true)
    getZones().then((res) => {
      let tmp = res.data.map((zone) => {
        return { id: zone.id, value: zone.code, taux: zone.taux }
      })
      setZones(tmp)
    })

    getAllRates()
      .then((res) => {
        let data = res.data
        let map = data.map((rate) => {
          return {
            id: rate.id,
            product: rate.product_id,
            guaranty: rate.guarantee_id,
            age: rate.age_max === 0 ? rate.age : [rate.age_min, rate.age_max],
            priceType: rate.is_price,
            base_price: rate.base_price,
            percent_salary: rate.percent_salary || 0,
            zone: rate.zone_id,
            commission: rate.commission,
            frais_gestion: rate.gestion_taux,
            price_no_tva: rate.price_no_tva,
            regime: parseInt(rate.regime),
            structure: rate.structure_cotisation,
            tva: rate.tva,
            price_with_tva: rate.price_with_tva,
            starting_date: rate.starting_date,
            ending_date: rate.ending_date,
          }
        })

        setRows(map)
        setLoadingAPI(false)
      })
      .catch((error) => {
        console.warn(error)
        setLoadingAPI(false)
      })
  }, [openModal, openModificationModal])

  useEffect(() => {}, [filters])

  const handleRowEditCommit = (params) => {
    try {
      setLoadingAPI(true)

      let isCompany =
        products.find((product) => product.id === editRowsModel[params].product.value)?.market === "company"
      let newRow = editRowsModel[params]
      let rowId
      rows.forEach((row, index) => {
        if (row.id === params) {
          rowId = index
        }
      })

      let data = {
        age: isCompany ? 0 : newRow.age.value,
        age_min: isCompany ? newRow.age.value[0] : 0,
        age_max: isCompany ? newRow.age.value[1] : 0,
        base_price: newRow.base_price.value,
        percent_salary: newRow.percent_salary.value,
        commission: newRow.commission.value,
        ending_date: moment(newRow.ending_date.value).format("YYYY-MM-DD"),
        gestion_taux: newRow.frais_gestion.value,
        guarantee_id: newRow.guaranty.value,
        is_price: newRow.priceType.value,
        price_no_tva: newRow.price_no_tva.value,
        price_with_tva: newRow.price_with_tva.value,
        product_id: newRow.product.value,
        rate_type_id: newRow.priceType.value,
        regime: newRow.regime.value,
        structure_cotisation: newRow.structure.value,
        starting_date: moment(newRow.starting_date.value).format("YYYY-MM-DD"),
        tva: newRow.tva.value,
        zone_id: newRow.zone.value,
      }

      patchRate(data, params)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setSuccessAPI(true)
            let newRows = [...rows]
            newRows[rowId] = createRow(
              params,
              newRow.product.value,
              newRow.guaranty.value,
              isCompany ? [newRow.age.value[0], newRow.age.value[1]] : newRow.age.value,
              newRow.priceType.value,
              newRow.base_price.value,
              newRow.percent_salary.value,
              newRow.zone.value,
              newRow.commission.value,
              newRow.frais_gestion.value,
              newRow.price_no_tva.value,
              newRow.regime.value,
              newRow.structure.value,
              newRow.tva.value,
              newRow.price_with_tva.value,
              newRow.starting_date.value,
              newRow.ending_date.value
            )
            setRows(newRows)
          } else {
            setErrorAPI(true)
          }

          setSuccessAPI(true)
          setLoadingAPI(false)
        })
        .catch((err) => {
          setErrorAPI(true)
          setRows((prev) => [...prev])
          setLoadingAPI(false)
          console.warn(err)
        })
    } catch (error) {
      setErrorAPI(true)
      setLoadingAPI(false)
      setRows((prev) => [...prev])
      console.warn(error)
    }
  }

  const handleAddRowButton = () => {
    let market = products[0].market === "company" ? 1 : 0
    let data = {
      age: 0,
      age_min: 0,
      age_max: 0,
      base_price: 0,
      percent_salary: 0,
      commission: commissions[0].id,
      ending_date: moment().endOf("year").format("YYYY-MM-DD"),
      gestion_taux: frais_gestion[0].id,
      guarantee_id: garanties[0].id,
      is_price: priceTypes[0].id,
      price_no_tva: 0,
      price_with_tva: 0,
      product_id: products[0].id,
      rate_type_id: priceTypes[0].id,
      regime: regimes.filter((regime) => regime.market === market)[0].id,
      structure_cotisation: structures[0].id,
      starting_date: moment().startOf("year").format("YYYY-MM-DD"),
      tva: tvas[0].id,
      zone_id: zones[0].id,
    }

    setLoadingAPI(true)
    addRate(data)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          let newRow = createRow(
            res.data.id,
            res.data.product_id,
            res.data.guarantee_id,
            products[0].market !== "company" ? res.data.age : [res.data.age_min, res.data.age_max],
            res.data.is_price,
            res.data.base_price,
            res.data.percent_salary,
            res.data.zone_id,
            res.data.commission,
            res.data.gestion_taux,
            res.data.price_no_tva,
            res.data.regime,
            res.data.structure_cotisation,
            res.data.tva,
            res.data.price_with_tva,
            res.data.starting_date,
            res.data.ending_date
          )
          setRows((prevState) => [...prevState, newRow])
          setSuccessAPI(true)
          setLoadingAPI(false)
        } else {
          setErrorAPI(true)
        }
      })
      .catch((error) => {
        console.warn(error)
        setErrorAPI(true)
        setLoadingAPI(false)
      })
  }

  const handleDeleteSelectionButton = () => {
    if (selectionModel.length > 0) {
      setLoadingAPI(true)
      selectionModel.forEach((id) => {
        deleteRate(id)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              setRows((prevState) => prevState.filter((row) => row.id !== id))
              setSuccessAPI(true)
            } else {
              setErrorAPI(true)
            }
          })
          .catch((error) => {
            console.warn(error)
            setErrorAPI(true)
            setLoadingAPI(false)
          })
      })
      setLoadingAPI(false)
    } else {
      setErrorSelection(true)
    }
  }

  const handleCloneSelectionButton = () => {
    if (selectionModel.length > 0) {
      selectionModel.forEach((id) => {
        let row = rows.find((row) => row.id === id)
        let isCompany = products.find((product) => product.id === row.product)?.market === "company"
        let data = {
          age: isCompany ? 0 : row.age,
          age_min: isCompany ? row.age[0] : 0,
          age_max: isCompany ? row.age[1] : 0,
          base_price: row.base_price,
          percent_salary: row.percent_salary,
          commission: row.commission,
          ending_date: moment(row.ending_date).format("YYYY-MM-DD"),
          gestion_taux: row.frais_gestion,
          guarantee_id: row.guaranty,
          is_price: row.priceType,
          price_no_tva: row.price_no_tva,
          price_with_tva: row.price_with_tva,
          product_id: row.product,
          rate_type_id: row.priceType,
          regime: row.regime,
          structure_cotisation: row.structure,
          starting_date: moment(row.starting_date).format("YYYY-MM-DD"),
          tva: row.tva,
          zone_id: row.zone,
        }

        addNewRate(data)
      })
    } else {
      setErrorSelection(true)
    }
  }

  const handleRevalSelectionButton = () => {
    if (selectionModel.length > 0) {
      setOpenModal(true)
    } else {
      setErrorSelection(true)
    }
  }

  const handleModifSelectionButton = () => {
    if (selectionModel.length > 0) {
      setOpenModificationModal(true)
    } else {
      setErrorSelection(true)
    }
  }

  const addNewRate = (data) => {
    setLoadingAPI(true)
    addRate(data)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          let newRow = createRow(
            res.data.id,
            res.data.product_id,
            res.data.guarantee_id,
            products.find((product) => product.id === res.data.product_id)?.market !== "company"
              ? res.data.age
              : [res.data.age_min, res.data.age_max],
            res.data.is_price,
            res.data.base_price,
            res.data.percent_salary,
            res.data.zone_id,
            res.data.commission,
            res.data.gestion_taux,
            res.data.price_no_tva,
            res.data.regime,
            res.data.structure_cotisation,
            res.data.tva,
            res.data.price_with_tva,
            res.data.starting_date,
            res.data.ending_date
          )
          setRows((prevState) => [...prevState, newRow])
          setSuccessAPI(true)
          setLoadingAPI(false)
        } else {
          setLoadingAPI(false)
          setErrorAPI(true)
        }
      })
      .catch((error) => {
        console.warn(error)
        setErrorAPI(true)
        setLoadingAPI(false)
      })
  }

  const handleFilters = (filter, e) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        [filter]: e,
      }
    })
  }

  const columns = [
    {
      field: "id",
      headerName: "N°",
      isOrderable: true,
      minWidth: 5,
      flex: 0.5,
    },
    {
      field: "product",
      headerName: "Produit",
      renderCell: (params) => renderProductCell(params, products),
      renderEditCell: (params) => renderEditProductCell(params, products, setEditRowsModel),
      isOrderable: true,
      editable: isAdmin,
      flex: 1.5,
    },
    {
      field: "guaranty",
      headerName: "Garantie",
      renderCell: (params) => renderGuarantyCell(params, garanties),
      renderEditCell: (params) => renderEditGuarantyCell(params, garanties),
      isOrderable: true,
      editable: isAdmin,
      flex: 1.3,
    },
    {
      field: "age",
      headerName: "Âge",
      type: "number",
      renderCell: renderAgeCell,
      renderEditCell: (params) => renderEditAgeCell(params, products, editRowsModel, setEditRowsModel),
      isOrderable: true,
      editable: isAdmin,
      flex: 0.8,
    },
    {
      field: "priceType",
      headerName: "Type tarif",
      renderCell: (params) => renderPriceTypeCell(params, priceTypes),
      renderEditCell: (params) => renderEditPriceTypeCell(params, priceTypes, setEditRowsModel),
      isOrderable: true,
      editable: isAdmin,
      flex: 0.5,
    },
    {
      field: "base_price",
      headerName: "Tarif pur",
      isOrderable: true,
      editable: isAdmin,
      renderCell: (params) => renderPriceCell(params, priceTypes),
      renderEditCell: (params) => renderEditBasePriceCell(params, zones, editRowsModel, setEditRowsModel),
      flex: 1,
    },
    {
      field: "percent_salary",
      headerName: "Pourcentage salaire",
      isOrderable: true,
      editable: isAdmin,
      renderCell: renderPercentSalaryCell,
      renderEditCell: renderEditPercentSalaryCell,
      flex: 1,
    },
    {
      field: "zone",
      headerName: "Zonier",
      renderCell: (params) => renderZoneCell(params, zones),
      renderEditCell: (params) => renderEditZoneCell(params, zones, editRowsModel, setEditRowsModel),
      isOrderable: true,
      editable: isAdmin,
      flex: 1,
    },
    {
      field: "commission",
      headerName: "Commission",
      renderCell: (params) => renderCommissionCell(params, commissions),
      renderEditCell: (params) => renderEditCommissionCell(params, zones, commissions, editRowsModel, setEditRowsModel),
      isOrderable: true,
      editable: isAdmin,
      flex: 0.5,
    },
    {
      field: "frais_gestion",
      headerName: "Tx gestion",
      renderCell: (params) => renderFraisGestionCell(params, frais_gestion),
      renderEditCell: (params) =>
        renderEditFraisGestionCell(params, zones, frais_gestion, editRowsModel, setEditRowsModel),
      isOrderable: true,
      editable: isAdmin,
      flex: 0.6,
    },
    {
      field: "price_no_tva",
      headerName: "HT",
      renderCell: (params) => renderPriceCell(params, priceTypes),
      renderEditCell: (params) => renderEditHTCell(params, zones, editRowsModel, setEditRowsModel),
      isOrderable: true,
      editable: isAdmin,
      flex: 0.8,
    },
    {
      field: "regime",
      headerName: "Régime",
      renderCell: (params) => renderRegimeCell(params, regimes),
      renderEditCell: (params) => renderEditRegimeCell(params, products, regimes, editRowsModel),
      isOrderable: true,
      editable: isAdmin,
      flex: 1,
    },
    {
      field: "structure",
      headerName: "Structure",
      renderCell: (params) => renderStructureCell(params, structures),
      renderEditCell: (params) => renderEditStructureCell(params, structures),
      isOrderable: true,
      editable: isAdmin,
      flex: 0.8,
    },
    {
      field: "tva",
      headerName: "TVA",
      renderCell: (params) => renderTvaCell(params, tvas),
      renderEditCell: (params) => renderEditTvaCell(params, tvas, editRowsModel, setEditRowsModel),
      isOrderable: true,
      editable: isAdmin,
      flex: 0.5,
    },
    {
      field: "price_with_tva",
      headerName: "TTC",
      renderCell: (params) => renderPriceCell(params, priceTypes),
      renderEditCell: (params) => renderEditTTCCell(params, zones, editRowsModel, setEditRowsModel),
      isOrderable: true,
      editable: isAdmin,
      flex: 0.8,
    },
    {
      field: "starting_date",
      headerName: "Date de début",
      renderCell: renderDateCell,
      type: "date",
      isOrderable: true,
      editable: isAdmin,
      flex: 1,
    },
    {
      field: "ending_date",
      headerName: "Date de fin",
      renderCell: renderDateCell,
      type: "date",
      isOrderable: true,
      editable: isAdmin,
      flex: 1,
    },
  ]

  return (
    <>
      {successAPI && <AlertGeneral color="" setTrigger={setSuccessAPI} description="Enregistré !" />}
      {errorAPI && <AlertGeneral color="danger" setTrigger={setErrorAPI} description="Une erreur est survenue" />}
      {errorSelection && (
        <AlertGeneral color="danger" setTrigger={setErrorSelection} description="Veuillez sélectionner une ligne" />
      )}
      <div style={{ display: "flex", flexDirection: "column", height: "100vh", minHeight: "100vh", width: "100%" }}>
        <CustomHeader
          icon={<ImPriceTags size={40} style={{ fill: "#c31d23", marginRight: ".8rem" }} />}
          pageName={"Tarifs"}
        />

        <Box className="spacer1" />

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={2} style={{ display: "flex", justifyContent: "start" }}>
            <TextField
              placeholder={"Rechercher par N°, Produit ..."}
              variant="outlined"
              onChange={(e) => setSearchFieldValue(e.target.value)}
              value={searchFieldValue}
              onKeyDown={(e) => e.key === "Enter" && setSearchValue(searchFieldValue)}
              style={{ width: "100%", maxWidth: "400px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSearchValue("")
                      setSearchFieldValue("")
                    }}
                  >
                    <MdClear size={18} fill={"#757575"} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment style={{ cursor: "pointer" }} onClick={() => setSearchValue(searchFieldValue)}>
                    <AiOutlineCheck size={18} fill={"#757575"} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={1} style={{ display: "flex", justifyContent: "start" }}>
            <TextField
              placeholder={"Âge minimum"}
              variant="outlined"
              onChange={(e) => handleFilters("ageMin", e.target.value)}
              value={filters.ageMin}
              style={{ width: "100%", maxWidth: "400px" }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={1} style={{ display: "flex", justifyContent: "start" }}>
            <TextField
              placeholder={"Âge maximum"}
              variant="outlined"
              onChange={(e) => handleFilters("ageMax", e.target.value)}
              value={filters.ageMax}
              style={{ width: "100%", maxWidth: "400px" }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2} style={{ display: "flex", justifyContent: "start" }}>
            <CommonSelect
              label={"Zone"}
              name="zone"
              defaultValue={"all"}
              field={{ onChange: (e) => handleFilters("zone", e) }}
              options={[
                { id: "all", value: "Toutes les zones" },
                ...zones
                  .sort((a, b) => (a.id <= b.id ? -1 : 1))
                  .map((zone) => {
                    return {
                      id: zone.id,
                      value: `${zone.value}`,
                    }
                  }),
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2} style={{ display: "flex", justifyContent: "start" }}>
            <CommonSelect
              label={"Commission"}
              name="commission"
              defaultValue={"all"}
              field={{ onChange: (e) => handleFilters("commission", e) }}
              options={[
                { id: "all", value: "Toutes les commissions" },
                ...commissions
                  .sort((a, b) => (a.id <= b.id ? -1 : 1))
                  .map((commission) => {
                    return {
                      id: commission.id,
                      value: `${commission.value}`,
                    }
                  }),
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2} style={{ display: "flex", justifyContent: "start" }}>
            <CommonSelect
              label={"Régime"}
              name="regime"
              defaultValue={"all"}
              field={{ onChange: (e) => handleFilters("regime", e) }}
              options={[
                { id: "all", value: "Tous les régimes" },
                ...regimes
                  .sort((a, b) => (a.id <= b.id ? -1 : 1))
                  .map((regime) => {
                    return {
                      id: regime.id,
                      value: `${regime.value}`,
                    }
                  }),
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2} style={{ display: "flex", justifyContent: "start" }}>
            <CommonSelect
              label={"Structure"}
              name="structure"
              defaultValue={"all"}
              field={{ onChange: (e) => handleFilters("structure", e) }}
              options={[
                { id: "all", value: "Toutes les structures" },
                ...structures
                  .sort((a, b) => (a.id <= b.id ? -1 : 1))
                  .map((structure) => {
                    return {
                      id: structure.id,
                      value: `${structure.value}`,
                    }
                  }),
              ]}
            />
          </Grid>
        </Grid>

        <Box display={"flex"} style={{ gap: "10px", flexWrap: "wrap" }}>
          {isAdmin && (
            <>
              <Button
                className="buttonGeneralStyleSmall"
                onClick={handleAddRowButton}
                style={{ padding: "15px 20px", maxHeight: "100%" }}
              >
                <MdOutlineAdd size={25} style={{ fill: "#ffffff", cursor: "pointer", paddingRight: "7px" }} />
                Ajouter une ligne
              </Button>

              <Button
                className="buttonGeneralStyleSmall"
                onClick={handleCloneSelectionButton}
                style={{ padding: "15px 20px", maxHeight: "100%" }}
              >
                <FaClone size={25} style={{ fill: "white", cursor: "pointer", paddingRight: "7px" }} />
                Dupliquer la sélection
              </Button>

              <Button
                className="buttonGeneralStyleSmall"
                onClick={handleModifSelectionButton}
                style={{ marginRight: "20px", padding: "15px 20px", maxHeight: "100%" }}
              >
                <ImPriceTags size={20} style={{ fill: "white", cursor: "pointer", paddingRight: "5px" }} />
                Modifier la sélection
              </Button>

              <Button
                className="buttonGeneralStyleSmall"
                onClick={handleRevalSelectionButton}
                style={{ marginRight: "20px", padding: "15px 20px", maxHeight: "100%" }}
              >
                <ImPriceTags size={25} style={{ fill: "white", cursor: "pointer", paddingRight: "7px" }} />
                Revaloriser la sélection
              </Button>

              <Button
                className="buttonGeneralStyleSmall"
                onClick={() => setOpenDeleteModal(true)}
                style={{ padding: "15px 20px", maxHeight: "100%" }}
              >
                <FaRegTrashAlt size={25} style={{ fill: "white", cursor: "pointer", paddingRight: "7px" }} />
                Supprimer la sélection
              </Button>
            </>
          )}

          <CSVLink
            className="buttonGeneralStyle"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "53.5",
              maxHeight: "none",
              borderRadius: "4px",
              textDecoration: "none",
            }}
            data={csvData}
            headers={RatesHeader}
            filename={"Tarifs.csv"}
          >
            <Typography
              style={{
                fontSize: 15,
                color: "#fff",
                fontWeight: "500",
                fontFamily: ["roboto", "-apple-system"].join(","),
              }}
            >
              Exporter en CSV
            </Typography>
          </CSVLink>
        </Box>

        <DataGrid
          loading={loadingAPI}
          style={{ flex: 1, marginTop: 10 }}
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          editMode={"row"}
          rows={filteredRows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          onRowEditCommit={handleRowEditCommit}
          editRowsModel={editRowsModel}
          onEditRowsModelChange={handleEditRowsModelChange}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel)
          }}
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: GridToolbar,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "id", sort: "desc" }],
            },
          }}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
      </div>

      <CustomModal size={500} open={openModal} handleClose={() => setOpenModal(false)}>
        <RevalorisationForm
          selectedRatesIds={selectionModel}
          handleClose={() => setOpenModal(false)}
          title={"Revaloriser"}
        />
      </CustomModal>

      <CustomModal size={500} open={openModificationModal} handleClose={() => setOpenModificationModal(false)}>
        <RevalorisationForm
          selectedRatesIds={selectionModel}
          handleClose={() => setOpenModificationModal(false)}
          title={"Modifier"}
          update
        />
      </CustomModal>

      <CustomModal open={openDeleteModal} handleClose={() => setOpenDeleteModal(false)} size="300px">
        <Typography variant="h3">Suppression</Typography>
        <Typography variant="body1" style={{ margin: "1rem 0 2rem", textAlign: "center" }}>
          Êtes-vous sûr de vouloir supprimer ?
        </Typography>
        <Button
          className="buttonGeneralStyle"
          style={{ width: "100%", maxWidth: "none" }}
          onClick={() => {
            handleDeleteSelectionButton()
            setOpenDeleteModal(false)
          }}
        >
          Supprimer
        </Button>
      </CustomModal>
    </>
  )
}

export default EditableRates
