import React, { useCallback, useEffect, useState } from "react"
import CustomHeader from "../../../Components/Header/CustomHeader"
import { useParams, useHistory } from "react-router-dom"
import { getQuote } from "../../../Axios/Call/ClientCredential"
import { useForm, Controller, useFieldArray } from "react-hook-form"
import { Grid, Typography, Button, Box } from "@material-ui/core"

import CommonDateTimePicker from "../../../Components/FormComponents/CommonDateTimePicker"
import CommonTextField from "../../../Components/FormComponents/CommonTextField"
import CommonSelect from "../../../Components/FormComponents/CommonSelect"
import { useDispatch, useSelector } from "react-redux"
import * as actionCreator from "../../../Store/action/index"
import { upperCaseFirst } from "../../../utils/utils"
import _ from "lodash"
import Back from "../../../Components/Commons/Back/Back"
import { AiOutlineEuroCircle } from "react-icons/ai"
import { MdHealthAndSafety } from "react-icons/md"
import { GiHealthIncrease } from "react-icons/gi"
import { BiPackage } from "react-icons/bi"
import QuoteDocuments from "../../../Components/QuoteDocuments/QuoteDocuments"
import { MdPersonOutline } from "react-icons/md"

const DevisIndividualSingle = () => {
  const params = useParams()
  const history = useHistory()

  const [taux, setTaux] = useState(0);
  const [data, setdata] = useState([])
  const [names, setNames] = useState({
    product: "Inconnu",
    pack: "Inconnu",
    garanties: [],
  })
  const [price, setPrice] = useState(0)

  const dispatch = useDispatch()

  const packs = useSelector((state) => state.user.packs)
  const garanties = useSelector((state) => state.user.garanties)
  const produits = useSelector((state) => state.user.products)
  const regimes = useSelector((state) => state.user.regimes)
  const civilites = useSelector((state) => state.user.civilites)

  const { register, handleSubmit, errors, control, setValue, getValues } = useForm({
    mode: "onBlur",
    reValidateMode: "all",
    shouldDirty: true,
    defaultValues: {
      civilite: "",
      firt_name: "",
      last_name: "",
      birth_date: null,
      ssn: "",
      joint_first_name: "",
      joint_last_name: "",
      joint_birth_date: null,
      joint_regime: "",
      joint_ssn: "",
      enfant: [],
      id_type: "",
      id_number: "",
      delivery_date: null,
      delivery_city: "",
      address: "",
      zip_code: "",
      city: "",
      email: "",
      phone: "",
      bank_account_owner: "",
      iban: "",
      periodicite: "",
      bic: "",
      modalite: "",
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "enfant",
  })

  const reset = () => {
    dispatch(actionCreator.cleanOavData())
    history.push(`/Tarificateur/Individuel/Garanties?current=${params.id}`)
  }

  const calculateNumberOfPerson = (row) => {
    let value = 1

    if (row.joint_first_name) {
      value++
    }

    value += row?.childrens?.length || 0

    return value
  }

  useEffect(() => {
    let nameProduct = "..."
    let idPro = data?.product_id
    nameProduct = produits?.find((p) => idPro === p.id)?.description
    // -------------
    let namePack = "..."
    if (_.some(data.documents, "package_id")) {
      namePack = packs?.find((p) => p.id === data.documents?.find((e) => e)?.package_id)?.name
    } else if (_.some(data.packages, "package_id")) {
      namePack = packs?.find((p) => p.id === data.packages.find((e) => e)?.package_id)?.name
    }
    // -----------
    let ids = [] // lsite des ID des garanties du client
    let listGaranties = []
    if (_.some(data.documents, "package_id")) {
      ids = data.documents?.map((p) => p.guarantee_id)
    } else if (_.some(data.packages, "package_id")) {
      ids = data.packages?.map((p) => p.guarantee_id)
    }
    garanties?.forEach((g) => {
      ids?.forEach((id) => {
        if (id === g.id) {
          listGaranties.push({ id: g.id, name: g.name, nature: g.nature })
        }
      })
    })
    setNames({
      product: nameProduct,
      pack: namePack,
      garanties: listGaranties,
    })
  }, [produits, data, packs, garanties])

  const fetchQuote = useCallback(() => {
    getQuote(params.id).then((res) => {
      const tmp = res.data
      setdata(tmp)
      setPrice(tmp?.total_price)
      // findNames(tmp);
      setValue("address", res.data.address)
      setValue("civilite", res.data.civilite)
      setValue("zip_code", res.data.zip_code)
      setValue("city", res.data.city)
      setValue("firt_name", res.data.firt_name)
      setValue("last_name", res.data.last_name)
      setValue("phone", res.data.phone)
      setValue("email", res.data.email)
      setValue("birth_date", res.data.birth_date)
      setValue("enfant", res.data.childrens)
      setValue("joint_regime", res.data.joint_regime)
      setValue("joint_birth_date", res.data.joint_birth_date)
      setTaux(res.data.taux)

    })
  }, [params, setValue])

  useEffect(() => {
    fetchQuote()
  }, [fetchQuote])

  useEffect(() => {}, [fields])

  return (
    <div>
      <CustomHeader icon={<Back />} pageName={`Devis ${params.id}`} userName={"Username here"} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={3} className="containerImportant" style={{ maxHeight: "120px" }}>
          {/* HERE */} <MdHealthAndSafety size={30} style={{ fill: "#FFF", marginRight: ".5rem" }} />
          <Typography variant="h4" style={{ color: "white", textAlign: "center" }}>
            Produit
            <Typography variant="h6" style={{ color: "white", textAlign: "center" }}>
              <strong>{names.product}</strong>
            </Typography>{" "}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3} className="containerImportant" style={{ maxHeight: "120px" }}>
          <GiHealthIncrease size={30} style={{ fill: "#FFF", marginRight: ".5rem" }} />
          <Typography variant="h4" style={{ color: "white", textAlign: "center" }}>
            Garanties
            <br />
            {names.garanties?.map((g) => {
              switch (g.nature) {
                case "base":
                  return (
                    <Typography variant="h6" style={{ color: "white", textAlign: "center" }}>
                      {upperCaseFirst(g.nature)} : <strong>{g.name}</strong>
                    </Typography>
                  )
                case "renfort":
                  return (
                    <Typography variant="h6" style={{ color: "white", textAlign: "center" }}>
                      {upperCaseFirst(g.nature)} : <strong>{g.name}</strong>
                    </Typography>
                  )
                case "option":
                  return (
                    <Typography variant="h6" style={{ color: "white", textAlign: "center" }}>
                      {upperCaseFirst(g.nature)} : <strong>{g.name}</strong>
                    </Typography>
                  )
                  case "supp":
                    return (
                      <Typography
                        variant="h6"
                        style={{ color: "white", textAlign: "center" }}
                      >
                        {upperCaseFirst('Produit complémentaire')} :{" "}
                        <strong>{g.description}</strong>
                      </Typography>
                    ); 
                default:
                  return (
                    <Typography variant="h3" style={{ color: "white", textAlign: "center" }}>
                      ...
                    </Typography>
                  )
              }
            })}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          className="containerImportant"
          style={{ backgroundColor: "#1278B2", maxHeight: "120px" }}
        >
          <AiOutlineEuroCircle size={30} style={{ fill: "#FFF", marginRight: ".5rem" }} />
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "center" }}
            direction="column"
            alignItems="center"
            justify="center"
          >
            Cotisation
            <br />
            <strong>{Math.round(price * 100) / 100}</strong>€ /mois
          </Typography>
        </Grid>



        <Grid
          item
          xs={12}
          md={2}
          className="containerImportant"
          style={{ backgroundColor: "#000", maxHeight: "120px" }}
        >
          <AiOutlineEuroCircle size={30} style={{ fill: "#FFF", marginRight: ".5rem" }} />
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "center" }}
            direction="column"
            alignItems="center"
            justify="center"
          >
            Taux
            <br />
            <strong>{taux}</strong>

          </Typography>
        </Grid>




        <Grid item xs={12} lg={5}>
          {data && <QuoteDocuments quote={data} refresh={fetchQuote} />}
        </Grid>

        <Grid item xs={12} lg={6} className="box-shadow">
          {" "}
          <form style={{ marginTop: 20, textAlign: "left" }} noValidate>
            <Typography variant="h3" style={{ marginTop: 20, textAlign: "left" }}>
              <MdPersonOutline size={30} style={{ marginRight: 10, marginBottom: "-5px" }} />
              Informations
            </Typography>
            <Box className="spacer2" />

            <Typography variant="h4">Information personnelles</Typography>
            <Grid container spacing={2}>
              <Grid item xs={2} md={2}>
                <Controller
                  control={control}
                  name="civilite"
                  rules={{ required: true }}
                  render={(field) => {
                    return (
                      <CommonSelect
                        field={field}
                        error={errors.civilite}
                        label={"Civilité"}
                        options={[
                          { id: 1, value: "Mr" },
                          { id: 2, value: "Mme" },
                        ]}
                        disabled={true}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={5} md={5}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="last_name"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Nom"
                        error={errors.last_name}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={5} md={5}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="firt_name"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Prénom"
                        error={errors.firt_name}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Controller
                  name={"birth_date"}
                  control={control}
                  render={(field) => (
                    <CommonDateTimePicker
                      field={field}
                      name={"birth_date"}
                      error={errors.birth_date}
                      label={"Date de naissance"}
                      disabled={true}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  rules={{}}
                  name="birth_name"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Nom de naissance"
                        error={errors.birth_name}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Box className="spacer1" />

            {data?.joint_birth_date && (
              <>
                <Box className="spacer1" />

                <Typography variant="h4" style={{ alignSelf: "center" }}>
                  Conjoint
                  {"  "}{" "}
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name={"joint_birth_date"}
                      control={control}
                      rules={{}}
                      defaultValue={getValues("joint_birth_date")}
                      render={(field) => (
                        <CommonDateTimePicker
                          field={field}
                          name={"joint_birth_date"}
                          label={"Date de naissance"}
                          error={errors.joint_birth_date}
                          required={true}
                          disabled={true}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      control={control}
                      name="joint_regime"
                      defaultValue={getValues("joint_regime")}
                      render={(field) => (
                        <CommonSelect label="Régime" field={field} options={regimes} disabled={true} />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {data?.childrens?.map((child, index) => {
              return (
                <>
                  <Grid
                    container
                    spacing={2}
                    key={child.id}
                    // style={{ margin: ".8rem 0 .8rem .8rem" }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h4" style={{ alignSelf: "center" }}>
                        Enfant {index + 1}
                        {"  "}{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name={`enfant[${index}].birth_date`}
                        control={control}
                        defaultValue={child.birth_date}
                        render={(field) => (
                          <CommonDateTimePicker
                            field={field}
                            label={"Date de naissance"}
                            InputProps={{ disabled: true }}
                            disabled={true}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Controller
                        control={control}
                        name={`enfant[${index}].regime`}
                        defaultValue={child.regime}
                        render={(field) => (
                          <CommonSelect label="Régime" field={field} options={regimes} disabled={true} />
                        )}
                      />
                    </Grid>
                  </Grid>
                </>
              )
            })}

            <Box className="spacer2" />

            <Typography variant="h4">Coordonnées</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="address"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Adresse (numéro et libellé de voie)"
                        error={errors.address}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <Controller
                  control={control}
                  name="zip_code"
                  render={(field) => (
                    <CommonTextField
                      type="number"
                      field={field}
                      label="Code postal"
                      name="zip_code"
                      error={errors.zip_code}
                      InputProps={{ disabled: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="city"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Ville"
                        error={errors.city}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  name="email"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"email"}
                        field={field}
                        label="Email"
                        error={errors.email}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  name="phone"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"tel"}
                        field={field}
                        label="Téléphone"
                        error={errors.phone}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
            </Grid>

            <Box className="spacer2" />
          </form>
        </Grid>
      </Grid>
    </div>
  )
}

export default DevisIndividualSingle
