import React, { useEffect, useState } from "react"
import CustomHeader from "../../Components/Header/CustomHeader"
import { RiFileShield2Line } from "react-icons/ri"
import {
  Typography,
  Box,
  Grid,
  Button,
  TextField,
  MenuItem,
  ListItemText,
  Checkbox,
} from "@material-ui/core"
import { useForm, Controller } from "react-hook-form"
import TextFieldCustom from "../../Components/FormComponents/TextFieldCustom"
import { useParams, useHistory } from "react-router-dom"
import { addGuarantee, getProduct, getAllProductsIndependants } from "../../Axios/Call/ClientCredential"
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral"
import { useSelector } from "react-redux"
import CommonSelect from "../../Components/FormComponents/CommonSelect"
import _ from "lodash"
import { set } from "date-fns"

const NewGarantie = () => {
  const [successapi, setsuccessapi] = useState(false)
  const [errorapi, seterrorapi] = useState(false)

  const structures = useSelector((state) => state.user.structures_codes);
  const warrantyNaturesBrut = useSelector((state) => state.user.warrantyNatures);
  const [warrantyNatures, setWarrantyNatures] = useState(warrantyNaturesBrut);




  const [updatedStructures, setupdatedStructures] = useState([]);

  const [produits_independants, setproduits_independants] = useState([]);

  const [produits_independants_complete, setproduits_independants_complete] = useState([]);

  const [guarantess_independants, setGuarantessIndependants] = useState([]);

  const [guarantess_independants_complete, setGuarantessIndependantsComplete] = useState([]);

  const [typeProduct,setTypeProduct] = useState(0);


  const history = useHistory()
  const params = useParams()

  const createProduct = (
    product_id,
    code,
    name,
    description,
    nature,
    long_description,
    structure_cotisation,
    linked_product,
    guarante_independant,
    typerate
  ) => {
    return {
      product_id,
      code,
      name,
      description,
      nature,
      long_description,
      structure_cotisation,
      linked_product,
      guarante_independant,
      typerate
    }
  };

  const goToTarif = () => {
    const tmpProduct = createProduct(
      params.id,
      getValues("code"),
      getValues("name"),
      getValues("description"),
      getValues("nature"),
      getValues("long_description"),
      getValues("structure_cotisation").join(","),
      getValues("linked_product"),
      getValues("guarante_independant"),
      getValues("typerate")
    );
    addGuarantee(tmpProduct).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setsuccessapi(true)
        setTimeout(() => {
          history.push(`/Produits/${params.id}/Garantie/${res.data.id}`)
        }, 300)
      } else {
        seterrorapi(true)
      }
    })
  };

  // required docs handling

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "300px",
      },
    },
  }

  // form

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    watch,
    unregister,
  } = useForm({
    mode: "all",
  }) // initialise the hook

  const submitNewProduct = (data) => {
    console.log(data)
    const tmpProduct = createProduct(
      params.id,
      data.code,
      data.name,
      data.description,
      data.nature,
      data.long_description,
      data.structure_cotisation.join(","),
      data.linked_product,
      data.guarante_independant,
      data.typerate
    )
    addGuarantee(tmpProduct).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setsuccessapi(true)
        setTimeout(() => {
          history.push(`/Produits/${params.id}/Garanties`)
        }, 1500)
      } else {
        seterrorapi(true)
      }
    })
  }

  useEffect(() => {
    if(typeProduct == 1){
        const newArray = [...warrantyNaturesBrut];
        newArray.pop(); // retire la dernière valeur du tableau
        setWarrantyNatures(newArray);
    }
  }, [typeProduct,warrantyNaturesBrut]);

  //   hook
  useEffect(() => {
    getProduct(params.id).then((res) => {
      if (res.data?.market === "company") {
        setupdatedStructures(structures)
        setTypeProduct(1);
      } else {
        setupdatedStructures([{ id: "AE", value: "Adulte/Enfant" },{id: "U", value: "Unique"}])
      }
    });



    getAllProductsIndependants().then((res) => {
      const mappedData = res?.data.map((p) => {
        return {
          id: p.id,
          value: p.code,
        };
      });

      console.log(res?.data)
  
      mappedData.unshift({ id: "aucun", value: "Ne pas lier à un produit complémentaire"});
  
      setproduits_independants(mappedData);
      setproduits_independants_complete(res?.data);
    });
  }, [])

  useEffect(() => {
    setValue("guarante_independant",null);
    if (watch("linked_product") === "aucun" || !watch("linked_product")) {
      setGuarantessIndependantsComplete([]);
      setValue("guarante_independant",null);
      setValue("typerate",null);
    } else {
      const product = produits_independants_complete.find(product => product.id == watch("linked_product"));
      
      setGuarantessIndependantsComplete(product?.guarantess ?? []);
    }
  

  }, [watch("linked_product")]);

  useEffect(() => {
    setValue("guarante_independant",null);
    if (watch("nature") != "supp") {
      setGuarantessIndependantsComplete([]);
      setValue("linked_product",null);
      setValue("guarante_independant",null);
      setValue("typerate",null);
    }
  

  }, [watch("nature")]);

  useEffect(() => {
   
    const mappedGuarantee = guarantess_independants_complete.map((p) => {
      return {
        id: p.id,
        value: p.name,
      };
    });
    console.log(mappedGuarantee);
    setGuarantessIndependants(mappedGuarantee)
  }, [guarantess_independants_complete]);

  useEffect(() => {}, [watch("product_code")]);

  const options = [
    { id: 1, value: "Par tranches d'âge" },
    ...(typeProduct == 0 ? [{ id: 0, value: "Par âge fixe" }] : [])
  ];
  

  return (
    <>
      {successapi && (
        <AlertGeneral
          setTrigger={setsuccessapi}
          color=""
          description="Garanties enregistrée!"
        />
      )}
      {errorapi && (
        <AlertGeneral
          setTrigger={seterrorapi}
          color="danger"
          description="Une erreur est survenue"
        />
      )}
      <CustomHeader
        icon={
          <RiFileShield2Line
            size={40}
            style={{ color: "c31d23", cursor: "pointer", marginRight: ".8rel" }}
          />
        }
        pageName={`Produit ${params.id} - Nouvelle garantie`}
        userName={"Username here"}
      />
      <Typography
        variant="body1"
        style={{
          color: "#c23d31",
          textAlign: "left",
          marginLeft: "42px",
          marginTop: "-20px",
        }}
      >
        Création d'un nouvelle garantie associée au produit {params.id}
      </Typography>
      <Box className="spacer2" />
      <form
        style={{ maxWidth: "980px", margin: "0 auto" }}
        onSubmit={handleSubmit(submitNewProduct)}
        noValidate
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" style={{ textAlign: "left" }}>
              Informations sur la garantie
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFieldCustom
              name="code"
              label="Code garantie"
              ref={register("code")}
              onChange={(e) => setValue("code", e.target.value)}
              required
              error={errors.code}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFieldCustom
              name="name"
              label="Nom de la garantie"
              ref={register("name")}
              onChange={(e) => setValue("name", e.target.value)}
              required
              error={errors.name}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFieldCustom
              name="description"
              label="Niveau de besoin"
              ref={register("description")}
              onChange={(e) => setValue("description", e.target.value)}
              required
              error={errors.description}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="nature"
              render={(field) => (
                <CommonSelect
                  nullFirstOption
                  label="Nature de la garantie"
                  field={field}
                  options={warrantyNatures}
                  error={errors.nature}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="structure_cotisation"
              defaultValue={[]}
              rules={{ required: true }}
              render={({ onChange, value }) => {
                return (
                  <>
                    <TextField
                      fullWidth
                      select
                      variant="outlined"
                      label="Structures de cotisation"
                      SelectProps={{
                        multiple: true,
                        value: value,
                        renderValue: (selected) => selected?.map(item => updatedStructures?.find(structure => structure.id === item)?.value)?.join(", "),
                        onChange: onChange,
                      }}
                    >
                      <MenuItem/>
                      {updatedStructures?.map((s) => {
                        return (
                          <MenuItem key={s.id} value={s.id}>
                            <Checkbox checked={value.includes(s.id)} />
                            <ListItemText primary={s.value} />
                          </MenuItem>
                        )
                      })}
                    </TextField>
                    <Typography
                      component="span"
                      style={{
                        color: "#F44336",
                        fontSize: 12,
                        margin: "3px 14px 0",
                      }}
                    >
                      {errors.structure_cotisation
                        ? "Sélectionnez une option"
                        : ""}
                    </Typography>
                  </>
                )
              }}
            />
          </Grid>

        {watch("nature") == "supp" && <>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={{ required: false }}
              name="linked_product"
              render={(field) => (
                <CommonSelect
                  nullFirstOption
                  required={false}
                  label="Lié à un produit complémentaire (facultatif)"
                  field={field}
                  options={produits_independants}
                  error={errors.linked_product}
                />
              )}
            />
          </Grid>

          {(watch('linked_product') && watch('linked_product') !== "aucun") &&<Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="guarante_independant"
              render={(field) => (
                <CommonSelect
                  nullFirstOption
                  required={true}
                  label="Choisir la garantie à lier"
                  field={field}
                  options={guarantess_independants}
                  error={errors.guarante_independant}
                />
              )}
            />
          </Grid>}
          </>}

          {((!watch('linked_product') || watch('linked_product') === "aucun")) && <Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="typerate"
              render={(field) => (
                <CommonSelect
                  nullFirstOption
                  required={true}
                  label="Gestion des tarifs"
                  field={field}
                  options={options}
                  error={errors.typerate}
                />
              )}
            />
          </Grid>}

          <Grid item xs={12}>
            <TextField
              multiline
              minRows={4}
              label="Description de la garantie (facultatif)"
              name="description"
              ref={register("long_description")}
              onChange={(e) => setValue("long_description", e.target.value)}
              variant="outlined"
              fullWidth
              error={errors.description}
            />
          </Grid>
        </Grid>
        <Box className="spacer2" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              className="buttonGeneralStyle"
              style={{ maxWidth: "none" }}
              onClick={() => goToTarif()}
            >
              ENREGISTER ET GÉRER LES TARIFS
            </Button>
          </Grid>
        </Grid>
        <Box className="spacer2" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button className="buttonGeneralStyle" type="submit">
              ENREGISTRER
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default NewGarantie
