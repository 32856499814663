import React, { useState, useEffect } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  InputAdornment,
  TextField,
  Box,
  Grid,
} from "@material-ui/core"
import CustomModal from "../../../Components/Commons/Modal/CustomModal"
import { FaRegTrashAlt } from "react-icons/fa"
import { AiOutlineFileAdd } from "react-icons/ai"
import { BsSearch } from "react-icons/bs"
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom"
import CommonDateTimePicker from "../../../Components/FormComponents/CommonDateTimePicker"
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral"
import { addDocument, deleteDocument, getAllDocuments, showDocument } from "../../../Axios/Call/ClientCredential"
import { pdfjs } from "react-pdf/dist/esm/entry.webpack"
import { toBase64 } from "../../../utils/utils"
import moment from "moment"
import PDFViewer from "../../../Components/Commons/FileHandler/PDFViewer"
import UserComponentLayout from "../../../Hoc/UserComponentLayout"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const Generic = () => {
  const [loadingApi] = useState(false)
  const [searchValue, setsearchValue] = useState("")
  const [open, setopen] = useState(false)
  const [name, setname] = useState("")
  const [description, setdescription] = useState("")
  const [starting_date, setstarting_date] = useState(null)
  const [ending_date, setending_date] = useState(null)
  const [file, setfile] = useState()
  const [focus, setfocus] = useState("")
  const [opendelete, setopendelete] = useState(false)
  const [successapi, setsuccessapi] = useState(false)
  const [errorapi, seterrorapi] = useState(false)
  const [data, setdata] = useState([])
  const [loading, setloading] = useState(false)

  const [show, setShow] = useState(false)
  const [showFile, setShowFile] = useState(false)

  const [errorEmpty, seterrorEmpty] = useState(false)

  const handleSearchValue = (val) => {
    setsearchValue(val)
  }

  const createData = (name, description, starting_date, ending_date, id) => {
    return { name, description, starting_date, ending_date, id }
  }

  const getFileBase64 = (id) => {
    setShow(true)
    showDocument(id)
      .then((res) => {
        setShowFile(res.data)
      })
      .catch((error) => console.warn(error))
  }

  const handleErrorEmpty = () => {
    seterrorEmpty(true)
    const timer = setTimeout(() => {
      seterrorEmpty(false)
    }, 2500)
    return () => clearTimeout(timer)
  }

  const handleNew = async () => {
    if (name !== null && starting_date !== null && file !== undefined) {
      setloading(true)
      const tmpFile = await toBase64(file)
      let data = {
        name,
        description,
        type: "general",
        starting_date,
        ending_date,
        file: {
          name: name,
          base64: tmpFile,
        },
      }
      console.log(data)
      addDocument(data)
        .then((res) => {
          console.log(res)
          if (res.status === 200 || res.status === 201) {
            setsuccessapi(true)
          } else {
            seterrorapi(true)
          }
        })
        .finally(() => {
          setloading(false)
          setopen(false)
          setname("")
          setdescription("")
          setstarting_date(null)
          setending_date(null)
          setfile(undefined)
          getAllDocuments().then((res) => {
            console.log(res)
            if (res.data !== "") {
              const tmpData = res?.data?.filter((d) => d.type === "general")
              return setdata(tmpData)
            } else {
              seterrorapi(true)
            }
          })
        })
    } else {
      handleErrorEmpty()
    }
  }

  /*  const handleModif = async () => {
    const tmpFile = await toBase64(file);
    addDocument({
      name,
      description,
      type: "general",
      starting_date,
      ending_date,
      file: {
        name: name,
        base64: tmpFile,
      },
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setsuccessapi(true);
        } else {
          seterrorapi(true);
        }
      })
      .finally(() => {
        setname("");
        setdescription("");
        setstarting_date("");
        setending_date("");
        setfile("");
        getAllDocuments().then((res) => {
          console.log(res);
          if (res.data !== "") {
            const tmpData = res?.data?.filter((d) => d.type === "general");
            return setdata(tmpData);
          } else {
            seterrorapi(true);
          }
        });
      });
  };*/

  const handleOpenDelete = (x) => {
    setopendelete(true)
    setfocus(x)
  }

  const handleDelete = () => {
    deleteDocument(focus)
      .then((res) => {
        if (res.status === 200) {
          setsuccessapi(true)
        } else {
          seterrorapi(true)
        }
      })
      .finally(() => {
        setopendelete(false)
        setfocus("")
        getAllDocuments().then((res) => {
          if (res.data !== "") {
            const tmpData = res?.data
              ?.filter((d) => d.type === "general")
              ?.map((d) => createData(d.name, d.description, d?.starting_date, d?.ending_date, d.id))
            return setdata(tmpData)
          } else {
            seterrorapi(true)
          }
        })
      })
  }

  const handleCloseModal = () => {
    setopendelete(false)
    setopen(false)
    setfocus("")
    setShow(false)
    setShowFile(undefined)
    setfile(undefined)
  }

  // hooks

  useEffect(() => {
    getAllDocuments().then((res) => {
      console.log(res)
      if (res.data !== "") {
        const tmpData = res?.data
          ?.filter((d) => d.type === "general")
          ?.map((d) => createData(d.name, d.description, d?.starting_date, d?.ending_date, d.id))
        return setdata(tmpData)
      } else {
        seterrorapi(true)
      }
    })
  }, [])

  return (
    <>
      {successapi && <AlertGeneral color="" setTrigger={setsuccessapi} description="Enregistré !" />}
      {errorapi && <AlertGeneral color="danger" setTrigger={seterrorapi} description="Une erreur est survenue" />}
      {/* TODO: setLoadingAPI */}
      <Box style={{ display: "flex" }}>
        <TextField
          variant="outlined"
          size="small"
          style={{ width: "300px", margin: "1rem 0" }}
          placeholder="Rechercher par nom, date..."
          value={searchValue}
          onChange={(e) => handleSearchValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BsSearch size={20} />
              </InputAdornment>
            ),
          }}
        />
        <UserComponentLayout>
          <Button
            style={{ marginLeft: "1em", alignSelf: "center" }}
            className="buttonGeneralStyle"
            onClick={() => setopen(true)}
          >
            NOUVEAU DOCUMENT
          </Button>
        </UserComponentLayout>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="h6">Nom du document</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6">Description</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6">Début de validité</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6">Fin de validité</Typography>
              </TableCell>
              <TableCell align="left" />
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.filter(
                (row) =>
                  row.name?.toLowerCase().match(searchValue.toLowerCase().trim()) ||
                  row.description?.toLowerCase().match(searchValue.toLowerCase().trim())
              )
              ?.map((row) => (
                <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row" align="left">
                    <Typography variant="body2">{row.name}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.description}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">
                      {row.starting_date !== null ? moment(row.starting_date).format("DD/MM/yyyy") : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">
                      {row.ending_date !== null ? moment(row.ending_date).format("DD/MM/yyyy") : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Button className={"buttonGeneralStyle"} onClick={() => getFileBase64(row.id)}>
                      Voir
                    </Button>
                  </TableCell>
                  <TableCell align="left" style={{ stroke: "#F24E1E" }}>
                    {/* TODO: changer d'icones ??? */}
                    <UserComponentLayout>
                      <FaRegTrashAlt size={25} onClick={() => handleOpenDelete(row.id)} />
                    </UserComponentLayout>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/*  */}
      <CustomModal open={open} handleClose={() => handleCloseModal()} loading={loading}>
        <Box display="flex" alignItems="center">
          <AiOutlineFileAdd size={30} style={{ fill: "#C31D23", marginRight: "1rem" }} />
          <Typography variant="h3">Document générique</Typography>
        </Box>
        {errorEmpty && (
          <Typography variant="body2" style={{ textAlign: "center", color: "#c31d23" }}>
            Veuillez remplir les champs obligatoires
          </Typography>
        )}
        <Box
          style={{
            padding: "0 1em",
            margin: "1em",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldCustom
                name="name"
                defaultValue={null}
                label="Titre du document"
                type="text"
                placeHolder=""
                value={name}
                required
                onChange={(e) => setname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CommonDateTimePicker
                field={{ value: starting_date, onChange: setstarting_date }}
                label={"Début de validité"}
                name={"starting_date"}
                required={true}
                defaultValue={null}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CommonDateTimePicker
                field={{ value: ending_date, onChange: setending_date }}
                label={"Fin de validité"}
                name={"ending_date"}
                required={false}
                defaultValue={null}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="file"
                defaultValue={null}
                labelCustom="Déposer le fichier"
                type="file"
                variant="outlined"
                fullWidth
                onChange={(e) => setfile(e.target.files[0])}
                required
              />
              <PDFViewer file={file} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                row={4}
                name="description"
                defaultValue={null}
                label="Description"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
                type="text"
                placeHolder=""
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <Button className="buttonGeneralStyle" style={{ margin: "1em auto 0" }} onClick={() => handleNew()}>
            ENREGISTRER
          </Button>
        </Box>
      </CustomModal>

      {/* VOIR */}
      <CustomModal open={show} handleClose={() => handleCloseModal()} loading={loadingApi}>
        <PDFViewer file={showFile} />
      </CustomModal>

      {/* DELETE */}
      <CustomModal open={opendelete} handleClose={() => handleCloseModal()} size="300px">
        <Typography variant="h3">Suppression</Typography>
        <Typography variant="body1" style={{ margin: "1rem 0 2rem", textAlign: "center" }}>
          Attention, cet document sera supprimé!
        </Typography>
        <Button
          className="buttonGeneralStyle"
          style={{ width: "100%", maxWidth: "none" }}
          onClick={() => handleDelete()}
        >
          Supprimer
        </Button>
      </CustomModal>
    </>
  )
}

export default Generic
