import React, { useEffect, useState } from "react"
import CustomHeader from "../../Components/Header/CustomHeader"
import { RiFileShield2Line } from "react-icons/ri"
import {
  Typography,
  Box,
  Grid,
  Button,
  TextField,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core"
import DocumentsHandler from "../../Components/Commons/DocumentHandling/DocumentsHandler"
import CustomModal from "../../Components/Commons/Modal/CustomModal"
import Back from "../../Components/Commons/Back/Back"
import { Controller, useForm } from "react-hook-form"
import { checkKeyDown, removeItemByIndex, updateItemByIndex } from "../../utils/utils"
import { useParams, useHistory } from "react-router-dom"
import { FaRegTrashAlt } from "react-icons/fa"
import CommonDateTimePicker from "../../Components/FormComponents/CommonDateTimePicker"
import { toBase64 } from "../../utils/utils"
import {
  addPackagesAssignations,
  getAllGuaranties,
  getAllPackages,
  getPackageDocuments,
  patchPackage,
  deletePackagesDocuments,
  deletePackagesAssignation,
  showPackageDocument,
} from "../../Axios/Call/ClientCredential"
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral"
import moment from "moment"
import _ from "lodash"
import "moment/locale/fr"
import CommonTextField from "../../Components/FormComponents/CommonTextField"
import { useSelector } from "react-redux"
import UserComponentLayout from "../../Hoc/UserComponentLayout"
import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular"
moment.locale("fr")

const ModifierPack = () => {
  const [loading, setLoading] = useState(false)
  const [pack, setpack] = useState([])
  const params = useParams()
  const history = useHistory()

  // ici ce sont toutes le G du pack qui ont été sélectionnées
  const [baseList, setbaseList] = useState([])
  const [renfortList, setrenfortList] = useState([])
  const [optionList, setoptionList] = useState([])
  const [PCList, setPCList] = useState([])
  // ici ce sont les G qui ont été sélectionnées pour faire une assoc dans le créateur d'assoc
  const [tmpBase, settmpBase] = useState([])
  const [tmpSelectRenfortList, settmpSelectRenfortList] = useState([])
  const [tmpSelectOptionList, settmpSelectOptionList] = useState([])
  const [tmpSelectPCList, settmpSelectPCList] = useState([])

  const [openAssoc, setopenAssoc] = useState(false)
  const [opendelete, setopendelete] = useState(false)

  const [assocList, setassocList] = useState([])
  const [attached, setattached] = useState([])
  const [oldAttached, setOldAttached] = useState([])

  const [focus, setfocus] = useState("")

  const [successapi, setsuccessapi] = useState(false)
  const [errorapi, seterrorapi] = useState(false)

  const garanties = useSelector((state) => state.user.garanties)
  const produits = useSelector((state) => state.user.products)

  function findName(id) {
    return produits.find((p) => p.id === id * 1)?.code
  }

  const { handleSubmit, errors, control, setValue, watch } = useForm({
    mode: "onChange",
    reValidateMode: "all",
    shouldDirty: true,
    defaultValues: {},
  }) // initialise the hook**

  // createData
  const createPack = async (product_id, code, name, need_level, starting_date, ending_date, description, docs) => {
    const package_documents = JSON.stringify(docs)
    return {
      product_id,
      code,
      name,
      need_level,
      starting_date,
      ending_date,
      description,
      package_documents,
    }
  }

  const createDataAssoc = (
    base_id,
    renforts_option_id,
    base_name,
    renforts_names,
    options_names,
    pc_names,
    isInDataBase,
    db_assoc_ids
  ) => {
    return {
      base_id,
      renforts_option_id,
      // down here data to display in table only
      base_name,
      renforts_names,
      options_names,
      pc_names,
      isInDataBase,
      db_assoc_ids,
    }
  }

  const createPackageDocument = async (name, description, starting_date, ending_date, b64) => {
    let base64
    if (!!b64) {
      base64 = await toBase64(b64)
    }
    return {
      name,
      description,
      starting_date,
      ending_date,
      base64,
    }
  }

  const createDataDocumentAttached = (
    package_id,
    name,
    description,
    type,
    starting_date,
    ending_date,
    file_path,
    isEdit,
    guarantee_id,
    document_id,
    base64,
    for_supp
  ) => {
    return {
      package_id: package_id,
      file_path: file_path,
      name: name,
      description: description,
      type,
      starting_date: starting_date,
      base64: base64,
      guarantee_id: guarantee_id,
      ...(document_id && { isEdit: isEdit }),
      ...(ending_date && { ending_date: ending_date }),
      ...(document_id && { id: document_id }),
      for_supp
    }
  }

  function deleteExistingPackageDocument(id) {
    return deletePackagesDocuments(id).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setsuccessapi(true)
        getAllPackages().then((res) => {
          console.log(res)
          setpack(res.data)
          const current = res.data.find((p) => p.id === params.package_id * 1)
          console.log(current)
          setValue("code", current?.code)
          setValue("name", current?.name)
          setValue("need_level", current?.need_level)
          setValue("starting_date", current?.starting_date)
          setValue("ending_date", current?.ending_date)
          setValue("description", current?.description)
          let test = current?.package_documents.map((d) => {
            getPackageDocuments(d.id).then((response) => {
              return response
            })
          })
          console.log(test)
          // setattached(current?.package_documents);
          if (current?.package_documents.length > 0) {
            createAttached(current?.package_documents.length)
              .then((res) => {
                setattached(res)
                setOldAttached(res)
              })
              .catch((error) => console.log(error))
          }

          const tmp = current?.package_assignation
          const tmpres = []
          tmp.map((a) => {
            if (!!_.find(tmpres, { base_id: a.base_id })) {
              let index = _.findIndex(tmpres, { base_id: a.base_id })
              tmpres[index].renfort_option_ids.push(a.renfort_option_id)
              tmpres[index].db_assoc_ids.push(a.id)
            } else {
              tmpres.push({
                base_id: a.base_id,
                renfort_option_ids: [a.renfort_option_id],
                db_assoc_ids: [a.id],
              })
            }
          })
          console.log(tmpres, "ça marche, faire un create data")
          let essai = tmpres.map((a) =>
            createDataAssoc(
              a.base_id,
              a.renfort_option_ids,
              findNameBase(a.base_id),
              a.renfort_option_ids.map((a) => findNameRenfort(a * 1))?.join(" "),
              a.renfort_option_ids.map((a) => findNameOption(a * 1))?.join(" "),
              a.renfort_option_ids.map((a) => findNamePC(a * 1))?.join(" "),
              true,
              a.db_assoc_ids
            )
          )
          console.log(essai)

          setassocList(essai)
        })
      } else {
        seterrorapi(true)
      }
    })
  }

  function findNameBase(id) {
    console.log(garanties)
    return garanties.find((g) => g.id === id && g.nature === "base")?.name
  }

  function findNameRenfort(id) {
    return garanties.find((g) => g.id === id && g.nature === "renfort")?.name
  }
  function findNameOption(id) {
    return garanties.find((g) => g.id === id && g.nature === "option")?.name
  }

  function findNamePC(id) {
    return garanties.find((g) => g.id === id && g.nature === "supp")?.name
  }

  // checker
  const checkIfContainName = (arr, value) => arr?.filter((i) => i === value).length > 0

  // changer
  const handleChangeRenfort = (event) => {
    const {
      target: { value },
    } = event
    settmpSelectRenfortList(typeof value === "string" ? value.split(",") : value)
  }

  const handleChangeOption = (event) => {
    const {
      target: { value },
    } = event
    settmpSelectOptionList(typeof value === "string" ? value.split(",") : value)
  }

  const handleChangePC= (event) => {
    const {
      target: { value },
    } = event
    settmpSelectPCList(typeof value === "string" ? value.split(",") : value)
  }

  // assoc
  const submitNewAssoc = () => {
    const tmpRenf = []
    const tmpRenfFull = []
    tmpSelectRenfortList.forEach((x) => {
      renfortList.map((y) => {
        if (y.name === x) {
          tmpRenf.push(y.id)
          tmpRenfFull.push(y)
        }
      })
    })

    const tmpOpt = []
    const tmpOptFull = []
    tmpSelectOptionList.forEach((x) => {
      optionList.map((y) => {
        if (y.name === x) {
          tmpOpt.push(y.id)
          tmpOptFull.push(y)
        }
      })
    })

    const tmpPC = []
    const tmpPCFull = []
    tmpSelectPCList.forEach((x) => {
      PCList.map((y) => {
        if (y.name === x) {
          tmpPC.push(y.id)
          tmpPCFull.push(y)
        }
      })
    })

    // console.log("renforts => ", tmpRenf);
    // console.log("options => ", tmpOpt);

    const newAssoc = createDataAssoc(
      tmpBase.id,
      tmpRenf.concat(tmpOpt),
      // et pour l'affichage tableau
      tmpBase.name,
      tmpRenfFull?.map((x) => x.name)?.join(", "),
      tmpOptFull?.map((x) => x.name)?.join(", "),
      tmpPCFull?.map((x) => x.name)?.join(", "),
      false
    )
    settmpBase("")
    // settmpRenfortList([]);
    // settmpOptionList([]);
    setassocList([...assocList, newAssoc])
    handleCloseAssoc()
  }

  const handleOpenDeleteAssoc = (x) => {
    setfocus(x)
    setopendelete(true)
  }

  const deleteAssoc = () => {
    console.log(assocList[focus])
    if (assocList[focus]?.isInDataBase) {
      assocList[focus]?.db_assoc_ids?.map((id) => deletePackagesAssignation(id))
      setassocList(removeItemByIndex(assocList, focus))
    } else {
      setassocList(removeItemByIndex(assocList, focus))
    }
    setopendelete(false)
    setfocus("")
  }

  const handleCloseAssoc = () => {
    setfocus("")
    settmpBase("")
    settmpSelectRenfortList([])
    settmpSelectOptionList([])
    settmpSelectPCList([])
    setopenAssoc(false)
  }

  // forms hooks

  const submit = async (elt, docs) => {
    // TODO : supprimer les doublons
    setLoading(true)
    let data = await createPack(
      params.id * 1,
      elt.code,
      elt.name,
      elt.need_level,
      elt.starting_date,
      elt.ending_date,
      elt.description,
      docs
    )

    console.log("data", data)

    patchPackage(data, params.package_id)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setsuccessapi(true)

          console.log("res package =", res)
          console.log(assocList)

          assocList.map((a) => {
            if (a.isInDataBase === false) {
              // * prevent duplicates
              const packageAssignation = {
                base_id: a.base_id,
                package_id: res.data.id * 1,
                ...(a.renforts_option_id.length > 0 && { renfort_option_ids: JSON.stringify(a.renforts_option_id) }),
              }

              console.log(packageAssignation)

              return addPackagesAssignations(packageAssignation)
                .then((response) => {
                  setTimeout(() => {
                    history.push(`/Produits/${params.id}/Packs/`)
                  }, 500)
                })
                .catch((error) => console.warn(error))
            }
          })
          setTimeout(() => {
            history.push(`/Produits/${params.id}/Packs`)
          }, 1500)
        }
      })
      .catch((err) => console.warn(err))
      .finally(() => {
        setLoading(false)
      })
  }

  const submitPatchPack = async (data) => {
    let tmpAttached
    if (attached?.length > 0) {
      tmpAttached = await Promise.all(
        attached?.map(async (a) => {
          let file
          if (a.file instanceof Blob) {
            file = await toBase64(a.file)
          } else {
            file = a.file
          }

          return createDataDocumentAttached(
            params.package_id,
            a.name,
            a.description,
            a.type,
            a.starting_date,
            a.ending_date,
            a.file_path,
            a.isEdit !== undefined,
            a.guarantee_id,
            a.id,
            file,
            a.for_supp
          )
        })
      )
    }

    if (oldAttached.length > 0) {
      oldAttached.forEach((oldDocument) => {
        let isDeleted = !attached.find((document) => document.id === oldDocument.id)
        if (isDeleted) {
          deletePackagesDocuments(oldDocument.id)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                return setsuccessapi(true)
              } else {
                return seterrorapi(true)
              }
            })
            .catch((error) => {
              console.warn(error)
              return seterrorapi(true)
            })
        }
      })
    }

    console.log("submit attached", tmpAttached)
    submit(data, tmpAttached)
  }

  const getBase64File = async (document) => {
    let file = await showPackageDocument(document.id).then((res) => res.data)
    return { ...document, file: file }
  }

  const createAttached = async (documents) => {
    if (documents.length > 0) {
      console.log("documents", documents)

      let data = []
      for (const document of documents) {
        let tmp = await getBase64File(document)
        data.push(tmp)
      }
      return data
    }
  }

  // useEffect
  useEffect(() => {
    console.log("attached", attached)
  }, [attached])

  useEffect(() => {
    getAllGuaranties().then((res) => {
      const tmpData = res.data.filter((g) => g.product_id === params.id * 1)
      const tmpBases = []
      const tmpOptions = []
      const tmpPCs = []
      const tmpRenforts = []
      tmpData.map((g) => {
        if (g.nature === "base") {
          return tmpBases.push(g)
        } else if (g.nature === "renfort") {
          return tmpRenforts.push(g)
        } else if (g.nature === "option") {
          return tmpOptions.push(g)
        } else if (g.nature === "supp") {
          return tmpPCs.push(g)
        }
      })
      setoptionList(tmpOptions)
      setrenfortList(tmpRenforts)
      setbaseList(tmpBases)
      setPCList(tmpPCs)
    })

    getAllPackages().then((res) => {
      console.log(res)
      setpack(res.data)
      const current = res.data.find((p) => p.id === params.package_id * 1)
      console.log(current)
      setValue("code", current?.code)
      setValue("name", current?.name)
      setValue("need_level", current?.need_level)
      setValue("starting_date", current?.starting_date)
      setValue("ending_date", current?.ending_date)
      setValue("description", current?.description)
      let test = current?.package_documents.map((d) => {
        getPackageDocuments(d.id).then((response) => {
          return response
        })
      })
      console.log(test)
      // setattached(current?.package_documents);

      //If documents are already attached, fetch them.
      if (current?.package_documents.length > 0) {
        createAttached(current?.package_documents)
          .then((res) => {
            setattached(res)
            setOldAttached(res)
          })
          .catch((error) => console.log(error))
      }

      const tmp = current?.package_assignation
      console.log("package assignation", tmp)
      const tmpres = []
      tmp.map((a) => {
        console.table(a)
        if (!!_.find(tmpres, { base_id: a.base_id })) {
          let index = _.findIndex(tmpres, { base_id: a.base_id })
          tmpres[index].renfort_option_ids.push(a.renfort_option_id)
          tmpres[index].db_assoc_ids.push(a.id)
        } else {
          tmpres.push({
            base_id: a.base_id,
            renfort_option_ids: [a.renfort_option_id],
            db_assoc_ids: [a.id],
          })
        }
      })

      console.log({ tmpres })

      let essai = tmpres.map((a) =>
        createDataAssoc(
          a.base_id,
          _.uniq(a.renfort_option_ids),
          findNameBase(a.base_id),
          _.uniq(a.renfort_option_ids)
            .map((a) => findNameRenfort(a * 1))
            ?.join(" "),
          _.uniq(a.renfort_option_ids)
            .map((a) => findNameOption(a * 1))
            ?.join(" "),
            _.uniq(a.renfort_option_ids)
            .map((a) => findNamePC(a * 1))
            ?.join(" "),
          true,
          a.db_assoc_ids
        )
      )
      console.log(essai)

      setassocList(essai)
    })
    // je dois charger les documents dans le tableau
    // je dois charger les assignations
  }, [])

  return (
    <>
      {successapi && <AlertGeneral setTrigger={setsuccessapi} color="" description="Pack enregistré !" />}
      {errorapi && <AlertGeneral setTrigger={seterrorapi} color="danger" description="Une erreur est survenue" />}
      <CustomHeader
        icon={<RiFileShield2Line size={40} style={{ color: "c31d23", cursor: "pointer", marginRight: ".8rel" }} />}
        pageName={`Produit ${findName(params.id)} - Modification`}
      />
      {loading ? (
        <LoaderCircular />
      ) : (
        <>
          <Box display={"flex"} alignItems={"center"}>
            <Back />
            <Typography variant="body1" style={{ color: "#c23d31", textAlign: "left" }}>
              Modification du pack
            </Typography>
          </Box>

          <Box className="spacer2" />

          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" style={{ textAlign: "left" }}>
                Informations
              </Typography>
              <form
                style={{ margin: "0 auto" }}
                onSubmit={handleSubmit(submitPatchPack)}
                onKeyDown={(e) => checkKeyDown(e)}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="code"
                      defaultValue={""}
                      render={(field) => {
                        return <CommonTextField field={field} label="Code pack" error={errors.code} />
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="name"
                      defaultValue={""}
                      render={(field) => {
                        return <CommonTextField field={field} label="Libellé du pack" error={errors.name} />
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="need_level"
                      defaultValue={""}
                      render={(field) => {
                        return <CommonTextField field={field} label="Niveau de besoin" error={errors.need_level} />
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      render={(field) => (
                        <CommonDateTimePicker
                          field={field}
                          label={"Début de commercialisation"}
                          name={"starting_date"}
                          required={true}
                        />
                      )}
                      control={control}
                      name="starting_date"
                      rules={{}}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name={"ending_date"}
                      render={(field) => <CommonDateTimePicker field={field} label={"Fin de commercialisation"} />}
                      control={control}
                      rules={{}}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      rules={{}}
                      name={"description"}
                      defaultValue={""}
                      render={(field) => (
                        <CommonTextField
                          InputProps={{ multiline: true, minRows: 4 }}
                          label={"Description du pack (facultatif)"}
                          field={field}
                        />
                      )}
                    />
                  </Grid>
                  <UserComponentLayout>
                    <Grid
                      item
                      xs={12}
                      style={{
                        bottom: "-80px",
                        paddingBottom: "20px",
                        position: "absolute",
                      }}
                    >
                      <Button className="buttonGreenStyle" type="submit" style={{ marginTop: ".1rem" }}>
                        ENREGISTRER LE PACK
                      </Button>
                    </Grid>
                  </UserComponentLayout>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={1}>
              <Box className="spacer2" />
            </Grid>
            <Grid item xs={12} md={5}>
              <DocumentsHandler
                title={"Nouveau document"}
                newProductCode={1}
                attached={attached}
                newAttached={(x) => setattached([...attached, x])}
                deleteAttached={(id) => {
                  setattached(removeItemByIndex(attached, id))
                }}
                // definitiveDeleteAttached={(id) =>
                //   deleteExistingPackageDocument(id)
                // }
                updateAttached={(x) => setattached(updateItemByIndex(attached, x.updatedData, x.selectedIndex))}
                // assignementList={[...baseList, ...optionList, ...renfortList]} // DISABLED: assigne un document à une garantie et au pack par defaut
              />
            </Grid>
          </Grid>

          <Box className="spacer2" />

          {/*  */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography variant="h3" style={{ textAlign: "left", marginBottom: ".8rem" }}>
                Association de garanties
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button size="small" className="buttonGeneralStyle" onClick={() => setopenAssoc(true)}>
                CRÉER UNE ASSOCIATION
              </Button>
            </Grid>
          </Grid>
          <Grid container style={{ border: "1px dashed lightgrey" }} />
          {/* creation d'associations */}
          <CustomModal open={openAssoc} handleClose={() => handleCloseAssoc()} size="550px">
            <Typography variant="h3" style={{ textAlign: "left" }}>
              Nouvelle association
            </Typography>
            <Typography variant="body2" style={{ textAlign: "left", color: "#c31d23" }}>
              Création d'une nouvelle association de garantie
            </Typography>
            <Box className="spacer2" />
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h3" style={{ textAlign: "left" }}>
                  Base
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" style={{ textAlign: "left", color: "#c31d23" }}>
                  Sélectionner la garantie de base pour cette association
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  variant="outlined"
                  size="large"
                  fullWidth
                  label="Bases"
                  value={tmpBase?.name}
                  onChange={(e) => settmpBase(e.target.value)}
                >
                  {baseList?.map((base) => (
                    <MenuItem key={base.name} value={base}>
                      <Typography variant="body1">{base.name}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Box className="spacer2" />
              <Grid items xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h3" style={{ textAlign: "left" }}>
                    Renforts
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" style={{ textAlign: "left", color: "#c31d23" }}>
                    Sélectionner les renforts pour cette association
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-checkbox-label" style={{ paddingLeft: ".8rem", marginTop: "-3px" }}>
                      Renforts
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={tmpSelectRenfortList}
                      onChange={handleChangeRenfort}
                      input={<OutlinedInput label="Structure de cotisation" fullWidth />}
                      renderValue={(selected) => {
                        return selected.join(", ")
                      }}
                      style={{ textAlign: "left" }}
                    >
                      {renfortList?.map((base) => (
                        <MenuItem key={base.name} value={base.name}>
                          <Checkbox checked={checkIfContainName(tmpSelectRenfortList, base?.name)} />
                          <ListItemText primary={base.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Box className="spacer2" />
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="h3" style={{ textAlign: "left" }}>
                      Options
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" style={{ textAlign: "left", color: "#c31d23" }}>
                      Sélectionner les options pour cette association
                    </Typography>
                  </Grid>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-checkbox-label" style={{ paddingLeft: ".8rem", marginTop: "-3px" }}>
                      Options
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={tmpSelectOptionList}
                      onChange={handleChangeOption}
                      input={<OutlinedInput label="Structure de cotisation" fullWidth />}
                      renderValue={(selected) => selected.join(", ")}
                      style={{ textAlign: "left" }}
                    >
                      {optionList?.map((base) => (
                        <MenuItem key={base.name} value={base.name}>
                          <Checkbox checked={checkIfContainName(tmpSelectOptionList, base?.name)} />
                          <ListItemText primary={base.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Box className="spacer2" />
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="h3" style={{ textAlign: "left" }}>
                      Produits complémentaires
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" style={{ textAlign: "left", color: "#c31d23" }}>
                      Sélectionner les produits complémentaires pour cette association
                    </Typography>
                  </Grid>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-checkbox-label" style={{ paddingLeft: ".8rem", marginTop: "-3px" }}>
                      Produits
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={tmpSelectPCList}
                      onChange={handleChangePC}
                      input={<OutlinedInput label="Structure de cotisation" fullWidth />}
                      renderValue={(selected) => selected.join(", ")}
                      style={{ textAlign: "left" }}
                    >
                      {PCList?.map((base) => (
                        <MenuItem key={base.name} value={base.name}>
                          <Checkbox checked={checkIfContainName(tmpSelectPCList, base?.name)} />
                          <ListItemText primary={base.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                className="buttonGeneralStyle"
                style={{ marginTop: "2rem", margin: "2rem auto 0" }}
                onClick={() => submitNewAssoc()}
              >
                CRÉER
              </Button>
            </Grid>
          </CustomModal>
          {/* delete */}
          <CustomModal open={opendelete} handleClose={() => setopendelete(false)} size={400}>
            <Typography variant="h3">Suppression de l'association</Typography>
            <Typography variant="body1" style={{ margin: "1rem 0 2rem" }}>
              Attention, cette association sera supprimée !
            </Typography>
            <Button className="buttonGeneralStyle" fullWidth onClick={() => deleteAssoc()}>
              Supprimer
            </Button>
          </CustomModal>
          {/* tableau associations */}
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    <Typography component="p" variant="h6">
                      Base
                    </Typography>
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    <Typography component="p" variant="h6">
                      Renforts
                    </Typography>
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    <Typography component="p" variant="h6">
                      Options
                    </Typography>
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    <Typography component="p" variant="h6">
                      Produits complémentaires
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assocList?.map((row, index) => {
                  return (
                    <TableRow
                      key={row.base}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row" align="left">
                        <Typography variant="body2">{row.base_name}</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        <Typography variant="body2">{row.renforts_names}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">{row.options_names}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">{row.pc_names}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          <FaRegTrashAlt
                            size={25}
                            style={{ fill: "#f24e1e", cursor: "pointer" }}
                            onClick={() => handleOpenDeleteAssoc(index, row)}
                          />{" "}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}

export default ModifierPack
