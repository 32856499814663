import React, { useState, useEffect } from "react"
import {
  Grid,
  Typography,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Box,
} from "@material-ui/core"
import { BsPencilSquare } from "react-icons/bs"
import { FaRegTrashAlt } from "react-icons/fa"
import CustomModal from "../Modal/CustomModal"
import PDFHandler from "../FileHandler/PDFHandler"
import moment from "moment"
import "moment/locale/fr"
import { showDocument, showProductDocument } from "../../../Axios/Call/ClientCredential"
moment.locale("fr")

const DocumentsHandler = ({
  title,
  newProductCode,
  attached,
  newAttached,
  deleteAttached,
  definitiveDeleteAttached,
  updateAttached,
  assignementList,
}) => {
  const [open, setopen] = useState(false)
  const [openmodif, setopenmodif] = useState(false)
  const [selectedData, setselectedData] = useState(null)
  const [selectedIndex, setselectedIndex] = useState(undefined)
  const [rows, setrows] = useState() // TODO: remonter

  const createData = (
    name,
    description,
    type,
    starting_date,
    ending_date,
    linkedTo,
    file,
    guarantee_id,
    file_path,
    isEdit,
    for_supp
  ) => {
    return {
      name,
      description,
      type,
      starting_date,
      ending_date,
      linkedTo,
      file,
      guarantee_id,
      file_path,
      isEdit,
      for_supp
    }
  }

  // new Row
  const handleNewAttached = (newFile) => {
    var newRow = createData(
      newFile.name,
      newFile.description,
      newFile.type,
      newFile.starting_date,
      newFile.ending_date,
      newProductCode,
      newFile.file,
      newFile.guarantee_id,
      null,
      false,
      newFile.for_supp
    )
    console.log("new", newRow)
    newAttached(newRow)
  }

  // delete

  const handleDeleteAttached = (id) => {
    return deleteAttached(id)
  }

  const handleDefinitiveDeleteAttached = (id) => {
    return definitiveDeleteAttached(id)
  }

  // update

  const getOnUpdateModal = (data, index) => {
    setselectedData(data)
    setselectedIndex(index)
    setopenmodif(true)
  }

  const getOutUpdateModal = () => {
    setopenmodif(false)
    setselectedData([])
    setselectedIndex(undefined)
  }

  const handleUpdateAttached = (selectedAttached) => {
    console.log("update", selectedAttached)
    let updatedData = {
      ...attached[selectedIndex],
      name: selectedAttached.name,
      starting_date: selectedAttached.starting_date,
      ending_date: selectedAttached.ending_date,
      file: selectedAttached.file,
      description: selectedAttached.description,
      type: selectedAttached.type,
      isEdit: true,
      for_supp: selectedAttached.for_supp
    }
    console.log(updatedData)
    // setAttached(updatedAttached);

    // const {
    //   name,
    //   description,
    //   starting_date,
    //   ending_date,
    //   file,
    //   guarantee_id,
    //   file_path,
    // } = selectedAttached;
    // const updatedData = createData(
    //   name,
    //   description,
    //   starting_date,
    //   ending_date,
    //   newProductCode,
    //   file,
    //   guarantee_id,
    //   file_path,
    //     true
    // )
    return updateAttached({ updatedData, selectedIndex })
  }

  // hooks

  useEffect(() => {
    setrows(attached)
  }, [attached])

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography variant="h3" style={{ textAlign: "left" }}>
            Documents
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button className="buttonGeneralStyle" onClick={() => setopen(true)}>
            NOUVEAU
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography component="p" variant="h6">
                  Nom du document
                </Typography>
              </TableCell>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography variant="h6">Description du document</Typography>
              </TableCell>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography variant="h6">Date de début</Typography>
              </TableCell>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography variant="h6">Date de fin</Typography>
              </TableCell>
              {!!assignementList && (
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  <Typography variant="h6">Assignation</Typography>
                </TableCell>
              )}
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                <Typography variant="h6">Modifier</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="left">
                  <Typography variant="body2">{row.name}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2">{row.description}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2">{moment(row.starting_date).format("DD/MM/yyyy")}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2">
                    {row.ending_date ? moment(row.ending_date).format("DD/MM/yyyy") : "-"}
                  </Typography>
                </TableCell>
                {!!assignementList && (
                  <TableCell align="left">
                    <Typography variant="body2">
                      {assignementList.find((a) => a.id * 1 === row?.guarantee_id * 1)?.name
                        ? assignementList.find((a) => a.id * 1 === row?.guarantee_id * 1)?.name
                        : "Au pack"}
                    </Typography>
                  </TableCell>
                )}
                <TableCell align="center">
                  <Typography variant="body2">
                    <Box display={"flex"}>
                      <BsPencilSquare
                        size={25}
                        style={{ cursor: "pointer", marginRight: ".8rem" }}
                        onClick={() => getOnUpdateModal(row, index)}
                      />
                      <FaRegTrashAlt
                        size={25}
                        style={{ fill: "#f24e1e", cursor: "pointer" }}
                        onClick={() => handleDeleteAttached(index)}
                      />{" "}
                    </Box>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomModal open={open} handleClose={() => setopen(false)}>
        <PDFHandler
          hasType
          title={title}
          func={(x) => handleNewAttached(x)}
          close={() => setopen(false)}
          assign={assignementList}
        />
      </CustomModal>
      <CustomModal open={openmodif} handleClose={() => setopenmodif(false)}>
        <PDFHandler
          hasType
          title={"Modification"}
          func={(x) => handleUpdateAttached(x)}
          close={getOutUpdateModal}
          selectedData={selectedData}
          assign={assignementList}
        />
      </CustomModal>
    </>
  )
}

export default DocumentsHandler
