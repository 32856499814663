import { useState, useEffect } from "react";
import { Grid, Tooltip } from "@material-ui/core";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";


const Map = ({ geoUrl, handleHoverMap, handleNotHoverMap, handleFillMap, strokeColor }) => {

    const [data, setData] = useState([]);
    const [tooltipContent, setTooltipContent] = useState('');

    // WE PRODUCE THE MAP FROM A LOCAL GEOJSON FILE
    const getData = () => {
        fetch(geoUrl
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setData(myJson)
            });
    }

    useEffect(() => {
        getData();
    }, [])


    return (


        <Grid item xs={12} md={8}>
            <ComposableMap
                projectionConfig={{
                    scale: 3500,
                    center: [2, 406],
                }}
                style={{
                    width: "100%",
                    height: "auto",

                }}
            >
                <Geographies
                    geography={data}
                    //fill="#D6D6DA"
                    stroke={strokeColor}
                    strokeWidth={1}
                >

                    {({ geographies }) =>
                        geographies.map((geo) => (

                            <Tooltip title={tooltipContent} key={geo.rsmKey}>
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill={handleFillMap(geo.properties.code)}

                                    onMouseEnter={() => {
                                        setTooltipContent(`${geo.properties.nom} (${geo.properties.code})`)
                                        { handleHoverMap && handleHoverMap(geo.properties.nom, geo.properties.code); }

                                    }}
                                    onMouseLeave={() => {
                                        { handleNotHoverMap && handleNotHoverMap(geo.properties.nom, geo.properties.code); }


                                    }}
                                    style={{

                                        hover: {
                                            fill: "#C31D23",
                                            //outline: "solid #000"
                                        },
                                        pressed: {
                                            fill: "#C31D23",
                                            //outline: "none"
                                        }
                                    }}
                                /></Tooltip>
                        ))
                    }
                </Geographies>
            </ComposableMap>



        </Grid>



    )
}

export default Map;