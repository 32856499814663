import React, { useState, useEffect } from "react";
import CustomHeader from "../../Components/Header/CustomHeader";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular";
import HeaderSections from "../../Components/Commons/HeaderSections/HeaderSections";
import { useHistory, useLocation } from "react-router-dom";
import { RiDashboardFill } from "react-icons/ri";

const Dashboard = ({ children }) => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [section, setSection] = useState("performances");
  const location = useLocation();

  const sections = [
    {
      action: "performances",
      name: "Tableau de bord",
      redirect: `/Dashboard`,
    },
    {
      action: "actu",
      name: "Actualités",
      redirect: `/Dashboard/actu`,
    },
    {
      action: "repartitions",
      name: "Statistiques",
      redirect: `/Dashboard/repartitions`,
    },
    {
      action: "carte",
      name: "Carte",
      redirect: `/Dashboard/Carte`,
    },
  
  ];

  useEffect(() => {
    if (location.pathname) {
      if (location.pathname.includes("Performances")) {
        setSection("performances");
      }
      if (location.pathname.includes("repartitions")) {
        setSection("repartitions");
      }
      if (location.pathname.includes("actu")) {
        setSection("actu");
      }
      if (location.pathname.includes("Carte")) {
        setSection("carte");
      }
    }
  }, [location.pathname]);

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={
            "Une erreur est survenue lors de l'appel à la base de données"
          }
        />
      )}
      <CustomHeader
        icon={
          <RiDashboardFill
            size={30}
            style={{ color: "rgb(195, 29, 35)", marginRight: ".5rem" }}
          />
        }
        pageName={`Documents ${section}`}
        userName={"Username here"}
        childComponent={
          <HeaderSections
            setSection={setSection}
            section={section}
            sections={sections}
          />
        }
      />
      {loadingApi ? <LoaderCircular /> : <>{React.cloneElement(children)}</>}
    </>
  );
};

export default Dashboard;
