import React, { useEffect, useState } from "react"
import CustomHeader from "../../../Components/Header/CustomHeader"
import { useParams, useHistory } from "react-router-dom"
import { getAllCommercials, getQuote } from "../../../Axios/Call/ClientCredential"
import { useForm, Controller } from "react-hook-form"
import { AiOutlineEuroCircle } from "react-icons/ai"
import { MdHealthAndSafety } from "react-icons/md"
import { GiHealthIncrease } from "react-icons/gi"
import { BiPackage } from "react-icons/bi"
import { BsFillCheckSquareFill } from "react-icons/bs"
import { ImCheckboxUnchecked } from "react-icons/im"
import { Grid, Typography, Box, Button } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import CommonTextField from "../../../Components/FormComponents/CommonTextField"
import CommonSelect from "../../../Components/FormComponents/CommonSelect"
import Back from "../../../Components/Commons/Back/Back"
import { upperCaseFirst } from "../../../utils/utils"
import QuoteDocuments from "../../../Components/QuoteDocuments/QuoteDocuments"
import _ from "lodash"
import { MdPersonOutline } from "react-icons/md"
import { authApiAxios as axios } from "../../../Axios/AxiosInstance"
import {BiNotepad} from "react-icons/bi"

const ContratCollectifSingle = () => {
  const [taux, setTaux] = useState()
  const params = useParams()
  const history = useHistory()
  const [data, setdata] = useState([])
  const [names, setNames] = useState({
    product: "Inconnu",
    pack: "Inconnu",
    garanties: [],
  })
  const [price, setPrice] = useState()
  // const [priceLines, setPriceLines] = useState([]);
  const [accept, setaccept] = useState(false)
  const [noteContent, setNoteContent] = useState(null)
  const [numeroAdherent, setNumeroAdherent] = useState(null)
  const [numberQuote, setNumberQuote] = useState("");
  const fontColor = {
    style: { color: "red" },
  }

  // accepte de recevoir les informations sur les contrats MBA Mutuelle

  const dispatch = useDispatch()

  const packs = useSelector((state) => state.user.packs)
  const garanties = useSelector((state) => state.user.garanties)
  const produits = useSelector((state) => state.user.products)
  const colleges = useSelector((state) => state.user.colleges)
  const priceTypes = useSelector((state) => state.user.priceTypes)

  const [ccn, setCcn] = useState()

  const { register, errors, control, getValues, setValue, watch } = useForm({
    mode: "onBlur",
    reValidateMode: "all",
    shouldDirty: true,
    defaultValues: {
      company: "",
      siren: "",
      nic: "",
      naf: "",
      idcc: "",
      ccn: "",
      address: "",
      zip_code: "",
      city: "",
      firt_name: "",
      first_name_comptable: "",
      last_name: "",
      last_name_comptable: "",
      phone: "",
      phone_comptable: "",
      email: "",
      email_comptable: "",
      college: "",
      effectif: "",
      age_moyen: "",
      appel: "",
      peridiocite_appel: "",
      periodicite: "",
      bank_account_owner: "",
      iban: "",
      bic: "",
    },
  })

  useEffect(() => {
    console.log("oui", data)
    let nameProduct = "..."
    let idPro = data?.product_id
    nameProduct = produits?.find((p) => idPro === p.id)?.description
    console.log("nameProduct", nameProduct)
    // -------------
    let namePack = "..."
    if (_.some(data.documents, "package_id")) {
      namePack = packs?.find((p) => p.id === data.documents?.find((e) => e)?.package_id)?.name
    } else if (_.some(data.packages, "package_id")) {
      namePack = packs?.find((p) => p.id === data.packages.find((e) => e)?.package_id)?.name
    }
    // -----------
    let ids = [] // lsite des ID des garanties du client
    let listGaranties = []
    if (_.some(data.documents, "package_id")) {
      ids = data.documents?.map((p) => p.guarantee_id)
    } else if (_.some(data.packages, "package_id")) {
      ids = data.packages?.map((p) => p.guarantee_id)
    }
    garanties?.forEach((g) => {
      ids?.forEach((id) => {
        if (id === g.id) {
          listGaranties.push({ id: g.id, name: g.name, nature: g.nature })
        }
      })
    })
    setNames({
      product: nameProduct,
      pack: namePack,
      garanties: listGaranties,
    })
  }, [produits, data, packs, garanties])

  // function calculateTotalByLine(data) {
  //   // * par effectif ?
  //   let res = [];
  //   if (!!data?.meta) {
  //     let formated = data?.meta;
  //     for (const [key, value] of Object?.entries(formated)) {
  //       res.push(`${key}: ${value.toFixed(3)}%PMSS /mois`, <br />);
  //     }
  //   }
  //   console.log(res);
  //   setPriceLines(res);
  // }

  const fetchQuote = () => {
    getQuote(params.id).then((res) => {
      const tmp = res.data
      setdata(tmp)
      setNumberQuote(tmp.number_avenant ? tmp.numero_devis + '.' + tmp.number_avenant + ' (Avenant)' : tmp.numero_devis);
      tmp.commercial_id &&
        getAllCommercials()
          .then((res) => {
            setValue("ccn", res.data.find((commercial) => commercial.id === tmp.commercial_id)?.name)
          })
          .catch((error) => console.warn(error))

      setaccept(tmp.info_contact === "1" ? true : false)

      const prices = JSON.parse(tmp.price)
      let price = 0
      prices.base.forEach((base) => (price = base.price + price))
     
      let priceTable = []
      prices.base.forEach((base) => {
        priceTable.push(`${base.label} : ${base.price} ${base.priceType===2 ? "% PMSS" : "€"}`);
      });
    
      
      setPrice(priceTable.join("\n"));

      setValue("company", res.data.company)
      setValue("siren", res.data.siren)
      setValue("nic", res.data.nic)
      setValue("naf", res.data.naf)
      setValue("idcc", res.data.idcc)
      setValue("address", res.data.address)
      setValue("zip_code", res.data.zip_code)
      setValue("city", res.data.city)
      setValue("firt_name", res.data.firt_name)
      setValue("last_name", res.data.last_name)
      setValue("phone", res.data.phone)
      setValue("email", res.data.email)
      setValue("first_name_comptable", res.data.first_name_comptable)
      setValue("last_name_comptable", res.data.last_name_comptable)
      setValue("email_comptable", res.data.email_comptable)
      setValue("phone_comptable", res.data.phone_comptable)
      setValue("college", res.data.college)
      setValue("effectif", res.data.effectif)
      setValue("age_moyen", res.data.age_moyen)
      setValue("peridiocite_appel", res.data.peridiocite_appel)
      setValue("periodicite", res.data.periodicite)
      setValue("appel", res.data.appel === "Auprès du salarié" ? 2 : 1)
      setValue("appel_renfort", res.data.appel_renfort === "Auprès du salarié" ? 2 : 1)
      setValue("bank_account_owner", res.data.bank_account_owner_payment)
      setValue("iban", res.data.iban_payment)
      setValue("bic", res.data.bic_payment)
      setTaux(res.data.taux)
      // setdata(res.data);
      setNoteContent(res.data.note)
      setNumeroAdherent(res.data.numero_adherent)
    })
  }

  useEffect(() => {
    fetchQuote()
  }, [])

  useEffect(() => {}, [watch("info_contact")])

  useEffect(() => {}, [watch])

  return (
    <div>
      <CustomHeader icon={<Back />} pageName={`Contrat ${numberQuote}`} userName={"Username here"} />

      <Box className="spacer2" />
      <Grid container spacing={2}>
        {/* infos & link */}

        <Grid item xs={12} md={3} className="containerImportant" style={{ maxHeight: "180px" }}>
          {" "}
          <MdHealthAndSafety size={30} style={{ fill: "#FFF", marginRight: ".5rem", marginTop: 20 }} />
          <Typography variant="h4" style={{ color: "white", textAlign: "center" }}>
            Produit
            <Typography variant="h6" style={{ color: "white", textAlign: "center" }}>
              <strong>{names.product}</strong>
            </Typography>{" "}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3} className="containerImportant" style={{ maxHeight: "180px" }}>
          <GiHealthIncrease size={30} style={{ fill: "#FFF", marginRight: ".5rem" }} />
          <Typography variant="h4" style={{ color: "white", textAlign: "center" }}>
            Garanties
            <br />
            {names.garanties?.map((g) => {
              switch (g.nature) {
                case "base":
                  return (
                    <Typography variant="h6" style={{ color: "white", textAlign: "center" }}>
                      {upperCaseFirst(g.nature)} : <strong>{g.name}</strong>
                    </Typography>
                  )
                case "renfort":
                  return (
                    <Typography variant="h6" style={{ color: "white", textAlign: "center" }}>
                      {upperCaseFirst(g.nature)} : <strong>{g.name}</strong>
                    </Typography>
                  )
                case "option":
                  return (
                    <Typography variant="h6" style={{ color: "white", textAlign: "center" }}>
                      {upperCaseFirst(g.nature)} : <strong>{g.name}</strong>
                    </Typography>
                  )
                  case "supp":
                    return (
                      <Typography
                        variant="h6"
                        style={{ color: "white", textAlign: "center" }}
                      >
                        {upperCaseFirst('Produit complémentaire')} :{" "}
                        <strong>{g.description}</strong>
                      </Typography>
                    ); 
                default:
                  return (
                    <Typography variant="h3" style={{ color: "white", textAlign: "center" }}>
                      ...
                    </Typography>
                  )
              }
            })}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          className="containerImportant"
          style={{ backgroundColor: "#1278B2", maxHeight: "180px" }}
        >
          <AiOutlineEuroCircle size={30} style={{ fill: "#FFF", marginRight: ".5rem" }} />
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "center" }}
            direction="column"
            alignItems="center"
            justify="center"
          >
            Cotisation base
          </Typography>
          <Typography
            variant="h6"
            style={{ color: "white", textAlign: "center" }}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <strong>{price}</strong>/mois
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          className="containerImportant"
          style={{ backgroundColor: "#000", maxHeight: "120px" }}
        >
          <AiOutlineEuroCircle size={30} style={{ fill: "#FFF", marginRight: ".5rem" }} />
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "center" }}
            direction="column"
            alignItems="center"
            justify="center"
          >
            Taux
            <br />
            <strong>{taux}</strong>

          </Typography>
        </Grid>





        <Grid item xs={12} lg={5}>
          <Box
            marginBottom={4}
            style={{
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              padding: 30,
              borderRadius: 10,
            }}
          >


            <Typography variant="h3" style={{ textAlign: "left" }}>
              <MdPersonOutline size={30} style={{ marginRight: 10, marginBottom: "-5px" }} />
              Numéro adhérent
            </Typography>
     
            <div
              contentEditable
              suppressContentEditableWarning
              style={{
                border: "1px solid #ccc",
                padding: 10,
                borderRadius: 5,
                marginTop: 10,
                color: "#000",
                textAlign: "left",
                minHeight: 50,
                maxHeight: 50,
                marginBottom: 12,
                lineHeight: "27px",
              }}
              onBlur={(e) => {
                setNumeroAdherent(e.target.innerHTML)
                axios.put(`admin/subscription/${params.id}/num_adherent`, { numero_adherent: e.target.innerHTML })

              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                }
              }}
              dangerouslySetInnerHTML={{ __html: numeroAdherent }}
            />
          
            <Typography variant="h3" style={{ textAlign: "left" }}>
              <BiNotepad size={30} style={{ marginRight: 10, marginBottom: "-5px" }} />
              Notes
            </Typography>
            {/* Content editable div that edits notes */}
            <div
              contentEditable
              suppressContentEditableWarning
              style={{
                border: "1px solid #ccc",
                padding: 10,
                borderRadius: 5,
                marginTop: 10,
                color: "#000",
                textAlign: "left",
                minHeight: 70,
              }}
              onBlur={(e) => {
                setNoteContent(e.target.innerHTML)
                axios.put(`admin/subscription/${params.id}/notes`, { note: e.target.innerHTML })
              }}
              dangerouslySetInnerHTML={{ __html: noteContent }}
            />
          </Box>
          {data && <QuoteDocuments quote={data} onDocumentUploaded={fetchQuote} />}
        </Grid>

        {/* form */}
        <Grid item xs={12} lg={6} className="box-shadow">
          <form
            style={{
              marginTop: 10,
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            noValidate
          >
            <Typography variant="h3" style={{ marginTop: 20, textAlign: "left" }}>
              <MdPersonOutline size={30} style={{ marginRight: 10, marginBottom: "-5px" }} />
              Informations
            </Typography>
            <Box className="spacer1" />

            <Typography variant="h4">Entreprise</Typography>
            {/* entreprise */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="company"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Raison sociale"
                        error={errors.company}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="siren"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Numéro de siren"
                        error={errors.siren}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="nic"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Code nic"
                        error={errors.nic}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="naf"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Code naf"
                        error={errors.naf}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="idcc"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Numéro idcc"
                        error={errors.idcc}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>

              {data.commercial_id && (
                <Grid item xs={12} md={4}>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="ccn"
                    render={(field) => {
                      return (
                        <CommonTextField
                          field={field}
                          label="Cible commerciale"
                          error={errors.ccn}
                          InputProps={{ disabled: true }}
                        />
                      )
                    }}
                  />
                </Grid>
              )}
            </Grid>

            <Box className="spacer1" />

            <Typography variant="h4">Adresse de l'entreprise</Typography>
            {/* addresse */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{}}
                  name="address"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Adresse (numéro et libellé de voie)"
                        error={errors.address}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  control={control}
                  name="zip_code"
                  rules={{ required: true }}
                  render={(field) => (
                    <CommonTextField
                      type="number"
                      field={field}
                      label="Code postal"
                      name="zip_code"
                      error={errors.zip_code}
                      InputProps={{ disabled: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{}}
                  name="city"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Ville"
                        error={errors.city}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
            </Grid>

            <Box className="spacer1" />

            {/* coordonnée interlocuteurs */}

            <Typography variant="h4">Interlocuteur</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="last_name"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Nom"
                        error={errors.last_name}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="firt_name"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Prénom"
                        error={errors.firt_name}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="phone"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"tel"}
                        field={field}
                        label="Téléphone"
                        error={errors.phone}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="email"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"email"}
                        field={field}
                        label="Email"
                        error={errors.email}
                        InputProps={{ disabled: true, fontColor }}
                      />
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Box className="spacer1" />
            <Typography variant="h4">Interlocuteur comptable</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  rules={{}}
                  name="last_name_comptable"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Nom"
                        error={errors.last_name_comptable}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  rules={{}}
                  name="first_name_comptable"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Prénom"
                        error={errors.first_name_comptable}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  rules={{}}
                  name="phone_comptable"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"tel"}
                        field={field}
                        label="Téléphone"
                        error={errors.phone_comptable}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  rules={{}}
                  name="email_comptable"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"email"}
                        field={field}
                        label="Email"
                        error={errors.email_comptable}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
            </Grid>

            {/* categories rappel */}
            <Box className="spacer1" />
            <Typography variant="h4">Catégorie de personnel couverte et structure de l'effectif</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  name="college"
                  rules={{ required: true }}
                  render={(field) => (
                    <CommonSelect
                      label="Collège"
                      field={field}
                      error={errors.college}
                      disabled={true}
                      options={colleges}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true, valueAsNumber: true }}
                  name="effectif"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"number"}
                        field={field}
                        label="Effectif assurable du collège"
                        error={errors.effectif}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true, valueAsNumber: true }}
                  name="age_moyen"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"number"}
                        field={field}
                        label="Âge moyen"
                        error={errors.age_moyen}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Box className="spacer1" />

            {/* cotisation */}
            <Typography variant="h4">Cotisations</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="peridiocite_appel"
                  rules={{ required: true }}
                  render={(field) => (
                    <CommonSelect
                      label="Périodicité d'appel"
                      field={field}
                      error={errors.peridiocite_appel}
                      disabled={true}
                      options={[
                        { id: 1, value: "Mensuellement à terme échu" },
                        { id: 2, value: "Trimestriellement à terme échu" },
                      ]}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="appel"
                  rules={{ required: true }}
                  render={(field) => (
                    <CommonSelect
                      label="Modalité d'appel bénéficiaires autres que le salarié (base)"
                      field={field}
                      error={errors.appel}
                      disabled={true}
                      options={[
                        { id: 1, value: "Auprès de l'entreprise" },
                        { id: 2, value: "Auprès du salarié" },
                      ]}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="appel_renfort"
                  rules={{ required: true }}
                  defaultValue={getValues("appel_renfort")}
                  render={(field) => (
                    <CommonSelect
                      label="Modalité d'appel de cotisation (renfort)"
                      field={field}
                      error={errors.appel}
                      disabled={true}
                      options={[
                        { id: 1, value: "Auprès de l'entreprise" },
                        { id: 2, value: "Auprès du salarié" },
                      ]}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* coordonnées bancaires */}

            <Box className="spacer1" />

            <Typography variant="h4">Coordonnées bancaires</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="bank_account_owner"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Titulaire du compte"
                        error={errors.bank_account_owner}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="periodicite"
                  render={(field) => {
                    return (
                      <CommonSelect
                        field={field}
                        label={"Périodicité de prélèvement"}
                        error={errors.periodicite}
                        disabled={true}
                        options={[
                          { id: 1, value: "Mensuelement" },
                          { id: 2, value: "Trimestriellement" },
                        ]}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="iban"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="IBAN"
                        error={errors.iban}
                        color="black"
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="bic"
                  render={(field) => {
                    return (
                      <CommonTextField field={field} label="BIC" error={errors.bic} InputProps={{ disabled: true }} />
                    )
                  }}
                />
              </Grid>
            </Grid>

            <Box className="spacer1" />

            {accept === true ? (
              <Box style={{ display: "flex", alignItems: "middle" }}>
                <BsFillCheckSquareFill style={{ height: "20px", width: "20px" }} fill={"#C31D23"} />
                <Typography variant="body1" style={{ marginLeft: "8px", paddingTop: "1px" }}>
                  Accepte de recevoir les informations sur les contrats MBA Mutuelle
                </Typography>
              </Box>
            ) : (
              <Box style={{ display: "flex", alignItems: "middle" }}>
                <ImCheckboxUnchecked style={{ height: "20px", width: "20px" }} fill={"#C31D23"} />
                <Typography variant="body1" style={{ marginLeft: "8px", paddingTop: "1px" }}>
                  N'accepte pas de recevoir les informations sur les contrats MBA Mutuelle
                </Typography>
              </Box>
            )}

            <Box className="spacer2" />
          </form>
        </Grid>
      </Grid>
    </div>
  )
}

export default ContratCollectifSingle
