import React, { useState, useEffect, useCallback } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  InputAdornment,
  TablePagination,
  TextField,
  Box,
  Grid,
} from "@material-ui/core"
import { defaultLabelDisplayedRows } from "../../../utils/utils"
import { getComparator, stableSort } from "../../../utils/tableSort"
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomModal from "../../../Components/Commons/Modal/CustomModal"
import { AiOutlineFileAdd } from "react-icons/ai"
import { BsSearch } from "react-icons/bs"
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom"
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral"
import { addDocument, deleteDocument, getAllDocuments, showDocument } from "../../../Axios/Call/ClientCredential"
import { pdfjs } from "react-pdf/dist/esm/entry.webpack"
import { toBase64 } from "../../../utils/utils"
import CommonSelect from "../../../Components/FormComponents/CommonSelect"
import { useSelector } from "react-redux"
import PDFViewer from "../../../Components/Commons/FileHandler/PDFViewer"
import UserComponentLayout from "../../../Hoc/UserComponentLayout"
import GenerateCourtierDocumentModal from "../../../Components/GenerateCourtierDocumentModal/GenerateCourtierDocumentModal"
import { authApiAxios } from "../../../Axios/AxiosInstance"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`


const Courtiers = () => {
  const [loadingApi, setLoadingApi] = useState(false)
  const [searchValue, setsearchValue] = useState("")
  const [open, setopen] = useState(false)
  const [generateOpen, setGenerateOpen] = useState(false)
  const [name, setname] = useState("")
  const [description, setdescription] = useState("")
  const [file, setfile] = useState()
  const [broker, setbroker] = useState("")
  const [courtier, setCourtier] = useState("all")
  const [numpage, setNumpage] = useState(null)
  const [pageNumber, setpageNumber] = useState(0)
  const [focus, setfocus] = useState("")
  const [opendelete, setopendelete] = useState(false)
  const [successapi, setsuccessapi] = useState(false)
  const [errorapi, seterrorapi] = useState(false)
  const [data, setdata] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [loading, setloading] = useState(false)
  const [errorEmpty, seterrorEmpty] = useState(false)
  const [order, setOrder] = React.useState("asc")
const [orderBy, setOrderBy] = React.useState("")

  // PAGINATION
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const [show, setShow] = useState(false)
  const [showFile, setShowFile] = useState(false)

  const brokers = useSelector((state) => state.user.brokers)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumpage(numPages)
  }
  const goToPrevPage = () => setpageNumber(moderatePageNumber(pageNumber - 1))
  const goToNextPage = () => setpageNumber(moderatePageNumber(pageNumber + 1))

  const moderatePageNumber = (num) => {
    if (num > 1) {
      return num > numpage ? 1 : num
    }
    if (num < 1) {
      return 1
    }
  }

  const getFileBase64 = (id) => {
    setShow(true)
    showDocument(id)
      .then((res) => {
        setShowFile(res.data)
      })
      .catch((error) => console.warn(error))
  }

  const handleSearchValue = (val) => {
    setsearchValue(val)

  }


  // PAGINATION
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }



  const createData = (name, description, broker, id) => {
    return { name, description, broker, id }
  }

  const handleErrorEmpty = () => {
    seterrorEmpty(true)
    const timer = setTimeout(() => {
      seterrorEmpty(false)
    }, 2500)
    return () => clearTimeout(timer)
  }

  const handleNew = async () => {
    if (name !== null && broker !== null && file !== undefined) {
      setloading(true)
     
      const tmpFile = await toBase64(file)
      addDocument({
        name,
        description,
        broker_id: broker * 1,
        type: "broker",
        file: {
          name: name,
          base64: tmpFile,
        },
      })
        .then((res) => {
         
          if (res.status === 200 || res.status === 201) {
            setsuccessapi(true)
          } else {
            seterrorapi(true)
          }
        })
        .finally(() => {
          setloading(false)
          setopen(false)
          setname("")
          setdescription("")
          setfile(undefined)
          getAllDocuments().then((res) => {
            if (res.data !== "") {
              const tmpData = res?.data
                ?.filter((d) => d.type === "broker")
                ?.map((d) => {
                  const tmpbroker = brokers?.find((b) => b.id * 1 === d.broker_id * 1)
                  return createData(d.name, d.description, `${tmpbroker?.company}`, d.company, d.id)
                })
              return setdata(tmpData)
            } else {
              seterrorapi(true)
            }
          })
        })
    } else {
      handleErrorEmpty()
    }
  }

  const handleOpenDelete = (x) => {
    setopendelete(true)
    setfocus(x)
  }

  const handleDelete = () => {
    deleteDocument(focus)
      .then((res) => {
        if (res.status === 200) {
          setsuccessapi(true)
        } else {
          seterrorapi(true)
        }
      })
      .finally(() => {
        setopendelete(false)
        setfocus("")
        getAllDocuments().then((res) => {
          if (res.data !== "") {
            const tmpData = res?.data
              ?.filter((d) => d.type === "broker")
              ?.map((d) => {
                const tmpbroker = brokers.find((b) => b.id * 1 === d.broker_id * 1)
            
                return createData(d.name, d.description, `${tmpbroker.name} ${tmpbroker.surname}`, d.id)
              })
            return setdata(tmpData)
          } else {
            seterrorapi(true)
          }
        })
      })
  }

  const handleCloseModal = () => {
    setopendelete(false)
    setopen(false)
    setfocus("")
    setShow(false)
    setShowFile(undefined)
    setfile(undefined)
  }

  useEffect(() => {

    const searchTerms = searchValue.toLowerCase().trim().split(" ");

    setFilteredData(
      data?.filter(
        (row) =>
        row.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
          row.description?.toLowerCase().match(searchTerms) ||
          row.broker?.toLowerCase().match(searchTerms)
      ) 
    )
    
  }, [data, searchValue])

  useEffect(() => {

    if(courtier !== "all") {
      setFilteredData(data?.filter(row => row.broker?.toString() === courtier?.toString()))
    }
    else {
      setFilteredData(data)
    }
  }, [data, courtier])


  // hooks
  const fetchDocuments = useCallback(() => {
    if (brokers.length > 0) {
      getAllDocuments().then((res) => {
        if (res.data !== "") {
          const tmpData = res?.data
            ?.filter((d) => d.type === "broker")
            ?.map((d) => {
              const tmpbroker = brokers.find((b) => b.id * 1 === d.broker_id * 1)
              return createData(d.name, d.description, tmpbroker ? tmpbroker.company : "", d.id)
            })
          return setdata(tmpData)
        } else {
          seterrorapi(true)
        }
      })
    }
  }, [brokers])

  const handleGenerate = async (data) => {
    setloading(true)
    try {
      await authApiAxios.post("/admin/document/generate", data)
    } catch (error) {
      console.log(error)
      seterrorapi(true)
    } finally {
      setloading(false)
      fetchDocuments()
      setGenerateOpen(false)
    }
  }

  useEffect(() => {
    fetchDocuments()
  }, [fetchDocuments])

  return (
    <>
      {successapi && <AlertGeneral color="" setTrigger={setsuccessapi} description="Enregistré !" />}
      {errorapi && <AlertGeneral color="danger" setTrigger={seterrorapi} description="Une erreur est survenue" />}
      {/* TODO: setLoadingAPI */}
      <Box style={{ display: "flex" }}>
        <TextField
          variant="outlined"
          size="small"
          style={{ width: "300px", margin: "1rem 0" }}
          placeholder="Rechercher..."
          value={searchValue}
          onChange={(e) => handleSearchValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BsSearch size={20} />
              </InputAdornment>
            ),
          }}
        />


          <Grid item style={{ padding: 10, marginTop: 5 }}>
          <CommonSelect
          style={{height:40}}
            label={"Courtier"}
            name="courtier"
            defaultValue={[]}
            field={{ value: courtier, onChange: setCourtier }}
            options={[
              { id: "all", value: "Tous" },
              ...brokers
                .sort((a, b) => (a.company <= b.company ? -1 : 1))
                .map((courtier, index) => {
                  return {
                    //id: courtier.id,
                    key:index,
                    value: `${courtier.company}`,
                  }
                }),
            ]}
          />
          </Grid>


        <Grid item style={{ padding: 10, marginTop: 5 }}>
          <Button
            style={{ marginLeft: "1em", alignSelf: "center" }}
            className="buttonGeneralStyle"
            onClick={() => setopen(true)}
          >
            NOUVEAU DOCUMENT
          </Button>
        </Grid>

        <Grid item style={{ padding: 10, marginTop: 5 }}>
          <Button
            style={{ marginLeft: "1em", alignSelf: "center" }}
            className="buttonGeneralStyle"
            onClick={() => setGenerateOpen(true)}
          >
            GÉNÉRER UN BORDEREAU
          </Button>
        </Grid>
      </Box>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="h6">Nom du document</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6">Description</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6">Assigné à</Typography>
              </TableCell>
              <TableCell align="right" >
                <Typography variant="h6" >Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(filteredData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" align="left">
                  <Typography variant="body2">{row.name}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2">{row.description}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2">{row.broker}</Typography>
                </TableCell>

                <TableCell align="right" style={{height:40}}>
                 <VisibilityIcon  onClick={() => getFileBase64(row.id)} style={{cursor:'pointer', color:"#444"}}/>
                </TableCell>
                <TableCell align="left" >
                    <DeleteIcon  onClick={() => handleOpenDelete(row.id)} style={{cursor:'pointer', color:"#c31d23"}}/>
       
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/*  */}
      <CustomModal open={open} handleClose={() => handleCloseModal()} loading={loading}>
        <Box display="flex" alignItems="center">
          <AiOutlineFileAdd size={30} style={{ fill: "#C31D23", marginRight: "1rem" }} />
          <Typography variant="h3">Document courtiers</Typography>
        </Box>
        {errorEmpty && (
          <Typography variant="body2" style={{ textAlign: "center", color: "#c31d23" }}>
            Veuillez remplir les champs obligatoires
          </Typography>
        )}
        <Box
          style={{
            padding: "0 1em",
            margin: "1em",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6}>
              <TextFieldCustom
                name="name"
                defaultValue={null}
                label="Titre du document"
                type="text"
                placeHolder=""
                value={name}
                required
                onChange={(e) => setname(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <CommonSelect
                label={"Courtier"}
                name="broker"
                defaultValue={[]}
                field={{ value: broker, onChange: setbroker }}
                options={[
                  { id: "all", value: "Tous les courtiers" },
                  ...brokers
                    .sort((a, b) => (a.company <= b.company ? -1 : 1))
                    .map((broker,index) => {
                      return {
                        key:index,
                        id: broker.id,
                        value: `${broker.company}`,
                      }
                    }),
                ]}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="file"
                defaultValue={null}
                labelCustom="Déposer le fichier"
                type="file"
                variant="outlined"
                fullWidth
                onChange={(e) => setfile(e.target.files[0])}
                required
              />

              <PDFViewer file={file} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                row={4}
                name="description"
                defaultValue={null}
                label="Description"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
                type="text"
                placeHolder=""
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item>
              <Button className="buttonGeneralStyle" style={{ width: "400px" }} onClick={() => handleNew()}>
                ENREGISTRER
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CustomModal>

      {/* VOIR */}
      <CustomModal open={show} handleClose={() => handleCloseModal()} loading={loadingApi}>
        <PDFViewer file={showFile} />
      </CustomModal>

      {/* DELETE */}
      <CustomModal open={opendelete} handleClose={() => handleCloseModal()} size="300px">
        <Typography variant="h3">Suppression</Typography>
        <Typography variant="body1" style={{ margin: "1rem 0 2rem", textAlign: "center" }}>
          Attention, cet document sera supprimé!
        </Typography>
        <Button
          className="buttonGeneralStyle"
          style={{ width: "100%", maxWidth: "none" }}
          onClick={() => handleDelete()}
        >
          Supprimer
        </Button>
      </CustomModal>

      <GenerateCourtierDocumentModal
        open={generateOpen}
        onClose={() => setGenerateOpen(false)}
        onSave={handleGenerate}
        brokers={brokers}
      />



<TablePagination
        component="div"
        size="medium"
        variant="outlined"
        count={filteredData?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={() =>
          defaultLabelDisplayedRows({
            from: rowsPerPage * page + 1,
            to:
              rowsPerPage * page + rowsPerPage > filteredData.length
                ? filteredData.length
                : rowsPerPage * page + rowsPerPage,
            count: filteredData?.length,
          })
        }
        labelRowsPerPage={"Lignes par page :"}
      />



    </>
  )
}

export default Courtiers
