import React from "react"
import { useState } from "react"
import {
  Grid,
  Typography,
  Divider,
  Tooltip,
  IconButton,
  TextField,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import { Button } from "@material-ui/core"

const Zones = ({ code, caps, taux, color, onSave }) => {
  const [visible, setVisible] = useState(false)
  const [edit, setEdit] = useState(false)

  const [form, setForm] = useState({
    code: code,
    taux: taux,
    caps: caps,
  })

  const setFormTaux = (e) => {
    setForm({
      ...form,
      taux: e.target.value,
    })
  }

  const setFormCaps = (e) => {
    setForm({
      ...form,
      caps: e.target.value,
    })
  }

  const submit = (event) => {
    event.preventDefault()
    setEdit(false)
    onSave(form)
  }

  return (
    <Grid
      container
      style={{ padding: 10 }}
      alignContent="center"
      alignItems="center"
    >
      <Typography
        variant="h3"
        style={{
          fontSize: "22px",
          paddingBottom: 3,
          textAlign: "left",
          fontWeight: 500,
          color: color,
        }}
      >
        {code}{" "}
      </Typography>
      <Typography
        variant="h6"
        style={{
          color: "#444",
          paddingBottom: 5,
          fontSize: 15,
          marginLeft: 10,
        }}
      >
        {" "}
        (x {taux}){" "}
      </Typography>

      <Divider />

      <Grid
        item
        xs={10}
        style={{
          margin: "auto",
          textAlign: "left",
          borderRadius: 10,
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          padding: 20,
          marginTop: 10,
        }}
      >
        {edit ? (
          <form onSubmit={submit} noValidate>
            <Typography
              variant="h6"
              style={{ color: "#444", paddingBottom: 5, fontSize: 15 }}
            >
              Taux :{" "}
            </Typography>

            <Grid
              container
              p={3}
              mt={1}
              mb={1}
              style={{
                borderRadius: 10,
                boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px",
                backgroundColor: "#FFF",
              }}
            >
              <TextField
                name="taux"
                value={form.taux}
                variant="standard"
                onChange={setFormTaux}
                style={{ width: "100%", backgroundColor: "#FFF" }}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              />
            </Grid>

            <Typography
              variant="h6"
              style={{ color: "#444", paddingBottom: 5, fontSize: 15 }}
            >
              Départements :{" "}
            </Typography>
            <Grid
              container
              p={3}
              mt={1}
              mb={1}
              style={{
                borderRadius: 10,
                boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px",
                backgroundColor: "#FFF",
              }}
            >
              <TextField
                name="departements"
                multiline
                rows={4}
                value={form.caps}
                variant="standard"
                onChange={setFormCaps}
                style={{ width: "100%", backgroundColor: "#FFF" }}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              />
              <Grid container justifyContent={"flex-end"} mt={1}>
                <Button type="submit">Enregistrer</Button>
                <Button onClick={() => setEdit(false)}>Annuler</Button>
              </Grid>
            </Grid>
          </form>
        ) : (
          <Typography
            variant="body2"
            style={{ color: "#444" }}
            onClick={() => setEdit(!edit)}
          >
            {caps}
          </Typography>
        )}
      </Grid>

      <Grid
        item
        md={2}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        <Tooltip title="Modifier la zone">
          <IconButton
            style={{ visibility: visible ? "visible" : "visible" }}
            onClick={() => setEdit(!edit)}
          >
            <EditIcon style={{ color: color }} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default Zones
