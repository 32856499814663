

export default function exportToCsv({data, formatter, headerFormatter, filename}) {
    const _data = [headerFormatter(data), ...data.map(formatter)];
    const csvData = "data:text/csv;charset=utf-8," + _data.map(row => row.join(",")).join("\n");

    const link = document.createElement('a');
    link.setAttribute('href', encodeURI(csvData));
    link.setAttribute('download', filename);
    document.body.appendChild(link);

    link.click();
}