import React, { useState, useEffect } from "react";
import CustomHeader from "../../Components/Header/CustomHeader";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import LoaderCircular from "../../Components/Commons/Loading/LoaderCircular";
import HeaderSections from "../../Components/Commons/HeaderSections/HeaderSections";
import { RiFoldersLine } from "react-icons/ri";
import { useHistory, useLocation } from "react-router-dom";
import { IoDocumentsOutline } from "react-icons/io5";

const Documents = ({ children }) => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [section, setSection] = useState("génériques");

  const history = useHistory();
  const location = useLocation();

  const sections = [
    {
      action: "génériques",
      name: "Documents génériques",
      redirect: `/Documents/Generiques`,
    },
    {
      action: "courtiers",
      name: "Documents courtiers",
      redirect: `/Documents/Courtiers`,
    },
    {
      action: "souscription",
      name: "Documents de souscription",
      redirect: `/Documents/Souscription`,
    },
    {
      action: "justificatif",
      name: "Documents adhérents",
      redirect: `/Documents/Justificatifs`,
    },
  ];

  useEffect(() => {
    if (location.pathname) {
      if (location.pathname.includes("Generiques")) {
        setSection("génériques");
      }
      if (location.pathname.includes("Courtiers")) {
        setSection("courtiers");
      }
      if (location.pathname.includes("Souscription")) {
        setSection("souscription");
      }
      if (location.pathname.includes("Justificatifs")) {
        setSection("justificatif");
      }
    }
  }, [location.pathname]);

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={
            "Une erreur est survenue lors de l'appel à la base de données"
          }
        />
      )}
      <CustomHeader
        icon={
          <IoDocumentsOutline
            size={40}
            style={{ color: "rgb(195, 29, 35)", marginRight: ".5rem" }}
          />
        }
        pageName={`Documents ${section}`}
        userName={"Username here"}
        childComponent={
          <HeaderSections
            setSection={setSection}
            section={section}
            sections={sections}
          />
        }
      />
      {loadingApi ? <LoaderCircular /> : <>{React.cloneElement(children)}</>}
    </>
  );
};

export default Documents;
