import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreator from "../../Store/action/index";
import { Box, Typography, Tabs, Tab, withStyles } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import { AiOutlineEuroCircle } from "react-icons/ai";
import { useWindowWidth } from "@react-hook/window-size";
import classes from "./NavLayout.module.css";
import {
  RiDashboardFill,
  RiLogoutCircleLine,
} from "react-icons/ri";
import { FaUserFriends } from "react-icons/fa";
import { BsFileCheck, BsPeople, BsTools } from "react-icons/bs";
import { GoFile } from "react-icons/go";
import MBA from "../../Assets/mbalogo.png";
import { IoDocumentsOutline } from "react-icons/io5";
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";
import { AiOutlineMenu } from "react-icons/ai";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';

const NavLayout = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [value, setValue] = useState(false);
  const user = useSelector((state) => state.user);
  const [checked, setchecked] = useState(true);
  const [hide, setHide] = useState(true);

  const dispatch = useDispatch();
  const width = useWindowWidth();

  const outOfRangeLocation = ["/Profil", "/Contrats/Collectifs", "/Devis/Collectifs", "/Dashboard/Carte", "/Documents/Courtiers"];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    return history.push(newValue);
  };

  const logout = () => {
    dispatch(actionCreator.cleanUserData());
    history.push("/");
  };

  const StyledTabs = withStyles({
    root: {
      color: "white",
    },
    indicator: {
      backgroundColor: "white",
      width: "5px",
      borderTopRightRadius: "2px",
      borderBottomRightRadius: "2px",
      left: 0,
    },
    scrollButtons: {
      backgroundColor: "lightgrey",
    },

  })(props => <Tabs {...props} />);

  const StyledTab = withStyles({
    root: {
      minHeight: "22px",
      margin: "8px 0",
      padding: "0",
      textTransform: "none",
      fontSize: "18px",
      minWidth: 0,
      width: !hide ? "100px" : "100%",
    },

    textColorInherit: {
      color: "#FFFFFF60",
      opacity: 1,
    },
    wrapper: {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      paddingLeft: "35px",
    },
    selected: {
      opacity: 1,
      color: "#FFFFFF",
    },
  })(props => <Tab {...props} />);

  useEffect(() => {
    if (Object.keys(user.userData).length) {
      if (user.userData?.status === "active") {
        if (user.userData.type === "admin" || user.userData.type === "user") {
          if (!outOfRangeLocation.includes(location.pathname)) {
            setValue(location.pathname);
          }
        }
      } else {
        dispatch(actionCreator.cleanUserData());
      }
    }
  }, [user.userData]);

  return (
    <Box>
      {/*  */}
      <Box
        style={{
          position: "fixed",
          zIndex: 1002,
          borderRight: "1px solid #353f6a42",
          // width: "280px",
          minHeight: "100%",
          backgroundColor: "#C31D23",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          transition: "all .4s .1s cubic-bezier(0.63, 0.32, 0.27, 0.93)",
          paddingTop: "5px",
          width:
            !hide
              ? "100px"
              : "230px" && width < 600
                ? "100%"
                : "230px",
        }}

        className={
          checked && width < 600
            ? classes.sideMenuWrapperMobile
            : classes.sideMenuWrapper
        }
      >
        <Box
          style={{ width: "100%" }}
          xs={12}
          align="right"
          justifyContent="right"
        >
          <div
            className={`${!checked && width < 600 ? classes.display : classes.none
              }`}
            onClick={() => setchecked(!checked)}
          >
            <AiOutlineMenu size="30" color="white" />
          </div>
        </Box>

        <img
          src={MBA}
          alt="logo"
          style={{ width: hide === true ? "160px" : "70px", margin: "0 auto" }}
        />

        <StyledTabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
        >
          <StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Dashboard
              </div>
            }
            
            icon={
              <RiDashboardFill
                size={25}
                style={{ marginRight: "1rem", marginTop: "4px" }}
              />
            }
            disableRipple
            value="/Dashboard"
          />
          <StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Devis
              </div>
            }
            
            icon={
              <GoFile
                size={25}
                style={{ marginRight: "1rem", marginTop: "4px" }}
              />
            }
            disableRipple
            value="/Devis/Individuels"
          />
          <StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Contrats
              </div>
            }
            
            icon={
              <BsFileCheck
                size={25}
                style={{ marginRight: "1rem", marginTop: "4px" }}
              />
            }
            disableRipple
            value="/Contrats/Individuels"
          />
          <StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Courtiers
              </div>
            }
            
            icon={
              <BsPeople
                size={25}
                style={{ marginRight: "1rem", marginTop: "4px" }}
              />
            }
            disableRipple
            value="/Courtiers"
          />
          <StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Documents
              </div>
            }
            
            icon={
              <IoDocumentsOutline
                size={25}
                style={{ marginRight: "1rem", marginTop: "4px" }}
              />
            }
            disableRipple
            value="/Documents/Generiques"
          />
          <StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Produits
              </div>
            }
            
            icon={
              <BsTools
                size={25}
                style={{ marginRight: "1rem", marginTop: "4px" }}
              />
            }
            disableRipple
            value="/Produits"
          />

          <StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Tarifs
              </div>
            }
            
            icon={
              <AiOutlineEuroCircle
                size={25}
                style={{ marginRight: "1rem", marginTop: "4px" }}
              />
            }
            disableRipple
            value="/Tarifs"
          />

<StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Zones
              </div>
            }
            
            icon={
              <AddLocationAltIcon
                size={25}
                style={{ marginRight: "1rem", marginTop: "4px" }}
              />
            }
            disableRipple
            value="/zones"
          />

          <StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Utilisateurs
              </div>
            }
            
            icon={
              <FaUserFriends
                size={25}
                style={{ marginRight: "1rem", marginTop: "4px" }}
              />
            }
            disableRipple
            value="/Utilisateurs"
          />


        {/*}  <StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Gestion
              </div>
            }
            
            icon={
              <SettingsSuggestIcon
                size={25}
                style={{ marginRight: "1rem", marginTop: "4px" }}
              />
            }
            disableRipple
            value="/gestion"
          />*/}


<StyledTab
            label={
              <div className={`${hide ? classes.display : classes.label_none}`}>
                Contacts
              </div>
            }
            
            icon={
              <ContactPhoneIcon
                size={30}
                style={{ marginRight: ".5rem", opacity: 1, marginTop:3 }}
              />
            }
            disableRipple
            value="/contacts"
          />




        </StyledTabs>


        <Box style={{
          display: "flex",
          alignItems: "center",
          position: "fixed",
          bottom: "4rem",
          left: "2.7rem",
        }}>
          <BsArrowBarRight
            size={30}
            style={{ fill: "#FFFFFF60", left: "2rem" }}
            onClick={() => setHide(!hide)}
            className={`${!hide ? classes.display : classes.none}`}
          />
        </Box>

        <Box style={{
          display: "flex",
          alignItems: "center",
          position: "fixed",
          bottom: "1rem",
          left: "1rem",
          cursor: "pointer",
        }}>
          <RiLogoutCircleLine
            size={25}
            style={{ fill: "#FFFFFF60", marginRight: " .5rem" }}
          />
          <Typography
            component="p"
            variant="body2"
            style={{ color: "#FFFFFF60" }}
            className={`${hide ? classes.display : classes.label_none}`}
            onClick={logout}
          >
            Déconnexion
          </Typography>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              position: "fixed",
              marginTop: "5rem",
              left: "200px",
              bottom: "1rem",
            }}
          >
            <BsArrowBarLeft
              size={30}
              style={{ fill: "#FFFFFF60" }}
              onClick={() => setHide(!hide)}
              className={`${hide && width > 600 ? classes.display : classes.label_none
                }`}
            />
          </Box>
        </Box>
      </Box>



      {checked && width < 600 && (
        <Box
          xs={6}
          className={classes.box}
          style={{ float: "left", padding: 3 }}
        >
          <AiOutlineMenu
            size="30"
            color="black"
            onClick={() => setchecked(!checked)}
          />
        </Box>
      )}
      <div
        style={{
          backgroundColor: "#F8F9FA",
          minHeight: "calc(100vh - 62px)",
          paddingLeft:
            width > 600 && hide === true
              ? "240px"
              : "1%" && width > 600 && hide === false
                ? "110px"
                : "1%",
          paddingRight: "10px",
          position: "relative"
        }}
      >
        {children}
      </div>

    </Box>
  );
};

export default NavLayout;
