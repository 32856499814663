import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: "25px"
  }
}));

const Form = ({ children, ...props }) => {
  const styles = useStyles();
  return (
      <form className={styles.form} noValidate {...props}>
        {children}
      </form>
  );
};

export default Form;
