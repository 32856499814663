import React, { forwardRef } from "react";
import { TextField, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  label: {
    color: "#00000055",
    fontSize: "14px",
  },
  input: {
    fontSize: "14px",
  },
});

const TextFieldCustom = (
  {
    name,
    defaultValue,
    label,
    type,
    error,
    readOnly,
    labelCustom,
    placeHolder,
    InputProps,
    onChange,
    required,
  },
  ref
) => {
  const classes = useStyles();

  return (
    <Grid container>
      {labelCustom && (
        <Grid item xs={12} align="left">
          <Typography component="span" className={classes.label}>
            {labelCustom}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          error={!!error ?? false}
          variant="outlined"
          label={label}
          name={name}
          type={type}
          defaultValue={defaultValue}
          inputRef={ref}
          required={required}
          fullWidth
          helperText={error?.message}
          disabled={readOnly}
          InputProps={{
            ...InputProps,
            className: [classes.input, InputProps?.className ?? ""].join(" "),
          }}
          size="medium"
          placeholder={placeHolder}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default forwardRef(TextFieldCustom);
