import React, { useEffect, useState } from "react"
import CustomHeader from "../../../Components/Header/CustomHeader"
import { useParams, useHistory } from "react-router-dom"
import { getQuote } from "../../../Axios/Call/ClientCredential"
import { useForm, Controller, useFieldArray } from "react-hook-form"
import { Grid, Typography, Button, Box } from "@material-ui/core"
import CommonDateTimePicker from "../../../Components/FormComponents/CommonDateTimePicker"
import CommonTextField from "../../../Components/FormComponents/CommonTextField"
import CommonSelect from "../../../Components/FormComponents/CommonSelect"
import { useSelector } from "react-redux"
import Back from "../../../Components/Commons/Back/Back"
import _ from "lodash"
import { upperCaseFirst } from "../../../utils/utils"
import { BsFillCheckSquareFill } from "react-icons/bs"
import { ImCheckboxUnchecked } from "react-icons/im"
import { MdHealthAndSafety } from "react-icons/md"
import { GiHealthIncrease } from "react-icons/gi"
import { BiPackage } from "react-icons/bi"
import { AiOutlineEuroCircle } from "react-icons/ai"
import QuoteDocuments from "../../../Components/QuoteDocuments/QuoteDocuments"
import { MdPersonOutline } from "react-icons/md"
import {BiNotepad} from "react-icons/bi"
import { authApiAxios as axios } from "../../../Axios/AxiosInstance"
import MonochromePhotosIcon from '@mui/icons-material/MonochromePhotos';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const ContratIndividuelSingle = () => {

  const itemData = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
    },
    {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
    },
    {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
    },
    {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
    },
    {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
    },
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
    },
    {
      img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
      title: 'Tomato basil',
    },
    {
      img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
      title: 'Sea star',
    },
    {
      img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
      title: 'Bike',
    },
  ];

  
  const params = useParams()
  const history = useHistory()
  const [data, setdata] = useState([])
  const [names, setNames] = useState({
    product: "Inconnu",
    pack: "Inconnu",
    garanties: [],
  })
  const [price, setPrice] = useState(0)
  const [accept, setaccept] = useState(false)

  const [noteContent, setNoteContent] = useState(null)
  const [numeroAdherent, setNumeroAdherent] = useState(null)
  const [numberQuote, setNumberQuote] = useState(null)
  // accepte de recevoir les informations sur les contrats MBA Mutuelle

  const packs = useSelector((state) => state.user.packs)
  const garanties = useSelector((state) => state.user.garanties)
  const produits = useSelector((state) => state.user.products)
  const modalites = useSelector((state) => state.user.modalites)
  const civilites = useSelector((state) => state.user.civilites)
  const id_type = useSelector((state) => state.user.id_type)
  const periodicites = useSelector((state) => state.user.periodicites)
  const dates_prelevement = useSelector((state) => state.user.dates_prelevement)
  const regimes = useSelector((state) => state.user.regimes)

  const [taux, setTaux] = useState(0)

  const { register, handleSubmit, errors, control, setValue, getValues, watch } = useForm({
    mode: "onBlur",
    reValidateMode: "all",
    shouldDirty: true,
    defaultValues: {
      starting_date:"",
      civilite: "",
      firt_name: "",
      last_name: "",
      birth_date: null,
      birth_name: "",
      ssn: "",
      joint_first_name: "",
      joint_last_name: "",
      joint_birth_date: null,
      joint_regime: "",
      joint_ssn: "",
      enfant: [],
      id_type: "",
      id_number: "",
      delivery_date: null,
      delivery_city: "",
      address: "",
      zip_code: "",
      city: "",
      email: "",
      phone: "",
      bank_account_owner: "",
      iban: "",
      periodicite: "",
      bic: "",
      modalite: "",
      info_contact: false,
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "enfant",
  })

  useEffect(() => {

    let nameProduct = "..."
    let idPro = data?.product_id
    nameProduct = produits?.find((p) => idPro === p.id)?.description
    // -------------
    let namePack = "..."
    if (_.some(data.documents, "package_id")) {
      namePack = packs?.find((p) => p.id === data.documents?.find((e) => e)?.package_id)?.name
    } else if (_.some(data.packages, "package_id")) {
      namePack = packs?.find((p) => p.id === data.packages.find((e) => e)?.package_id)?.name
    }
    // -----------
    let ids = [] // lsite des ID des garanties du client
    let listGaranties = []
    if (_.some(data.documents, "package_id")) {
      ids = data.documents?.map((p) => p.guarantee_id)
    } else if (_.some(data.packages, "package_id")) {
      ids = data.packages?.map((p) => p.guarantee_id)
    }
    garanties?.forEach((g) => {
      ids?.forEach((id) => {
        if (id === g.id) {
          listGaranties.push({ id: g.id, name: g.name, nature: g.nature })
        }
      })
    })
    setNames({
      product: nameProduct,
      pack: namePack,
      garanties: listGaranties,
    })

  }, [produits, data, packs, garanties])

  const fetchQuote = () => {
    getQuote(params.id)
      .then((res) => {
        console.log("data =>", res.data)
        const tmp = res.data
        // findNames(tmp)
        setdata(tmp)
        setNumberQuote(tmp.number_avenant ? tmp.numero_devis + '.' + tmp.number_avenant + ' (Avenant)' : tmp.numero_devis);
        setaccept(tmp.info_contact === "1" ? true : false)
        setPrice(res?.data?.total_price)
        setValue("civilite", res.data.civilite)
        setValue("starting_date", res.data.starting_date)
        setValue("firt_name", res.data.firt_name)
        setValue("last_name", res.data.last_name)
        setValue("ssn", res.data.ssn)
        setValue("birth_date", res.data.birth_date)
        setValue("birth_name", res.data.birth_name)
        setValue("joint_regime", res.data.joint_regime)
        setValue("joint_birth_date", res.data.joint_birth_date)
        setValue("joint_ssn", res.data.joint_ssn)
        setValue("joint_first_name", res.data.joint_first_name)
        setValue("joint_last_name", res.data.joint_last_name)
        setValue("enfant", res.data.childrens)
        setValue("id_type", res.data.id_type)
        setValue("id_number", res.data.id_number)
        setValue("delivery_date", res.data.delivery_date)
        setValue("delivery_city", res.data.delivery_city)
        setValue("address", res.data.address)
        setValue("zip_code", res.data.zip_code)
        setValue("city", res.data.city)
        setValue("phone", res.data.phone)
        setValue("email", res.data.email)
        setValue("bank_account_owner", res.data.bank_account_owner_payment)
        setValue("iban", res.data.iban_payment)
        setValue("bic", res.data.bic_payment)
        setValue("periodicite", res.data.periodicite)
        setValue("modalite", res.data.modalite)
        setValue("date_prelevement", res.data.date_prelevement)
        setNumeroAdherent(res.data.numero_adherent)
        setNoteContent(res.data.note)
        setTaux(res.data.taux)
      
        console.log("data =>", data);
      })
  
      .catch((error) => console.log(error))
  }

  useEffect(fetchQuote, [])

  useEffect(() => {
    setValue("bank_account_owner", data.bank_account_owner_payment)
    setValue("iban", data.iban_payment)
    setValue("bic", data.bic_payment)
    setValue("periodicite", data.periodicite)
    setValue("date_prelevement", data.date_prelevement)
  }, [watch("modalite")])

  function saveNumAdherent(e) {
    axios.put(`admin/subscription/${params.id}/num_adherent`, { numero_adherent: e.target.value })
  }


  return (
    <div style={{ paddingTop: 10 }}>
  <CustomHeader icon={<Back />} pageName={`Contrat ${numberQuote}`} userName={"Username here"} />


      <Box className="spacer2" />

      {/* infos & link */}

      {/* HERE */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} className="containerImportant" style={{ maxHeight: "120px" }}>
          {" "}
          <MdHealthAndSafety size={30} style={{ fill: "#FFF", marginRight: ".5rem" }} />
          <Typography variant="h4" style={{ color: "white", textAlign: "center" }}>
            Produit
            <Typography variant="h6" style={{ color: "white", textAlign: "center" }}>
              <strong>{names.product}</strong>
            </Typography>{" "}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3} className="containerImportant" style={{ maxHeight: "120px" }}>
          <GiHealthIncrease size={30} style={{ fill: "#FFF", marginRight: ".5rem" }} />
          <Typography variant="h4" style={{ color: "white", textAlign: "center" }}>
            Garanties
            <br />
            {names.garanties?.map((g) => {
              switch (g.nature) {
                case "base":
                  return (
                    <Typography variant="h6" style={{ color: "white", textAlign: "center" }}>
                      {upperCaseFirst(g.nature)} : <strong>{g.name}</strong>
                    </Typography>
                  )
                case "renfort":
                  return (
                    <Typography variant="h6" style={{ color: "white", textAlign: "center" }}>
                      {upperCaseFirst(g.nature)} : <strong>{g.name}</strong>
                    </Typography>
                  )
                case "option":
                  return (
                    <Typography variant="h6" style={{ color: "white", textAlign: "center" }}>
                      {upperCaseFirst(g.nature)} : <strong>{g.name}</strong>
                    </Typography>
                  )
                  case "supp":
                    return (
                      <Typography
                        variant="h6"
                        style={{ color: "white", textAlign: "center" }}
                      >
                        {upperCaseFirst('Produit complémentaire')} :{" "}
                        <strong>{g.description}</strong>
                      </Typography>
                    ); 

                default:
                  return (
                    <Typography variant="h3" style={{ color: "white", textAlign: "center" }}>
                      ...
                    </Typography>
                  )
              }
            })}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          className="containerImportant"
          style={{ backgroundColor: "#1278B2", maxHeight: "120px" }}
        >
          <AiOutlineEuroCircle size={30} style={{ fill: "#FFF", marginRight: ".5rem" }} />
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "center" }}
            direction="column"
            alignItems="center"
            justify="center"
          >
            Cotisation
            <br />
            <strong>{Math.round(price * 100) / 100}</strong>€ /mois
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          className="containerImportant"
          style={{ backgroundColor: "#000", maxHeight: "120px" }}
        >
          <AiOutlineEuroCircle size={30} style={{ fill: "#FFF", marginRight: ".5rem" }} />
          <Typography
            variant="h4"
            style={{ color: "white", textAlign: "center" }}
            direction="column"
            alignItems="center"
            justify="center"
          >
            Taux
            <br />
            <strong>{taux}</strong>

          </Typography>
        </Grid>



       


        <Grid item xs={12} lg={5}>
          
          <Box
            marginBottom={4}
            style={{
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              padding: 30,
              borderRadius: 10,
            }}
          >


<Typography variant="h3" style={{ textAlign: "left" }}>
              <MdPersonOutline size={30} style={{ marginRight: 10, marginBottom: "-5px" }} />
              Numéro adhérent
            </Typography>
     
            <div
              contentEditable
              suppressContentEditableWarning
              style={{
                border: "1px solid #ccc",
                padding: 10,
                borderRadius: 5,
                marginTop: 10,
                color: "#000",
                textAlign: "left",
                minHeight: 50,
                maxHeight: 50,
                marginBottom: 12,
                lineHeight: "27px",
              }}
              onBlur={(e) => {
                setNumeroAdherent(e.target.innerHTML)
                axios.put(`admin/subscription/${params.id}/num_adherent`, { numero_adherent: e.target.innerHTML })

              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                }
              }}
              dangerouslySetInnerHTML={{ __html: numeroAdherent }}
            />


            <Typography variant="h3" style={{ textAlign: "left" }}>
              <BiNotepad size={30} style={{ marginRight: 10, marginBottom: "-5px" }} />
              Notes
            </Typography>
            {/* Content editable div that edits notes */}
            <div
              contentEditable
              suppressContentEditableWarning
              style={{
                border: "1px solid #ccc",
                padding: 10,
                borderRadius: 5,
                marginTop: 10,
                color: "#000",
                textAlign: "left",
                minHeight: 70,
              }}
              onBlur={(e) => {
                setNoteContent(e.target.innerHTML)
                axios.put(`admin/subscription/${params.id}/notes`, { note: e.target.innerHTML })
              }}
              dangerouslySetInnerHTML={{ __html: noteContent }}
            />
           {/* <Typography variant="h3" style={{ textAlign: "left", marginTop:"10px" }}>
              <MonochromePhotosIcon size={30} style={{ marginRight: 10, marginBottom: "-5px" }} />
              Photos associées
            </Typography>
   
            <div
              contentEditable
              suppressContentEditableWarning
              style={{
                border: "1px solid #ccc",
                padding: 10,
                borderRadius: 5,
                marginTop: 10,
                color: "#000",
                textAlign: "left",
                minHeight: 70,
              }}
              onBlur={(e) => {
                setNoteContent(e.target.innerHTML)
                axios.put(`admin/subscription/${params.id}/notes`, { note: e.target.innerHTML })
              }}
              dangerouslySetInnerHTML={{ __html: noteContent }}
            />*/}
          </Box>
          {data && <QuoteDocuments quote={data} onDocumentUploaded={fetchQuote} />}
        </Grid>


       


        {/* form */}
        <Grid item xs={12} lg={6} className="box-shadow">





          {" "}
          <form style={{ marginTop: 2, textAlign: "left" }} noValidate>
            <Typography variant="h3" style={{ marginTop: 20, textAlign: "left" }}>
              <MdPersonOutline size={30} style={{ marginRight: 10, marginBottom: "-5px" }} />
              Informations
            </Typography>

    
            <Grid item xs={12} md={12} style={{paddingTop:20}}>
                <Controller
                  name={"starting_date"}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={null}
                  render={(field) => (
                    <CommonDateTimePicker
                      field={field}
                      label={"Date d'effet du contrat"}
                      error={errors.delivery_date}
              
                    />
                  )}
                />
              </Grid>

            <Box className="spacer2" />

            <Grid container spacing={2}>
              <Grid item xs={2} md={2}>
                <Controller
                  control={control}
                  name="civilite"
                  rules={{ required: true }}
                  render={(field) => {
                    return (
                      <CommonSelect
                        field={field}
                        error={errors.civilite}
                        label={"Civilité"}
                        options={civilites}
                        disabled={true}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={5} md={3}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="last_name"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Nom"
                        error={errors.last_name}
                        InputProps={{ disabled: true }}
                        color={"black"}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={5} md={3}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="firt_name"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Prénom"
                        error={errors.firt_name}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={6} md={4}>
                <Controller
                  name={"birth_date"}
                  control={control}
                  render={(field) => (
                    <CommonDateTimePicker
                      field={field}
                      name={"birth_date"}
                      error={errors.birth_date}
                      label={"Date de naissance"}
                      disabled={true}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} md={3}>
                <Controller
                  control={control}
                  rules={{}}
                  name="birth_name"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Nom de naissance"
                        error={errors.birth_name}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12} md={9}>
                <Controller
                  control={control}
                  rules={{}}
                  name="ssn"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type="number"
                        field={field}
                        label="Numéro de sécurité sociale"
                        error={errors.ssn}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
            </Grid>

            <Box className="spacer2" />
            <Typography variant="h4">Pièce d'identité</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name="id_type"
                  rules={{ required: true }}
                  render={(field) => {
                    return (
                      <CommonSelect
                        field={field}
                        error={errors.id_type}
                        label={"Type de pièce"}
                        options={id_type}
                        disabled={true}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  rules={{ valueAsNumber: true }}
                  name="id_number"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"text"}
                        field={field}
                        label="Numéro de pièce d'identité"
                        error={errors.id_number}
                        InputProps={{ disabled: true }}
                        readOnly={true}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name={"delivery_date"}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={null}
                  render={(field) => (
                    <CommonDateTimePicker
                      field={field}
                      label={"Délivrée le"}
                      error={errors.delivery_date}
                      disabled={true}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="delivery_city"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Délivré à"
                        error={errors.delivery_city}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
            </Grid>

            <Box className="spacer2" />

            {data?.joint_birth_date && (
              <>
                <Typography variant="h4">
                  Conjoint
                  {"  "}{" "}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="joint_first_name"
                      render={(field) => {
                        return (
                          <CommonTextField
                            field={field}
                            label="Prénom"
                            error={errors.firt_name}
                            InputProps={{ disabled: true }}
                          />
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="joint_last_name"
                      render={(field) => {
                        return (
                          <CommonTextField
                            field={field}
                            label="Nom"
                            error={errors.joint_last_name}
                            InputProps={{ disabled: true }}
                          />
                        )
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Controller
                      name={"joint_birth_date"}
                      control={control}
                      rules={{}}
                      defaultValue={getValues("joint_birth_date")}
                      render={(field) => (
                        <CommonDateTimePicker
                          field={field}
                          name={"joint_birth_date"}
                          label={"Date de naissance"}
                          error={errors.joint_birth_date}
                          required={true}
                          disabled={true}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      name="joint_regime"
                      defaultValue={getValues("joint_regime")}
                      render={(field) => (
                        <CommonSelect label="Régime" field={field} options={regimes} disabled={true} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      rules={{}}
                      name="joint_ssn"
                      render={(field) => {
                        return (
                          <CommonTextField
                            type="number"
                            field={field}
                            label="Numéro de sécurité sociale"
                            error={errors.joint_ssn}
                            InputProps={{ disabled: true }}
                          />
                        )
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Box className="spacer2" />

            {data?.childrens?.map((child, index) => {
              return (
                <>
                  <Typography variant="h4">
                    Enfant {index + 1}
                    {"  "}{" "}
                  </Typography>

                  <Grid
                    container
                    spacing={2}
                    key={child.id}
                    // style={{ margin: ".8rem 0 .8rem .8rem" }}
                  >
                    <Grid item xs={6} md={4}>
                      <Controller
                        name={`enfant[${index}].lastname`}
                        control={control}
                        defaultValue={child.surname}
                        render={(field) => (
                          <CommonTextField field={field} label={"Nom"} InputProps={{ disabled: true }} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name={`enfant[${index}].firt_name`}
                        control={control}
                        defaultValue={child.name}
                        render={(field) => (
                          <CommonTextField field={field} label={"Prénom"} InputProps={{ disabled: true }} />
                        )}
                      />
                    </Grid>

                    <Grid item xs={6} md={4}>
                      <Controller
                        name={`enfant[${index}].birth_date`}
                        control={control}
                        defaultValue={child.birth_date}
                        render={(field) => (
                          <CommonDateTimePicker
                            field={field}
                            label={"Date de naissance"}
                            InputProps={{ disabled: true }}
                            disabled={true}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Controller
                        control={control}
                        name={`enfant[${index}].regime`}
                        defaultValue={child.regime}
                        render={(field) => (
                          <CommonSelect label="Régime" field={field} options={regimes} disabled={true} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        control={control}
                        rules={{}}
                        name={`enfant[${index}].ssn`}
                        defaultValue={child.ssn}
                        render={(field) => {
                          return (
                            <CommonTextField
                              type="number"
                              field={field}
                              label="Numéro de sécurité sociale"
                              InputProps={{ disabled: true }}
                            />
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )
            })}

            <Box className="spacer2" />
            <Typography variant="h4">Coordonnées</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="address"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Adresse (numéro et libellé de voie)"
                        error={errors.address}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  control={control}
                  name="zip_code"
                  render={(field) => (
                    <CommonTextField
                      type="number"
                      field={field}
                      label="Code postal"
                      name="zip_code"
                      error={errors.zip_code}
                      InputProps={{ disabled: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="city"
                  render={(field) => {
                    return (
                      <CommonTextField
                        field={field}
                        label="Ville"
                        error={errors.city}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="email"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"email"}
                        field={field}
                        label="Email"
                        error={errors.email}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="phone"
                  render={(field) => {
                    return (
                      <CommonTextField
                        type={"tel"}
                        field={field}
                        label="Téléphone"
                        error={errors.phone}
                        InputProps={{ disabled: true }}
                      />
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Box className="spacer2" />
            <Typography variant="h4">Modalité de paiement</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="modalite"
                  render={(field) => {
                    return (
                      <CommonSelect
                        field={field}
                        label={"Modalité de paiement"}
                        error={errors.modalite}
                        options={modalites}
                        disabled={true}
                      />
                    )
                  }}
                />
              </Grid>
              {getValues("modalite") === "1" && (
                <Grid item xs={12} md={6}>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="date_prelevement"
                    render={(field) => {
                      return (
                        <CommonSelect
                          field={field}
                          label={"Prélèvement le"}
                          options={dates_prelevement}
                          error={errors.date_prelevement}
                          disabled
                          InputProps={{ disabled: true }}
                        />
                      )
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Box className="spacer2" />

            {getValues("modalite") === "1" && (
              <>
                <Typography variant="h4">Coordonnées bancaires</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="bank_account_owner"
                      render={(field) => {
                        return (
                          <CommonTextField
                            field={field}
                            label="Titulaire du compte"
                            error={errors.bank_account_owner}
                            InputProps={{ disabled: true }}
                          />
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      name="iban"
                      render={(field) => {
                        return (
                          <CommonTextField
                            field={field}
                            label="IBAN"
                            error={errors.iban}
                            InputProps={{ disabled: true }}
                          />
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      name="periodicite"
                      render={(field) => {
                        return (
                          <CommonSelect
                            field={field}
                            label={"Périodicité de prélèvement"}
                            error={errors.periodicite}
                            options={periodicites}
                            disabled={true}
                          />
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      name="bic"
                      render={(field) => {
                        return (
                          <CommonTextField
                            field={field}
                            label="BIC"
                            error={errors.bic}
                            InputProps={{ disabled: true }}
                          />
                        )
                      }}
                    />
                  </Grid>
                </Grid>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "#C31D23",
                    fontStyle: "italic",
                    marginTop: ".5rem",
                  }}
                ></Typography>

                <Box className="spacer2" />
                <Grid item xs={12} md={12}>
                  {accept === true ? (
                    <Box style={{ display: "flex", alignItems: "middle" }}>
                      <BsFillCheckSquareFill style={{ height: "20px", width: "20px" }} fill={"#C31D23"} />
                      <Typography variant="body1" style={{ marginLeft: "8px", paddingTop: "1px" }}>
                        Accepte de recevoir les informations sur les contrats MBA Mutuelle
                      </Typography>
                    </Box>
                  ) : (
                    <Box style={{ display: "flex", alignItems: "middle" }}>
                      <ImCheckboxUnchecked style={{ height: "20px", width: "20px" }} fill={"#C31D23"} />
                      <Typography variant="body1" style={{ marginLeft: "8px", paddingTop: "1px" }}>
                        N'accepte pas de recevoir les informations sur les contrats MBA Mutuelle
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Box className="spacer2" />
              </>
            )}

            <Grid container spacing={2}></Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  )
}

export default ContratIndividuelSingle
