import React, { useCallback, useEffect, useState, useContext } from "react"
import { SearchContextCC,EtatContextCC,DebutContextCC,FinContextCC,CourtierContextCC } from "../../../Context/Search/SearchDevisContratsContext"

import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import { AiOutlineEye } from "react-icons/ai"
import { BsSearch } from "react-icons/bs"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import CommonDateTimePicker from "../../../Components/FormComponents/CommonDateTimePicker"
import { changeQuote, getQuotes } from "../../../Axios/Call/ClientCredential"
import moment from "moment"
import "moment/locale/fr"
import _ from "lodash"

import CommonSelect from "../../../Components/FormComponents/CommonSelect"
import EnhancedTableHead from "../../../Components/Table/EnhancedTableHead"
import { getComparator, stableSort } from "../../../utils/tableSort"
import exportToCsv from "../../../utils/covertToCsv"
import { defaultLabelDisplayedRows } from "../../../utils/utils"
import LoaderCircular from "../../../Components/Commons/Loading/LoaderCircular"

moment.locale("fr")

const Collectif = () => {
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [broker, setbroker] = useState("all")
  const [status, setstatus] = useState("all")
  const [starting_date, setstarting_date] = useState(null)
  const [ending_date, setending_date] = useState(null)
  const [rows, setRows] = useState([])
  const [filteredRows, setFilteredRows] = useState([])
  const [changeStatus, setChangeStatus] = useState(null)
  const brokers = useSelector((state) => state.user.brokers)
  const contrat_status = useSelector((state) => state.user.contrat_status)
  const garanties = useSelector((state) => state.user.garanties)
  const products = useSelector((state) =>
    state.user.products.map((p) => {
      return { id: p.id, value: p.description }
    })
  )
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("")

  const history = useHistory()

  const filterByDates = (data, start, end) => {
    return data.filter(
      (q) => (debutTermCC !== null ? q.date >= start : true) && (finTermCC !== null ? q.date <= end : true)
    )
  }

  const exportCsv = useCallback(() => {
    const headerFormatter = () => [
      "N°",
      "Entreprise",
      "Date effet",
      "Code Postal",
      "Téléphone",
      "Email",
      "Courtier",
      "Date du devis",
      "Produit",
      "Garantie",
      "Salarié(s)",
    ]
    const formatter = (quote) => [
      quote.number,
      quote.numberQuote,
      quote.entreprise,
      moment(quote.starting_date).format("DD/MM/yyyy"),
      quote.zip,
      quote.phone,
      quote.email,
      quote.broker,
      moment(quote.date).format("DD/MM/yyyy"),
      quote.product,
      quote.warranty,
      Math.round(quote.CA * 12),
      quote.nbPers,
    ]
    exportToCsv({
      formatter,
      headerFormatter,
      data: filteredRows,
      filename: "Contrats collectifs.csv",
    })
  }, [filteredRows])

  function filterByBroker(data, id) {
    switch (id) {
      case "all":
        return data
      default:
        return data.filter((q) => q.broker_id * 1 === id * 1)
    }
  }

  function filterByContratStatus(data, status) {
    switch (status) {
      case "all":
        return data
      default:
        return data.filter((q) => q.status === status)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const onChangeStatusHandler = (status) => {
    changeQuote(changeStatus, {
      devis_status: undefined,
      contract_status: status,
    })
      .then((res) => {
        console.log(res)
        setChangeStatus(null)
      })
      .catch((error) => console.log(error))
  }

  const createData = (
    number,
    numberQuote,
    entreprise,
    starting_date,
    zip,
    phone,
    email,
    broker,
    broker_id,
    date,
    product,
    warranty,
    taux,
    CA,
    nbPers,
    status,
    date_status,
    status_updated_by,
    index,
    numero_adherent,
    all
  ) => {
    return {
      number,
      numberQuote,
      entreprise,
      starting_date,
      zip,
      phone,
      email,
      broker,
      broker_id,
      date,
      product,
      warranty,
      taux,
      CA,
      nbPers,
      status,
      date_status,
      status_updated_by,
      index,
      numero_adherent,
      all,
    }
  }

  const {searchTermCC, setSearchTermCC } = useContext(SearchContextCC);
  const {etatTermCC, setEtatTermCC } = useContext(EtatContextCC);
  const {debutTermCC, setDebutTermCC} = useContext(DebutContextCC);
  const {finTermCC, setFinTermCC} = useContext(FinContextCC);
  const {courtierTermCC, setCourtierTermCC} = useContext(CourtierContextCC);

  const handlesetsearchValue = (val) => {
    setSearchTermCC(val);
  }

  const handleSetCourtierValue = (val) => {
    setCourtierTermCC(val);
  }

  const handleSetEtatValue = (val) => {
    setEtatTermCC(val);
  }

  const handleSetDebutValue = (val) => {
    setDebutTermCC(val);
  }

  const handleSetFinValue = (val) => {
    setFinTermCC(val);
  }

  const findProductName = (quote) => {
    let name = products?.filter((p) => quote.product_id === p.id)[0]?.value
    return name || `...`
  }

  const calculateTotal = (data, PMSS, effectif) => {
    // * par effectif ?
    let total = 0
    if (!!data && data !== "000") {
      for (const [value] of Object?.entries(data)) {
        total += value
      }
    }
    return Math.round((total / 100) * PMSS * 12 * effectif).toFixed(0)
  }

  const findBaseGarantieName = (quote) => {
    let ids = [] // lsite des ID des garanties du client
    let name = "..."
    if (_.some(quote.documents, "package_id")) {
      ids = quote.documents?.map((p) => p.guarantee_id)
    } else if (_.some(quote.packages, "package_id")) {
      ids = quote.packages?.map((p) => p.guarantee_id)
    }
    garanties?.forEach((g) => {
      ids?.forEach((id) => {
        if (id === g.id && g.nature === "base") {
          name = g.name
        }
      })
    })
    return name
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const headCells = [
    {
      id: "See",
      label: "Voir",
      isOrderable: false,
    },
    {
      id: "status",
      label: "État",
      isOrderable: true,
    },
    {
      id: "number",
      label: "N°",
      isOrderable: true,
    },
    {
      id: "entreprise",
      label: "Entreprise",
      isOrderable: true,
    },
    {
      id: "starting_date",
      label: "Date effet",
      isOrderable: true,
    },
 
 
    {
      id: "zip",
      label: "Code Postal",
      isOrderable: true,
    },
    {
      id: "phone",
      label: "Téléphone",
      isOrderable: true,
    },
    {
      id: "email",
      label: "Email",
      isOrderable: true,
    },
    {
      id: "broker",
      label: "Courtier",
      isOrderable: true,
    },

    {
      id: "product",
      label: "Produit",
      isOrderable: true,
    },
    {
      id: "warranty",
      label: "Garantie",
      isOrderable: true,
    },
    {
      id: "taux",
      label: "Commission",
      isOrderable: true,
    },
    {
      id: "nbPers",
      label: "Salarié(s)",
      isOrderable: true,
    },
  ]

  useEffect(() => {
    getQuotes().then((res) => {
      setLoading(true)
      const data = res.data?.filter((q) => q.type === "subscription")?.filter((q) => q.company !== null)

      let tmp = data.map((q) => {
        let broker = brokers.find((broker) => broker.id === q.broker_id)

        return createData(
          q.id,
          q.number_avenant ? q.numero_devis + '.' + q.number_avenant : q.numero_devis,
          `${q.company} ${q.numero_adherent ? `(${q.numero_adherent})` : ''}`,
          q.starting_date,
          parseInt(q.zip_code),
          q.phone,
          q.email,
          `${broker?.company}`,
          q.broker_id,
          q.created_at,
          findProductName(q),
          findBaseGarantieName(q),
          q.taux,
          parseInt(calculateTotal(q.meta, 3428, q.effectif)),
          q.effectif,
          q.contract_status,
          q.date_status_contract,
          q.status_updated_by,

        )
      })
      setRows(tmp)
      setLoading(false)
    })
  }, [brokers, changeStatus])

  useEffect(() => {
    let tmp = rows
    tmp = filterByDates(tmp, debutTermCC, finTermCC)
    tmp = filterByBroker(tmp, courtierTermCC)
    tmp = filterByContratStatus(tmp, etatTermCC)
    setFilteredRows(
      tmp?.filter(
        (row) =>
          row.numberQuote.toString().toLowerCase().match(searchTermCC.toLowerCase().trim()) ||
          row.phone.toString().toLowerCase().match(searchTermCC.toLowerCase().trim()) ||
          row.zip.toString().toLowerCase().match(searchTermCC.toLowerCase().trim()) ||
          row.entreprise.toLowerCase().match(searchTermCC.toLowerCase().trim()) ||
          row.email?.toLowerCase().match(searchTermCC.toLowerCase().trim())
      )
    )
    setPage(0)
  }, [rows, searchTermCC, debutTermCC, finTermCC, courtierTermCC, etatTermCC])

  return (
    <>
      <Grid container spacing={1} style={{ margin: "1.5rem 0 1rem" }}>
        <Grid item xs={12} md={3} style={{ display: "flex", justifyContent: "start" }}>
          <TextField
            variant="outlined"
            size="medium"
            fullWidth
            placeholder="Rechercher par nom, date..."
            value={searchTermCC}
            defaultValue={searchTermCC}
            onChange={(e) => handlesetsearchValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsSearch size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid
          item
          xs={8}
          md={2}
          style={{
            display: "flex",
            justifyContent: "start",
            textAlign: "left",
          }}
        >
          <CommonSelect
            label={"Courtier"}
            name="broker"
            defaultValue={[]}
            field={{ value: courtierTermCC, onChange: handleSetCourtierValue }}
            options={[
              { id: "all", value: "Tous les courtiers" },
              ...brokers
                .sort((a, b) => (a.company <= b.company ? -1 : 1))
                .map((broker) => {
                  return {
                    id: broker.id,
                    value: `${broker.company}`,
                  }
                }),
            ]}
          />
        </Grid>

        <Grid item xs={4} md={1} style={{ display: "flex", justifyContent: "start" }}>
          <CommonSelect
            label={"État"}
            name="status"
            defaultValue={[]}
            field={{ value: etatTermCC, onChange: handleSetEtatValue }}
            options={[
              { id: "all", value: "Tous" },
              ...contrat_status.map((s) => {
                return {
                  id: s.id,
                  value: s.name,
                }
              }),
            ]}
          />
        </Grid>

        <Grid item xs={6} md={2} style={{ display: "flex", justifyContent: "start" }}>
          <CommonDateTimePicker
            field={{ value: debutTermCC, onChange: handleSetDebutValue }}
            label={"Date de début"}
            name={"starting_date"}
            required={true}
          />
        </Grid>

        <Grid item xs={6} md={2} style={{ display: "flex", justifyContent: "start" }}>
          <CommonDateTimePicker
            field={{ value: finTermCC, onChange: handleSetFinValue }}
            label={"Date de fin"}
            name={"starting_date"}
            required={true}
          />
        </Grid>

        <Grid item xs={6} md={2} style={{ display: "flex", justifyContent: "start" }}>
          <Button className="buttonGeneralStyle" fullWidth style={{ maxHeight: "53px" }} onClick={exportCsv}>
            Exporter en CSV
          </Button>
        </Grid>
      </Grid>
      {/* Tableau */}
      {loading ? <div style={{paddingTop:"100px"}}><LoaderCircular /><p>Chargement des contrats collectifs</p></div> :  <TableContainer>

        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          //number, entreprise, zip, phone, email, broker, date, warranty, CA, nbPers, index
        >
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />

          <TableBody>
            {stableSort(filteredRows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row) => (
                <TableRow
                  key={row.index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">
                    <AiOutlineEye
                      fill="rgba(0,0,0,0.72"
                      size={25}
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push(`/Contrats/Collectifs/${row.number}`)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {changeStatus === row.number ? (
                      <CommonSelect
                        field={{
                          value: row.status,
                          onChange: (status) => onChangeStatusHandler(status),
                        }}
                        options={contrat_status.map((state) => {
                          return { id: state.id, value: state.name }
                        })}
                      />
                    ) : (
                      <Box
                        style={{
                          background: contrat_status?.find((s) => s.id === row.status)?.color,
                          color: "white",
                          borderRadius: "3px",
                          padding: "0.3rem",
                          fontWeight: "500",
                          minWidth: "150px",
                        }}
                        onClick={() => setChangeStatus(row.number)}
                      >
                        {contrat_status?.find((s) => s.id === row.status)?.name}
                        <Typography style={{ color: "#DDD", fontSize: "13px" }}>
                          {row.date_status && "le " + moment(row.date_status).format("DD/MM/yyyy")}
                        </Typography>
                        <Typography style={{ color: "#DDD", fontSize: "13px" }}>
                          {row.status_updated_by && "par " + row.status_updated_by.toUpperCase()}
                        </Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row" align="left">
                    <Typography variant="body2">{row.numberQuote}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.entreprise}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2" style={{fontWeight:800}}>
                      {row.starting_date !== null ? moment(row.starting_date).format("DD/MM/yyyy") : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.zip}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.phone}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.email}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{row.broker}</Typography>
                  </TableCell>
               
                  <TableCell align="center">
                    <Typography variant="body2">{row.product}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{row.warranty}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{row.taux}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{row.nbPers}</Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>}

      <TablePagination
        component="div"
        size="medium"
        variant="outlined"
        count={filteredRows?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={() =>
          defaultLabelDisplayedRows({
            from: rowsPerPage * page + 1,
            to:
              rowsPerPage * page + rowsPerPage > filteredRows.length
                ? filteredRows.length
                : rowsPerPage * page + rowsPerPage,
            count: filteredRows?.length,
          })
        }
        labelRowsPerPage={"Lignes par page :"}
      />
    </>
  )
}

export default Collectif
